import React, { Component } from 'react';
import bindModel from '../../common/bindModel.js';
import axios from 'axios';
import lodash from 'lodash';
import { toast } from 'react-toastify';
import { Parser, Player } from 'svga';
import { Base64 } from 'js-base64';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import '../../assets/css/giftmanager.css';
import $ from 'jquery';
import ModalDeleteIconEvent from './components/ModalDeleteIconEvent';
import ban_thu from '../../assets/images/ban_thu.svg';

const defaultInputFileIcon1 = "https://s3.us-west-002.backblazeb2.com/ikara-data/images/iconInApp/cb7cb43f-090e-b4cd-6929-fc7539b27b1f.svg";
const defaultInputFileIconClick1 = "https://s3.us-west-002.backblazeb2.com/ikara-data/images/iconInApp/7d2b7702-10ee-444f-8353-0eac4d10ce7b.svg";
const defaultInputFileIcon2="https://s3.us-west-002.backblazeb2.com/ikara-data/images/iconInApp/83655dc3-54e8-0951-1156-5873d71ffb6f.svg";
const defaultInputFileIconClick2="https://s3.us-west-002.backblazeb2.com/ikara-data/images/iconInApp/911081bf-6f78-f728-d71b-f34b6e45e09f.svg";
const defaultInputFileIcon3="https://s3.us-west-002.backblazeb2.com/ikara-data/images/iconInApp/46833a5e-65b1-b010-36d6-bb9c431543e5.svg";
const defaultInputFileIconClick3="https://s3.us-west-002.backblazeb2.com/ikara-data/images/iconInApp/755ecaf0-3bc7-3b12-5c8d-8e5e838837a8.svg";
const defaultInputFileIcon4="https://s3.us-west-002.backblazeb2.com/ikara-data/images/iconInApp/a1d7fb9e-2a9c-83fd-e10e-d0a1d609ded6.svg";
const defaultInputFileIconClick4="https://s3.us-west-002.backblazeb2.com/ikara-data/images/iconInApp/a6e11992-8d4c-a458-3c7e-8804b8b8b43a.svg";
const defaultInputFileIcon5="https://s3.us-west-002.backblazeb2.com/ikara-data/images/iconInApp/b79d8416-c2fc-0f8e-1d1d-8e4a33dc1534.svg";
const defaultInputFileIconClick5="https://s3.us-west-002.backblazeb2.com/ikara-data/images/iconInApp/197efa74-6069-dba1-1d62-ca45f036a869.svg";

export default class AddIconForEventPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            text: null,
            playingStart: new Date(),
            playingEnd: "",
            inputFileIcon1: null,
            inputFileIcon2: null,
            inputFileIcon3: null,
            inputFileIcon4: null,
            inputFileIcon5: null,
            inputFileIcon6: null,
            inputFileIconAfterClick1: null,
            inputFileIconAfterClick2: null,
            inputFileIconAfterClick3: null,
            inputFileIconAfterClick4: null,
            inputFileIconAfterClick5: null,
            inputFileIconAfterClick6: null,
            language: null,
            nameEvent: null,
            eventId: null,
            listAnimationInApp: [],
            listIconEventInApp: [],
            iconEvents: [],
            color: "btn btn-secondary",
            index: null,
            nameIcon: null,
            idIcon: null
        }

        this.playingStartDate = this.playingStartDate.bind(this);
        this.playingEndDate = this.playingEndDate.bind(this);
        this.uploadThumbnailToS3 = this.uploadThumbnailToS3.bind(this);
        this.uploadFileJson = this.uploadFileJson.bind(this);
        this.addEvent = this.addEvent.bind(this);
        this.showEventInfo = this.showEventInfo.bind(this);
        this.editIconForEvent = this.editIconForEvent.bind(this);
        this.updateEvent = this.updateEvent.bind(this);
        this.clearEvent = this.clearEvent.bind(this);
        this.callModalDelete = this.callModalDelete.bind(this);
    }

    componentWillMount() {
        this.showEventInfo();
    }
    componentDidUpdate() {
        console.log('componentDidUpdate');
      }
    componentDidMount() {
        $("#modalUpdateGift").on("hidden.bs.modal", function () {
            this.setState({
                gift: null
            });
        }.bind(this));

        console.log("Did mount")
        $("#modalShowGift").on("hidden.bs.modal", function () {
            this.setState({
                giftShow: null
            });
        }.bind(this));

        $("#modalShowGiftAnimation").on("hidden.bs.modal", function () {
            this.setState({
                giftShow: null
            });
        }.bind(this));

    }

    async loadSVGA(id, url) {
        const parser = new Parser()
        const svga = await parser.load(url)

        const player = new Player(document.getElementById(id))
        await player.mount(svga)

        player.onStart = () => console.log('onStart')

        player.start()
    }

    newGetKey(data, password, password2) {
        var listOfNumbers = "" + password2;
        for (var i = 0 ; i < data.length; i++) {
            if (data.charAt(i) >= '0' && data.charAt(i) <= '9'){
                listOfNumbers = [listOfNumbers.slice(0, 0), data.charAt(i), listOfNumbers.slice(0)].join('');
            }
        }

        var originalKey = null;
      if (listOfNumbers.length > 15) 
        originalKey = listOfNumbers.substring(0,15);
      else
        originalKey = listOfNumbers;
      return Number(originalKey) + Number(password);
    }

    playingStartDate(props) {
        this.setState({
            playingStart: props.value,
        });
    }
    playingEndDate(props) {
        this.setState({
            playingEnd: props.value
        });
    }
    
    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
    uploadThumbnailToS3(event) {
        var target = event.target;
        // if (target.files[0].size > 512999){
        //     toast.error("Kích thước file phải dưới 500kb.");
        //     return;
        // }

        const fileReader1 = new FileReader();
        fileReader1.readAsDataURL(target.files[0]);
        const namesvg = target.files[0].name;
        const targetFile = target.files[0];
        fileReader1.onload =  (fe) => {
          const image = new Image();
          image.src = fe.currentTarget.result;
          image.onload = function(ie){

            // let width = 0, height = 0;
            // width = this.naturalWidth;
            // height = this.naturalHeight;
            // if (width != height || height > 256){
            //     toast.error("Kích thước ảnh tối đa 256px và Width = Height");
            //     return;
            // }

            this.setState({
                            loading: true
                        });
                
                        var iconUrl = "";
                        var frameUrl = "";
                        var bucketName = "ikara-data/images/iconInApp";
                        var extension = namesvg.split('.').pop().toLowerCase();
                        // var extension = target.files[0].name.split('.').pop().toLowerCase();
                        var keyName = this.guid() + "." + extension;
                        var contentType = "image/svga";
                        if (extension == "png") contentType = "image/png";
                        if (extension == "svg") contentType = "image/svg";

                        if (extension !== "png" && extension !== "svg" && extension !== "svga"){
                            toast.error("Chỉ up file png, svg, svga");
                            this.setState({
                                loading: false
                            });
                            return;
                          }
                
                        var fileReader = new FileReader();
                
                        fileReader.onload = function () {
                            var fd = new FormData();
                            fd.append('file', targetFile);
                            fd.append('bucketName', bucketName);
                            fd.append('objectKey', keyName);
                            fd.append('contentType', contentType)
                            $.ajax({
                                url: global.config.apiDomain + '/web.UploadFile',
                                data: fd,
                                processData: false,
                                contentType: false,
                                type: 'POST',
                                success: async function (data) {
                                    console.log(data)
                                    this.setState({
                                        loading: false
                                    });
                
                                    iconUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                                    if(target.id == 'inputFileIcon1') {
                                        this.setState({
                                            inputFileIcon1: iconUrl
                                        });
                                        let text = await this.getAndModifySVG(iconUrl);
                                        $( "div#iconTrangChu1" ).html(function() {
                                            return text.documentElement;
                                        });
                                    } else if(target.id == 'inputFileIcon2') {
                                        this.setState({
                                            inputFileIcon2: iconUrl
                                        });
                                        let text = await this.getAndModifySVG(iconUrl);
                                        $( "div#iconKhamPha1" ).html(function() {
                                            return text.documentElement;
                                        });
                                    } else if(target.id == 'inputFileIcon3') {
                                        this.setState({
                                            inputFileIcon3: iconUrl
                                        });
                                        let text = await this.getAndModifySVG(iconUrl);
                                        $( "div#iconHatKara1" ).html(function() {
                                            return text.documentElement;
                                        });
                                    } else if(target.id == 'inputFileIcon4') {
                                        this.setState({
                                            inputFileIcon4: iconUrl
                                        });
                                        let text = await this.getAndModifySVG(iconUrl);
                                        $( "div#iconTinNhan1" ).html(function() {
                                            return text.documentElement;
                                        });
                                    } else if(target.id == 'inputFileIcon5') {
                                        this.setState({
                                            inputFileIcon5: iconUrl
                                        });
                                        let text = await this.getAndModifySVG(iconUrl);
                                        $( "div#iconToi1" ).html(function() {
                                            return text.documentElement;
                                        });
                                    }
                                    //  else if(target.id == 'inputFileIcon6') {
                                    //     this.setState({
                                    //         inputFileIcon6: iconUrl
                                    //     });
                                    // }

                                    if(target.id == 'inputFileIconAfterClick1') {
                                        this.setState({
                                            inputFileIconAfterClick1: iconUrl
                                        });
                                        let text = await this.getAndModifySVG(iconUrl);
                                        $( "div#iconTrangChu2" ).html(function() {
                                            return text.documentElement;
                                        });
                                    } else if(target.id == 'inputFileIconAfterClick2') {
                                        this.setState({
                                            inputFileIconAfterClick2: iconUrl
                                        });
                                        let text = await this.getAndModifySVG(iconUrl);
                                        $( "div#iconKhamPha2" ).html(function() {
                                            return text.documentElement;
                                        });
                                    } else if(target.id == 'inputFileIconAfterClick3') {
                                        this.setState({
                                            inputFileIconAfterClick3: iconUrl
                                        });
                                        let text = await this.getAndModifySVG(iconUrl);
                                        $( "div#iconHatKara2" ).html(function() {
                                            return text.documentElement;
                                        });
                                    } else if(target.id == 'inputFileIconAfterClick4') {
                                        this.setState({
                                            inputFileIconAfterClick4: iconUrl
                                        });
                                        let text = await this.getAndModifySVG(iconUrl);
                                        $( "div#iconTinNhan2" ).html(function() {
                                            return text.documentElement;
                                        });
                                    } else if(target.id == 'inputFileIconAfterClick5') {
                                        this.setState({
                                            inputFileIconAfterClick5: iconUrl
                                        });
                                        let text = await this.getAndModifySVG(iconUrl);
                                        $( "div#iconToi2" ).html(function() {
                                            return text.documentElement;
                                        });
                                    }
                                    //  else if(target.id == 'inputFileIconAfterClick6') {
                                    //     this.setState({
                                    //         inputFileIconAfterClick6: iconUrl
                                    //     });
                                    // }
                                }.bind(this),
                                error: function (request, status, error) {
                                    this.setState({
                                        loading: false
                                    });
                                    toast.error("Đang xảy ra lỗi, xin hãy thử lại!");
                                }.bind(this),
                            });
                    
                
                        }.bind(this);
                        fileReader.readAsArrayBuffer(targetFile);
            }.bind(this);
        }
    }

    uploadFileJson(event) {
        var target = event.target;
        const namejson = target.files[0].name;
        const targetFIle = target.files[0];
        const fileReader1 = new FileReader();
        fileReader1.readAsDataURL(target.files[0]);

        fileReader1.onload =  (fe) => {
        //   const image = new JSON();
        //   image.src = fe.currentTarget.result;
        //   image.onload = function(ie){

                this.setState({
                    loading: true
                });
        
                var iconUrl = "";
                var frameUrl = "";
                var bucketName = "ikara-data/images/iconAnimationInApp";
                // var extension = target.files[0].name.split('.').pop().toLowerCase();
                var extension = namejson.split('.').pop().toLowerCase();
                var keyName = this.guid() + "." + extension;
                var contentType = "application/json";
                if (extension == "png") contentType = "image/png";
                if (extension == "svg") contentType = "image/svg";
                if (extension == "svga") contentType = "image/svga";

                if (extension !== "json" && extension !== "png" && extension !== "svg" && extension !== "svga"){
                  toast.error("Chỉ up file json, png, svg, svga");
                  this.setState({
                    loading: false
                });
                  return;
                }
                
                var fileReader = new FileReader();
        
                fileReader.onload = function () {
                    var fd = new FormData();
                    fd.append('file', targetFIle);
                    fd.append('bucketName', bucketName);
                    fd.append('objectKey', keyName);
                    fd.append('contentType', contentType)
                    $.ajax({
                        url: global.config.apiDomain + '/web.UploadFile',
                        data: fd,
                        processData: false,
                        contentType: false,
                        type: 'POST',
                        success: async function (data) {
                            console.log("check data", data)
                            this.setState({
                                loading: false
                            });
        
                            iconUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                            console.log('frame.url: ');
                            console.log(iconUrl);
                            let iconAnimation = {nameTab: 'mySelf', nameIcon: "Tôi", urlPreview: this.state.listAnimationInApp.urlNormal, urlNormal: iconUrl, urlClick: iconUrl}; 

                            if(target.id == 'inputFileIcon6') {
                                this.setState({    
                                    inputFileIcon6: iconUrl,
                                    inputFileIconAfterClick6: iconUrl,
                                    listAnimationInApp: iconAnimation,
                                });
                            }

                            // if(target.id == 'inputFileIconAfterClick6') {
                            //     this.setState({
                            //         inputFileIconAfterClick6: iconUrl
                            //     });
                            // }
                        }.bind(this),
                        error: function (request, status, error) {
                            this.setState({
                                loading: false
                            });
                            toast.error("Đang xảy ra lỗi, xin hãy thử lại nha!");
                        }.bind(this),
                    });
        
                }.bind(this);
                fileReader.readAsArrayBuffer(targetFIle);
            // }.bind(this);
        }
    }

    async getAndModifySVG(url) {
        const response = await fetch(url);
        const str = await response.text();
        return (new window.DOMParser()).parseFromString(str, "text/xml");
                  // Find path with id="myPath" and return the d attribute
                //   .then(data => data.getElementById("myPath").getAttribute("d"))
    }

    async setIconShow(listIconInfo, i) {
        listIconInfo.map(async (icon, index) => {
            let idAppendImgSvgBeforeClick = "#iconAppendImgSvgBeforeClick" + i + index;
            let text = await this.getAndModifySVG(icon.urlNormal);
            $( idAppendImgSvgBeforeClick ).html(function() {
                return text.documentElement;
            });

            let idAppendImgSvgAfterClick = "#iconAppendImgSvgAfterClick" + i + index;
            let text1 = await this.getAndModifySVG(icon.urlClick);
            $( idAppendImgSvgAfterClick ).html(function() {
                return text1.documentElement;
            });
        });
    }
    // setAnimationShow(listAnimationInfo, i) {
    //     listAnimationInfo.map(async (animation, index) => {
    //         let idAppendAnimation = "#iconAppendAnimation" + i + index;
    //         alert(animation.urlNormal);
    //         if(animation.urlNormal && animation.urlNormal != null) {
    //             document.getElementById(idAppendAnimation).setAttribute("href", animation.urlNormal)
    //         }
    //         // $( idAppendAnimation ).html(function() {
    //         //     return animation.urlNormal;
    //         // });
    //     });
    // }


    addEvent() {
        var appSelected = $('#languageSelected :selected').val();
        let name = document.getElementById('inputTenSuKien').value;
        let timeStart = this.state.playingStart;
        let timeEnd = this.state.playingEnd;
        let listIconEventInApp = [];
        let listAnimationInApp = [];
        let iconHome;
        let iconDiscover;
        let iconKara ;
        let iconMessage;
        let iconMySelf ;
        let iconAnimation ;

        if(name == null || name == "") {
            toast.error("Nhập tên sự kiện!");
            return;
        }
        if(timeStart == null || timeStart == "" || timeEnd == null || timeEnd == "") {
            toast.error("Nhập đầy đủ thời gian!");
            return;
        }
        if(appSelected == null || appSelected == "") {
            toast.error("Chọn ngôn ngữ!");
            return;
        } 

        if((this.state.inputFileIcon6 != null || this.state.inputFileIconAfterClick6 != null)&&
            (this.state.inputFileIcon1 == null || this.state.inputFileIconAfterClick1 == null)
            &&
            (this.state.inputFileIcon2 == null || this.state.inputFileIconAfterClick2 == null)
            &&
            (this.state.inputFileIcon3 == null || this.state.inputFileIconAfterClick3 == null)
            &&
            (this.state.inputFileIcon4 == null || this.state.inputFileIconAfterClick4 == null)
            &&
            (this.state.inputFileIcon5 == null || this.state.inputFileIconAfterClick5 == null)){
                iconHome = {nameTab: 'home', nameIcon: "Trang chủ", urlPreview: defaultInputFileIcon1, urlNormal: defaultInputFileIcon1, urlClick: defaultInputFileIconClick1};
                iconDiscover = {nameTab: 'discover', nameIcon: "Khám phá", urlPreview: defaultInputFileIcon2, urlNormal: defaultInputFileIcon2, urlClick: defaultInputFileIconClick2};
                iconKara = {nameTab: 'sing', nameIcon: "Hát kara", urlPreview: defaultInputFileIcon3, urlNormal: defaultInputFileIcon3, urlClick: defaultInputFileIconClick3};
                iconMessage = {nameTab: 'message', nameIcon: "Tin nhắn", urlPreview: defaultInputFileIcon4, urlNormal: defaultInputFileIcon4, urlClick: defaultInputFileIconClick4};
                iconMySelf = {nameTab: 'mySelf', nameIcon: "Tôi", urlPreview: defaultInputFileIcon5, urlNormal: defaultInputFileIcon5, urlClick: defaultInputFileIconClick5};     
                iconAnimation = {nameTab: 'mySelf', nameIcon: "Tôi", urlPreview: this.state.inputFileIcon6, urlNormal: this.state.inputFileIcon6, urlClick: this.state.inputFileIconAfterClick6};     
        }else{
            if(this.state.inputFileIcon1 == null || this.state.inputFileIconAfterClick1 == null) {
                toast.error("Chọn đầy đủ Icon Trang chủ!");
                return;
            }
            if(this.state.inputFileIcon2 == null || this.state.inputFileIconAfterClick2 == null) {
                toast.error("Chọn đầy đủ Icon Khám phá!");
                return;
            }
            if(this.state.inputFileIcon3 == null || this.state.inputFileIconAfterClick3 == null) {
                toast.error("Chọn đầy đủ Icon Hát Kara!");
                return;
            }
            if(this.state.inputFileIcon4 == null || this.state.inputFileIconAfterClick4 == null) {
                toast.error("Chọn đầy đủ Icon Tin nhắn!");
                return;
            }
            if(this.state.inputFileIcon5 == null || this.state.inputFileIconAfterClick5 == null) {
                toast.error("Chọn đầy đủ Icon Tôi!");
                return;
            }
            // if(this.state.inputFileIcon6 == null || this.state.inputFileIconAfterClick6 == null) {
            //     toast.error("Chọn đầy đủ Icon Animation!");
            //     return;
            // }
            iconHome = {nameTab: 'home', nameIcon: "Trang chủ", urlPreview: this.state.inputFileIcon1, urlNormal: this.state.inputFileIcon1, urlClick: this.state.inputFileIconAfterClick1};
            iconDiscover = {nameTab: 'discover', nameIcon: "Khám phá", urlPreview: this.state.inputFileIcon2, urlNormal: this.state.inputFileIcon2, urlClick: this.state.inputFileIconAfterClick2};
            iconKara = {nameTab: 'sing', nameIcon: "Hát kara", urlPreview: this.state.inputFileIcon3, urlNormal: this.state.inputFileIcon3, urlClick: this.state.inputFileIconAfterClick3};
            iconMessage = {nameTab: 'message', nameIcon: "Tin nhắn", urlPreview: this.state.inputFileIcon4, urlNormal: this.state.inputFileIcon4, urlClick: this.state.inputFileIconAfterClick4};
            iconMySelf = {nameTab: 'mySelf', nameIcon: "Tôi", urlPreview: this.state.inputFileIcon5, urlNormal: this.state.inputFileIcon5, urlClick: this.state.inputFileIconAfterClick5};
            iconAnimation = {nameTab: 'mySelf', nameIcon: "Tôi", urlPreview: this.state.inputFileIcon6, urlNormal: this.state.inputFileIcon6, urlClick: this.state.inputFileIconAfterClick6};    
        }  


        listIconEventInApp.push(iconHome);
        listIconEventInApp.push(iconDiscover);
        listIconEventInApp.push(iconKara);
        listIconEventInApp.push(iconMessage);
        listIconEventInApp.push(iconMySelf);

        listAnimationInApp.push(iconAnimation);

        let json = {
            nameEvent: name,
            timeStart: timeStart,
            timeEnd: timeEnd,
            language: appSelected,
            listIconEventInApp: listIconEventInApp,
            listAnimationInApp: listAnimationInApp
        };
        const jsonBase64 = Base64.encode(JSON.stringify(json));
        var pass = this.newGetKey(jsonBase64, 7123358024, 3564);
        var keys = jsonBase64 + "-" + pass;
        var url = global.config.apiDomain + "/web.v5.AddIconEvent?parameters=" + keys;
        axios.post(url, json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.data.status == "OK") {
                toast.success(res.data.message);
                this.showEventInfo();
            } else {
                toast.error(res.data.message);
            }
        }, err => {
            console.log("err nen set tam5: ");
            console.log(err);
            toast.error("Đã có lỗi xảy ra, vui lòng thử lại!");
        });
        this.clearEvent();
    }

    showEventInfo() {
        axios.post(global.config.apiDomain + '/web.v5.GetIconEventList')
            .then(res => {
                const sortedData = res.data.sort((a, b) => b.timeStart - a.timeStart);

                this.setState({
                    iconEvents: sortedData,
                });
            });
        // let listIconEventInApp = [
        //     {urlNormal: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/iconInApp/b107d90e-ba5e-5159-4c7c-ef93993c4857.svg",
        //     urlClick: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/iconInApp/8714fd5c-34d4-6641-0bca-ca6bd812c280.svg"},
        //     {urlNormal: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/iconInApp/41274b1b-2b3a-32a7-91d0-b8b2b5835b84.svg",
        //     urlClick: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/iconInApp/125e324b-87e0-bb88-dd31-29020da337f7.svg"}
        // ];
        // let listAnimationInApp = [{urlNormal: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/iconAnimationInApp/80f95081-a7f6-d77a-ecd6-de95fd434a21.json"}];
        // let listAnimationInApp2 = [{urlNormal: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/iconAnimationInApp/80f95081-a7f6-d77a-ecd6-de95fd434a22.json"}];
        
        //  const iconEvents = [
        //     {eventId: 1,listAnimationInApp: listAnimationInApp, listIconEventInApp: listIconEventInApp, nameEvent: "Không tên", timeStart: 1669859836759, timeEnd: 1669910092000, language: 'vi'},
        //     {eventId: 2,listAnimationInApp: listAnimationInApp2, listIconEventInApp: listIconEventInApp, nameEvent: "Test 123", timeStart: 1672365436759, timeEnd: 1669859836759, language: 'en.yokara'}
        // ];
        // this.setState({
        //     iconEvents: iconEvents
        // });
    }

    editIconForEvent(event){
        $(function() {
            $('button.btn-primary').attr("class", "btn btn-secondary")
         });
        let index = event.target.name;
        this.setState({
            eventId : this.state.iconEvents[index].eventId,
            listAnimationInApp :  this.state.iconEvents[index].listAnimationInApp[0],
            listIconEventInApp : this.state.iconEvents[index].listIconEventInApp,
            nameEvent : this.state.iconEvents[index].nameEvent,
            playingStart : new Date(this.state.iconEvents[index].timeStart),
            playingEnd : new Date(this.state.iconEvents[index].timeEnd),
            language : this.state.iconEvents[index].language,
            index: Number(index)
        }); 
        $(function() {
           $('button#changecolor'+ index).attr("class", "btn btn-primary")
        });
    }

    updateEvent(){
        this.setState({
            iconEvents: []
        });
        $(function() {
            $('button.btn-primary').attr("class", "btn btn-secondary")
         });
        var appSelected = $('#languageSelected :selected').val();
        let name = document.getElementById('inputTenSuKien').value;
        let timeStart = this.state.playingStart;
        let timeEnd = this.state.playingEnd;
        let eventId = this.state.eventId;
        if(this.state.playingStart != null && this.state.playingStart != ""){
            timeStart = this.state.playingStart;
        }
        if(this.state.playingEnd != null && this.state.playingEnd != ""){
            timeEnd = this.state.playingEnd;
        }

        if(name == null || name == "") {
            toast.error("Nhập tên sự kiện!");
            return;
        }
        if(appSelected == null || appSelected == "") {
            toast.error("Chọn ngôn ngữ!");
            return;
        }
        if(this.state.inputFileIcon1 == null){
            this.state.inputFileIcon1 = this.state.listIconEventInApp[0].urlNormal
        }
        if(this.state.inputFileIconAfterClick1 == null){
            this.state.inputFileIconAfterClick1 = this.state.listIconEventInApp[0].urlClick
        }
        if(this.state.inputFileIcon2 == null){
            this.state.inputFileIcon2 = this.state.listIconEventInApp[1].urlNormal
        }
        if(this.state.inputFileIconAfterClick2 == null){
            this.state.inputFileIconAfterClick2 = this.state.listIconEventInApp[1].urlClick
        }
        if(this.state.inputFileIcon3 == null){
            this.state.inputFileIcon3 = this.state.listIconEventInApp[2].urlNormal
        }
        if(this.state.inputFileIconAfterClick3 == null){
            this.state.inputFileIconAfterClick3 = this.state.listIconEventInApp[2].urlClick
        }
        if(this.state.inputFileIcon4 == null){
            this.state.inputFileIcon4 = this.state.listIconEventInApp[3].urlNormal
        }
        if(this.state.inputFileIconAfterClick4 == null){
            this.state.inputFileIconAfterClick4 = this.state.listIconEventInApp[3].urlClick
        }
        if(this.state.inputFileIcon5 == null){
            this.state.inputFileIcon5 = this.state.listIconEventInApp[4].urlNormal
        }
        if(this.state.inputFileIconAfterClick5 == null){
            this.state.inputFileIconAfterClick5 = this.state.listIconEventInApp[4].urlClick
        }
        if(this.state.inputFileIcon6 == null){
            this.state.inputFileIcon6 = this.state.listAnimationInApp.urlNormal
        }

        let listIconEventInApp = [];
        let listAnimationInApp = [];
        let iconHome = {nameTab: 'home', nameIcon: "Trang chủ", urlPreview: this.state.inputFileIcon1, urlNormal: this.state.inputFileIcon1, urlClick: this.state.inputFileIconAfterClick1};
        let iconDiscover = {nameTab: 'discover', nameIcon: "Khám phá", urlPreview: this.state.inputFileIcon2, urlNormal: this.state.inputFileIcon2, urlClick: this.state.inputFileIconAfterClick2};
        let iconKara = {nameTab: 'sing', nameIcon: "Hát kara", urlPreview: this.state.inputFileIcon3, urlNormal: this.state.inputFileIcon3, urlClick: this.state.inputFileIconAfterClick3};
        let iconMessage = {nameTab: 'message', nameIcon: "Tin nhắn", urlPreview: this.state.inputFileIcon4, urlNormal: this.state.inputFileIcon4, urlClick: this.state.inputFileIconAfterClick4};
        let iconMySelf = {nameTab: 'mySelf', nameIcon: "Tôi", urlPreview: this.state.inputFileIcon5, urlNormal: this.state.inputFileIcon5, urlClick: this.state.inputFileIconAfterClick5};
        let iconAnimation = {nameTab: 'mySelf', nameIcon: "Tôi", urlPreview: this.state.inputFileIcon6, urlNormal: this.state.inputFileIcon6, urlClick: this.state.inputFileIconAfterClick6};
        listIconEventInApp.push(iconHome);
        listIconEventInApp.push(iconDiscover);
        listIconEventInApp.push(iconKara);
        listIconEventInApp.push(iconMessage);
        listIconEventInApp.push(iconMySelf);
        listAnimationInApp.push(iconAnimation);
        let json = {
            eventId: eventId,
            nameEvent: name,
            timeStart: timeStart,
            timeEnd: timeEnd,
            language: appSelected,
            listIconEventInApp: listIconEventInApp,
            listAnimationInApp: listAnimationInApp
        };
        const jsonBase64 = Base64.encode(JSON.stringify(json));
        var pass = this.newGetKey(jsonBase64, 7123358024, 3564);
        var keys = jsonBase64 + "-" + pass;
        var url = global.config.apiDomain + "/web.v5.UpdateIconEvent?parameters=" + keys;
        console.log(url);
        axios.post(url, json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.clearEvent();
            if (res.data.status == "OK") {
                toast.success(res.data.message);
                this.showEventInfo();
            } else {
                toast.error(res.data.message);
            }
        }, err => {
            this.showEventInfo();
            console.log("err nen set tam5: ");
            console.log(err);
            toast.error("Đã có lỗi xảy ra, vui lòng thử lại!");
        });
    }
    

    clearEvent(){
        this.setState({
            eventId : null,
            listAnimationInApp : [],
            listIconEventInApp : null,
            nameEvent : null,
            playingStart : new Date(),
            playingEnd : '',
            language : null,
            index: null,
            inputFileIcon6: null
        });
        $(function(){
            $('div#iconTrangChu1 svg').empty()
            $('div#iconTrangChu2 svg').empty()
            $('div#iconKhamPha1  svg').empty()
            $('div#iconKhamPha2 svg').empty()
            $('div#iconHatKara1  svg').empty()
            $('div#iconHatKara2 svg').empty()
            $('div#iconTinNhan1  svg').empty()
            $('div#iconTinNhan2 svg').empty()
            $('div#iconToi1 svg').empty()
            $('div#iconToi2 svg').empty()
            $('button.btn-primary').attr("class", "btn btn-secondary")
            $('a#filejson').attr("href", "")
        })
        document.getElementById("inputTenSuKien").value = "";
        document.getElementById("languageSelected").value = "";
    }
    callModalDelete(event){
        let index = event.target.name;
        this.setState({
            idIcon : this.state.iconEvents[index].eventId,
            nameIcon : this.state.iconEvents[index].nameEvent,
        }); 
    }
    render() {
        console.log(this.state.iconEvents)
        const model = bindModel(this);
        const { gifts,iconEvents,language,nameEvent,listAnimationInApp,listIconEventInApp,eventId,nameIcon,idIcon } = this.state;
        return (
            <>
            <ModalDeleteIconEvent
                id={idIcon}
                nameIcon = {nameIcon}
                showEventInfo={this.showEventInfo}
            ></ModalDeleteIconEvent>
                <div id="content">
                    <div className="container">
                        <h1 className="text-center">Add Icon Cho Sự Kiện</h1><br/>
                        {iconEvents && (
                        <div>                            
                            <div className='row'>
                                <div className='col-md-9'>
                                </div>
                                <div className='col-md-3'>
                                    <div style = {{marginLeft: 89}}>
                                    <button  className="btn btn-secondary" 
                                     onClick={this.clearEvent}>Làm mới</button>

                                    {this.state.loading ?
                                        (
                                            <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                {eventId ? "Cập nhật..." : "Thêm..."}</button>
                                        ):(
                                            <button  className="btn btn-success" style = {{float: 'right'}}
                                            onClick={eventId ? this.updateEvent : this.addEvent}>
                                                {eventId ? "Cập nhật" : "Thêm"}</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h5>Tên Sự Kiện</h5>
                                    <input id='inputTenSuKien'
                                        className="form-control" placeholder="Nhập tên sự kiện" 
                                        aria-describedby="basic-addon1"
                                        type="text"  
                                        value =""                                    
                                        {...model('nameEvent')}></input>
                                </div>
                            </div><br/>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h5>Thời gian bắt đầu</h5>
                                    <DateTimePickerComponent 
                                        change= {this.playingStartDate}
                                        value = {this.state.playingStart? this.state.playingStart : ''}
                                    >    
                                    </DateTimePickerComponent>
                                </div>
                                <div className='col-md-6'>
                                    <h5>Thời gian kết thúc</h5>
                                    <DateTimePickerComponent 
                                        change= {this.playingEndDate}
                                        value = {this.state.playingEnd ? this.state.playingEnd : ''}
                                    >    
                                    </DateTimePickerComponent>
                                </div>
                            </div><br/>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h5>Chọn ngôn ngữ</h5>
                                    <select id='languageSelected'
                                        className="form-control"
                                        type="text"
                                        name="language"
                                        {...model('language')} >
                                        <option value="">Chọn ngôn ngữ</option>
                                        <option value="vi">Vietnamese</option>
                                        <option value="en.yokara">English</option>
                                        <option value="lo.okara">Laos</option>
                                        <option value="mz.okara">Mozambique</option>
                                        <option value="tl.okara">TimorLeste</option>
                                        <option value="ht.okara">Haiti</option>
                                        <option value="all">All</option>
                                    </select>
                                </div>
                            </div><br/>
                            <br/><br/>
                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <a className="nav-item nav-link active" id="nav-trangchu-tab" data-toggle="tab" href="#nav-trangchu" role="tab" aria-controls="nav-home" aria-selected="true">Trang chủ</a>
                                    <a className="nav-item nav-link" id="nav-khampha-tab" data-toggle="tab" href="#nav-khampha" role="tab" aria-controls="nav-profile" aria-selected="false">Khám phá</a>
                                    <a className="nav-item nav-link " id="nav-hatkara-tab" data-toggle="tab" href="#nav-hatkara" role="tab" aria-controls="nav-profile" aria-selected="false">Hát Kara</a>
                                    <a className="nav-item nav-link " id="nav-tinnhan-tab" data-toggle="tab" href="#nav-tinnhan" role="tab" aria-controls="nav-profile" aria-selected="false">Tin nhắn</a>
                                    <a className="nav-item nav-link " id="nav-me-tab" data-toggle="tab" href="#nav-me" role="tab" aria-controls="nav-profile" aria-selected="false">Tôi</a>
                                    <a className="nav-item nav-link " id="nav-me-animation-tab" data-toggle="tab" href="#nav-me-animation" role="tab" aria-controls="nav-profile" aria-selected="false">Animation</a>
                                </div>
                            </nav>
                            <br/><br/>
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-trangchu" role="tabpanel" aria-labelledby="nav-trangchu-tab">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                                <input
                                                    value = ""
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="inputFileIcon1"
                                                    onChange={this.uploadThumbnailToS3}
                                                />
                                                <button className="custom-file-label ml-3 mr-3" htmlFor="inputFileIcon1">Chọn Icon Hiển Thị</button>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                value = ""
                                                type="file"
                                                className="custom-file-input"
                                                id="inputFileIconAfterClick1"
                                                onChange={this.uploadThumbnailToS3}
                                            />
                                            <button className="custom-file-label ml-3 mr-3" htmlFor="inputFileIconAfterClick1">Chọn Icon Sau Khi Click</button>
                                        </div>
                                    </div>
                                        <div className='row'>
                                        <div className='col-md-3' id='iconTrangChu1'> 
                                        </div>
                                        <div className='col-md-3'> 
                                        {listIconEventInApp && (
                                        <span style={{float:'right'}} id={'iconAppendImgSvgBeforeClick'+ this.state.index + '0'}></span>
                                        )}
                                        </div>
                                        <div className='col-md-3' id='iconTrangChu2'>
                                        </div>
                                        <div className='col-md-3' >
                                        {listIconEventInApp && (
                                        <span style={{float:'right'}} id={'iconAppendImgSvgAfterClick'+ this.state.index + '0'}></span>
                                        )}
                                        </div>
                                    </div>
                                    
                                    <br/><br/>
                                </div>
                                <div className="tab-pane fade" id="nav-khampha" role="tabpanel" aria-labelledby="nav-khampha-tab">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                                <input
                                                    value = ""
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="inputFileIcon2"
                                                    onChange={this.uploadThumbnailToS3}
                                                />
                                                <button className="custom-file-label ml-3 mr-3" htmlFor="inputFileIcon2">Chọn Icon Hiển Thị</button>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                value = ""
                                                type="file"
                                                className="custom-file-input"
                                                id="inputFileIconAfterClick2"
                                                onChange={this.uploadThumbnailToS3}
                                            />
                                            <button className="custom-file-label ml-3 mr-3" htmlFor="inputFileIconAfterClick2">Chọn Icon Sau Khi Click</button>
                                        </div>
                                    </div>
                                        <div className='row'>
                                        <div className='col-md-3' id='iconKhamPha1'>
                                        </div>
                                        <div className='col-md-3'>
                                        {listIconEventInApp && (
                                        <span style={{float:'right'}} id={'iconAppendImgSvgBeforeClick'+ this.state.index + '1'}></span>
                                        )}
                                        </div>
                                        <div className='col-md-3' id='iconKhamPha2'>
                                        </div>
                                        <div className='col-md-3'>
                                        {listIconEventInApp && (
                                        <span style={{float:'right'}} id={'iconAppendImgSvgAfterClick'+ this.state.index + '1'}></span>
                                        )}
                                        </div>
                                    </div>
                                    
                                    <br/><br/>
                                </div>
                                <div className="tab-pane fade" id="nav-hatkara" role="tabpanel" aria-labelledby="nav-hatkara-tab">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                                <input
                                                    value = ""
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="inputFileIcon3"
                                                    onChange={this.uploadThumbnailToS3}
                                                />
                                                <button className="custom-file-label ml-3 mr-3" htmlFor="inputFileIcon3">Chọn Icon Hiển Thị</button>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                value = ""
                                                type="file"
                                                className="custom-file-input"
                                                id="inputFileIconAfterClick3"
                                                onChange={this.uploadThumbnailToS3}
                                            />
                                            <button className="custom-file-label ml-3 mr-3" htmlFor="inputFileIconAfterClick3">Chọn Icon Sau Khi Click</button>
                                        </div>
                                    </div>
                                         <div className='row'>
                                         <div className='col-md-3' id='iconHatKara1'>
                                         </div>
                                         <div className='col-md-3'>
                                        {listIconEventInApp && (
                                         <span style={{float:'right'}} id={'iconAppendImgSvgBeforeClick'+ this.state.index + '2'}></span>
                                         )}
                                         </div>
                                         <div className='col-md-3' id='iconHatKara2'>
                                         </div>
                                         <div className='col-md-3'>
                                        {listIconEventInApp && (
                                         <span style={{float:'right'}} id={'iconAppendImgSvgAfterClick'+ this.state.index + '2'}></span>
                                         )}
                                         </div>
                                     </div>
                                   
                                    <br/><br/>
                                </div>
                                <div className="tab-pane fade" id="nav-tinnhan" role="tabpanel" aria-labelledby="nav-tinnhan-tab">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                                <input
                                                    value = ""
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="inputFileIcon4"
                                                    onChange={this.uploadThumbnailToS3}
                                                />
                                                <button className="custom-file-label ml-3 mr-3" htmlFor="inputFileIcon4">Chọn Icon Hiển Thị</button>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                value = ""
                                                type="file"
                                                className="custom-file-input"
                                                id="inputFileIconAfterClick4"
                                                onChange={this.uploadThumbnailToS3}
                                            />
                                            <button className="custom-file-label ml-3 mr-3" htmlFor="inputFileIconAfterClick4">Chọn Icon Sau Khi Click</button>
                                        </div>
                                    </div>
                                        <div className='row'>
                                        <div className='col-md-3' id='iconTinNhan1'>
                                        </div>
                                        <div className='col-md-3'>
                                        {listIconEventInApp && (
                                        <span style={{float:'right'}} id={'iconAppendImgSvgBeforeClick'+ this.state.index + '3'}></span>
                                        )}
                                        </div>
                                        <div className='col-md-3' id='iconTinNhan2'>
                                        </div>
                                        <div className='col-md-3'>
                                        {listIconEventInApp && (
                                        <span style={{float:'right'}} id={'iconAppendImgSvgAfterClick'+ this.state.index + '3'}></span>
                                        )}
                                        </div>
                                    </div>
                                    
                                    <br/><br/>
                                </div>
                                <div className="tab-pane fade" id="nav-me" role="tabpanel" aria-labelledby="nav-me-tab">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                                <input
                                                    value = ""
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="inputFileIcon5"
                                                    onChange={this.uploadThumbnailToS3}
                                                />
                                                <button className="custom-file-label ml-3 mr-3" htmlFor="inputFileIcon5">Chọn Icon Hiển Thị</button>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                value = ""
                                                type="file"
                                                className="custom-file-input"
                                                id="inputFileIconAfterClick5"
                                                onChange={this.uploadThumbnailToS3}
                                            />
                                            <button className="custom-file-label ml-3 mr-3" htmlFor="inputFileIconAfterClick5">Chọn Icon Sau Khi Click</button>
                                        </div>
                                    </div>
                                        <div className='row'>
                                        <div className='col-md-3' id='iconToi1'>
                                        </div>
                                        <div className='col-md-3'>
                                        {listIconEventInApp && (
                                        <span style={{float:'right'}} id={'iconAppendImgSvgBeforeClick'+ this.state.index + '4'}></span>
                                        )}
                                        </div>
                                        <div className='col-md-3' id='iconToi2'>
                                        </div>
                                        <div className='col-md-3'>
                                        {listIconEventInApp && (
                                        <span style={{float:'right'}} id={'iconAppendImgSvgAfterClick'+ this.state.index + '4'}></span>
                                        )}
                                        </div>
                                    </div>
                                    
                                    <br/><br/>
                                </div>

                                <div className="tab-pane fade" id="nav-me-animation" role="tabpanel" aria-labelledby="nav-me-animation-tab">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                                <input
                                                    value = ""
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="inputFileIcon6"
                                                    onChange={this.uploadFileJson}
                                                />
                                                <button className="custom-file-label ml-3 mr-3" htmlFor="inputFileIcon6">Chọn File</button>
                                        </div>
                                        {/* <div className='col-md-6'>
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="inputFileIconAfterClick6"
                                                onChange={this.uploadFileJson}
                                            />
                                            <button className="custom-file-label ml-3 mr-3" htmlFor="inputFileIconAfterClick6">Chọn Icon Sau Khi Click</button>
                                        </div> */}
                                    </div>
                                    {listAnimationInApp && (
                                         <div className='row'>
                                         <div className='col-md-12'>
                                                 <a id = "filejson" href={listAnimationInApp.urlNormal ? listAnimationInApp.urlNormal : this.state.inputFileIcon6 ? this.state.inputFileIcon6 : ""}
                                                  target="_blank">{listAnimationInApp.urlNormal ? listAnimationInApp.urlNormal : this.state.inputFileIcon6? this.state.inputFileIcon6: ""}</a>
                                            
                                             
                                         </div>
                                         {/* <div className='col-md-6'>
                                             {this.state.inputFileIconAfterClick6 != null ? (
                                                 <a href={this.state.inputFileIconAfterClick6} target="_blank">{this.state.inputFileIconAfterClick6}</a>) : <></>}
                                         </div> */}
                                     </div>
                                    )}
                                   
                                    <br/><br/>
                                </div>
                            </div>
                        </div>)}
                        <br/><br/>
                        
                        <h2>Danh sách Event</h2>
                        <table className="table table-hover">
                            <thead>
                                <tr className="align-middle">
                                    <th className="align-middle" scope="col">Tên Event</th>
                                    <th className="align-middle" scope="col">Thời gian bắt đầu</th>
                                    <th className="align-middle" scope="col">Thời gian kết thúc</th>
                                    <th className="align-middle" scope="col">Icon trước khi click</th>
                                    <th className="align-middle" scope="col">Icon sau khi click</th>
                                    <th className="align-middle" scope="col">Ngôn ngữ</th>
                                    <th className="align-middle" scope="col">Animation</th>
                                    <th className="align-middle" scope="col">Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.iconEvents.map((event, index) => {
                                    this.setIconShow(event.listIconEventInApp, index);
                                    return (
                                        <tr key={index}>
                                            <td>{event.nameEvent}</td>
                                            <td>{(new Date(event.timeStart)).toLocaleString('en-GB')}</td>
                                            <td>{(new Date(event.timeEnd)).toLocaleString('en-GB')}</td>
                                            <td>
                                                <span id={'iconAppendImgSvgBeforeClick' + index + '0'}></span>
                                                <span id={'iconAppendImgSvgBeforeClick' + index + '1'}></span>
                                                <span id={'iconAppendImgSvgBeforeClick' + index + '2'}></span>
                                                <span id={'iconAppendImgSvgBeforeClick' + index + '3'}></span>
                                                <span id={'iconAppendImgSvgBeforeClick' + index + '4'}></span>
                                            </td>
                                            <td>
                                                <span id={'iconAppendImgSvgAfterClick' + index + '0'}></span>
                                                <span id={'iconAppendImgSvgAfterClick' + index + '1'}></span>
                                                <span id={'iconAppendImgSvgAfterClick' + index + '2'}></span>
                                                <span id={'iconAppendImgSvgAfterClick' + index + '3'}></span>
                                                <span id={'iconAppendImgSvgAfterClick' + index + '4'}></span>
                                            </td>
                                            <td>{event.language}</td>
                                            <td>
                                                <a href={this.state.iconEvents[index].listAnimationInApp[0].urlNormal} target="_blank">View file</a>
                                            </td>
                                            <td>
                                                <button id = {'changecolor'+ index} className={this.state.color} name = {index} 
                                                onClick = {this.editIconForEvent}>Sửa</button>&ensp;
                                                <button className="btn btn-danger" data-toggle="modal" 
                                                    data-target="#modalDeleteIconEvent" name = {index} onClick={this.callModalDelete} >Xóa</button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}