import React, { Component } from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import $ from 'jquery';

import { toast } from 'react-toastify';

export default class SellerId extends Component {
    constructor(props) {
        super(props);
        this.state = {
            originalSellers: [],
            sellers: [],
            searchField: '',
            inputValue: '' 
        };

        this.getAllCustomerOfSeller = this.getAllCustomerOfSeller.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    getAllCustomerOfSeller() {
        const { sellerId } = this.props.match.params;
        var json = {
            id: sellerId
        }
        axios.post(global.config.apiDomain + '/rest/seller/getAllCustomerOfSeller', json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                originalSellers: res.data.models,
                sellers: res.data.models
            });
        })
    }

    componentDidMount() {
        this.getAllCustomerOfSeller();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        this.setState({
            inputValue: value
        });
    }

    handleSearch() {
        const { searchField, inputValue, originalSellers } = this.state;
        
        if (!searchField) {
            this.setState({
                sellers: originalSellers
            });
            return;
        }
        
        if (!inputValue) {
            return;
        }
    
        const filteredSellers = originalSellers.filter(seller => {
            let vipLevel = seller.vipStatus ? seller.vipStatus.vipLevel : null;
            
            if (searchField === 'level') {
                return seller[searchField] === parseInt(inputValue);
            } else if (searchField === 'vipStatus.vipLevel') {
                return vipLevel === parseInt(inputValue, 10);
            } else if (searchField === 'addTime' || searchField === 'lastAccess') {
                const inputValueDate = new Date(inputValue).toLocaleDateString();
                const sellerDate = new Date(seller[searchField]).toLocaleDateString();
                return sellerDate === inputValueDate;
            } else if (searchField === 'name' || searchField === 'uid') {
                return seller[searchField].toLowerCase().includes(inputValue.toLowerCase());
            }   
        });
    
        this.setState({
            sellers: filteredSellers
        });
    }

    handleSelectChange(event) {
        const { value } = event.target;
        this.setState({
            searchField: value,
            inputValue: ''
        });
    }

    render() {
        const { sellers, searchField, inputValue } = this.state;
        return (
            <>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Seller</h1>
                        <div>
                            <select value={searchField} onChange={this.handleSelectChange} style={{height: '30px'}}>
                                <option value="">All</option>
                                <option value="uid">ID</option>
                                <option value="name">Tên</option>
                                <option value="addTime">Ngày vào app</option>
                                <option value="level">Level</option>
                                <option value="vipStatus.vipLevel">Level VIP</option>
                                <option value="lastAccess">Thời gian hoạt động gần nhất</option>
                            </select>
                            {['uid', 'level', 'vipStatus.vipLevel'].includes(searchField) ?
                                <input type="number" value={inputValue} onChange={this.handleInputChange} /> :
                                searchField === 'addTime' || searchField === 'lastAccess' ?
                                    <input type="date" value={inputValue} onChange={this.handleInputChange} /> :
                                    <input type="text" value={inputValue} onChange={this.handleInputChange} />
                            }
                            <button onClick={this.handleSearch}>Tìm kiếm</button>
                        </div>
                        <table className="table table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th className="align-middle" scope="col">ID user</th>
                                    <th className="align-middle" scope="col">Tên user</th>
                                    <th className="align-middle" scope="col">Ngày vào app</th>
                                    <th className="align-middle" scope="col">Level</th>
                                    <th className="align-middle" scope="col">Level VIP</th>
                                    <th className="align-middle" scope="col">Thời gian hoạt động gần nhất</th>
                                    <th className="align-middle" scope="col">Tổng số tiền nạp</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sellers && sellers.map((item) => {
                                        let addTime = new Date(item.addTime);
                                        let lastAccess = new Date(item.lastAccess);
                                        return (
                                            <tr>
                                                <td className="align-middle text-center" scope="col">{item.uid}</td>
                                                <td className="align-middle text-center" scope="col">{item.name}</td>
                                                <td className="align-middle text-center" scope="col">{addTime.toLocaleString()}</td>
                                                <td className="align-middle text-center" scope="col">{item.level}</td>
                                                <td className="align-middle text-center" scope="col">{item.vipStatus.vipLevel}</td>
                                                <td className="align-middle text-center" scope="col">{lastAccess.toLocaleString()}</td>
                                                <td className="align-middle text-center" scope="col">{item.totalIcoin}</td>
                                            </tr>
                                        )
                                    })

                                }

                            </tbody>
                        </table>
                    </div>
                </div>

            </>
        );
    }
}

