import React, { Component } from 'react';
import axios from 'axios';
import ModalAddPartner from './components/ModalAddPartner';
import ModalEditPartnerRoom from './components/ModalEditPartnerRoom';
import RoomListModal from './components/RoomListModal'; // Import the new modal component
import { toast } from 'react-toastify';
import './components/partner.css';
import ModalMinOnChair from './components/ModalMinOnChair';
import ModalViewTime from './components/ModalViewTime';
import ModalGiftIcoin from './components/ModalGiftIcoin';

export default class PartnerManagerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            partners: [],
            partner: null,
            uid: null,
            roomUid: null,
            loading: false,
            showRoomListModal: false, // New state to control RoomListModal visibility
        };

        this.getAllPartner = this.getAllPartner.bind(this);
        this.search = this.search.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount() {
        this.getAllPartner();
    }

    getAllPartner() {
        this.setState({ loading: true });
        axios.post(global.config.apiDomain + '/rest/partner-duration/web/get-partner-list', JSON.stringify(''), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                partners: res.data.data.list,
                loading: false
            });
        }).catch(error => {
            console.error('Error during search:', error);
            this.setState({ loading: false });
        });
    }

    search() {
        this.setState({ loading: true });
        let { uid } = this.state;
        axios.post(global.config.apiDomain + '/rest/partner/get-partner?uid=' + uid, JSON.stringify(''), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                partners: res.data,
                loading: false,
            });
        }).catch(error => {
            console.error('Error during search:', error);
            this.setState({ loading: false });
        });
    }

    handleChange(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value;

        this.setState({
            [name]: value
        });
    }

    handleDeletePartner(deletePartner) {
        const json = {
            uid: deletePartner.uid,
            role: 'USER'
        };
        axios
            .post(
                global.config.apiDomain + "/rest/privilegeAdmin/create",
                json
            )
            .then((res) => {
                if (res.data.status === "FAILED") {
                    toast.warning(res.data.message);
                } else {
                    toast.success(res.data.message);

                    let partners = this.state.partners;
                    let index = partners.findIndex(partner => partner.id === deletePartner.id);
                    if (index !== -1) {
                        partners.splice(index, 1);
                    }

                    this.setState({
                        partners: partners,
                    });
                }
            })
            .catch((err) => {
                toast.warning("Xóa CTV thất bại ");
            });
    }

    openRoomListModal(partner) {
        this.setState({
            partner: partner,
            showRoomListModal: true
        });
    }

    closeRoomListModal = () => {
        this.setState({
            showRoomListModal: false
        });
    };

    render() {
        const { partner, loading, showRoomListModal } = this.state;

        return (
            <>
                <RoomListModal
                    show={showRoomListModal}
                    onHide={this.closeRoomListModal}
                    roomList={partner ? partner.roomList : []}
                />
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí Cộng Tác Viên</h1>
                        {loading ? (
                            <div className="text-center">
                                <i className="fa fa-spinner fa-spin fa-2x"></i>
                                <p>Đang tải...</p>
                            </div>
                        ) : (
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">STT</th>
                                        <th scope="col">Avatar</th>
                                        <th scope="col">Tên</th>
                                        <th scope="col">UID</th>
                                        <th scope="col">Phòng live đang quản lý</th>
                                        <th scope="col">Chỉ số</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.partners.map((partner, index) => (
                                        <tr key={partner.id}>
                                            <th scope="row">{index + 1}</th>
                                            <td className="align-middle text-center" scope="col">
                                                    <img src={partner.collaborator.profileImageLink} alt="Thumbnail" width="100" height="100" />
                                                </td>
                                            <td>{partner.collaborator.name}</td>
                                            <td>{partner.collaborator.uid}</td>
                                            <td>
                                                <button
                                                    onClick={() => this.openRoomListModal(partner)}
                                                    className="btn btn-primary"
                                                >
                                                    Xem danh sách phòng
                                                </button>
                                            </td>
                                            <td className="flex-container" style={{width: '430px'}}>
                                                <button 
                                                    className="btn btn-danger"
                                                    data-toggle="modal" 
                                                    data-target="#modalMinOnChair" 
                                                    onClick={() => this.setState({ selectedUid: partner.collaborator.uid, showModalOnChair: true })}
                                                >
                                                    Số phút lên ghế
                                                </button>
                                                <ModalMinOnChair uid={this.state.selectedUid} showModalOnChair={this.state.showModalOnChair}  />

                                                <button 
                                                    className="btn btn-success"
                                                    data-toggle="modal" 
                                                    data-target="#modalViewTime" 
                                                    onClick={() => this.setState({ selectedUid: partner.collaborator.uid, showModalViewTime: true })}
                                                >
                                                    Số phút xem
                                                </button>
                                                <ModalViewTime uid={this.state.selectedUid} showModalViewTime={this.state.showModalViewTime} />
                                                
                                                <button 
                                                    className="btn btn-warning text-white"
                                                    data-toggle="modal" 
                                                    data-target="#modalGiftIcoin" 
                                                    onClick={() => {
                                                        this.setState({ selectedUid: partner.collaborator.uid, showModalGiftIcoin: true });
                                                    }}
                                                >
                                                    Icoin Quà
                                                </button>
                                                <ModalGiftIcoin uid={this.state.selectedUid} showModalGiftIcoin={this.state.showModalGiftIcoin} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </>
        );
    }
}