import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import bindModel from '../../../common/bindModel.js';

const ModalUpdateLevelPrivilege = ({ levelPrivilege: propLevelPrivilege , resetList }) => {
    const [levelPrivilege, setLevelPrivilege] = useState({
        idTab: '',
        name: '',
        description: '',
        detail: '',
        icon: '',
        levelRequirement: 0,
        image: '',
        isActive: '',
        index: 0,
        app: ''
    });
    const [loading, setLoading] = useState(false);
    const descriptionRef = useRef();
    const idTabRef = useRef();
    const nameRef = useRef();
    const levelRequirementRef = useRef();
    const isActiveRef = useRef();
    const appRef = useRef();

    useEffect(() => {
        $("#iconPreview").children("svg").remove();
            $("#iconPreview").children("svg").remove();
            console.log(propLevelPrivilege);
            if (propLevelPrivilege !== null) {
                console.log("vào");
                setLevelPrivilege(propLevelPrivilege);
                if (propLevelPrivilege.icon) {
                    renderSvg(propLevelPrivilege.icon);
                }
            } else {
                console.log("vào 1");
                clearInputs();
                setLevelPrivilege({
                    idTab: '',
            name: '',
            description: '',
            detail: '',
            icon: '',
            levelRequirement: 0,
            image: '',
            isActive: '',
            index: 0,
            app: ''
                });
            }
            
        if (propLevelPrivilege && propLevelPrivilege.icon) {
            renderSvg(propLevelPrivilege.icon);
        }
    }, [propLevelPrivilege]);
    const clearInputs = () => {
        idTabRef.current.value = '';
        nameRef.current.value = '';
        levelRequirementRef.current.value = 0;
        isActiveRef.current.value = '';
        appRef.current.value = '';
    };
    const handleIdTab = (e) => {
        setLevelPrivilege({ ...levelPrivilege, idTab: e.target.value });
    };
    const handleName = (e) => {
        setLevelPrivilege({ ...levelPrivilege, name: e.target.value });
    };
    const handleApp = (e) => {
        setLevelPrivilege({ ...levelPrivilege, app: e.target.value });
    };
    const handleLevelRequirement = (e) => {
        setLevelPrivilege({ ...levelPrivilege,levelRequirement : e.target.value });
    };
    const handleActive = (e) => {
        setLevelPrivilege({ ...levelPrivilege, isActive: e.target.value });
    };
    const saveLevelPrivilege = () => {
        let obj = { ...levelPrivilege };
        console.log(obj)
        if (
            !obj ||
            !obj.idTab ||
            !obj.name ||
            !obj.icon ||
            !obj.levelRequirement ||
            !obj.isActive ||
            !obj.app
        ) {
            toast.error("Vui lòng điền đầy đủ thông tin.");
            return;
        }

        obj.isActive = obj.isActive === '1';

        setLoading(true);

        axios.post(global.config.apiDomain + '/rest/levelPrivilege/createOrUpdate', obj)
            .then(res => {
                setLoading(false);
                if (res.data.status === "OK") {
                    window.$("#modalUpdateLevelPrivilege").modal("hide");
                    resetList();
                    toast.success(res.data.message);
                } else {
                    toast.warning(res.data.message);
                }
            })
            .catch(error => {
                setLoading(false);
                toast.error(error);
            });
    };

    const guid = () => {
        const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
    };

    const uploadAnimationToS3 = async (event) => {
        const target = event.target;
        if (target.files[0].size > 10495760) {
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        const property = target.name;
        const bucketName = "ikara-data/images/levelPrivileges";
        const extension = target.files[0].name.split('.').pop().toLowerCase();
        const keyName = `${guid()}.${extension}`;
        const contentType = extension === "png" ? "image/svga" : "image/svga";

        setLoading(true);

        const fd = new FormData();
        fd.append('file', target.files[0]);
        fd.append('bucketName', bucketName);
        fd.append('objectKey', keyName);
        fd.append('contentType', contentType);

        try {
            const response = await $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
            });

            setLoading(false);
            const url = `https://data4.ikara.co/data/minio/${bucketName}/${keyName}`;
            setLevelPrivilege(prevState => ({
                ...prevState,
                [property]: url
            }));

            if (url.endsWith(".svg") || url.endsWith(".svga")) {
                const text = await getAndModifySVG(url);
                $("#iconPreview").html(() => text.documentElement);
            } else {
                $("#iconPreview").html(`<img src=${url}></img>`);
            }
        } catch (error) {
            setLoading(false);
            toast.error(error);
        }
    };

    const uploadAnimationToS4 = async (event) => {
        const target = event.target;
        if (target.files[0].size > 10495760) {
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        const property = target.name;
        const bucketName = "ikara-data/images/levelPrivileges";
        const extension = target.files[0].name.split('.').pop().toLowerCase();
        const keyName = `${guid()}.${extension}`;
        const contentType = extension === "png" ? "image/svga" : "image/svga";

        setLoading(true);

        const fd = new FormData();
        fd.append('file', target.files[0]);
        fd.append('bucketName', bucketName);
        fd.append('objectKey', keyName);
        fd.append('contentType', contentType);

        try {
            const response = await $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
            });

            setLoading(false);
            setLevelPrivilege(prevState => ({
                ...prevState,
                [property]: `https://data4.ikara.co/data/minio/${bucketName}/${keyName}`
            }));
        } catch (error) {
            setLoading(false);
            toast.error(error);
        }
    };

    const getAndModifySVG = async (url) => {
        const response = await fetch(url);
        const str = await response.text();
        return (new window.DOMParser()).parseFromString(str, "text/xml");
    };

    const renderSvg = async (url) => {
        const text = await getAndModifySVG(url);
        if (url.endsWith('.svg') || url.endsWith('.svga')) {
            $("#iconPreview").html(() => text.documentElement);
        } else {
            $("#iconPreview").html(`<img src=${url}></img>`);
        }
    };

    // const model = bindModel({ state: { levelPrivilege }, setState: setLevelPrivilege });

    return (
        <div>
            <div className="modal fade" id="modalUpdateLevelPrivilege" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật Đặc quyền Level</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div id="idTab" className="row mb-2">
                                <div className="col-3">
                                    <label>Mã đặc quyền</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        className="form-control mb-2"
                                        type="text"
                                        name="idTab"
                                        placeholder="Nhập mã đặc quyền..."
                                        ref={idTabRef}
                                        value=
                                        {levelPrivilege && levelPrivilege.idTab ? levelPrivilege.idTab : null}
                                        onChange={handleIdTab}
                                        
                                    />
                                </div>
                            </div>
                            <div id="name" className="row mb-2">
                                <div className="col-3">
                                    <label>Tên đặc quyền</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        className="form-control mb-2"
                                        type="text"
                                        name="name"
                                        placeholder="Nhập tên đặc quyền..."
                                        // {...model('levelPrivilege.name')}
                                        ref={nameRef}
                                        value=
                                        {levelPrivilege && levelPrivilege.name  ? levelPrivilege.name : null}
                                        onChange={handleName}
                                       
                                    />
                                </div>
                            </div>
                            <div id="icon" className="row mb-2">
                                <div className="col-3">
                                    <label>Icon</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        name="icon"
                                        onChange={uploadAnimationToS3}
                                       
                                    />
                                    <label className="custom-file-label ml-3 mr-3" htmlFor="icon">Choose file</label>
                                </div>
                            </div>
                            <div id="image" className="row mb-2">
                                <div className="col-3">
                                    <label>Ảnh minh họa</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        name="image"
                                        onChange={uploadAnimationToS4}
                                  
                                    />
                                    <label className="custom-file-label ml-3 mr-3" htmlFor="image">Choose file</label>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-3">
                                    <label></label>
                                </div>
                                <div className="col-4">
                                    <label>Icon</label>
                                </div>
                                <div className="col-4">
                                    <label>Ảnh Minh họa</label>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-3">
                                    <label></label>
                                </div>
                                {levelPrivilege && levelPrivilege.icon && 
                                <div id='iconPreview' className="col-4"></div>}
                                <div className="col-4">
                                    {levelPrivilege && levelPrivilege.image  && <img src={levelPrivilege.image} alt="Level Privilege" />}
                                </div>
                            </div>
                            <div id="description" className="row mb-2" hidden={true}>
                                <div className="col-3">
                                    <label>Mô tả</label>
                                </div>
                                <div className="col-9">
                                    <textarea
                                        className="form-control mb-2"
                                        name="description"
                                        placeholder="Nhập mô tả..."
                                        // {...model('levelPrivilege.description')}
                                        value=
                                        {levelPrivilege && levelPrivilege.description   ? levelPrivilege.levelRequirement : null}
                                        ref={descriptionRef}
                                    ></textarea>
                                </div>
                            </div>
                            <div id="levelRequirement" className="row mb-2">
                                <div className="col-3">
                                    <label>Level yêu cầu</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        className="form-control mb-2"
                                        type="number"
                                        name="levelRequirement"
                                        placeholder="Nhập level yêu cầu..."
                                        // {...model('levelPrivilege.levelRequirement')}
                                        value=
                                        {levelPrivilege && levelPrivilege.levelRequirement ? levelPrivilege.levelRequirement : null}
                                        onChange={handleLevelRequirement}
                                        ref={levelRequirementRef}
                                    />
                                </div>
                            </div>
                            <div id="isActive" className="row mb-2">
                                <div className="col-3">
                                    <label>Trạng thái</label>
                                </div>
                                <div className="col-9">
                                    <select
                                        className="form-control mb-2"
                                        name="isActive"
                                        value=
                                        {levelPrivilege && levelPrivilege.isActive  ? levelPrivilege.isActive : 
                                            ""
                                        }
                                        ref={isActiveRef}
                                        onChange={handleActive}
                                        // {...model('levelPrivilege.isActive')}
                                    >
                                        <option value="">Chọn trạng thái</option>
                                        <option value="1">Đang chạy</option>
                                        <option value="0">Không hoạt động</option>
                                    </select>
                                </div>
                            </div>
                            <div id="app" className="row mb-2">
                                <div className="col-3">
                                    <label>App</label>
                                </div>
                                <div className="col-9">
                                    <select
                                        className="form-control mb-2"
                                        name="app"
                                        // {...model('levelPrivilege.app')}
                                        value=
                                        {levelPrivilege && levelPrivilege.app ? levelPrivilege.app : null}
                                        onChange={handleApp}
                                        ref={appRef}
                                    ><option value="">Chọn Loai APP</option>
                                        <option value="ikara">iKara</option>
                                        <option value="ikaraweb">iKaraWeb</option>
                                        <option value="YOKARA">YOKARA</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Đóng</button>
                            <button type="button" className="btn btn-primary" onClick={saveLevelPrivilege}>
                                {loading ? "Đang tải ..." : "Lưu"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalUpdateLevelPrivilege;
