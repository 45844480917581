import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';

import bindModel from '../../../common/bindModel.js';

// const $ = window.$;

export default class ModalUpdateAppPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appPolicyModel: null,
            loading: false
        }

        this.createOrUpdateAppPolicy = this.createOrUpdateAppPolicy.bind(this);
    }

    componentWillReceiveProps(props) {
        let appPolicyModel = props.appPolicyModel;

        this.setState({
            appPolicyModel: appPolicyModel,
        })   
    }

    createOrUpdateAppPolicy() {
        let obj = {...this.state.appPolicyModel};
        if (obj.order == 0 || !obj.app || !obj.name || !obj.link) {
           return toast.error('Nhập đầy đủ thông tin');
        }

        console.log(obj);

        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/app-policy/createOrUpdateAppPolicy', obj)
            .then(res => {
                this.setState({
                    loading: false
                });
                console.log('loading: false');
                console.log('res: ' + res);

                if (res.data.status == "OK") {
                    window.$("#modalUpdateAppPolicy").modal("hide");
                    this.props.resetList();
                    toast.success(res.data.message);
                } else {
                    toast.warning(res.data.message);
                }
            }, error => {
                this.setState({
                    loading: false
                });
                toast.error(error);
            })
    }

    render() {
        const model = bindModel(this);
        const { loading } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalUpdateAppPolicy" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật Chính sách App</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>ID</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            id="id"
                                            className="form-control mb-2"
                                            readOnly 
                                            {...model('appPolicyModel.id')}/>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>Thứ tự</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            id="order"
                                            className="form-control mb-2"
                                            type="number"
                                            name="order"
                                            {...model('appPolicyModel.order')} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>App</label>
                                    </div>
                                    <div className="col-9">
                                        <select
                                            className="form-control"
                                            type="text"
                                            name="language"
                                            {...model('appPolicyModel.app')} >
                                            <option value="">Chọn</option>
                                            <option value="Ikara">Ikara</option>
                                            <option value="Yokara">Yokara</option>
                                            <option value="Okara Laos">Okara Laos</option>
                                            <option value="Okara Mozambique">Okara Mozambique</option>
                                            <option value="Okara TimorLeste">Okara TimorLeste</option>
                                            <option value="Okara Haiti">Okara Haiti</option>
                                        </select>
                                    </div>
                                </div>
                                <div id="name" className="row mb-2">
                                    <div className="col-3">
                                        <label>Tên Bài viết</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="name"
                                            placeholder="Nhập Tên bài viết ..."
                                            {...model('appPolicyModel.name')} />
                                    </div>
                                </div>

                                <div id="link" className="row mb-2">
                                    <div className="col-3">
                                        <label>Link Bài Viết</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="link"
                                            placeholder="Nhập Link ..."
                                            {...model('appPolicyModel.link')} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.createOrUpdateAppPolicy}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}