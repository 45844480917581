import React, { Component } from 'react';
import axios from 'axios';
import OpenApp from "react-open-app";
import { Base64 } from 'js-base64';

export default class PostPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            postId: this.props.match.params.postId,
            app: null,
            deeplink: null,
            content: null,
            imageUrl: null,
            nameSong: null,
            thumbnailImageUrl: null
        };

        this.getDeeplinkPost = this.getDeeplinkPost.bind(this);
        this.getPostInfo = this.getPostInfo.bind(this);
        this.newGetKey = this.newGetKey.bind(this);
        this.getDeeplinkPost();
    }

    getDeeplinkPost() {
        let json = {
            postId: this.state.postId,
            domain: window.location.hostname,
            type: "GROUP",
            app: "IKARA"
        }

        const jsonBase64 = Base64.encode(JSON.stringify(json));
        var pass = this.newGetKey(jsonBase64, 7123358024, 3564);
        var keys = jsonBase64 + "-" + pass;

        var url = "/GetDeepLinkFromParam?parameters=" + keys;

        axios.post(url, json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                deeplink: res.data
            })
        }, err => {
            console.log("err get deeplink:");
            console.log(err);
        });
    }
    getPostInfo() {
        const json = {
            language: this.state.language,
            platform: "IOS",
            postId: this.state.postId
        }
        const jsonBase64 = Base64.encode(JSON.stringify(json));
        var pass = this.newGetKey(jsonBase64, 7123358024, 3564);
        var keys = jsonBase64 + "-" + pass;

        var url = "/v39.GetPostInfo?parameters=" + keys;
        axios.post(url, json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            console.log(res);
            if (res.data && res.data.post) {

                this.setState({
                    songName: res.data.post.recording ? res.data.post.recording.song.songName : null,
                    thumbnailImageUrl: res.data.post.recording ? res.data.post.recording.thumbnailImageUrl : null,
                    content: res.data.post.content,
                    imageUrl: res.data.post.imageUrl,
                });
            }
        }, err => {
            console.log("err:");
            console.log(err);
        });
    }
    newGetKey(data, password, password2) {
        var listOfNumbers = "" + password2;
        for (var i = 0; i < data.length; i++) {
            if (data.charAt(i) >= '0' && data.charAt(i) <= '9') {
                listOfNumbers = [listOfNumbers.slice(0, 0), data.charAt(i), listOfNumbers.slice(0)].join('');
            }
        }
        var originalKey = null;
        if (listOfNumbers.length > 15)
            originalKey = listOfNumbers.substring(0, 15);
        else
            originalKey = listOfNumbers;
        return Number(originalKey) + Number(password);
    }

    render() {
        const { error, deeplink, postId, songName, content, imageUrl, thumbnailImageUrl } = this.state;
        const { t } = this.props;

        if (songName == null) {
            this.getPostInfo();
        }

        if (error != null) {
            return (
                <div id="content">
                    <div className="page-error">
                        <div className="container text-center">
                            <img src={require('../../assets/images/404.png')} />
                            <h2 className="heading">{t("post_page.error_post", "Lỗi bài thu")}</h2>
                            <p>{t("post_page.invalid_path", "Đường dẫn không hợp lệ")}</p>
                            <a href="/" className="btn-yellow">
                                <i className="fa fa-angle-left" /> {t("post_page.back_home", "Quay về trang chủ")}
                            </a>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <>
                    <div id="content">
                        <div className="record-detail-page">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="item-record pl-xl-4 containerOpenApp"
                                            playsInline={true}>
                                            {imageUrl ? (
                                                <img src={imageUrl} style={{ width: '100%', height: 'auto' }} alt="Hình ảnh bài viết" />
                                            ) : thumbnailImageUrl ? (
                                                <img src={thumbnailImageUrl} style={{ width: '100%', height: 'auto' }} alt="Thumbnail hình ảnh bài viết" />
                                            ) : (
                                                <img src="https://www.yokara.com/react-source/static/media/social_icon_yokara.fcb9e0c2.jpg" style={{ width: '100%', height: 'auto' }} alt="Hình ảnh mặc định" />
                                            )}
                                            {deeplink ? (
                                                <OpenApp href={deeplink}>
                                                    <button className='btnOpenApp'>Mở Ứng Dụng</button>
                                                </OpenApp>
                                            ) : (
                                                <span>Vui lòng đợi...</span>
                                            )}
                                        </div>
                                        <br />
                                        <div className="info-record pl-xl-4">
                                            <div className="middle" style={{ color: 'chocolate' }}>
                                                Mời bạn Mở Ứng Dụng để cùng vào hát Kara!<br />
                                                Tên bài thu: {songName}<br />
                                                Nội dung: {content}<br />
                                                ID bài viết: {postId}<br />
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );

        }
    }
}