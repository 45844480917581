import React, { Component } from 'react';
import bindModel from '../../../common/bindModel.js';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Parser, Player } from 'svga'
import SVGA from 'svgaplayerweb'
import $ from 'jquery';

export default class ModalUpdateBirthdayGift extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            accountType: "NORMAL",
            gifts: [],
            birthdayGift: null,
            commentFrame:[],
            avatarFrame:[],
            userDecoration:[],
            roomEntryEffect:[],
            personalCard:[],
            numGift: 0,
            giftOption:"",
            selectGift:[],
            selectItem:[],
            medalItems:[],
            frameItems:[]
        }
        this.uploadFileJson = this.uploadFileJson.bind(this);
        this.getGifts = this.getGifts.bind(this);
        this.getCommentFrame = this.getCommentFrame.bind(this);
        this.getAvatarFrame = this.getAvatarFrame.bind(this);
        this.getDecorationFrame = this.getDecorationFrame.bind(this);
        this.getRoomEntryFrame = this.getRoomEntryFrame.bind(this);
        this.getPersonalCardFrame = this.getPersonalCardFrame.bind(this);
        this.handleAddGift = this.handleAddGift.bind(this);
        this.handleChoiceGift = this.handleChoiceGift.bind(this);
        this.saveBirthdayGifts = this.saveBirthdayGifts.bind(this);
        this.handleDeleteGift = this.handleDeleteGift.bind(this);
        this.handleDeleteShopItem = this.handleDeleteShopItem.bind(this);
        this.handleAddFrame = this.handleAddFrame.bind(this);
        this.getDecorationItemType = this.getDecorationItemType.bind(this);
        this.loadSVGA = this.loadSVGA.bind(this);
        this.loadSVGA4 = this.loadSVGA4.bind(this);
    }

    async loadSVGA(id, url) {
        const parser = new Parser()
        const svga = await parser.load(url)

        const player = new Player(document.getElementById(id))
        await player.mount(svga)

        player.onStart = () => console.log('onStart')

        player.start()
    }

    loadSVGA4(url, canvasId) {
        var parser = new SVGA.Parser('#' + canvasId);
        parser.load(url, function (videoItem) {
            var player = new SVGA.Player('#' + canvasId);
            console.log("videoItem")
            console.log(canvasId)
            console.log(player)
            console.log(videoItem)
            player.setVideoItem(videoItem);
            // player.setFrame(0, 0, 750, 750);
            player.setContentMode("AspectFill")
            player.startAnimation();
        })
    }

    getDecorationItemType(){
       let url = global.config.apiDomain + "/rest/decorationItemsType/getAllItems";

        axios.post(url)
        .then(res =>{
            let arrMedal = [];
            let frameMedal = [];

            res.data && res.data.decorationItemTypes.map(item =>{
                if(item.type == "FRAME"){
                    frameMedal.push(item)
                }else{
                    arrMedal.push(item) 
                }
            })

            let medalStr =`<option value="0">Chọn</option>`;
            let frameStr =`<option value="0">Chọn</option>`;

            arrMedal.map(item =>{
                medalStr += `<option value='${item.id}'>${item.name}</option>`
            })

            frameMedal.map(item =>{
                frameStr += `<option value='${item.id}'>${item.name}</option>`
            })

            $("#medalRecordingSelect").append(`<div class='row'>
            <div class='col-9'>
            <select name='choiceMedal' id="choiceMedal" class="form-control mb-2 mt-2">${medalStr}</select>
            </div>
            <div class='col-3'><button class='btn btn-danger' id='btnDeleteMedal'>Xóa</button></div>
            </div>`)
            $("#frameRecordingSelect").append(`<div class='row'>
            <div class='col-9'>
            <select name='choiceFrameRecording' id="choiceFrameRecording" class="form-control mb-2 mt-2">${frameStr}</select>
            </div>
            <div class='col-3'><button class='btn btn-danger' id='btnDeleteFrame'>Xóa</button></div>
            </div>`)

            $(`#choiceMedal`).on("change",(e)=>{
                let id = e.target.value
                $("#reviewMedalRecordingSelect").html("")
                arrMedal.map(item =>{
                    if(item.id == id){
                        $("#reviewMedalRecordingSelect").html(`<img src='${item.resourceUrl}' style="max-height: 200px;" />`)
                    }
                })
            })

            $("#btnDeleteMedal").on('click',(e)=>{
                $("#choiceMedal").val("0")
                $("#reviewMedalRecordingSelect").html("")
            })

            $(`#choiceFrameRecording`).on("change",(e)=>{
                let id = e.target.value
                $("#reviewFrameRecordingSelect").html("")
                frameMedal.map(item =>{
                    if(item.id == id){
                        console.log(item.resourceUrl)
                        $("#reviewFrameRecordingSelect").html(`<img src='${item.resourceUrl}' style="max-height: 200px;" />`)
                    }
                })
            })

            $("#btnDeleteFrame").on('click',(e)=>{
                $("#choiceFrameRecording").val("0")
                $("#reviewFrameRecordingSelect").html("")
            })

            this.setState({
                medalItems:arrMedal,
                frameItems:frameMedal
            })
        })
        
    }

    componentWillReceiveProps(props) {
        const { birthdayGift } = props;
        const { giftOption, gifts, selectGift } = this.state;
        let thisItem = birthdayGift;
        if(birthdayGift == null){
            thisItem = {
                id: 0
            }

            this.setState({
                selectItem:[],
                selectGift: []
            })
            $("#choiceAvatar").val("0")
            $("#choiceComment").val("0")
            $("#choiceRoomEntry").val("0")
            $("#choiceDecor").val("0")
            $("#choiceCard").val("0")
            $("#choiceMedal").val("0")
            $("#choiceFrameRecording").val("0")

            $("#reviewAvatarFrameSelect").html("")
            $("#reviewCommentFrameSelect").html("")
            $("#reviewUserDecorSelect").html("")
            $("#reviewPersonalCardSelect").html("")
            $("#reviewRoomEntrySelect").html("")
            $("#reviewMedalRecordingSelect").html("")
            $("#reviewFrameRecordingSelect").html("")
            $("#gifts").html("")

        }

        if(birthdayGift && birthdayGift.typeAccount){
            $("#accountType").val(birthdayGift.typeAccount)
        }

        if(birthdayGift && birthdayGift.gifts){
            let nItem = 0;
            $("#gifts").html("");
            birthdayGift.gifts.map((item, index)=>{
                nItem = nItem +1;
                $("#gifts").append(`<div class='row'>
                <div class='col-9'><select name='addGift${index}' id='addGift${index}' class="form-control mb-2 mt-2">${giftOption}</select></div>
                <div class='col-3'><button class='btn btn-danger' id='btn${index}'>Xóa</button></div>
                </div>`)
        
                let arr = selectGift;
                let idC = "0";
        
                $(`#btn${index}`).on('click', ()=>{
                    let selectId =  $(`#addGift${index}`).val();
                    let deleteItem;
                    for(let i=0; i< arr.length ; i++){
                        if(arr[i].id == selectId){
                            deleteItem = arr[i]
                        }
                    }
        
                    let indexOfItem = arr.indexOf(deleteItem)
        
                    if(indexOfItem >= 0){
                        arr.splice(indexOfItem,1);
                    }

                    this.setState({
                        selectGift: arr
        
                    })
        
                    $(`#addGift${index}`).val("0")
                })
        
                $(`#addGift${index}`).on('focusin', function(e){
                    idC= e.target.value
                });
        
        
                $(`#addGift${index}`).on('change',(e)=>{
                    this.handleChoiceGift(e, idC, index)
                })
                 $(`#addGift${index}`).val(item.id)
            })

            this.setState({
                selectGift: birthdayGift.gifts,
                numGift: nItem
            })
        }

        if(birthdayGift && birthdayGift.shopItems){
            birthdayGift.shopItems.map((item,index)=>{
                switch(item.type){
                    case "AvatarFrame":
                        $("#choiceAvatar").val(item._id)
                        
                        if(item.resourceUrl && item.resourceUrl.endsWith(".png")){
                            $("#reviewAvatarFrameSelect").html(`<img src='${item.resourceUrl}' />`)
                        }else{
                            this.loadSVGA4(item.resourceUrl,"reviewAvatarFrameSelect")
                        }
                        break
                    case "CommentFrame":
                        $("#choiceComment").val(item._id)

                        if(item.resourceUrl && item.resourceUrl.endsWith(".png")){
                            $("#reviewCommentFrameSelect").html(`<img src='${item.resourceUrl}' />`)
                        }else{
                            this.loadSVGA4(item.resourceUrl,"reviewCommentFrameSelect")
                        }

                        break
                    case "UserDecoration":
                        $("#choiceDecor").val(item._id)

                        if(item.resourceUrl && item.resourceUrl.endsWith(".png")){
                            $("#reviewUserDecorSelect").html(`<img src='${item.resourceUrl}' />`)
                        }else{
                            this.loadSVGA4(item.resourceUrl,"reviewUserDecorSelect")
                        }

                        break
                    case "RoomEntryEffect":
                        $("#choiceRoomEntry").val(item._id)

                        if(item.resourceUrl && item.resourceUrl.endsWith(".png")){
                            $("#reviewRoomEntrySelect").html(`<img src='${item.resourceUrl}' />`)
                        }else{
                            this.loadSVGA4(item.resourceUrl,"reviewRoomEntrySelect")
                        }
                       
                        break
                    case "PersonalCard":
                        $("#choiceCard").val(item._id)
                        if(item.resourceUrl && item.resourceUrl.endsWith(".png")){
                            $("#reviewPersonalCardSelect").html(`<img src='${item.resourceUrl}' />`)
                        }else{
                            this.loadSVGA4(item.resourceUrl,"reviewPersonalCardSelect")
                        }
                        break
                }
            })

            this.setState({
                selectItem: birthdayGift.shopItems
            })
        }

        if(birthdayGift && birthdayGift.medalRecording){
            let value = birthdayGift.medalRecording.id;
            console.log("value",value)
            $("#choiceMedal").val(value);
            $("#reviewMedalRecordingSelect").html(`<img src='${birthdayGift.medalRecording.resourceUrl}' style="max-height: 200px;"/>`)
        }

        if(birthdayGift && birthdayGift.frameRecording){
            let value = birthdayGift.frameRecording.id;
            $("#choiceFrameRecording").val(value);
            $("#reviewFrameRecordingSelect").html(`<img src='${birthdayGift.frameRecording.resourceUrl}' style="max-height: 200px;"/>`)
        }
        this.setState({
            birthdayGift: thisItem
        })
    }
       
    uploadFileJson(event) {
        var target = event.target;
        const namejson = target.files[0].name;
        const targetFIle = target.files[0];
        const fileReader1 = new FileReader();
        fileReader1.readAsDataURL(target.files[0]);

        var decorationItemType = this.state.decorationItem;

        fileReader1.onload =  (fe) => {

                this.setState({
                    loading: true
                });
        
                var iconUrl = "";
                var frameUrl = "";
                var bucketName = "ikara-data/images/birthdayGifts";
                // var extension = target.files[0].name.split('.').pop().toLowerCase();
                var extension = namejson.split('.').pop().toLowerCase();
                var keyName = this.guid() + "." + extension;
                var contentType = "application/json";
                // if (extension == "png") contentType = "image/png";
        
                var fileReader = new FileReader();
        
                fileReader.onload = function () {
                    var fd = new FormData();
                    fd.append('file', targetFIle);
                    fd.append('bucketName', bucketName);
                    fd.append('objectKey', keyName);
                    fd.append('contentType', contentType)
                    $.ajax({
                        url: global.config.apiDomain + '/web.UploadFile',
                        data: fd,
                        processData: false,
                        contentType: false,
                        type: 'POST',
                        success: async function (data) {
                            console.log(data)
                            this.setState({
                                loading: false
                            });
        
                            iconUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                            decorationItemType.resourceUrl = iconUrl;
                            this.state.decorationItem.resourceUrl = iconUrl;

                            this.setState({
                                decorationItemType: decorationItemType
                            })

                            $("#sweetHeartUrlJson").html(`<a href=${iconUrl} target="blank">${iconUrl}</a>`)

                        }.bind(this),
                        error: function (request, status, error) {
                            toast.error("Đang xảy ra lỗi, xin hãy thử lại nha!");
                        }.bind(this),
                    });
        
                }.bind(this);
                fileReader.readAsArrayBuffer(targetFIle);
            // }.bind(this);
        }
    }

    componentDidMount(){
        this.getAvatarFrame();
        this.getCommentFrame();
        this.getDecorationFrame();
        this.getGifts();
        this.getRoomEntryFrame();
        this.getPersonalCardFrame();
        this.getDecorationItemType();
    }

    saveBirthdayGifts(){
        const { selectGift, selectItem, birthdayGift, accountType, medalItems, frameItems, commentFrame, avatarFrame, userDecoration, personalCard,
        roomEntryEffect } = this.state;
        let medalId = $('#choiceMedal').val();
        let frameId = $('#choiceFrameRecording').val();
        let choiceCard = $('#choiceCard').val();
        let choiceAvatar = $('#choiceAvatar').val();
        let choiceDecor = $('#choiceDecor').val();
        let choiceComment = $('#choiceComment').val();
        let choiceRoomEntry = $('#choiceRoomEntry').val();
        let accountTypeChoice = $('#accountType').val();

        let arr =[];

        console.log("frameId",frameId)
        console.log("medalId",medalId)
        let medal =  null;
        let frame =  null;

        if(choiceCard != 0){
            personalCard.map(item =>{
                if(item._id == choiceCard ){
                    arr.push(item)
                }
            })
        }

        if(choiceAvatar != 0){
            avatarFrame.map(item =>{
                if(item._id == choiceAvatar ){
                    arr.push(item)
                }
            })
        }

        if(choiceComment != 0){
            commentFrame.map(item =>{
                if(item._id == choiceComment ){
                    arr.push(item)
                }
            })
        }

        if(choiceDecor != 0){
            userDecoration.map(item =>{
                if(item._id == choiceDecor ){
                    arr.push(item)
                }
            })
        }

        if(choiceRoomEntry != 0){
            roomEntryEffect.map(item =>{
                if(item._id == choiceRoomEntry ){
                    arr.push(item)
                }
            })
        }

        if(medalId != 0){
            medalItems.map(item =>{
                if(item.id == medalId ){
                    medal = item;
                }
            })
        }

        if(medalId != 0){
            frameItems.map(item =>{
                if(item.id == frameId ){
                    frame = item;
                }
            })
        }
        let json = {
            id: birthdayGift.id,
            typeAccount: accountTypeChoice,
            gifts: selectGift,
            shopItems: arr,
            description: birthdayGift.description,
            deleted: false,
            medalRecording: medal,
            frameRecording: frame
        }

        // if(json.gifts.length == 0){
        //     toast.warning("Vui lòng chọn quà")
        //     return
        // }

        if(json.shopItems.length == 0){
            toast.warning("Vui lòng chọn trang trí")
            return
        }


        this.setState({
            loading: true
        })
        axios.post(global.config.apiDomain + "/rest/birthday/createOrUpdate", json)
        .then(res =>{
            if(res.data.status == "OK"){
                window.$("#modalUpdateBirthdayGift").modal("hide");
            }
            toast.success(res.data.message)
            this.setState({
                loading: false
            })
        })
    }

    handleChoiceGift(event, preValue, numGift){
        const { giftOption, gifts, selectGift } = this.state;
        let arr = selectGift;
        console.log("arr", arr)
        console.log("event", event.target.value)
        if(event.target.value !== 0 && event.target.value !== "0"){
        for(let i=0; i<gifts.length; i++){
            if(gifts[i].id == event.target.value){
                if(arr.indexOf(gifts[i]) < 0){
                    arr.splice(numGift,1,gifts[i])
                }else{
                    toast.warning('quà này đã được chọn')
                    event.target.value = preValue
                }
                break
            }
        }
        this.setState({
            selectGift: arr
        })
       }
    }

    handleDeleteGift(giftId){
        const { selectGift } = this.state;
        let deleteItem;
        selectGift && selectGift.map(item =>{
            if(item.id == giftId){
                deleteItem = item;
            }
        })

        let indexOfItem = selectGift.indexOf(deleteItem);

        if(indexOfItem >= 0){
            selectGift && selectGift.splice(selectGift.indexOf(deleteItem),1)
            this.setState({
                selectGift: selectGift
            })
        }
    }

    handleDeleteShopItem(idDelete){
        const { selectItem } = this.state;
        let deleteItem;
        
        selectItem && selectItem.map(item =>{
            if(item.id == idDelete){
                deleteItem = item;
            }
        })
        let indexOfItem = selectItem.indexOf(deleteItem);

        if(indexOfItem >= 0){
            selectItem && selectItem.splice(selectItem.indexOf(deleteItem),1)
            this.setState({
                selectGift: selectItem
            })
        }
    }

    handleAddFrame(event, type){
        const { giftOption, gifts, selectItem, avatarFrame, commentFrame, userDecoration, roomEntryEffect, personalCard } = this.state;

        console.log(type)
        let arr ;
        let choiceArr = selectItem;
        let previewId = "reviewAvatarFrameSelect"

        switch(type){
            case "CommentFrame": arr = commentFrame;
                previewId = "reviewCommentFrameSelect"
                break;
            case "UserDecoration": arr = userDecoration;
                previewId = "reviewUserDecorSelect"
                break;
            case "RoomEntryEffect": arr = roomEntryEffect;
                previewId = "reviewRoomEntrySelect"
                break;
            case "PersonalCard": arr = personalCard;
                previewId = "reviewPersonalCardSelect"
                break;
            default: arr = avatarFrame;
        }
        
        if(event.target.value !== 0 && event.target.value !== "0"){
            $(`#${previewId}`).html("")
            for(let i=0; i< arr.length; i++){
                if(arr[i]._id == event.target.value){
                
                //get item in select list
                let thisItem;
                for(let i=0; i< choiceArr.length ; i++){
                    if(choiceArr[i].type == type){
                        thisItem = choiceArr[i]
                    }
                }

                //get index
                let index  = choiceArr.indexOf(thisItem);

                //delete if index < 0
                if(index >= 0){
                    choiceArr.splice(index,1,arr[i])
                }else{
                    choiceArr.push(arr[i])
                }

                // $(`#${previewId}`).html(`<img src=${arr[i].thumbnailUrl} />`)

                if(arr[i].resourceUrl && arr[i].resourceUrl.endsWith(".png")){
                    $(`#${previewId}`).html(`<img src='${arr[i].resourceUrl}' />`)
                }else{
                    this.loadSVGA4(arr[i].resourceUrl,previewId)
                }
                break
            }
        }
        this.setState({
            selectItem: choiceArr
        })
       }

    }

    getGifts() {
        axios.post(global.config.apiDomain + '/rest/gifts')
            .then(res => {
                this.setState({
                    gifts: res.data,
                });
                let str =`<option value="0">Chọn</option>`;
                res.data && res.data.map(gift =>{
                    str += `<option value="${gift.id}">${gift.id}</option>`
                })

                this.setState({
                    giftOption: str
                })
            })
    }

    getCommentFrame(reset) {
       const { selectItem } =this.state;
        let obj = {
            typeString: "CommentFrame"
        }

        axios.post(global.config.apiDomain + '/rest/shop-items/getItemWithPage', obj)
            .then(res => {

                this.setState({
                    commentFrame: res.data.listItem,
                });

                let str =`<option value="0">Chọn</option>`;
                res.data && res.data.listItem &&  res.data.listItem.map(item =>{
                    str += `<option value='${item._id}'>${item.name}</option>`
                })

                $("#commentFrameSelect").append(`<div class='row'>
                <div class='col-9'>
                <select name='commentFrameSelect' id="choiceComment" class="form-control mb-2 mt-2">${str}</select>
                </div>
                <div class='col-3'><button class='btn btn-danger' id='deleteCommentFrame'>Xóa</button></div>
                </div>`)

                $("#deleteCommentFrame").on('click',()=>{
                    let idDelete = $('#choiceComment').val();
                    // this.handleDeleteShopItem(idDelete)
                    let deleteItem;
                    let arr = selectItem;
        
                    arr && arr.map(item =>{
                        if(item._id == idDelete){
                            deleteItem = item;
                        }
                    })
                    let indexOfItem = arr.indexOf(deleteItem);
                
                    if(indexOfItem >= 0){
                        arr && arr.splice(arr.indexOf(deleteItem),1)
                        this.setState({
                            selectGift: arr
                        })
                    }

                    $('#choiceComment').val("0");
                    $("#reviewCommentFrameSelect").html("")
                })

                $("#choiceComment").on("change",(e)=>{
                    this.handleAddFrame(e, "CommentFrame")
                })
            });
    }
    getAvatarFrame(reset) {
       const { selectItem } = this.state;
        let obj = {
            typeString: "AvatarFrame"
        }

        let str =`<option value="0">Chọn</option>`;
        axios.post(global.config.apiDomain + '/rest/shop-items/getItemWithPage', obj)
        .then(res => {         
            this.setState({
                avatarFrame: res.data.listItem,
            });

            let str =`<option value="0">Chọn</option>`;
            res.data && res.data.listItem &&  res.data.listItem.map(item =>{
                str += `<option value='${item._id}'>${item.name}</option>`
            })

            $("#avatarFrameSelect").append(`
            <div class='row'>
            <div class='col-9'>
            <select name='avatarFrameSelect' id="choiceAvatar" class="form-control mb-2 mt-2">${str}</select>
            </div>
            <div class='col-3'><button class='btn btn-danger' id='deleteAvatarFrame'>Xóa</button></div>
            </div>`)

            $("#deleteAvatarFrame").on('click',()=>{
                let idDelete = $('#choiceAvatar').val();
                // this.handleDeleteShopItem(idDelete)

                let deleteItem;
                let arr = selectItem;
    
                arr && arr.map(item =>{
                    if(item._id == idDelete){
                        deleteItem = item;
                    }
                })
                let indexOfItem = arr.indexOf(deleteItem);
            
                if(indexOfItem >= 0){
                    arr && arr.splice(arr.indexOf(deleteItem),1)
                    this.setState({
                        selectGift: arr
                    })
                }

                $('#choiceAvatar').val("0");
                $("#reviewAvatarFrameSelect").html("")
            })

            $("#choiceAvatar").on("change",(e)=>{
                this.handleAddFrame(e, "AvatarFrame")
            })
            });
    }
    getDecorationFrame(reset) {
       const { selectItem }  = this.state;
        let obj = {
            typeString: "UserDecoration"
        }

        axios.post(global.config.apiDomain + '/rest/shop-items/getItemWithPage', obj)
            .then(res => {

                this.setState({
                    userDecoration: res.data.listItem,
                });

                let str =`<option value="0">Chọn</option>`;
                res.data && res.data.listItem &&  res.data.listItem.map(item =>{
                    str += `<option value='${item._id}'>${item.name}</option>`
                })

                $("#userDecorSelect").append(`<div class='row'>
                <div class='col-9'>
                <select name='userDecorSelect' id="choiceDecor" class="form-control mb-2 mt-2">${str}</select>
                </div>
                <div class='col-3'><button class='btn btn-danger' id='deleteUserDecor'>Xóa</button></div>
                </div>`)

                $("#deleteUserDecor").on('click',()=>{
                    let idDelete = $('#choiceDecor').val();
                    // this.handleDeleteShopItem(idDelete)
                    let deleteItem;
                    let arr = selectItem;
        
                    arr && arr.map(item =>{
                        if(item._id == idDelete){
                            deleteItem = item;
                        }
                    })
                    let indexOfItem = arr.indexOf(deleteItem);
                
                    if(indexOfItem >= 0){
                        arr && arr.splice(arr.indexOf(deleteItem),1)
                        this.setState({
                            selectGift: arr
                        })
                    }
                    $('#choiceDecor').val("0");
                    $("#reviewUserDecorSelect").html("")
                })

                $("#choiceDecor").on("change",(e)=>{
                    this.handleAddFrame(e, "UserDecoration")
                })
            });
    }
    getRoomEntryFrame(reset) {
        const { selectItem } = this.state;
       
        let obj = {
            typeString: "RoomEntryEffect"
        }

        axios.post(global.config.apiDomain + '/rest/shop-items/getItemWithPage', obj)
            .then(res => {

                this.setState({
                    roomEntryEffect: res.data.listItem,
                });

                let str =`<option value="0">Chọn</option>`;
                res.data && res.data.listItem &&  res.data.listItem.map(item =>{
                    str += `<option value='${item._id}'>${item.name}</option>`
                })

                $("#roomEntrySelect").append(`<div class='row'>
                <div class='col-9'>
                <select name='roomEntrySelect' id="choiceRoomEntry" class="form-control mb-2 mt-2">${str}</select>
                </div>
                <div class='col-3'><button class='btn btn-danger' id='deleteRoomEntry'>Xóa</button></div>
                </div>`)

                $("#deleteRoomEntry").on('click',()=>{
                    let idDelete = $('#choiceRoomEntry').val();
                    // this.handleDeleteShopItem(idDelete);
                    let deleteItem;
                    let arr = selectItem;
        
                    arr && arr.map(item =>{
                        if(item._id == idDelete){
                            deleteItem = item;
                        }
                    })
                    let indexOfItem = arr.indexOf(deleteItem);
                
                    if(indexOfItem >= 0){
                        arr && arr.splice(arr.indexOf(deleteItem),1)
                        this.setState({
                            selectGift: arr
                        })
                    }
                    $('#choiceRoomEntry').val("0");
                    $("#reviewRoomEntrySelect").html("")
                })

                $("#choiceRoomEntry").on("change",(e)=>{
                    this.handleAddFrame(e, "RoomEntryEffect")
                })
            });
    }
    getPersonalCardFrame(reset) {

        const { selectItem } = this.state;
       
        let obj = {
            typeString: "PersonalCard"
        }

        axios.post(global.config.apiDomain + '/rest/shop-items/getItemWithPage', obj)
            .then(res => {

                this.setState({
                    personalCard: res.data.listItem,
                });

                let str =`<option value="0">Chọn</option>`;
                res.data && res.data.listItem &&  res.data.listItem.map(item =>{
                    str += `<option value='${item._id}'>${item.name}</option>`
                })

                $("#personalCardSelect").append(`<div class='row'>
                <div class='col-9'>
                <select name='personalCardSelect' id="choiceCard" class="form-control mb-2 mt-2">${str}</select>
                </div>
                <div class='col-3'><button class='btn btn-danger' id='deletePersonalCard'>Xóa</button></div>
                </div>`)

                $("#deletePersonalCard").on('click',()=>{
                    let idDelete = $('#choiceCard').val();
                    // this.handleDeleteShopItem(idDelete)

                    let deleteItem;
                    let arr = selectItem;
        
                    arr && arr.map(item =>{
                        if(item._id == idDelete){
                            deleteItem = item;
                        }
                    })
                    let indexOfItem = arr.indexOf(deleteItem);
                
                    if(indexOfItem >= 0){
                        arr && arr.splice(arr.indexOf(deleteItem),1)
                        this.setState({
                            selectGift: arr
                        })
                    }
                    $('#choiceCard').val("0")
                    $("#reviewPersonalCardSelect").html("")
                })

                $("#choiceCard").on("change",(e)=>{
                    this.handleAddFrame(e, "PersonalCard")
                })
            })
    }

    handleAddGift(){
        const { numGift, giftOption, gifts, selectGift } = this.state;
        console.log(numGift)
        $("#gifts").append(`<div class='row'>
        <div class='col-9'><select name='addGift${numGift}' id='addGift${numGift}' class="form-control mb-2 mt-2">${giftOption}</select></div>
        <div class='col-3'><button class='btn btn-danger' id='btn${numGift}'>Xóa</button></div>
        </div>`)

        let arr = selectGift;
        let idC = "0";

        $(`#btn${numGift}`).on('click', ()=>{
            let selectId =  $(`#addGift${numGift}`).val();
            let deleteItem;
            for(let i=0; i< arr.length ; i++){
                if(arr[i].id == selectId){
                    deleteItem = arr[i]
                }
            }

            let indexOfItem = arr.indexOf(deleteItem)

            if(indexOfItem >= 0){
                arr.splice(indexOfItem,1);
            }

            $(`#addGift${numGift}`).val("0")
            this.setState({
                selectGift: arr
            })
        })

        $(`#addGift${numGift}`).on('focusin', function(e){
            idC= e.target.value
        });


        $(`#addGift${numGift}`).on('change',(e)=>{
            this.handleChoiceGift(e, idC, numGift)

            // for(let i=0; i<gifts.length; i++){
            //     if(gifts[i].id == e.target.value){
            //         if(arr.indexOf(gifts[i]) < 0){
            //             arr.push(gifts[i])
            //         }else{
            //             toast.warning('quà này đã được chọn')
            //             $(`#addGift${numGift}`).val(idC)
            //         }
            //         break
            //     }
            // }
        })
        let number = Number(numGift) +1;
        this.setState({
            numGift: number
        })
    }


    // loadSVGA4(url) {
    //     var player = new SVGA.Player('#demoCanvas4');
    //     var parser = new SVGA.Parser('#demoCanvas4');
    //     parser.load(url, function (videoItem) {
    //         console.log(videoItem)
    //         player.setVideoItem(videoItem);
    //         player.startAnimation();
    //     })
    // }


    render() {
        const { birthdayGift, avatarFrame, commentFrame, userDecoration, roomEntryEffect, personalCard } = this.props;
        const model = bindModel(this);
        return (
            <div>
                <div className="modal fade" id="modalUpdateBirthdayGift" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật quà sinh nhật</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div id="order" className="row mb-2">
                                    <div className="col-3">
                                        <label>ID</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="number"
                                            name="order"
                                            disabled={true}
                                            placeholder="ID được tự động tạo"
                                            {...model('birthdayGift.id')} />
                                    </div>
                                </div>
                                <div id="order" className="row mb-2">
                                    <div className="col-3">
                                        <label>Loại tài khoản</label>
                                    </div>
                                    <div className="col-9">
                                        <select name="accountType" id="accountType" className='form-control mb-2 w-50'
                                        onChange={(e)=>{
                                            this.setState({
                                                accountType: e.target.value
                                            })
                                        }}>
                                            <option value="NORMAL">NORMAL</option>
                                            <option value="VIP1">VIP1</option>
                                            <option value="VIP2">VIP2</option>
                                            <option value="VIP3">VIP3</option>
                                            <option value="VIP4">VIP4</option>
                                            <option value="VIP5">VIP5</option>
                                            <option value="VIP6">VIP6</option>
                                            <option value="VIP7">VIP7</option>
                                            <option value="VIP8">VIP8</option>
                                            <option value="VIP9">VIP9</option>
                                            <option value="VIP10">VIP10</option>
                                            <option value="VIP11">VIP11</option>
                                            <option value="VIP12">VIP12</option>
                                        </select>
                                    </div>
                                </div>
                                <div id="order" className="row mb-2">
                                            <div className="col-3">
                                                <label>Mô tả</label>
                                            </div>
                                            <div className="col-9">
                                                <input
                                                    name="order"
                                                    className='form-control'
                                                    {...model('birthdayGift.description')} />
                                            </div>
                                </div>
                                <div id="order" className="row mb-2">
                                            <div className="col-3">
                                                <label>Quà</label>
                                            </div>
                                            <div className="col-9" id='gifts'>
                                            </div>
                                            <div className="col-3">
                                                <label></label>
                                            </div>
                                            <div className="col-9">
                                                <button className='btn btn-success'
                                                onClick={this.handleAddGift}
                                                >Thêm</button>
                                            </div>
                                </div>
                                {/* chọn avatarFrame */}
                                <div id="order" className="row mb-2">
                                            <div className="col-3">
                                                <label>Khung avatar</label>
                                            </div>
                                            <div className="col-9" id="avatarFrameSelect">
                                                
                                            </div>

                                            <div className="col-3">
                                                
                                            </div>
                                            <div className="col-9" id="reviewAvatarFrameSelect" style={{ maxHeight: "200px", maxWidth: "200px", align: 'center' }}> 
                                            <div width={50} height={50} id="canvas"></div>
                                            </div>
                                </div>
                                 {/* chọn comment frame */}
                                <div id="order" className="row mb-2">
                                            <div className="col-3">
                                                <label>Khung bình luận</label>
                                            </div>
                                            <div className="col-9" id="commentFrameSelect">
                                            </div>

                                            <div className="col-3">
                                                
                                            </div>
                                            <div className="col-9" id="reviewCommentFrameSelect" style={{ maxHeight: "200px", maxWidth: "200px", align: 'center' }}>
                                            <div width={50} height={50} id="canvas"></div>
                                            </div>
                                </div>
                                {/* chọn room entry effect */}
                                <div id="order" className="row mb-2">
                                            <div className="col-3">
                                                <label>Hiệu ứng vào phòng</label>
                                            </div>
                                            <div className="col-9" id="roomEntrySelect">
                                            </div>

                                            <div className="col-3">
                                                
                                            </div>
                                            <div className="col-9" id="reviewRoomEntrySelect" style={{ maxHeight: "200px", maxWidth: "200px", align: 'center' }}>
                                            <div width={50} height={50} id="canvas"></div>
                                            </div>
                                </div>
                                {/* chọn user decoration */}
                                <div id="order" className="row mb-2">
                                            <div className="col-3">
                                                <label>Trang trí cá nhân</label>
                                            </div>
                                            <div className="col-9" id="userDecorSelect">
                                            </div>
                                            <div className="col-3">
                                                
                                            </div>
                                            <div className="col-9" id="reviewUserDecorSelect" style={{ maxHeight: "500px", maxWidth: "200px", align: 'center' }}>
                                            {/* <div width={50} height={50} id="canvas"></div> */}
                                            </div>
                                </div>
                                {/* chọn personal card */}
                                <div id="order" className="row mb-2">
                                            <div className="col-3">
                                                <label>Thẻ cá nhân</label>
                                            </div>
                                            <div className="col-9" id="personalCardSelect">
                                            </div>
                                            <div className="col-3">
                                                
                                            </div>
                                            <div className="col-9" id="reviewPersonalCardSelect" style={{ maxHeight: "200px", maxWidth: "200px", align: 'center' }}>
                                            <div width={50} height={50} id="canvas"></div>
                                            </div>
                                </div>

                                {/* chọn huy hiệu bài thu */}
                                <div id="order" className="row mb-2">
                                            <div className="col-3">
                                                <label>Huy hiệu bài thu</label>
                                            </div>
                                            <div className="col-9" id="medalRecordingSelect">
                                            </div>
                                            <div className="col-3">
                                                
                                            </div>
                                            <div className="col-9" id="reviewMedalRecordingSelect" style={{ maxHeight: "300px" }}>
                                            <div width={50} height={50} id="canvas"></div>
                                            </div>
                                </div>

                                {/* chọn khung bài thu */}
                                <div id="order2" className="row mb-2">
                                            <div className="col-3">
                                                <label>Khung bài thu</label>
                                            </div>
                                            <div className="col-9" id="frameRecordingSelect">
                                            </div>
                                            <div className="col-3">
                                                
                                            </div>
                                            <div className="col-9" id="reviewFrameRecordingSelect" style={{ maxHeight: "300px" }}>
                                            <div width={50} height={50} id="canvas"></div>
                                            </div>
                                </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal" >Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.saveBirthdayGifts}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }

}