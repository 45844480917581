import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Base64 } from 'js-base64';

export default class ModalDeleteRecordingVip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recordingVip: null,
        };
        this.deleteRecordingVip = this.deleteRecordingVip.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            recordingVip: props.recordingVip,
        });
    }

    deleteRecordingVip() {
        axios.post(global.config.apiDomain + '/rest/recordingvip/cancel', this.state.recordingVip)
            .then(res => {
                if (res.data.status == "OK") {
                    window.$("#modalDeleteRecordingVip").modal("hide");
                    toast.success(res.data.message);
                     window.location.reload();
                } else {
                    toast.warning(res.data.message);
                }
            })
    }


    render() {
        const { recordingVip } = this.state;
        return (
            <div>
                <div className="modal fade" id="modalDeleteRecordingVip" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">

                            </div>
                            <div className="modal-body">
                                {recordingVip && (
                                    <div>
                                        <h5 className="modal-title" id="exampleModalLabel">Bạn có muốn hủy xử lý bài thu :<b> {recordingVip.songName}</b></h5>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                    Không
                                </button>
                                <button type="button" className="btn btn-danger" onClick={this.deleteRecordingVip}>
                                    Có
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
