import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import ModalDeleteRecordingVip from './components/ModalDeleteRecordingVip';

export default class RecordingVipPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recordingsVip: [],
      cursor: null,
      loading: false,
      statusButtonLoadPage: false,
      recordingVip: null,
   
    }
    this.getVipRecordingList = this.getVipRecordingList.bind(this);
    this.changeStatusProcessing = this.changeStatusProcessing.bind(this);
    this.deleteRecordingVip = this.deleteRecordingVip.bind(this);
  }

  // componentWillReceiveProps() {
  //   this.getVipRecordingList();
  // }

  componentDidMount() {
    this.getVipRecordingList();
  }

  getVipRecordingList() {
    const { loading } = this.state;
    if (!loading) {
      this.setState({
        loading: true
      });
      let json = {
        cursor: this.state.cursor
      }
      axios.post(global.config.apiDomain + '/rest/recordingvip/list', json)
        .then(res => {
          let status = true;
          if (res.data.cursor == "END") {
            status = false;
          }

          this.setState({
            recordingsVip: this.state.recordingsVip.concat(res.data.recordingsVip),
            cursor: res.data.cursor,
            statusButtonLoadPage: status,
            loading: false
          })
         
        });
    }
  }

  changeStatusProcessing(recordingId) {
    axios.post(global.config.apiDomain + '/rest/recordingvip/processing/' + recordingId)
        .then((res) => {});
  }

  deleteRecordingVip(event) {
    let index = parseInt(event.target.name);
    let recordingVip = this.state.recordingsVip[index];
    this.setState({
      recordingVip: recordingVip
    })
  
  }
 
  getStatus(status) {
    switch(status) {
        case 'NEW':
            return <i className="fa-solid fa-ban fa-xl text-danger"></i>
        case 'PROCESSED':
            return <i class="fa-solid fa-circle-check fa-xl text-success"></i>
        case 'PROCESSING':
          return <i class="fas fa-cog fa-spin fa-xl text-primary"></i>
        case 'REVERTED':
            return <i className="fa-solid fa-refresh fa-xl text-secondary"></i>
        case 'CANCELED':
          return <i className="fa-solid fa-trash fa-xl text-secondary"></i>
        default:
            return <i className="fa-solid fa-ban fa-xl text-danger"></i>
    }
   
}

  render() {
    const { recordingsVip, statusButtonLoadPage, loading,recordingVip } = this.state;
    
    return (
      <>
        <ModalDeleteRecordingVip
          recordingVip={recordingVip}
          >
        </ModalDeleteRecordingVip>
        <div id="content">
          <div className="container-fluid">
          <h1 className="text-center">Danh sách Yêu cầu Xử lý Bài thu</h1>  
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Trạng thái</th>
                  <th scope="col">Thời gian</th>
                  <th scope="col">ID User</th>
                  <th scope="col">Tên User</th>
                  <th scope="col">Link Bài thu</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {recordingsVip && recordingsVip.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="align-middle">{item.uid}</td>
                      <td className="align-middle">{this.getStatus(item.status)}</td>
                      <td className="align-middle">{new Date(item.addTime).toLocaleString()}</td>
                      <td className="align-middle"><strong>{item.owner.uid}</strong></td>
                      <td className="align-middle">{item.owner.name}</td>
                      <td className="align-middle"><a href={item.onlineRecordingUrl}>{item.songName}</a></td>

                      <td className="align-middle" style={{width: "100px"}}>
                        <Link  to={'/admin/recording-ai/detail/' + item.id}>
                          <button
                            onClick={() => this.changeStatusProcessing(item.id)} 
                            className='btn btn-success'
                          >
                            Xử lý
                          </button>
                        </Link>

                      </td>
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={this.deleteRecordingVip}
                          name={index}
                          data-toggle="modal"
                          data-target="#modalDeleteRecordingVip"
                        >
                        Hủy xử lý
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {
              statusButtonLoadPage ?
                (<div onClick={this.getVipRecordingList} className="view_more w-100 text-center mb-lg-4">
                  <a className="d-none d-md-block">
                    <span>{loading ? "ĐANG TẢI ..." : "TẢI THÊM"}</span>
                  </a>
                  <a className="view_more_mobile d-md-none">
                    <i className="fa fa-angle-down" aria-hidden="true" />
                  </a>
                </div>) :
                ('')
            }
          </div>
        </div>
      </>
    );
  }
}