import React, { Component } from "react";
import GameLevel from "./components/GameLevel";
import GameType from "./components/GameType";
import { Link } from "react-router-dom";
import axios from 'axios';

export default class GameManagerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gameTypes: [],
            gameLevels: []
        }

        this.getGameTypes = this.getGameTypes.bind(this);
        this.getGameLevels = this.getGameLevels.bind(this);
    }

    componentDidMount() {
        this.getGameLevels();
        this.getGameTypes();
    }

    getGameTypes() {
        
        axios.post(global.config.apiDomain + '/rest/game/type/list').then((res) => {
          const gameTypes = res.data;
    
          this.setState({
            gameTypes: gameTypes
          });
        })
    }

    getGameLevels() {
        
        axios.post(global.config.apiDomain + '/rest/game/level/list').then((res) => {
          const gameLevels = res.data;
    
          this.setState({
            gameLevels: gameLevels
          });
        })

    }

    render() {
        const {gameTypes, gameLevels} = this.state;
        return (
            <div id="content">
                <h1 className="text-center">Trang quản lí Game</h1>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Level</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Thể loại game</a>
                    </li>
                    <li className="nav-item">
                        <Link to="/admin/game-manager/create-game-level" className="btn btn-red mt-1 mr-1" >Tạo Level +</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/admin/game-manager/create-game-type" className="btn btn-red mt-1" >Tạo thể loại game +</Link>
                    </li>
                </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <GameLevel getGameLevels={this.getGameLevels} gameLevels={gameLevels}></GameLevel>
                        </div>
                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <GameType getGameTypes={this.getGameTypes} gameTypes={gameTypes}></GameType>
                        </div>
                        <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div>
                    </div>
            </div>
        )
    }
}