import axios from 'axios';

let versionDeploy = '';
const initAuth = () => {
  return window.gapi.auth2.init({
    client_id: "103251569425059687765", //paste your client ID here
    scope: "https://www.googleapis.com/auth/analytics.readonly",
  });
};

export function detectMob() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

export function detectApp(language) {
  // if(window.location.hostname.includes("localhost")) return true;

  if (language == "okara") {
    if (window.location.hostname.includes("okara.la")
      || window.location.hostname.includes("okara.tls")
      || window.location.hostname.includes("mkaraoke.co")
      || window.location.hostname.includes("mz.ikara.co")
      || window.location.hostname.includes(".okara.co")
      || window.location.hostname.includes("okara.natcom.com.ht")) { //myanmar
      return true;
    }
  } else if (language == "my.okara") {
    if (window.location.hostname.includes(".myanmar.co")) { //myanmar
      return true;
    }
  } else if (language == "lo.okara") {
    if (window.location.hostname.includes("okara.la")) {
      return true;
    }
  } else if (language == "tl.okara") {
    if (window.location.hostname.includes("okara.tls")) {
      return true;
    }           
  } else if (language == "mz.okara") {
    if (window.location.hostname.includes("mkaraoke.co")
      || window.location.hostname.includes("mz.ikara.co")) {
      return true;
    }
  } else if (language == "ht.okara") {
    if (window.location.hostname.includes("okara.natcom.com.ht") 
    || window.location.hostname.includes(".okara.co")) {
      return true;
    }
  } else if (language == "ikarayokara") {
    if (window.location.hostname.includes("ikara.co")
      || window.location.hostname.includes("yokara.com")) {
      return true;
    }
  } else if (language == "vi") {
    if (window.location.hostname.includes("ikara.co")) {
      return true;
    }
  } else if (language == "mi") {
    if (window.location.hostname.includes("mikara.org")) {
      return true;
    }
  } else if (language == "en.yokara") {
    if (window.location.hostname.includes("yokara.com")) {
      return true;
    }
  } else if (language == "en.karaokestar") {
    return false;
  } else {
    if (window.location.hostname.includes("localhost")) {
      return true;
    }
  }

  return false;
}

export function getVersion() {
  if (versionDeploy == '') {
    var url = global.config.apiDomain + "/v35.GetVersionDeploy";
    axios.post(url, null, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      versionDeploy = res.data;
    })
  }


  if (window.location.hostname.includes("yokara.com")) {
    return global.config.yokara_version + "\n GAE: " + versionDeploy;
  } else if (window.location.hostname.includes(".ikara.co")) {
    return global.config.ikara_version + "\n GAE: " + versionDeploy;
  } else if (window.location.hostname.includes(".mikara.org")) {
    return global.config.mikara_version + "\n GAE: " + versionDeploy;
  } else if (window.location.hostname.includes("okara.la")) {
    return global.config.okara_lo_version + "\n GAE: " + versionDeploy;
  } else if (window.location.hostname.includes("okara.tls")) {
    return global.config.okara_tl_version + "\n GAE: " + versionDeploy;
  } else if (window.location.hostname.includes("okara.natcom.com.ht") || window.location.hostname.includes(".okara.co")) {
    return global.config.okara_ht_version + "\n GAE: " + versionDeploy;
  } else if (window.location.hostname.includes("mkaraoke.co") || window.location.hostname.includes("mz.ikara.co")) {
    return global.config.okara_mz_version + "\n GAE: " + versionDeploy;
  } else if (window.location.hostname.includes(".myanmar.co")) {
    return global.config.okara_my_version + "\n GAE: " + versionDeploy;
  } else {
    return global.config.ikara_version + "\n GAE: " + versionDeploy;
  }
}
