import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export default class ModalDelete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: null,
            loading: false
        }

        this.delete = this.delete.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            model: props.model
        })
    }

    delete() {
        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/item3D/delete', this.state.model)
            .then(res => {
                this.setState({
                    loading: false
                });

                if (res.data.status == "OK") {
                    window.$("#modalDelete").modal("hide");
                    toast.success(res.data.message);
                    this.props.resetList(this.state.model.category);
                } else {
                    toast.warning(res.data.message);
                }
            })
    }

    render() {
        const { model } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalDelete" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">XÓA LUÔN Vật phẩm</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {(model && model.name != '') ? (
                                <div className="modal-body">
                                    <h3 className="text-center text-danger">Chắc chắn XÓA: <span className="font-weight-bold">{model.name}</span> ?</h3>
                                </div>
                            ) : ""}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button
                                            style={{ width: "120px" }}
                                            type="button"
                                            className="btn btn-red"
                                            onClick={this.delete}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}