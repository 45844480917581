import React, { Component } from 'react'
import axios from 'axios';
import {withTranslation} from 'react-i18next';

import TopRecordingsItemInRecordingPage from './TopRecordingsItemInRecordingPage';

class TopRecordingsInRecordingPage extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             topRecordings :[]
        }
    }

    componentDidMount (){
      const json = JSON.stringify({ domain: window.location.hostname});

      axios.post(global.config.apiDomain + '/rest/toprecordingsinrecordingpage', json, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        const {topRecordings} = res.data;
        this.setState(
          {
            topRecordings: topRecordings,
          });
      })	
  
    }
  
    render() {
        const {topRecordings} = this.state;
        const {t} = this.props;
        return (
            <div className="record-sidebar">
                <h3 className="heading">{t("recording_page.top_recordings", "BXH Bài Thu")}</h3>{
                    topRecordings.map((recording)=>(
                        <TopRecordingsItemInRecordingPage key={recording._id} recording={recording}></TopRecordingsItemInRecordingPage>
                    ))
                }        
            </div>
        )
    }
}

export default withTranslation()(TopRecordingsInRecordingPage)

