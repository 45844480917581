import React, { Component } from 'react';
import MyContext from '../common/MyContext';
import { toast } from 'react-toastify';
import axios from 'axios';
import bindModel from '../common/bindModel.js';
import { withTranslation } from 'react-i18next';
const $ = window.$;

class ConfirmSubscribeOkaraDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sending: false,
            second: 120,
            otp: "",
            loading: false,
            counterSecond : null
        }

        this.reSendOTP = this.reSendOTP.bind(this);
        this.confirmSubscribe = this.confirmSubscribe.bind(this);
        this.loadUser = this.loadUser.bind(this);
        this.sendOTP = this.sendOTP.bind(this);
    }

    componentDidMount() {
        $('#modalVerifyOTPOkara').on('show.bs.modal', function (e) {
            this.counterSecond();
        }.bind(this));
    }

    counterSecond() {
        if(this.state.counterSecond != null && this.state.counterSecond != undefined) {
            clearInterval(this.state.counterSecond);
        }

        this.setState({
            second: 60,
            sending: true
        });

        this.state.counterSecond = setInterval(() => {
            let second = --this.state.second;
            this.setState({
                second: second
            })

            if (second <= 0) {
                clearInterval(this.state.counterSecond);
                this.setState({
                    sending: false
                })
            }
        }, 1000);
    }

    loadUser() {
        const json = JSON.stringify({ domain: window.location.hostname});

		axios.post(global.config.apiDomain + '/rest/loginuser', json, {
			headers: {
			  'Content-Type': 'application/json'
			}
		  }).then(res => {
			const {user} = res.data;
			this.context.updateUser(user); 
		  })
    }

    confirmSubscribe() {
        this.setState({
            loading: true
        });

        const { user, registerVipType } = this.context;

        const json = {
            facebookId: user.facebookId,
            otp: this.state.otp,
            domain: window.location.hostname,
            type: registerVipType
        }

        axios.post(global.config.apiDomain + '/rest/okara/confirmSubscribe', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {

            this.setState({
                loading: false
            });

            if (res.data.status == "OK") {
                this.loadUser();
                this.setState({
                    otp: ""
                });
                $("#modalVerifyOTPOkara").modal("hide");
            } else {
                this.setState({
                    second: 0,
                    sending: false
                });
                toast.warning(res.data.message);
            }
        });
    }

    sendOTP() {
        const { user,registerVipType } = this.context;

        const json = {
            facebookId: user.facebookId,
            domain: window.location.hostname,
            type: registerVipType
        }

        axios.post(global.config.apiDomain + '/rest/okara/subscribe', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.data.status == "OK") {
                // $("#modalGetVipOkara").modal("hide");
                // $("#modalVerifyOTPOkara").modal("show");
            } else if(res.data.status == "WAITING"){
                toast.warning(res.data.message);
                this.setState({
                    second: res.data.time,
                    sending: true
                });
            } else {
                toast.warning(res.data.message);
            }
        });
    }


    reSendOTP() {
        this.setState({
            second: 60,
            sending: true
        });

        this.sendOTP();

        const counterSecond = setInterval(() => {
            let second = --this.state.second;
            this.setState({
                second: second
            })

            if (second <= 0) {
                clearInterval(counterSecond);
                this.setState({
                    sending: false
                })
            }
        }, 1000);

    }

    render() {
        const model = bindModel(this);
        const {t} = this.props;
        return (
            <div className="modal fade" id="modalVerifyOTPOkara" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div style={{ "borderRadius": "20px" }} className="modal-content">
                        <div>
                            <button type="button" className="btn float-right" data-dismiss="modal" aria-label="Close">
                                <i className='fa fa-close' />
                            </button>
                        </div>
                        <div style={{ "paddingBottom": "2.0rem", "paddingRight": "2.0rem", "paddingLeft": "2.0rem" }} className="modal-body pt-0">
                            <div className='text-center'>
                                <h4 className='text-danger'>{t("confirm_subscribe_okara_dialog.title","Xác nhận OTP")}</h4>
                            </div>
                            <div className='mt-4 text-center'>
                                <p className='p-0 m-0'>{t("confirm_subscribe_okara_dialog.label_1","Mã otp đã gửi đến số điện thoại của quý khách")}</p>
                                <p className='p-0 m-0'>{t("confirm_subscribe_okara_dialog.label_2","Vui lòng nhập OTP để xác nhận")}</p>
                            </div>
                            <div className='mt-4'>
                                <input {...model('otp')} className='form-control' type='text' placeholder={t("confirm_subscribe_okara_dialog.input_otp","Nhập OTP")} />

                            </div>
                            <div className='mt-3 text-center'>
                                {this.state.sending ? (
                                    <>
                                        <p className='text-secondary p-0 m-0'>{t("confirm_subscribe_okara_dialog.sending","Đang gửi OTP")} ... ({this.state.second})s </p>
                                        <p className='text-secondary p-0 m-0'></p>
                                    </>
                                ) : (
                                    <>
                                        <p onClick={this.reSendOTP} style={{ "cursor": "pointer" }} className='text-danger p-0 m-0'><u>{t("confirm_subscribe_okara_dialog.resend_otp","Xác nhận OTP")}</u>  </p>
                                    </>
                                )}
                            </div>

                            <div className='mt-5 text-center'>
                                {this.state.loading ?
                                    (
                                        <button style={{ "borderRadius": "20px", "backgroundColor": "#fa882c" }} className="btn w-50 text-white" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            {t("confirm_subscribe_okara_dialog.confirm","Xác nhận")}...
                                        </button>
                                    ) :
                                    (
                                        <button
                                            onClick={this.confirmSubscribe}
                                            style={{ "borderRadius": "20px", "backgroundColor": "#fa882c" }}
                                            className='btn w-75 text-white'>{t("confirm_subscribe_okara_dialog.confirm","Xác nhận")}</button>
                                    )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}

ConfirmSubscribeOkaraDialog.contextType = MyContext;
export default withTranslation()(ConfirmSubscribeOkaraDialog)