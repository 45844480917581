import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {withTranslation} from 'react-i18next';
import axios from 'axios';

import MyContext from '../../../common/MyContext.js';

class OwnerInRecordingPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            recording: this.props.recording
        }

        this.addFriend = this.addFriend.bind(this);
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            recording: nextProps.recording
        })
     }

    addFriend(ownerId) {
        
        const {user} = this.context;
        const {recording} = this.state;

        const json = {
            userId: user.facebookId,
            toFacebookId: ownerId,
            language: global.config.language
        }

        axios.post(global.config.apiDomain + '/rest/add-friend', JSON.stringify(json), {
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(res => {
            const {status, message} = res.data;
            // if(status == 'OK') {
                if(ownerId == recording.owner.facebookId) {
                    recording.owner.friendStatus = 'FRIEND';
                }

                if(recording.owner2 != null) {
                    if(ownerId == recording.owner2.facebookId) {
                        recording.owner2.friendStatus = 'FRIEND';
                    }
                }


                this.setState({
                    recording: recording
                })
            // }
          })
    }

    unFriend(ownerId) {
        const {user} = this.context;
        const {recording} = this.state;

        const json = {
            fromFacebookId: user.facebookId,
            toFacebookId: ownerId,
            language: global.config.language
        }

        axios.post(global.config.apiDomain + '/rest/un-friend', JSON.stringify(json), {
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(res => {
            const {status, message} = res.data;
            if(status == 'OK') {
                if(ownerId == recording.owner.facebookId) {
                    recording.owner.friendStatus = 'NOTFRIEND';
                }

                if(recording.owner2 != null) {
                    if(ownerId == recording.owner2.facebookId) {
                        recording.owner2.friendStatus = 'NOTFRIEND';
                    }
                }


                this.setState({
                    recording: recording
                })
            }
          })
    }



    render() {
        const {user} = this.context;
        const {t} = this.props;
        if (this.state.recording == null){
            return (
                <></>
            )
        }
        const { owner, owner2 } = this.state.recording;

        if (owner == null) {
            return (
                <></>
            )
        }
        if (owner2 != null) {
            return (
                <div className="singerInfo">
                    <div className="singer1">
                        <Link to={"/user/"+ owner.facebookId}>
                        <img src={owner.profileImageLink} alt={owner.name} className="singerAvatar" onError={(e)=>{e.target.onerror = null; e.target.src=`https://picsum.photos/seed/${owner.facebookId}/150/150`}}/>
                        </Link>
                        <span className="singerText">
                            <strong className="singerName">{owner.name}</strong>
                            <p className="singerUid">ID {owner.uid}</p>
                        </span>

                        {owner.friendStatus == 'FRIEND' ? 
                        (
                            <a className="followButton" onClick={() => this.unFriend(owner.facebookId)}>✓ {t("recording_page.following", "Đang theo dõi")}</a>
                        ) : 
                        (
                            (user ? (
                                <a className="followButton" onClick={() => this.addFriend(owner.facebookId)}>+ {t("recording_page.follow", "Theo dõi")}</a>
                            ) : (
                                <a data-toggle="modal" data-target="#loginDialog" className="followButton">+ {t("recording_page.follow", "Theo dõi")}</a>
                            ))
                        )}
                    </div>
                    <div className="singer2">
                        <Link to={"/user/" + owner2.facebookId}>
                        <img src={owner2.profileImageLink} alt={owner2.name} className="singerAvatar" onError={(e)=>{e.target.onerror = null; e.target.src=`https://picsum.photos/seed/${owner2.facebookId}/150/150`}}/>
                        </Link>
                        <span className="singerText">
                            <strong className="singerName">{owner2.name}</strong>
                            <p className="singerUid">ID {owner2.uid}</p>
                        </span>
                        {owner2.friendStatus == 'FRIEND' ? 
                        (
                            <a className="followButton" onClick={() => this.unFriend(owner2.facebookId)}>✓ {t("recording_page.following", "Đang theo dõi")}</a>
                        ) : 
                        (
                            (user ? (
                                <a className="followButton" onClick={() => this.addFriend(owner2.facebookId)}>+ {t("recording_page.follow", "Theo dõi")}</a>
                            ) : (
                                <a data-toggle="modal" data-target="#loginDialog" className="followButton" >+ {t("recording_page.follow", "Theo dõi")}</a>
                            ))
                        )}
                    </div>
                </div>
            )
        } else {
            return (
                <div className="singerInfo">
                    <div className="singer1" style={{width: '100%'}}>
                        <Link to={"/user/" + owner.facebookId}>
                        <img src={owner.profileImageLink} alt={owner.name} className="singerAvatar" onError={(e)=>{e.target.onerror = null; e.target.src=`https://picsum.photos/seed/${owner.facebookId}/150/150`}}/>
                        </Link>
                        <span className="singerText" style={{width:'auto'}}>
                            <strong className="singerName">{owner.name}</strong>
                            <p className="singerUid">ID {owner.uid}</p>
                        </span>
                        {owner.friendStatus == 'FRIEND' ? (
                            <a 
                                className="followButton" 
                                style={{float: 'right', width: '200px', margin: '29.4px 20.4px 0px'}}
                                onClick={() => this.unFriend(owner.facebookId)}
                                >✓ {t("recording_page.following", "Đang theo dõi")}</a>
                        ) : (
                            (user ? 
                            (
                                <a 
                                    className="followButton" 
                                    style={{float: 'right', width: '200px', margin: '29.4px 20.4px 0px'}}
                                    onClick={() => this.addFriend(owner.facebookId)}
                                >+ {t("recording_page.follow", "Theo dõi")}</a>
                            ) : (
                                <a data-toggle="modal" data-target="#loginDialog" className="followButton">+ {t("recording_page.follow", "Theo dõi")}</a>
                            ))
                            
                        )}
                    </div>
                </div>
            )
        }
    }
}

OwnerInRecordingPage.contextType = MyContext;
export default withTranslation()(OwnerInRecordingPage)
