import React, {Component} from 'react';
import ModalUpdatePromotion from './components/ModalUpdatePromotion';
import axios from 'axios';
import ModalDeletePromotion from './components/ModalDeletePromotion';

import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';


export default class PromotionManagerPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            statusButtonLoadPage: false,
            page: 0,
            promotion: null,
            promotions: []
        }

        this.getNewPromotion = this.getNewPromotion.bind(this);
        this.HandlePromotion = this.HandlePromotion.bind(this);
        this.getPromotionList = this.getPromotionList.bind(this);
        this.resetPromotionList = this.resetPromotionList.bind(this);
    }

    componentWillMount() {
        this.getPromotionList();
    }

    resetPromotionList() {
        this.setState({
            loading: true
        });
        
        let lastPromotionsSize = this.state.page * 5;

        axios.get(global.config.apiDomain + '/rest/promotion?page=0&size=' + lastPromotionsSize)
        .then(res => {
            let status = true;
            if (res.data.length < 5) {
                status = false;
            }
            this.setState({
                promotions: res.data,
                statusButtonLoadPage: status,
                loading: false
            })
        })
        .catch(error => {
            this.setState({
                loading: false
            });
            console.error("Error:", error);
        });;

    }

    getPromotionList() {
        this.setState({
            loading: true
        });
        
        axios.get(global.config.apiDomain + '/rest/promotion?page=' + this.state.page + "&size=5")
        .then(res => {
            let promotions = res.data;
            let status = true;
            if (promotions.length < 5) {
                status = false;
            }
            this.setState({
                promotions: this.state.promotions.concat(promotions),
                page: this.state.page + 1,
                statusButtonLoadPage: status,
                loading: false
            })
        })
        .catch(error => {
            this.setState({
                loading: false
            });
            console.error("Error:", error);
        });;
    }

    getNewPromotion() {
        axios.post(global.config.apiDomain + '/rest/promotion/create', JSON.stringify(''), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                promotion: res.data
            })
        })
    }


    HandlePromotion(promotion) {
        this.setState({
            promotion: promotion
        })
    }

    render() {
        console.log("Render component");
        console.log("Promotions: ", this.state.promotions)
        const {promotion, promotions, statusButtonLoadPage, loading} = this.state;
        return (
            <>
                <ModalUpdatePromotion 
                    promotion={promotion}
                    resetList={this.resetPromotionList}></ModalUpdatePromotion>
                <ModalDeletePromotion 
                    promotion={promotion}
                    resetList={this.resetPromotionList}></ModalDeletePromotion>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí khuyến mãi</h1>
                        <button 
                            className="btn btn-red mb-1" 
                            data-toggle="modal" 
                            data-target="#modalUpdatePromotion"
                            onClick={this.getNewPromotion}>+ Tạo khuyến mãi
                        </button>
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th width={200} scope="col">Id</th>
                                <th scope="col">Banner</th>
                                <th scope="col">Ngày bắt đầu</th>
                                <th scope="col">Ngày kết thúc</th>
                                <th scope="col">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {promotions.map((promotion, index) => {
                                return (
                                    <tr key={promotion.id}>
                                        <th scope="row">{promotion.id}</th>
                                        <td><img width={250} src={promotion.banner.thumbnail} /></td>
                                        <td> {new Date(promotion.startDate).toLocaleString()} </td>
                                        <td>{new Date(promotion.endDate).toLocaleString()}</td>
                                        <td>
                                            <button 
                                                onClick={() => {this.HandlePromotion(promotion)}}
                                                className="btn btn-red"
                                                data-toggle="modal" 
                                                data-target="#modalUpdatePromotion"
                                                >Sửa</button>
                                            <button 
                                                className="btn btn-red ml-sm-2"
                                                data-toggle="modal" 
                                                data-target="#modalDeletePromotion"
                                                onClick={() => {this.HandlePromotion(promotion)}}>Xóa</button>
                                        </td>
                                    </tr>                                
                                )
                            })}
                            </tbody>
                        </table>
                        {
                            statusButtonLoadPage ?
                                (<div onClick={this.getPromotionList} className="view_more w-100 text-center mb-lg-4">
                                    <a className="d-none d-md-block">
                                        <span>{loading ? "ĐANG TẢI..." : "TẢI THÊM"}</span>
                                    </a>
                                </div>) :
                                ('')
                        }
                    </div>
                </div>
            </>
        );
    }
}

