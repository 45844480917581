import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Base64 } from 'js-base64';
import $ from 'jquery';

export default class ModalDialogNotify extends Component {

    constructor(props) {
        super(props);

        this.state = {
            register: true,
            uid: "",
            type: "USER",
            app: "IKARA",
            song: "",
            duet: "",
            deeplink: "",
            loading: false,
            language: "",
            songKeyNames: []
        }

        this.pushNotification = this.pushNotification.bind(this);
        this.displayM = this.displayM.bind(this);
        this.getSingSongs = this.getSingSongs.bind(this);
        this.newGetKey = this.newGetKey.bind(this);
        this.setDataInUrl = this.setDataInUrl.bind(this);
    }

    render() {
        return (
            <div>
                <div className="modal fade" id="modalDialogNotify" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Get Deeplink</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {true ? (
                                <div id ="content">
                                <div className="container p-5">
                                    <h2 className="m-3 text-center">Generate Deeplink</h2>
                                    {
                                        this.state.type != 'WALLET' ? 
                                        (
                                            <div className="input-group mb-3">
                                                <input 
                                                    value={this.state.uid} 
                                                    onChange={(event) => {
                                                        this.setState({
                                                            uid: event.target.value
                                                        })
                                                    }}
                                                    type="text"
                                                    className="form-control" 
                                                    placeholder="Nhập uid" />
                                            </div>
                                        ) : ""
                                    }
                                    
                                    <div className="input-group mb-3">
                                        <select
                                            value={this.state.type}
                                            onChange={(event) => {
                                                this.setState({
                                                    type: event.target.value
                                                })
                                                this.getSingSongs(this.state.app, event.target.value);
                                            }}
                                            className="form-control">
                                            <option value="USER">USER</option>
                                            <option value="LIVEROOM">LIVEROOM</option>
                                            <option value="FAMILY">FAMILY</option>
                                            <option value="RECORDING">RECORDING</option>
                                            <option value="SINGSONGHOT">BÀI ĐANG HOT</option>
                                            <option value="DUET">CHỜ SONG CA</option>
                                            <option value="WALLET">VÍ TIỀN</option>
                                        </select>
                                    </div>
                                    
                                    <div className="input-group mb-3">
                                        <select
                                            value={this.state.app}
                                            onChange={(event) => {
                                                this.setState({
                                                    app: event.target.value
                                                });
                                                this.state.songKeyNames = [];
                                                this.getSingSongs(event.target.value, this.state.type);
                                            }}
                                            className="form-control">
                                            <option value="IKARA">IKARA</option>
                                            <option value="YOKARA">YOKARA</option>
                                            <option value="OKARA">OKARA</option>
                                        </select>
                                    </div>
                                    {this.displayM() }
                                    
                                    <div className="">
                                        {this.state.loading ?
                                            (
                                                <button style={{ width: "120px" }} className="btn btn-red float-right" type="button" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    ...
                                                </button>
                                            ) :
                                            (
                                                <button style={{ width: "120px" }} onClick={this.pushNotification} className="btn btn-red float-right" type="button">Push</button>
                                            )}
                                    </div>
                                    <div className="input-group mb-3 text-break">{this.state.deeplink}</div>
                
                                </div>
                            </div>) : ''}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" data-dismiss="modal" onClick={this.setDataInUrl}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    setDataInUrl(){
        this.props.resetList(this.state.deeplink);
    }

    getSingSongs(app, type) {
        let songKeyNameArr = [];
        let songObj = {};
        if(app == 'IKARA') {
            this.state.language = "vi";
        } else if(app == 'YOKARA'){
            this.state.language = "en.yokara";
        } else if(app == 'OKARA'){
            this.state.language = "tl.okara";
        }

        const json = {
            cursor: null,
            language: this.state.language,
            platform: "IOS"
        }

        const jsonBase64 = Base64.encode(JSON.stringify(json));
        var pass = this.newGetKey(jsonBase64, 7123358024, 3564);
        var keys = jsonBase64 + "-" + pass;
        var api = "";
        
        if(type == "SINGSONGHOT") {
            api = "/v33.TopSongs?parameters=";
        } else if(type == "DUET") {
            api = "/v35.TopAsk4DuetRecordings?parameters=";
        }
        let url = global.config.apiDomain + api + keys;
        
        axios.post(url, json, {
			headers: {
				'Content-Type': 'application/json'
			}
		}).then(res => {
            if(type == "SINGSONGHOT") {
                if(this.state.app == "IKARA") {
                    res.data.songs.map((item) => {
                        songObj = new Object();
                        songObj.key = item._id;
                        songObj.value = item.songName;
                        songKeyNameArr.push(songObj);
                    });
                } else {
                    res.data.songs.map((item) => {
                        songObj = new Object();
                        songObj.key = item.videoId;
                        songObj.value = item.songName;
                        songKeyNameArr.push(songObj);
                    });
                }
            } else if(type == "DUET") {
                res.data.recordings.map((item) => {
                    songObj = new Object();
                    songObj.key = item._id;
                    songObj.value = item.song.songName;
                    songKeyNameArr.push(songObj);
                    
                });
            }
            this.setState({
                songKeyNames: songKeyNameArr
            });
		}, err => {
            console.log("err:");
			console.log(err);
        });
    }

    newGetKey(data, password, password2) {
        var listOfNumbers = "" + password2;
        for (var i = 0 ; i < data.length; i++) {
            if (data.charAt(i) >= '0' && data.charAt(i) <= '9'){
                listOfNumbers = [listOfNumbers.slice(0, 0), data.charAt(i), listOfNumbers.slice(0)].join('');
            }
        }
        var originalKey = null;
			if (listOfNumbers.length > 15) 
				originalKey = listOfNumbers.substring(0,15);
			else
				originalKey = listOfNumbers;
			return Number(originalKey) + Number(password);
    }

    displayM() {
        const { songKeyNames, app, type } = this.state;
        if(this.state.type == 'SINGSONGHOT') {
            return (
                <div className="input-group mb-3">
                    <select
                        value={this.state.song}
                        onChange={(event) => {
                            this.setState({
                                song: event.target.value,
                                uid: event.target.value
                            })
                        }}
                        className="form-control">
                            <option value=''>Chọn bài hát đang hot</option>
                            {
                                (songKeyNames.map((item) => {
                                    return (
                                      <option key={item.key} value={item.key}>
                                        {item.value}
                                      </option>
                                    );
                                }))
                            }
                    </select>
                </div>
            )
        } else if(this.state.type == 'DUET') {
            return (
                <div className="input-group mb-3">
                    <select
                        value={this.state.duet}
                        onChange={(event) => {
                            this.setState({
                                duet: event.target.value,
                                uid: event.target.value
                            })
                        }}
                        className="form-control">
                        <option value=''>Chọn bài chờ song ca</option>
                        {
                            (songKeyNames.map((item) => {
                                return (
                                    <option key={item.key} value={item.key}>
                                    {item.value}
                                    </option>
                                );
                                }))
                        }
                    </select>
                </div>
            )
        }
        
    }

    pushNotification() {
        this.setState({
            loading: true
        });

        if(this.state.type == 'SINGSONGHOT') {
            this.state.uid = this.state.song;
        } else if(this.state.type == 'DUET') {
            this.state.uid = this.state.duet;
        }

        let json;
        if (this.state.type == 'WALLET') {
            json = {
                domain: window.location.hostname,
                type: this.state.type,
                app: this.state.app
            }
        } else if (this.state.type == 'SINGSONGHOT'){
            if(this.state.app == "IKARA") {
                json = {
                    uid: this.state.uid,
                    domain: window.location.hostname,
                    type: this.state.type,
                    app: this.state.app,
                    // deeplink: this.state.deeplink
                }
            } else {
                json = {
                    youtubeId: this.state.uid,
                    domain: window.location.hostname,
                    type: this.state.type,
                    app: this.state.app,
                    // deeplink: this.state.deeplink
                }
            }
        } else {
            json = {
                uid: this.state.uid,
                domain: window.location.hostname,
                type: this.state.type,
                app: this.state.app,
                // deeplink: this.state.deeplink
            }
        }

        axios.post("https://www.ikara.co/rest/getDeepLink", JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                // uid: "",
                // type: "USER",
                // app: "IKARA",
                // song: "",
                // duet: "",
                deeplink: res.data
            })

            console.log(res.data)
            this.setState({
                loading: false
            });
        }, err => {
            toast.error("ERR");
        });
    }

}