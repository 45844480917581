import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import AWS from 'aws-sdk';
import $ from 'jquery';
import { Base64 } from 'js-base64';
import {Link} from 'react-router-dom';
import bindModel from '../../common/bindModel.js';
import MyContext from '../../common/MyContext';
import { Helmet } from 'react-helmet';
export default class UploadBeatPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            request: {
                userId: "",
                songName: "",
                singerName: "",
                lyrics: "",
                songUrl: ""
            },
            loading: false
        }

        this.uploadBeatForWasabi = this.uploadBeatForWasabi.bind(this);
        this.uploadBeat = this.uploadBeat.bind(this);
        this.uploadBeatToB2 = this.uploadBeatToB2.bind(this);
    }


    uploadBeat() {
        this.setState({
            loading: true
        });

        var { request } = this.state;
        const { user } = this.context;
        request.userId = user.facebookId;

        axios.post(global.config.apiDomain + '/rest/uploadBeat', JSON.stringify(request), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                loading: false
            });
            if (res.data.status == "OK") {
                toast.success(res.data.message);
                window.location.href = "https://www.ikara.co/beat-manager";
            } else {
                toast.warning(res.data.message);
            }
        }, error => {
            this.setState({
                loading: false
            });
            toast.error('Cập nhật thất bại');
        });
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadBeatForWasabi(event) {
        this.setState({
            loading: true
        });

        var { request } = this.state;

        var target = event.target;

        var credentials = new AWS.Credentials("0IOD12IF62UYZ22355HV", "H1hKICheXJrZCwJlIu6BeBV21laYErelMgArrMsu");
        var ep = new AWS.Endpoint("s3.wasabisys.com");
        var s3 = new AWS.S3({
            endpoint: ep,
            credentials: credentials,
            region: 'us-east-1'
        });

        // Create a bucket and upload something into it
        var bucketName = "ikara_files";

        var keyName = this.guid() + ".mp3";

        var contentType = "audio/mpeg";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var data = fileReader.result; // data <-- in this var you have the file data in Base64 format
            var params = {
                ACL: 'public-read',
                ContentType: contentType,
                Bucket: bucketName,
                Key: keyName,
                Body: data
            };
            s3.putObject(params, function (err, data) {
                this.setState({
                    loading: false
                });
                if (err) {
                    alert("Upload Failed " + err);
                } else {
                    console.log("OK");
                    console.log('https://s3.wasabisys.com/' + bucketName + '/' + keyName);
                    request.songUrl = 'https://s3.wasabisys.com/' + bucketName + '/' + keyName;
                    this.setState({
                        request: request
                    });
                }
            }.bind(this));
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]); 

    }


    uploadBeatToB2(event) {
        this.setState({
            loading: true
        });

        var { request } = this.state;

        var target = event.target;

        // var credentialsBackblazeb2 = new AWS.Credentials("0023003e6144cac0000000004", "K002AE3odq+2F6w/D5Q8WUuNs8T84ps");
        var credentialsBackblazeb2 = new AWS.Credentials("0023003e6144cac0000000002", "K002nj/j9xWKFIZ/X8nEqZFQfchNPI0");

        var epBackblazeb2 = new AWS.Endpoint("s3.us-west-002.backblazeb2.com");
        var s3Backblazeb2 = new AWS.S3({
            endpoint: epBackblazeb2,
            credentials: credentialsBackblazeb2,
            region: "us-west-2",
        });



        // Create a bucket and upload something into it
        var bucketName = "ikara-data";

        var keyName = "audios/userbeats/" + this.guid() + ".mp3";

        var contentType = "audio/mpeg";

        var fileReader = new FileReader();


        fileReader.onload = function () {
            var data = fileReader.result; // data <-- in this var you have the file data in Base64 format
            console.log('https://s3.us-west-002.backblazeb2.com/' + bucketName + '/' + keyName);

            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', 'ikara-data');
            fd.append('objectKey', keyName);
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    request.songUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        loading: false,
                        request: request
                    });
                    console.log(data);
                }.bind(this),
            });

            // $.post(global.config.apiDomain + "/web.UploadB2", { bucketName: 'ikara-data', objectKey: keyName, base64Data: new Int8Array(data).toString() })
            //     .done(function (data) {
            //         this.setState({
            //             loading: false
            //         });
            //         request.songUrl = 'https://s3.us-west-002.backblazeb2.com/' + bucketName + '/' + keyName;
            //         this.setState({
            //             request: request
            //         });
            //     }.bind(this))
            //     .fail(function () {
            //         this.setState({
            //             loading: false
            //         });
            //         request.songUrl = 'https://s3.us-west-002.backblazeb2.com/' + bucketName + '/' + keyName;
            //         this.setState({
            //             request: request
            //         });
            //     }.bind(this));


            // var objectParams = {
            //     ACL: 'public-read',
            //     ContentType: contentType,
            //     Bucket: bucketName,
            //     Key: keyName,
            //     Body: data
            // };


            // s3Backblazeb2.putObject(objectParams, function (err, data) {
            //     this.setState({
            //         loading: false
            //     });
            //     if (err) {
            //         alert("Upload Failed " + err);
            //     } else {
            //         console.log("OK");
            //         console.log('https://s3.us-west-002.backblazeb2.com/' + bucketName + '/' + keyName);
            //         request.songUrl = 'https://s3.us-west-002.backblazeb2.com/' + bucketName + '/' + keyName;
            //         this.setState({
            //             request: request
            //         });
            //     }
            // }.bind(this));
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);
    }

    render() {
        const model = bindModel(this);

        return (
            <div id="content">
                <Helmet>
					<title>Đăng Tải beat</title>
				</Helmet>
                <div className="container">
                    <div className="d-flex justify-content-between">
                        <h3 className="text-center">Đăng tải beat</h3>
                        <Link to="/beat-manager" className="pt-3 text-danger">Quản lí các beat đã upload</Link>
                    </div>
                    <hr className="m-1" />
                    <div className="">
                        <div className="form-group">
                            <input
                                // onChange={this.uploadBeatForWasabi}
                                onChange={this.uploadBeatToB2}
                                type="file"
                                className="form-control-file"
                                id="exampleFormControlFile1" 
                                accept=".mp3"/>
                        </div>
                        <span><strong>Ghi chú</strong>: Upload file định dạng .mp3 và có dung lượng nhỏ hơn 10 MB</span>
                        <div className="row mt-2">
                            <label className="col-form-label col-2">Tên bài hát</label>
                            <input
                                type="text"
                                className="col-6 form-control"
                                placeholder="Nhập tên bài hát"
                                {...model('request.songName')} />
                        </div>
                        <div className="row mt-2">
                            <label className="col-form-label col-2">Ca sĩ</label>
                            <input
                                type="text"
                                className="col-6 form-control"
                                placeholder="Nhập tên ca sĩ"
                                {...model('request.singerName')} />
                        </div>
                        <div className="row mt-2">
                            <label className="col-form-label col-2">Lời bài hát</label>
                            <textarea
                                rows={10}
                                type="text"
                                className="col-6 form-control"
                                placeholder="Nhập lời bài hát"
                                {...model('request.lyrics')} />
                        </div>
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="text-center col-6 p-2">
                                {this.state.loading ?
                                    (
                                        <button className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Đang tải...
                                        </button>
                                    ) :
                                    (
                                        <button type="button" className="btn btn-red btn-block" onClick={this.uploadBeat}>Upload</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

UploadBeatPage.contextType = MyContext;