import React, { Component } from 'react';
import bindModel from '../../../../common/bindModel.js';
import axios from 'axios';
import { toast } from 'react-toastify';

export default class AddRecordingVipPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            recordingId: "",
            loading: false,
            processingCard: ""
        }

        this.addRecordingVip = this.addRecordingVip.bind(this);
    }

    addRecordingVip() {
        this.setState({
            loading: true
        });
        const { recordingId, processingCard } = this.state;
        if (!processingCard) {
           
            toast.error("Hãy chọn trừ thẻ xử lí");
            this.setState({ loading: false });
            return;
        }
   
        const url = global.config.apiDomain+'/rest/recordingvip/add/'+recordingId+'?processingCard='+processingCard;
        axios.post(url).then((res) => {
            const { status, message } = res.data;

            if (status === "OK") {
                toast.success(message);

                this.setState({
                    recordingId: "",
                    processingCard: ""
                });
            } else {
                toast.error(message);
            }

            this.setState({
                loading: false
            });
        });
    }

    render() {
        const model = bindModel(this);
        const { loading } = this.state;

        return (
            <div id="content" className='text-center'>
                <h1 className='text-center'>Xử lí bài thu VIP</h1>
                <div className='row justify-content-center align-items-center' style={{ margin: "130px 0 50px 0" }}>
                    <div className='col-8 col-lg-6'>
                        <input
                            className="form-control"
                            type="text"
                            name="cPackage.url"
                            placeholder="Nhập ID bài thu..."
                            {...model('recordingId')}
                        />
                    </div>

                    {(loading) ?
                        (
                            <button style={{ width: "120px" }} className="btn btn-primary" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Thêm  <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                        ) :
                        (
                            <button style={{ width: "120px" }} type="button" className="btn btn-primary" onClick={this.addRecordingVip}>Thêm  <i className="fa fa-plus" aria-hidden="true"></i></button>
                        )
                    }
                </div>

                <div className='col-2' style={{ marginLeft: "300px" }}>
                    <select
                        className="form-control"
                        type="text"
                        name="processingCard"
                        value={this.state.processingCard}
                        onChange={(e) => this.setState({ processingCard: e.target.value })}
                    >
                        <option value="">Chọn trừ thẻ xử lí</option>
                        <option value="true">Có</option>
                        <option value="false">Không</option>
                    </select>
                </div>
            </div>
        );
    }
}
