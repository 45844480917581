import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';

export default class ModalDeleteVipPrivilege extends Component {
    constructor(props) {
        super(props);

        this.state = {
            vipPrivilege: null,
            loading: false
        }

        this.deleteOrActiveVipPrivilege = this.deleteOrActiveVipPrivilege.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            vipPrivilege : props.vipPrivilege
        })
    }

    deleteOrActiveVipPrivilege() {
        let obj = {...this.state.vipPrivilege};

        this.setState({
            loading: true
        });
    
        axios.post(global.config.apiDomain + '/rest/privileges/deleteOrActiveVipPrivilege', obj)
        .then(res => {
            this.setState({
                loading: false
            });

            if (res.data.status == "OK") {
                window.$("#modalDeleteVipPrivilege").modal("hide");
                toast.success(res.data.message); 
                this.props.resetList();
            } else {
                toast.warning(res.data.message);
            }
        })
    }

    render() {

        const {vipPrivilege} = this.state;

        return (
            <div>
                <div className="modal fade" id="modalDeleteVipPrivilege" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                            {
                                (vipPrivilege && vipPrivilege.isActive) ? 
                                (<h5 className="modal-title" id="exampleModalLabel">Ẩn Đặc quyền</h5>) :
                                <h5 className="modal-title" id="exampleModalLabel">Kích hoạt lại</h5>
                            }

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {
                                (vipPrivilege && vipPrivilege.name) ? (vipPrivilege.isActive?
                                    (<div className="modal-body">
                                        <h3 className="text-center text-danger">Bạn chắc chắn muốn Ẩn Đặc quyền: <span className="font-weight-bold">{vipPrivilege.name}</span> ?</h3>
                                    </div>) : 
                                    (<div className="modal-body">
                                        <h3 className="text-center text-info">Bạn muốn Kích hoạt lại: <span className="font-weight-bold">{vipPrivilege.name}</span> ?</h3>
                                    </div>)
                                ) : ""
                            }

                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {
                                    this.state.loading ? 
                                    (<button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Xác nhận...
                                    </button>) :
                                    (<button 
                                        style={{width: "120px"}} 
                                        type="button" 
                                        className="btn btn-red"
                                        onClick={this.deleteOrActiveVipPrivilege}
                                    >
                                        Xác nhận
                                    </button> )
                                }                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}