import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';


class TestPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí Đặc quyền Vip</h1>
                        <button 
                            className="btn btn-red mb-1" 
                            data-toggle="modal" 
                            data-target="#modalUpdateVipPrivilege"
                            >+ Tạo Mới Đặc quyền
                        </button>
                        <table className="table table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th className="align-middle text-center" scope="col" rowspan="2">Ngày nhận</th>
                                    <th className="text-center" scope="col" colspan="3">Người tặng</th>
                                    <th className="text-center" scope="col" colspan="6">Quà</th>
                                    <th className="align-middle text-center" scope="col" rowspan="2">Recording/LiveRoom</th>
                                </tr>
                                <tr>
                                    <th className="text-center" scope="col">UID</th>
                                    <th className="text-center" scope="col">Tên</th>
                                    <th className="text-center" scope="col">Avatar</th>
                                    <th className="text-center" scope="col">ID</th>
                                    <th className="text-center" scope="col">Tên</th>
                                    <th className="text-center" scope="col">Ảnh</th>      
                                    <th className="text-center" scope="col">Số lượng</th>
                                    <th className="text-center" scope="col">Giá mua</th>
                                    <th className="text-center" scope="col">Giá bán</th>
                                </tr>
                            </thead>
                            <tbody>                            
                              
                            </tbody>
                        </table>
                    </div>
                </div>

            </>
        );
    }
}

export default TestPage
