import React, { Component } from 'react'
import {withTranslation} from 'react-i18next';

class HeaderCommentBox extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        const {totalComment, t} = this.props;
        return (
            <div className="_ohe lfloat">
        <span>
          <span className=" _50f7">
            <em className="_4qba comment_counter" data-intl-translation="bình luận" data-intl-trid>
              {totalComment} {t("recording_page.comment", "bình luận")}</em>
          </span>
        </span>
      </div>
        )
    }
}

export default withTranslation()(HeaderCommentBox)

