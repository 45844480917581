import React, {Component} from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import bindModel from '../../common/bindModel.js';
export default class RecheckApplePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            uid : null,
            orderId : "",
            transactionInfo: null,
            loading: false
        }

        this.getTransactionInfo = this.getTransactionInfo.bind(this);
        this.recheckAppleTransaction = this.recheckAppleTransaction.bind(this);
    }


    getTransactionInfo() {
        const { loading } = this.state;
        if (!loading) {
          this.setState({
            loading: true
          });
          let json = {
            orderId: this.state.orderId
          }
          axios.post(global.config.apiDomain + '/rest/apple/transactioninfo', json)
            .then(res => {
                console.log(res.data)
                this.setState({
                    transactionInfo: res.data,
                    loading: false
                })
            });
        }
    }


    recheckAppleTransaction() {
        const { loading } = this.state;
        if (!loading) {
          this.setState({
            loading: true
          });
          let json = {
            orderId: this.state.orderId,
            uid: this.state.uid
          }
          axios.post(global.config.apiDomain + '/rest/apple/recheck', json)
            .then(res => {
                if (res.data.status == "OK") {
                    this.getTransactionInfo();
                    toast.success(res.data.message);
                } else {
                    toast.warning(res.data.message);
                }

                this.setState({
                    loading: false
                })
            });
        }
    }


    render() {
        const model = bindModel(this);
        const { loading, transactionInfo, orderId, uid } = this.state;

        return (
            <div id="content">
                <h1 className="text-center">Kiểm tra giao dịch Apple</h1>
                <div className='row justify-content-center'>
                    <div className="input-group mb-3 col-6">
                        <input {...model('orderId')} type="text" className="form-control" placeholder="Order ID" aria-label="Order ID" aria-describedby="button-addon2" />
                        {(loading) ?
                        (
                            <button style={{ width: "120px" }} className="btn btn-outline-secondary" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Kiểm tra
                            </button>
                        ) :
                        (
                            <button style={{ width: "120px" }} onClick={this.getTransactionInfo} className="btn btn-outline-secondary" type="button" id="button-addon2">Kiểm tra</button>
                        )
                    }
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className="input-group mb-3 col-6">
                        <input {...model('uid')} type="text" className="form-control" placeholder="ID user" aria-label="ID user" aria-describedby="button-addon2" />
                    </div>
                </div>
                <div className='d-flex justify-content-center mb-3'>
                    {(loading) ?
                        (
                            <button style={{ width: "120px" }} className="btn btn-outline-success" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Xử lí
                            </button>
                        ) :
                        (
                            <button onClick={this.recheckAppleTransaction} style={{ width: "120px" }} className='btn btn-outline-success'>Xử lí</button>

                        )
                    }
                </div>
                {transactionInfo && <div className='row justify-content-center'>
                    <div className="card mb-3" style={{width:"18rem"}}>
                        <div className="card-body">
                            <h5 className="card-title text-center">{orderId}</h5>
                            <h6 className="card-subtitle mb-2 text-body-secondary text-center">
                                {transactionInfo.status == "PROCESSED" ? <span className='text-success'>Đã xử lí</span> : <span className='text-secondary'> Chưa xử lí</span>}
                            </h6>
                            <p className="card-text">Transaction ID: {transactionInfo.transactionId}</p>
                            <p className="card-text">Signed date: {new Date(transactionInfo.signedDate).toLocaleString()}</p>
                            <p className="card-text">Country: {transactionInfo.storefront}</p>
                            <p className="card-text">Currency: {transactionInfo.currency}</p>
                            <p className="card-text">Environment: {transactionInfo.environment}</p>
                            <p className="card-text">Price: {transactionInfo.price}</p>
                            <p className="card-text">Product ID: {transactionInfo.productId}</p>
                        </div>
                    </div>
                </div>}
            </div>
        )
    }
}
