import React, { Component } from 'react';
import { Link } from "react-router-dom";
import MyContext from '../common/MyContext';
import axios from 'axios';

import { withTranslation } from 'react-i18next';
import { detectApp } from '../common/utils.js';

const $ = window.$;
class MenuInProfile extends Component {

    constructor(props) {
        super(props);
        this.logoutSubmit = this.logoutSubmit.bind(this);
    }

    logoutSubmit(event) {
        event.preventDefault();
        const json = JSON.stringify({ domain: window.location.hostname });
        let self = this;

        axios.post(global.config.apiDomain + '/rest/loginuser/logout', json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            window.localStorage.removeItem("login");
        })
        self.context.updateUser(null);
        if ($('#openNav').hasClass('active')) {
            $('#openNav, #header .icon_menu, body').toggleClass('active');
        }
    }

    showButtonUnsubscribeOkara() {
        const { user } = this.context;
        const { t } = this.props;

        if (user != null && user.vipOkara && detectApp("okara")) {
            return (
                <li>
                    <Link
                        data-toggle="modal"
                        data-target="#modalUnsubscribeOkara">
                        <span className="icon"><i className="fa-solid fa-xmark"></i></span> 
                        <span>{t("sub_menu.unsubscribe_vip", "Hủy đăng ký vip")}</span>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                    </Link>
                </li>
            )
        }
    }

    render() {
        const { t } = this.props;
        const { user } = this.context;
        return (
            <div className="sidebar-account">
                <div className="list_link">
                    <ul>
                        <li>
                            <Link to="/me">
                                <span className="icon">
                                    <i class="fa-solid fa-address-card"></i>
                                </span>
                                <span>{t("sub_menu.profile", "Hồ sơ")}</span>
                                <i className="fa fa-angle-right" aria-hidden="true" />
                            </Link>
                        </li>
                        <li>
                            <Link to="/my-gifts">
                                <span className="icon">
                                    <i class="fa-solid fa-gift"></i>
                                </span>
                                <span>{t("sub_menu.gift", "Quà tặng")}</span>
                                <i className="fa fa-angle-right" aria-hidden="true" />
                            </Link>
                        </li>
                        <li>
                            <Link to="/my-recordings">
                                <span className="icon">
                                    <i class="fa-solid fa-circle-play"></i>
                                </span>
                                <span>{t("sub_menu.recording", "Bài thu")}</span>
                                <i className="fa fa-angle-right" aria-hidden="true" />
                            </Link>
                        </li>
                        <li>
                            <Link to="/my-images">
                                <span className="icon">
                                    <i class="fa-solid fa-images"></i>
                                </span>
                                <span>{t("sub_menu.image", "Hình ảnh")}</span>
                                <i className="fa fa-angle-right" aria-hidden="true" />
                            </Link>
                        </li>
                        {detectApp("lo.okara") && this.showButtonUnsubscribeOkara()}

                        {detectApp("ikarayokara") && (
                            <li>
                                <Link to="/buy-icoin">
                                    <span className="icon">
                                        <i class="fa-solid fa-hand-holding-dollar"></i>
                                    </span>
                                    <span>{t("sub_menu.buy_icoin", "Nạp iCoin")}</span>
                                    <i className="fa fa-angle-right" aria-hidden="true" />
                                </Link>
                            </li>
                        )}

                        {/* <li>
                            <a onClick={this.logoutSubmit}>
                                <span className="icon"><img src={require('../assets/images/logout.png')} /></span>
                                <span>{t("logout", "Đăng xuất")}</span>
                            </a>
                        </li> */}

                    </ul>
                </div>
            </div>
        );
    }
}

MenuInProfile.contextType = MyContext;
export default withTranslation()(MenuInProfile)