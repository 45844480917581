import React, { Component } from "react";
import bindModel from "../../../common/bindModel.js";
import axios from "axios";
import { toast } from "react-toastify";
import SVGA from "svgaplayerweb";
import { Parser, Player } from "svga";
import lodash from "lodash";
import $ from "jquery";

export default class ModalSetAvatarFrame extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gifts: [],
      giftsTemp: [],
      gift: null,
      giftShow: null,
      nameSearch: "",
      idSearch: "",
      buyPriceSort: "",
      sellPriceSort: "",
      orderSort: "",
      status: "",
      show: "",
      type: "",
      tag: "",
      frameTop: null,
      currentPage: 0,
      statusButtonLoadPage: false,
      loading: false,
    };

    this.getGifts = this.getGifts.bind(this);
    this.search = this.search.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.reset = this.reset.bind(this);
    this.orderByBuyPrice = this.orderByBuyPrice.bind(this);
    this.orderBySellPrice = this.orderBySellPrice.bind(this);
    this.orderByOrder = this.orderByOrder.bind(this);
    this.newGift = this.newGift.bind(this);
    this.loadMoreGifts = this.loadMoreGifts.bind(this);
    this.handleChoice = this.handleChoice.bind(this);
  }

  componentWillMount() {
    this.getGifts(this.state.currentPage);
  }

  componentDidMount() {
    $("#modalUpdateGift").on(
      "hidden.bs.modal",
      function () {
        this.setState({
          gift: null,
        });
      }.bind(this)
    );

    console.log("Did mount");
    $("#modalShowGift").on(
      "hidden.bs.modal",
      function () {
        this.setState({
          giftShow: null,
        });
      }.bind(this)
    );

    $("#modalShowGiftAnimation").on(
      "hidden.bs.modal",
      function () {
        this.setState({
          giftShow: null,
        });
      }.bind(this)
    );
  }

  async loadSVGA(id, url) {
    const parser = new Parser();
    const svga = await parser.load(url);

    const player = new Player(document.getElementById(id));
    await player.mount(svga);

    player.onStart = () => console.log("onStart");

    player.start();
  }

  componentWillReceiveProps(props) {
    let { frameTop } = props;
    if (frameTop != null) {
      this.setState({
        frameTop: frameTop,
      });
      //alert(this.state.keyCup);
    }
  }

  getGifts(pageIndex) {
    let obj = {
      typeString: "AvatarFrame"
    };

    axios
      .post(global.config.apiDomain + "/rest/shop-items/getItemWithPage", obj)
      .then((res) => {
        let data = res.data.listItem;
        let status = true;

        if (data.length < 15) {
          status = false;
        }

        let newGifts = [];
        if (pageIndex === 0) {
          newGifts = data;
        } else {
          newGifts = this.state.gifts.concat(data);
        }
        this.setState({
          gifts: newGifts,
          giftsTemp: newGifts,
          currentPage: pageIndex + 1,
          statusButtonLoadPage: status,
          loading: false,
        });
      });

  }

  loadMoreGifts() {
    const { loading } = this.state;
    if (!loading) {
      this.setState({
        loading: true,
      });
      this.getGifts(this.state.currentPage);
    }
  }

  newGift() {
    axios.post(global.config.apiDomain + "/rest/gifts/newGift").then((res) => {
      console.log(res.data);
      this.setState({
        gift: res.data,
      });
    });
  }

  search() {
    let { gifts, giftsTemp, nameSearch, idSearch, show, type, tag } =
      this.state;

    gifts = giftsTemp.filter((e) => e.name.includes(nameSearch));
    gifts = gifts.filter((e) => Number.toString(e.idPublic).includes(idSearch));

    if (show.startsWith("!")) {
      gifts = gifts.filter(
        (e) =>
          !e.show.includes(show.replace("!", "")) && !e.show.includes("NONE")
      );
    } else {
      gifts = gifts.filter((e) => e.show.includes(show));
    }
    gifts = gifts.filter((e) => e.type.includes(type));
    gifts = gifts.filter((e) => e.tag.includes(tag));

    this.setState({
      gifts: gifts,
      giftsTemp: giftsTemp,
    });
  }

  reset() {
    this.setState({
      nameSearch: "",
      idSearch: "",
      status: "",
      type: "",
      tag: "",
      gifts: this.state.giftsTemp,
    });
  }

  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value = target.value;

    this.setState({
      [name]: value,
    });
  }

  orderByBuyPrice() {
    let { buyPriceSort, gifts } = this.state;

    if (buyPriceSort === "ASC") {
      gifts = lodash.orderBy(gifts, "buyPrice", "desc");
      buyPriceSort = "DESC";
    } else {
      gifts = lodash.orderBy(gifts, "buyPrice", "asc");
      buyPriceSort = "ASC";
    }

    this.setState({
      buyPriceSort: buyPriceSort,
      gifts: gifts,
    });
  }

  handleChoice(id, frameUrl, frameTop) {
    this.props.resetList(id, frameUrl, frameTop);
  }

  orderByOrder() {
    let { orderSort, gifts } = this.state;

    if (orderSort === "ASC") {
      gifts = lodash.orderBy(gifts, "order", "desc");
      orderSort = "DESC";
    } else {
      gifts = lodash.orderBy(gifts, "order", "asc");
      orderSort = "ASC";
    }

    this.setState({
      orderSort: orderSort,
      gifts: gifts,
    });
  }

  orderBySellPrice() {
    let { sellPriceSort, gifts } = this.state;

    if (sellPriceSort === "ASC") {
      gifts = lodash.orderBy(gifts, "sellPrice", "desc");
      sellPriceSort = "DESC";
    } else {
      gifts = lodash.orderBy(gifts, "sellPrice", "asc");
      sellPriceSort = "ASC";
    }

    this.setState({
      sellPriceSort: sellPriceSort,
      gifts: gifts,
    });
  }

  loadSVGA4(url, canvasId) {
    var parser = new SVGA.Parser("#" + canvasId);
    parser.load(url, function (videoItem) {
      var player = new SVGA.Player("#" + canvasId);
      player.setVideoItem(videoItem);
      // player.setFrame(0, 0, 750, 750);z
      player.setContentMode("AspectFill");
      player.startAnimation();
    });
  }

  componentDidUpdate() {
    const { gifts } = this.state;
    gifts.map((gift, index) => {
      let idResource = "show-animation_resourceUrl" + index;
      let idPreview = "show-animation_preview" + index;
      // let canvasIdResource = "show-demoCanvasResource" + index;
      // let canvasIdPreview = "show-demoCanvasPreview" + index;

      if (gift.resourceUrl != null && gift.resourceUrl.endsWith("svga")) {
        // $("#" + idResource).children().replaceWith("<div style=\" height: 40px !important\" id=\" " + canvasIdResource + "\"></div>");
        this.loadSVGA4(gift.resourceUrl, idResource);
      } else if (gift.resourceUrl != null) {
        $("#" + idResource).replaceWith(
          '<img style="height: 50px; width: 50px; align: center; " src="' +
            gift.resourceUrl +
            '"  />'
        );
      }

      //   if (gift.previewImg != null && gift.previewImg.endsWith("svga")) {
      //     // $("#" + idPreview).replaceWith("<div style=\"height: 50px; width: 50px; \" id=\" " + gift.previewImg + "\"></div>");
      //     this.loadSVGA4(gift.previewImg, idPreview);
      //   } else if (gift.previewImg != null) {
      //     $("#" + idPreview).replaceWith(
      //       '<img style="height: 50px; width: 50px; align: center; " src="' +
      //         gift.previewImg +
      //         '"  />'
      //     );
      //   }
    });
  }

  render() {
    const { gifts, statusButtonLoadPage, loading, currentPage } = this.state;

    return (
      <>
        <div>
          <div
            className="modal fade"
            id="modalSetAvatarFrame"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalCenterTitle">
                    Chọn CUP
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <table className="table table-hover">
                  <thead>
                    <tr className="align-middle">
                      <th className="align-middle" scope="col">
                        ID
                      </th>
                      <th className="align-middle" scope="col">
                        Tên
                      </th>
                      <th className="align-middle" scope="col" hidden>
                        Hình xem trước
                      </th>
                      <th className="align-middle" scope="col">
                        Hình vật phẩm
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {gifts.map((gift, index) => {
                      let idResource = "show-animation_resourceUrl" + index;
                      let idPreview = "show-animation_preview" + index;
                      // let idPreview = "show-demoCanvas" + index;
                      let result = (
                        <tr key={gift.idPublic}>
                          <td>{gift.idPublic}</td>
                          <td>{gift.name}</td>
                          <td hidden>
                            <div>
                              <div
                                id={idPreview}
                                style={{
                                  maxHeight: "50px",
                                  maxWidth: "50px",
                                  align: "center",
                                }}
                              >
                                <div width={50} height={50} id="canvas"></div>
                              </div>
                            </div>
                          </td>
                          <td style={{ maxHeight: "50px", maxWidth: "50px" }}>
                            <div>
                              <div
                                id={idResource}
                                style={{
                                  maxHeight: "50px",
                                  maxWidth: "50px",
                                  align: "center",
                                }}
                              >
                                <div width={50} height={50} id="canvas"></div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <button
                              onClick={() => {
                                this.handleChoice(
                                  gift.idPublic,
                                  gift.resourceUrl,
                                  this.state.frameTop
                                );
                              }}
                              className="btn btn-red"
                              data-toggle="modal"
                              data-target="#modalSetAvatarFrame"
                            >
                              Chọn
                            </button>
                            {/* <button
                                                    className="btn btn-red ml-sm-2"
                                                    data-toggle="modal"
                                                    data-target="#modalDeletePackage"
                                                    onClick={() => { this.UpdatePackage(gift) }}
                                                >Xóa</button> */}
                          </td>
                        </tr>
                      );

                      return result;
                    })}
                  </tbody>
                </table>
                {statusButtonLoadPage ? (
                  <div
                    onClick={this.loadMoreGifts}
                    className="view_more w-100 text-center mb-lg-4"
                  >
                    <a className="d-none d-md-block">
                      <span>{loading ? "ĐANG TẢI ..." : "TẢI THÊM"}</span>
                    </a>
                    <a className="view_more_mobile d-md-none">
                      <i className="fa fa-angle-down" aria-hidden="true" />
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
