import React, {Component} from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';

export default class GameType extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }

        this.removeGameType = this.removeGameType.bind(this);
        this.setLoading = this.setLoading.bind(this);
    }

    setLoading(value) {
        this.setState({
            loading: value
        })
    }

    removeGameType(gameTypeId) {
        if(window.confirm("Bạn có chắc chắn muốn xóa câu hỏi này không")) {
            this.setLoading(true);
            axios.post(global.config.apiDomain + '/rest/game/type/delete', JSON.stringify(gameTypeId), {
                headers : {
                  'Content-Type': 'application/json'
                }}).then((res) => {
                    const {status, message} = res.data;
                    
                    if(status == "OK") {
                        this.props.getGameTypes();
                        toast.success(message);
                    } else {
                        toast.error(message);
                    }
                    this.setLoading(false);
                }
            )
        }
    }


    render() {
        const {gameTypes} = this.props;
        const {loading} = this.state;
        console.log(gameTypes)
        return (
            <table className="table">
                <thead>
                    <tr>
                    <th scope="col">Tên</th>
                    <th scope="col">Tiêu đề</th>
                    <th scope="col">Mô tả</th>
                    <th scope="col">Xem chi tiết</th>
                    <th scope="col">Xóa</th>
                    </tr>
                </thead>
                <tbody>
                    {gameTypes.map((gameType, i) => {
                        return (
                            <tr>
                                <th scope="row">{gameType.name}</th>
                                <td>{gameType.title}</td>
                                <td>{gameType.description}</td>
                                <td>
                                    <Link
                                        style={{ width: "120px" }}
                                        to={"/admin/game-manager/type/" + gameType.id}
                                        className="btn btn-primary">
                                        Chi tiết <i className="fa fa-refresh" aria-hidden="true"></i>
                                    </Link>
                                </td>
                                <td>
                                {loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-danger" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xóa <i className="fa fa-trash" aria-hidden="true"></i>
                                        </button>
                                    ) :
                                    (
                                        <button
                                            onClick={() => this.removeGameType(gameType.id)}
                                            style={{ width: "120px" }}
                                            className="btn btn-danger">
                                            Xóa <i className="fa fa-trash" aria-hidden="true"></i>
                                        </button>
                                    )
                                }
                                </td>
                            </tr>
                        )
                    })
                    }
                </tbody>
            </table>
        );
    }
}