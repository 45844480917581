import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';

const $ = window.$;
export default class ModalUpdateUID extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            uid: ""
        }

        this.updateUID = this.updateUID.bind(this);
    }

    updateUID() {
        this.setState({
            loading: true
        })

        let json = {
            uid : this.state.uid,
            facebookId: this.props.account.id
        }

        axios.post(global.config.apiDomain + '/rest/admin/updateUID', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            
            this.setState({
                loading: false
            })
            if(res.data.status == "OK") {
                $('#modalUpdateUID').modal('hide');
                this.props.resetAccount();
                toast.success(res.data.message);
            } else {
                toast.warning(res.data.message);
            }
        }, err => {
            this.setState({
                loading: false
            })
            toast.error("FAILED");
        })

    }
    render() {
        return (
            <div className="modal fade" id="modalUpdateUID" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog  modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Update ID</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {this.props.account ? 
                    (
                        <input className="form-control" type="text" defaultValue={this.props.account.uid} onChange={(event) => this.setState({uid: event.target.value})} />
                    ) : (
                        ""
                    )
                    }
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                    {this.state.loading ? 
                    (
                    <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Xác nhận...
                    </button>
                    ) :
                    (
                    <button style={{width: "120px"}} type="button" className="btn btn-red" onClick={this.updateUID}>Xác nhận</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
    }
}