import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import SVGA from 'svgaplayerweb'
import $ from 'jquery';
import bindModel from '../../../common/bindModel';

export default class ModalCreate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stickerModel: null,
            loading: false,
            register: true,
            colorFilters: [],
            beautyFilters: [],
            count: 0,
        }

        this.createOrUpdate = this.createOrUpdate.bind(this);
        this.uploadAnimationToS3 = this.uploadAnimationToS3.bind(this);
        this.uploadAnimationToS4 = this.uploadAnimationToS4.bind(this);
        this.guid = this.guid.bind(this);
        this.loadSVGA = this.loadSVGA.bind(this);
        this.getAllFilters = this.getAllFilters.bind(this);
        this.addBeautyFilter = this.addBeautyFilter.bind(this);
        this.addColorFilter = this.addColorFilter.bind(this);
    }

    loadSVGA(url) {
        var player = new SVGA.Player('#demoCanvas');
        var parser = new SVGA.Parser('#demoCanvas');
        parser.load(url, function (videoItem) {
            player.setVideoItem(videoItem);
            player.startAnimation();
        })
    }

    createOrUpdate(){
        const { stickerModel, count } = this.state;

        if(!stickerModel._id){
            toast.warning("Đổi ý thì lo nhập ID nha mẹ");
            return
        } 
        if(!stickerModel.name){
            toast.warning("Thiếu tên rồi người đẹp");
            return
        }

        if(!stickerModel.resourceUrl){
            toast.warning("Thiếu ảnh vật phẩm");
            return
        }

        if(!stickerModel.thumbnailUrl){
            toast.warning("Thiếu ảnh mô tả");
            return
        }

        if(!stickerModel.order){
            toast.warning("Không chơi ưu tiên = 0");
            return
        }

        if(!stickerModel.colorFilter){
            toast.warning("Chọn Bộ lọc Màu");
            return
        }

        stickerModel._id = stickerModel._id.toUpperCase();
        stickerModel.beautyFilters = [];
        for (let i = 0; i < count; i++) {
            let _id = $(`#beauty_${i}`).val();
            let name = $(`#beauty_${i} :selected`).text();
            let value = $(`#beauty_value_${i}`).val();
            let extraProperty = $(`#property_${i}`).val();

            if (!value) value = 0;
            if (_id == 'BEAUTY' && value != 0 && value != 1) {
                toast.warning("BEAUTY chỉ nhận giá trị 1 hoặc 0");
                return
            }

            if (_id && name) {
                for (let j = 0; j < stickerModel.beautyFilters.length; j++) {
                    if (_id === stickerModel.beautyFilters[j]._id) {
                        toast.warning("Trang điểm " + stickerModel.beautyFilters[j].name + " bị lặp");
                        return
                    }
                }

                if (extraProperty) {
                    stickerModel.beautyFilters.push({
                        _id: _id,
                        name: name,
                        value: value,
                        extraProperty: extraProperty
                    })
                } else {
                    stickerModel.beautyFilters.push({
                        _id: _id,
                        name: name,
                        value: value
                    })
                }
                
            }
        }

        console.log(stickerModel);
       
        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + "/rest/deepAREffect/createOrUpdate", stickerModel)
        .then(res =>{
            this.setState({
                loading: false
            });
            if(res.data && res.data.status == "OK"){
                window.$("#modalCreateDeepAr").modal("hide");
                toast.success(res.data.message);
                this.props.resetList();
            }else {
                toast.success(res.data.message)
            }
        })

    }

    componentWillReceiveProps(props) {
        this.getAllFilters();
        const { stickerModel } = props;
        $('#filters').html('');

        if(stickerModel == null){
            this.setState({
                stickerModel: null,
                loading: false,
                count: 0
            })

            $("#show-resourceUrl").children().replaceWith('');
            $("#show-thumbnailUrl").children().replaceWith(`<div width={500} height={500} id="canvas"></div>`);
        }

        if(stickerModel != null){
            this.setState({
                stickerModel: stickerModel,
            })
            $('#colorFilter').val(stickerModel.colorFilter._id);


            if(stickerModel.resourceUrl) {
                $("#show-resourceUrl").html(`<h4>${stickerModel.resourceUrl.substring(stickerModel.resourceUrl.lastIndexOf("/"), stickerModel.resourceUrl.length)}</h4>`);
            }

            if(stickerModel.thumbnailUrl) {
                $("#show-thumbnailUrl").children().replaceWith(`<img src=${stickerModel.thumbnailUrl} width="150px" />`);
            }

            if (stickerModel.beautyFilters) {
                let size = stickerModel.beautyFilters.length;
                let beautyFilters = this.state.beautyFilters;

                for (let i = 0; i < size; i++) {
                    let str ="";
                    beautyFilters && beautyFilters.map((beautyFilter) => (
                        str +=  `<option value=${beautyFilter._id} name=${beautyFilter.extraProperties}>${beautyFilter.name}</option>`
                    ))

                    $('#filters').append(`
                        <div class = "row" id = "row_${i}">
                            <select
                                class= "form-control mb-2 ml-3 mr-2 col-3"
                                type= "text"
                                id = "beauty_${i}"
                            >
                                <option value=''>Chọn Bộ lọc</option>
                                <option value='BEAUTY'>BEAUTY</option>
                                ${str}
                            </select>
                            <input 
                                class= "form-control mb-2 ml-2 mr-2 col-2"
                                type = "number"
                                id = "beauty_value_${i}"
                            />
                            <button
                                class = "btn btn-danger mb-2 mr-2 col-1"
                                id="delete_${i}"
                            >X</button>
                        </div>
                    `)

                    $(`#beauty_${i}`).on('change', () => {
                        $(`#property_${i}`).remove();

                        let key = $(`#beauty_${i}`).find(":selected").val();
                        let name = $(`#beauty_${i}`).find(":selected").attr("name");
                        let strOption = '';
                        if (name) {
                            let extraProperties = name.split(",");
                            extraProperties && extraProperties.map((property) => (
                                strOption +=  `<option value=${property}>${property}</option>`
                            ))
                            $(`#row_${i}`).append(`
                                <select
                                    class= "form-control mb-2 ml-3 mr-2 col-3"
                                    type= "text"
                                    id = "property_${i}"
                                >
                                    <option value=''>Chọn</option>
                                    ${strOption}
                                </select>
                            `)
                        }
                    
                        if (key != null && key != '') {
                            $(`#lipColor_${i}`).remove();
                            const regexColor = /^#(?:[0-9a-fA-F]{3}){1,2}$/g;
                            if (regexColor.test('#' + key)) {
                                $(`#row_${i}`).append(`<input id = "lipColor_${i}" class = "form-control mb-2 ml-2 mr-2 col-1" style= "background-color: #${key}" readonly></input>`)
                            }
                        }
                    })
                    
                    $(`#delete_${i}`).on('click', () => {
                        $(`#row_${i}`).remove();
                    })

                    this.setState({
                        count: i + 1
                    })

                    let beautyFilter = stickerModel.beautyFilters[i];
                    $(`#beauty_${i}`).val(beautyFilter._id);
                    $(`#beauty_value_${i}`).val(beautyFilter.value);
                    const regexColor = /^#(?:[0-9a-fA-F]{3}){1,2}$/g;
                    if (regexColor.test('#' + beautyFilter._id)) {
                        $(`#row_${i}`).append(`<input id = "lipColor_${i}" class = "form-control mb-2 ml-2 mr-2 col-1" style= "background-color: #${beautyFilter._id}" readonly></input>`)
                    }

                        if (stickerModel.beautyFilters[i] && stickerModel.beautyFilters[i].extraProperty) {
                            let name = $(`#beauty_${i}`).find(":selected").attr("name");
                            let strOption = '';
                            if (name) {
                                let extraProperties = name.split(",");
                                extraProperties && extraProperties.map((property) => (
                                    strOption +=  `<option value=${property}>${property}</option>`
                                ))
                                $(`#row_${i}`).append(`
                                    <select
                                        class= "form-control mb-2 ml-3 mr-2 col-3"
                                        type= "text"
                                        id = "property_${i}"
                                    >
                                        <option value=''>Chọn</option>
                                        ${strOption}
                                    </select>
                                `)

                                $(`#property_${i}`).val(stickerModel.beautyFilters[i].extraProperty);
                            }
                        }
                    
                        // str +=  `<option value=${beautyFilter._id} name=${beautyFilter.extraProperties}>${beautyFilter.name}</option>`                
                }                
            }   
        }
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadAnimationToS3(event) {
        var target = event.target;
        const { stickerModel } =this.state;
        //10495760
        if (target.files[0].size > 10495760 ){
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        this.setState({
            loading: true
        });

        var bucketName = "ikara-data/images/deepAREffect/deepAr";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName =  target.files[0].name;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    let animatedUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;

                    if(target.id && target.id.includes("ResourceUrl")){
                        stickerModel.resourceUrl = animatedUrl;
                        $("#show-resourceUrl").html(`<h4>${stickerModel.resourceUrl.substring(stickerModel.resourceUrl.lastIndexOf("/"), stickerModel.resourceUrl.length)}</h4>`);
                    }else{
                        stickerModel.thumbnailUrl = animatedUrl;
                        $("#show-thumbnailUrl").children().replaceWith(`<img src=${stickerModel.thumbnailUrl} width="150px" />`);
                    }

                    this.setState({
                        stickerModel: stickerModel,
                        loading: false
                    })

                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }

    uploadAnimationToS4(event) {
        var target = event.target;
        const { stickerModel } =this.state;
        //10495760
        if (target.files[0].size > 10495760 ){
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        this.setState({
            loading: true
        });

        var bucketName = "ikara-data/images/deepAREffect/thumbnail";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    let animatedUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;

                    if(target.id && target.id.includes("ResourceUrl")){
                        stickerModel.resourceUrl = animatedUrl;
                        $("#show-resourceUrl").html(`<h4>${stickerModel.resourceUrl.substring(stickerModel.resourceUrl.lastIndexOf("/"), stickerModel.resourceUrl.length)}</h4>`);
                    }else{
                        stickerModel.thumbnailUrl = animatedUrl;
                        $("#show-thumbnailUrl").children().replaceWith(`<img src=${stickerModel.thumbnailUrl} width="150px" />`);
                    }

                    this.setState({
                        stickerModel: stickerModel,
                        loading: false
                    })

                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }

    getAllFilters() {
        axios.post(global.config.apiDomain + '/rest/deepARFilter/getAllFilterByType/COLOR')
            .then(res => {
                if (res.data.status === 'OK') {
                    this.setState({
                        colorFilters: res.data.filterList
                    })
                }
            })

        axios.post(global.config.apiDomain + '/rest/deepARFilter/getAllFilterByType/BEAUTY')
            .then(res => {
                if (res.data.status === 'OK') {
                    this.setState({
                        beautyFilters: res.data.filterList
                    })
                }
            })

    }

    addBeautyFilter() {
        let beautyFilters = this.state.beautyFilters;
        let count = this.state.count;
        console.log('count: ' + count);

        let str ="";
        beautyFilters && beautyFilters.map((beautyFilter) => (
            str +=  `<option value=${beautyFilter._id} name=${beautyFilter.extraProperties}>${beautyFilter.name}</option>`
        ))

        $('#filters').append(`
            <div class = "row" id = "row_${count}">
                <select
                    class= "form-control mb-2 ml-3 mr-2 col-3"
                    type= "text"
                    id = "beauty_${count}"
                >
                    <option value=''>Chọn Bộ lọc</option>
                    <option value='BEAUTY'>BEAUTY</option>
                    ${str}
                </select>
                <input 
                    class= "form-control mb-2 ml-2 mr-2 col-2"
                    type = "number"
                    id = "beauty_value_${count}"
                />
                <button
                    class = "btn btn-danger mb-2 mr-2 col-1"
                    id="delete_${count}"
                >X</button>
            </div>
        `)

        $(`#beauty_${count}`).on('change', () => {
            $(`#property_${count}`).remove();

            let key = $(`#beauty_${count}`).find(":selected").val();
            let name = $(`#beauty_${count}`).find(":selected").attr("name");
            let strOption = '';
            if (name) {
                let extraProperties = name.split(",");
                extraProperties && extraProperties.map((property) => (
                    strOption +=  `<option value=${property}>${property}</option>`
                ))
                $(`#row_${count}`).append(`
                    <select
                        class= "form-control mb-2 ml-3 mr-2 col-3"
                        type= "text"
                        id = "property_${count}"
                    >
                        <option value=''>Chọn</option>
                        ${strOption}
                    </select>
                `)
            }
           
            if (key != null && key != '') {
                $(`#lipColor_${count}`).remove();
                const regexColor = /^#(?:[0-9a-fA-F]{3}){1,2}$/g;
                if (regexColor.test('#' + key)) {
                    $(`#row_${count}`).append(`<input id = "lipColor_${count}" class = "form-control mb-2 ml-2 mr-2 col-1" style= "background-color: #${key}" readonly></input>`)
                }
            }
        })

        $(`#delete_${count}`).on('click', () => {
            $(`#row_${count}`).remove();
        })

        this.setState({
            count: count + 1
        })

    }

    addColorFilter(event) {
        let stickerModel = this.state.stickerModel;
        this.setState({
            stickerModel: {
                ...stickerModel,
                colorFilter: {
                    _id: event.target.value,
                    name: event.target.options[event.target.selectedIndex].text,
                    value: 0
                }
            }
        })
    }


    render() {
        const model = bindModel(this);
        const { stickerModel, colorFilters } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalCreateDeepAr" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">{stickerModel && stickerModel.resourceUrl ? "Cập nhật vật phẩm" : "Thêm mới vật phẩm"}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                                <div className="modal-body">
                                    <div id="id" className="row mb-2">
                                            <div className="col-3">
                                                <label>ID</label>
                                            </div>
                                            <div className="col-9">
                                                <input
                                                    className="form-control mb-2"
                                                    type="text"
                                                    name="id"
                                                    placeholder="Nhập ID vô..."
                                                    {...model('stickerModel._id')}
                                                    />
                                            </div>
                                    </div>
                                    <div id="name" className="row mb-2">
                                            <div className="col-3">
                                                <label>Tên vật phẩm</label>
                                            </div>
                                            <div className="col-9">
                                                <input
                                                    className="form-control mb-2"
                                                    type="text"
                                                    name="name"
                                                    placeholder="Nhập tên"
                                                    {...model('stickerModel.name')}/>
                                            </div>
                                    </div>
                                    <div id="resourceUrl" className="row mb-2">
                                            <div className="col-3">
                                                <label>Hình vật phẩm</label>
                                            </div>
                                            <div className="col-9">
                                                <input
                                                    className="custom-file-input"
                                                    type="file"
                                                    id="inputResourceUrl"
                                                    placeholder="Chọn hình vật phẩm"
                                                    onChange={this.uploadAnimationToS3}/>
                                                 <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                                            </div>
                                    </div>
                                    <div id="reviewResourceUrl" className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div id="show-resourceUrl" className="col-9">
                                            
                                        </div>
                                    </div>
                                    <div id="thumbnailUrl" className="row mb-2">
                                        <div className="col-3">
                                            <label>Hình mô tả</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="custom-file-input"
                                                type="file"
                                                id="inputThumbnailUrl"
                                                placeholder="Chọn hình mô tả"
                                                onChange={this.uploadAnimationToS4}/>
                                            <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                                        </div>
                                    </div>
                                    <div id="reviewThumbnailUrl" className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div id="show-thumbnailUrl" className="col-9" style={{ maxHeight: "300px" }}>
                                            <div width={500} height={500} id="canvas"></div>
                                        </div>
                                    </div>
                                    <div id="id" className="row mb-2">
                                            <div className="col-3">
                                                <label>Level yêu cầu</label>
                                            </div>
                                            <div className="col-9">
                                                <input
                                                    className="form-control mb-2"
                                                    type="number"
                                                    name="minLevel"
                                                    placeholder="Nhập level yêu cầu"
                                                    {...model('stickerModel.minLevel')}/>
                                            </div>
                                    </div>
                                    <div id="id" className="row mb-2">
                                            <div className="col-3">
                                                <label>Level VIP yêu cầu</label>
                                            </div>
                                            <div className="col-9">
                                                <input
                                                    className="form-control mb-2"
                                                    type="number"
                                                    name="minVipLevel"
                                                    placeholder="Nhập level VIP yêu cầu"
                                                    {...model('stickerModel.minVipLevel')}/>
                                            </div>
                                    </div>
                                    <div id="id" className="row mb-2">
                                            <div className="col-3">
                                                <label>Độ ưu tiên</label>
                                            </div>
                                            <div className="col-9">
                                                <input
                                                    className="form-control mb-2"
                                                    type="number"
                                                    name="order"
                                                    placeholder="Nhập độ ưu tiên"
                                                    {...model('stickerModel.order')}/>
                                            </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                            <label>Bộ lọc Màu</label>
                                        </div>
                                        <div className="col-9">
                                            <select
                                                className="form-control"
                                                type="text"
                                                name="colorFilter"
                                                id="colorFilter"
                                                onChange={this.addColorFilter}
                                            >
                                                <option value=''>Chọn Filter Màu</option>
                                                <option value='NONE'>NONE</option>
                                                    {
                                                        colorFilters && colorFilters.map((colorFilter) => (
                                                            <option key={colorFilter._id} value={colorFilter._id} >{colorFilter.name}</option>
                                                        ))
                                                    }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                            <label>Bộ lọc Trang điểm</label>
                                        </div>
                                        <div className="col-9">
                                            <div id='filters'>
                                            </div>

                                            <button className='btn btn-success'                             
                                                onClick={this.addBeautyFilter}
                                            >+</button>
                                        </div>
                                    </div>
                                </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red"
                                        onClick={this.createOrUpdate}
                                        >Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}