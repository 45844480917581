import React, { Component } from "react";
import axios from "axios";
import lodash from "lodash";

export default class ModalListUseLuckyGift extends Component {
  constructor(props) {
    super(props);

    this.state = {
      luckyGifts: [],
      listUseLuckyGift: [],
    };
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
  }

  componentWillReceiveProps(props) {
    const { luckyGiftGaveId } = props;
    if(luckyGiftGaveId == null){
        return
    }
    const url = global.config.apiDomain + "/rest/luckyGiftsHistory";
    const json = {
        luckyGiftGaveId: luckyGiftGaveId,
    };

    axios.post(url, json).then((res) => {
        this.setState({
            listUseLuckyGift: res.data
        })
    }).catch(err => console.log(err))
  }

  render() {
    const { listUseLuckyGift } = this.state;
    return (
    <div className="modal fade" id="modalListUseLuckyGift" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Danh sách người nhận lì xì</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <table className="table table-hover">
                    <thead>
                        <tr>
                            <th width={100} scope="col">STT</th>
                            <th width={100} scope="col">Id</th>
                            <th scope="col">UID Người nhận</th>
                            <th scope="col">Quà nhận được</th>
                            <th scope="col">Tên quà</th>
                            <th scope="col">Giá</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            listUseLuckyGift.map((item,index)=>{
                                return (
                                    <tr>
                                        <td>{index +1}</td>
                                        <td>{item.id}</td>
                                        <td>{item.receivedUserId}</td>
                                        <td>{item.giftId}</td>
                                        <td>{item.giftName}</td>
                                        <td>{item.price}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>  
    )  
  }
}
