import React, { Component } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { withTranslation } from 'react-i18next';

import bindModel from '../../common/bindModel.js';
import MenuInProfile from '../../components/MenuInProfile';
import { Helmet } from 'react-helmet';
import MyContext from '../../common/MyContext';

import { detectApp } from '../../common/utils.js';


const AWS = window.AWS;
class ProfilePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null
        }

        this.uploadThumbnailForWasabi = this.uploadThumbnailForWasabi.bind(this);
        this.saveProfile = this.saveProfile.bind(this);

    }

    componentDidMount() {
        axios.post(global.config.apiDomain + '/rest/profile')
            .then(res => {
                const user = res.data;
                this.setState({
                    user: user
                })
            });
    }

    saveProfile() {
        axios.post(global.config.apiDomain + '/rest/profile/update', JSON.stringify(this.state.user), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.data.status == "OK") {
                toast.success(res.data.message);
            } else {
                toast.warning(res.data.message);
            }
        }, error => {
            toast.warning("Cập nhật thất bại");
        });
    }


    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }


    uploadThumbnailForWasabi(event) {
        var user = this.state.user;

        var target = event.target;

        var credentials = new AWS.Credentials("0IOD12IF62UYZ22355HV", "H1hKICheXJrZCwJlIu6BeBV21laYErelMgArrMsu");
        var ep = new AWS.Endpoint("s3.wasabisys.com");
        var s3 = new AWS.S3({
            endpoint: ep,
            credentials: credentials,
            region: 'us-east-1'
        });

        // Create a bucket and upload something into it
        var bucketName = "ikara_files";

        var extension = target.files[0].name.split('.').pop().toLowerCase();

        var keyName = this.guid() + "." + extension;

        var contentType = "image/jpeg";


        if (extension == "png") contentType = "image/png";


        var fileReader = new FileReader();

        fileReader.onload = function () {
            var data = fileReader.result; // data <-- in this var you have the file data in Base64 format
            var params = {
                ACL: 'public-read',
                ContentType: contentType,
                Bucket: bucketName,
                Key: keyName,
                Body: data
            };
            s3.putObject(params, function (err, data) {
                if (err) {
                    alert("Upload Failed " + err);
                } else {
                    console.log("OK");
                    user.profileImageLink = 'https://s3.wasabisys.com/' + bucketName + '/' + keyName;
                    this.setState({
                        user: user
                    })
                }
            }.bind(this));
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }

    showButtonUnsubscribeOkara() {
        const { user } = this.context;
        const {t} = this.props;
        if (user != null && user.vipOkara && detectApp("okara")) {
            return (
                <div className='d-md-none d-flex justify-content-center mt-4'>
                    <button
                        data-toggle="modal"
                        data-target="#modalUnsubscribeOkara"
                        style={{ "borderRadius": "20px", "backgroundColor": "#fa882c" }}
                        className='btn w-50 text-white'>{t("my_profile_page.unsubscribe_vip","Hủy đăng ký VIP")}</button>
                </div>
            )

        }
    }

    showIconGetVipOkara() {
        const { user } = this.context;

        if (user != null && !user.vipOkara && detectApp("okara")) {
            return (
                <img
                    data-toggle="modal"
                    data-target="#modalGetVipOkara"
                    style={{ "cursor": "pointer", "position": "absolute", "bottom": "0", "left": "35px", "width": "80px" }}
                    src={require('../../assets/images/get_vip_okara.svg')}
                />
            )
        }
    }

    showIconVipOkara() {
        const { user } = this.context;
        if (user != null && user.vipOkara && detectApp("okara")) {
            return (
                <img
                    style={{ "position": "absolute", "bottom": "0", "left": "45px", "width": "60px" }}
                    src={require('../../assets/images/vip_okara.svg')}
                />
            );
        }
    }

    render() {
        const model = bindModel(this);
        const { user } = this.state;
        const { t } = this.props;

        if (user == null) {
            return (
                <div id="content">
                    <div className="container">
                        <h2 className="text-center mb-2 mt-xl-2">{t("my_profile_page.profile", "Hồ sơ")}</h2>
                        <hr />
                        <div className="row">
                            <div className="col-lg-3">
                                <MenuInProfile></MenuInProfile>
                            </div>
                        </div>
                    </div>
                </div>);
        }

        return (
            <div id="content" >
                <Helmet>
                    <title>{user.name}</title>
                </Helmet>
                <div className="container">
                    <h2 className="text-center mb-2 mt-2">{t("my_profile_page.profile", "Hồ sơ")}</h2>
                    <hr />
                    <div className="row">
                        <div className="col-lg-3 mb-2">
                            <MenuInProfile></MenuInProfile>
                        </div>
                        <div className="col-lg-9">
                            <div className="">
                                <div className="row">
                                    <div className="col-xl-4 p-xl-4 d-flex justify-content-center border-right">
                                        <div style={{ "position": "relative", "width": "150px", "height": "150px" }}>
                                            <img
                                                className="rounded-circle mb-sm-4 mb-2 avatar-profile"
                                                src={user.profileImageLink} />
                                            {this.showIconVipOkara()}
                                            {this.showIconGetVipOkara()}
                                        </div>
                                    </div>
                                    <div className="col-xl-8 mt-3 mt-md-0">
                                        <   div className="row m-2">
                                            <label className="col-md-3 col-4 col-form-label font-weight-bold">ID </label>
                                            <input className="border-0 form-control col-6" type="text" defaultValue={user.uid} readOnly={true} />
                                        </div>
                                        <div className="row m-2">
                                            <label className="col-md-3 col-4 col-form-label font-weight-bold" htmlFor="name">{t("my_profile_page.full_name")} </label>
                                            <input className="border-0 form-control col-6" type="text" id="name" {...model('user.name')} readOnly={true} />
                                        </div>
                                        <div className="row m-2">
                                            <label htmlFor="location" className="col-md-3 col-4 col-form-label font-weight-bold">{t("my_profile_page.address")} </label>
                                            <input className="border-0 form-control col-6" id="location" type="text" {...model('user.location')} readOnly={true} />
                                        </div>
                                        <div className="row m-2">
                                            <label htmlFor="phone" className="col-md-3 col-4 col-form-label font-weight-bold">{t("my_profile_page.phone")} </label>
                                            <input className="border-0 form-control col-6" id="phone" type="text"{...model('user.phoneNumber')} readOnly={true} />
                                        </div>
                                    </div>
                                </div>
                                {this.showButtonUnsubscribeOkara()}

                            </div>
                            {/* <div className="text-center mt-5 mb-2">
                                <button onClick={this.saveProfile} className="btn btn-red w-50">Lưu thông tin</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ProfilePage.contextType = MyContext;
export default withTranslation()(ProfilePage)

