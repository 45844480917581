import { event } from 'jquery';
import React, { Component } from 'react';
import MyContext from '../common/MyContext'
import { useHistory } from "react-router-dom";


class BoxSearch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			keyword: ''
		}

		this.onHandleChange = this.onHandleChange.bind(this);
		this.search = this.search.bind(this);
	}

	onHandleChange(event) {
		let target = event.target;
		let name = target.name;
		let value = target.value;
		
		this.setState({
			[name]: value
		});
	}

	search(event) {

		if(event.key == "Enter") {
			console.log("OK");
			const history = useHistory();
			if(history) history.push("/react")
		}
	}

	render() {
		return (
			<div id="box-search-pc" className="container" style={{ display: 'none' }}>
				<div className="main">
					<h3>Nhập từ khóa tìm kiếm</h3>
					<div>
						<div className="box-search-content">
							<input onKeyDown={this.search} onChange={this.onHandleChange} id="keyword" name="keyword" type="search" maxLength={255} placeholder="Nội dung tìm kiếm ..." className="ipt-search" autoComplete="off" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

BoxSearch.contextType = MyContext;


export default BoxSearch;