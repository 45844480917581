import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import Update from './Update';

export default class SpecialGiftDiscountPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
        specialGifts: [],
        specialGift:{},
        new1 :0,
        new2 :0,
   
    }
    this.getspecialGiftList = this.getspecialGiftList.bind(this);
    this.addNewSpecialGift = this.addNewSpecialGift.bind(this);
    this.addNewSpecialGift1 = this.addNewSpecialGift1.bind(this);
    this.updateSpecialGift = this.updateSpecialGift.bind(this);
  }

  // componentWillReceiveProps() {
  //   this.getVipRecordingList();
  // }

  componentDidMount() {
    this.getspecialGiftList();
  }
  addNewSpecialGift() {
    var specialGift1 = {
        id: 0,
        price: '',
        discount: '',
        priceAfterDiscount: '',
        startDay: '',
        endDay: '',
        vip: true,

    };
    this.setState({
        specialGift:specialGift1
    })

}
addNewSpecialGift1() {
    var specialGift = {
        id: '',
        price: '',
        discount:'',
        priceAfterDiscount: '',
        startDay: '',
        endDay: '',
        vip: false,

    };
    this.setState({
        specialGift:specialGift
    })

}
 updateSpecialGift = (event) => {
    var loca = event.target.name
    var list1 = [...this.state.specialGifts]
    
    var specialGift = list1[loca]
    this.setState({
        specialGift:specialGift
    })

}

  getspecialGiftList() {
      axios.post(global.config.apiDomain + '/rest/SpecialGiftDiscount/get')
        .then(res => {
            if(res.data.status =="OK"){
          this.setState({
            specialGifts : res.data.list
            
          })

          var  list= res.data.list
          console.log(list);
          if(list.length != 0 && list.length ==1 ){
            console.log("tới");
            console.log(list[0])
                if(list[0] ){
                    console.log("vào đây");
                    if(list[0].vip == true)
                        {
                            this.setState({
                                new1:1
                            })
                        }
                        else{
                            console.log("vào đây 2");
                            this.setState({
                                new2:1
                            })
                        }
                }
          }
          else{
            if(list.length != 0 && list.length == 2 ){
                this.setState({
                    new2:1,
                    new1:1
                })
            }
        }
        }
         
        });
    
  }


  render() {
    const { specialGifts , new1,new2 ,specialGift} = this.state;
    
    return (
      <>
        <Update
        specialGift ={specialGift}
        resetList ={this.getspecialGiftList}
          >
        </Update>
        <div id="content">
          <div className="container-fluid">
          <h1 className="text-center">Danh sách giảm giá thẻ xử lí AI</h1>  
          <button
                          className="btn btn-danger"
                          onClick={this.addNewSpecialGift}
                          data-toggle="modal"
                          data-target="#update"
                        >
                        Thêm mới 
                        </button>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Giá Gốc</th>
                  <th scope="col">Giảm giá %</th>
                  <th scope="col">Giá Sau Khi giảm</th>
                  <th scope="col">Ngày bắt đầu</th>
                  <th scope="col">Ngày kết thúc</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {specialGifts && specialGifts.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="align-middle">{item.price}</td>
                      <td className="align-middle">{item.discount} %</td>
                      <td className="align-middle">{item.priceAfterDiscount}</td>
                      {/* {item.vip ?
                      <td className="align-middle">true</td>
                :<td className="align-middle">false</td>} */}
                      <td className="align-middle">{item.startDay}</td>
                      <td className="align-middle">{item.endDay}</td>
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={this.updateSpecialGift}
                          name={index}
                          data-toggle="modal"
                          data-target="#update"
                        >
                        Sửa
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}