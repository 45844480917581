import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      songs: [],
      category: {
        id: "",
        name: "",
        rank: "",
        description: "",
        previewImg: ""
      },
      showCategories: {},
      searchIkara: {
        page: 0,
        keyWord: ''
      },
      searchList: [],
      loading: false,
      statusButtonLoadPage: false
    };

    this.getSongsByCategoryId = this.getSongsByCategoryId.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.addCategoryToSong = this.addCategoryToSong.bind(this);
    this.addOrRemoveCategoryIntoSong = this.addOrRemoveCategoryIntoSong.bind(this);
    this.removeCategoryFromSong = this.removeCategoryFromSong.bind(this);
    this.searchIkaraBeat = this.searchIkaraBeat.bind(this);
    this.loadMoreSearchIkaraBeat = this.loadMoreSearchIkaraBeat.bind(this);
    this.addIkaraBeat = this.addIkaraBeat.bind(this);

  }

  static getDerivedStateFromProps(props, state) {
    if (props.showCategories !== state.showCategories) {
      return {
        showCategories: props.showCategories
      };
    }
    return null;
  }

  componentDidMount() {
    this.getCategoryById();
    this.getSongsByCategoryId(this.state.currentPage);
  }

  getSongsByCategoryId() {
    let searchList = this.state.searchList;
    let json = this.state.category;
    json.id = this.state.showCategories.categoryId;

    axios.post(global.config.apiDomain + '/rest/youtubesong/getAllYoutubeSongOfCategory', json).then((res) => {
      let songs = res.data.youtubeSongs;
      this.setState({
        songs: songs
      });
      if (searchList && searchList.length) {
        searchList.forEach(searchSong => {
          if (songs && songs.length) {
            for (let i = 0; i < songs.length; i++) {
              let song = songs[i];
              if (song.songId == searchSong.songId) {
                searchSong.available = false;
                break;
              } 
              searchSong.available = true;
            }
          } else {
            searchSong.available = true;
          }
        })
        this.setState({
          searchList: searchList
        });
      }
    }).catch((error) => {
      console.log('error get songs ====');
      console.log(error);
    });
  }

  getCategoryById() {
    let modal = this.state.category;
    modal.id = this.state.showCategories.categoryId;

    axios.post(global.config.apiDomain + '/rest/categories/getCategoryById', modal).then((res) => {
      this.setState({
        category: res.data.categoryModel
      });
    }).catch((error) => {
      console.log('error get category ====');
      console.log(error);
    });
  }

  addCategoryToSong() {
    let songName = document.getElementById('songName').value;
    this.addOrRemoveCategoryIntoSong(songName);
  }

  removeCategoryFromSong(event) {
    let allowLink = window.confirm('Chắc chắn xóa?');
    if (allowLink) {
      let modal = {
        strCategoryId: this.state.category.id,
        strName: event.target.name,
        status: "remove"
      }

      axios.post(global.config.apiDomain + '/rest/youtubesong/addOrRemoveCategoryIntoSong', modal)
        .then((res) => {
          if (res.data.status === 'OK') {
            toast.success(res.data.message);
            this.getSongsByCategoryId(1);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log('error add song into category ====');
          console.log(error);
        });
    }
  }

  searchIkaraBeat() {
    let keyWord = document.getElementById('keyWord').value;
    let songs = this.state.songs;

    if (!keyWord) {
      toast.error('Nhập từ khóa tìm kiếm!')
      return
    }

    let searchIkara = {
      page: 0,
      keyWord: keyWord
    }

    axios.post(global.config.apiDomain + '/rest/youtubesong/searchIkaraBeat', searchIkara)
      .then((res) => {
        if (res.data.status === 'OK') {
          toast.success(res.data.message);
          let status = true;
          if (res.data.youtubeSongs.length < 20) {
            status = false;
          }
          let searchList = res.data.youtubeSongs;
          searchList.forEach(searchSong => {
            if (songs && songs.length) {
              for (let i = 0; i < songs.length; i++) {
                let song = songs[i];
                if (song.songId == searchSong.songId) {
                  searchSong.available = false;
                  break;
                } 
                searchSong.available = true;
              }
            } else {
              searchSong.available = true;
            }
          })
          // console.log('searchList');
          // console.log(searchList);
          searchIkara.page = searchIkara.page + 1
          this.setState({
            searchIkara: searchIkara,
            searchList: searchList,
            statusButtonLoadPage: status
          });
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log('error add song into category ====');
        console.log(error);
      });
  }

  loadMoreSearchIkaraBeat() {
    let searchIkara = this.state.searchIkara;
    let songs = this.state.songs;
    axios.post(global.config.apiDomain + '/rest/youtubesong/searchIkaraBeat', searchIkara)
      .then((res) => {
        if (res.data.status === 'OK') {
          toast.success(res.data.message);
          let status = true;
          let searchList = res.data.youtubeSongs;
          searchList.forEach(searchSong => {
            if (songs && songs.length) {
              for (let i = 0; i < songs.length; i++) {
                let song = songs[i];
                if (song.songId == searchSong.songId) {
                  searchSong.available = false;
                  break;
                } 
                searchSong.available = true;
              }
            } else {
              searchSong.available = true;
            }
          })
         
         
          if (searchList.length < 20) {
            status = false;
          }
          searchIkara.page = searchIkara.page + 1
          this.setState({
            searchIkara: searchIkara,
            searchList: this.state.searchList.concat(searchList),
            statusButtonLoadPage: status
          });
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log('error add song into category ====');
        console.log(error);
      });
  }

  addIkaraBeat(event) {
    let songName = event.target.name;
    this.addOrRemoveCategoryIntoSong(songName);
  }

  addOrRemoveCategoryIntoSong(songName) {
    if (this.state.category.id == null || this.state.category.id == '')
      toast.error('Chọn Thể Loại!')
    else {
      if (songName) {
        let modal = {
          strCategoryId: this.state.category.id,
          strName: songName,
          status: "create"
        }

        axios.post(global.config.apiDomain + '/rest/youtubesong/addOrRemoveCategoryIntoSong', modal)
          .then((res) => {
            if (res.data.status === 'OK') {
              toast.success(res.data.message);
              this.getSongsByCategoryId(1);
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((error) => {
            console.log('error add song into category ====');
            console.log(error);
          });
      } else {
        toast.error("Nhập mã Bài hát");
      }
    }
  }

  changeCategory() {
    this.props.updateShowCategory({
      show: true,
      categoryId: ''
    })
  }

  render() {
    const { songs, category, searchList, loading, statusButtonLoadPage } = this.state;

    return (
      <>
        <div id="content">
          <div className="container-fluid">
            <div className='row'>
              <button
                type="button"
                class="btn btn-success"
                fdprocessedid="w2ofpwc"
                onClick={this.changeCategory}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-checklist" viewBox="0 0 16 16">
                  <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"></path>
                  <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"></path>
                </svg>
                Quay về Danh sách Chủ đề
              </button>

            </div>
            <h1 className="text-center m-50">Danh sách Theo Chủ đề: <strong className='text-success'>{category.name}</strong></h1>
            <div className='row'>
              <div className='col-6'>
                <div style={{ marginBottom: '50px' }}>
                  <label>Thêm Beat mới vào Chủ đề: <strong className='text-success'>{category.name}</strong></label>
                  <div className="input-group m-30 ">
                    <input type="text" className="form-control col-4" placeholder="Nhập mã bài hát" id='songName' />
                    <div className="input-group-prepend">
                      <button type="button" className="btn btn-success" onClick={this.addCategoryToSong}>
                        + Thêm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <div style={{ marginBottom: '50px' }}>
                  <label>Tìm kiếm Beat <strong className='text-primary'>IKARA</strong></label>
                  <div className="input-group m-30 ">
                    <input type="text" className="form-control col-4" placeholder="Nhập từ khóa tìm kiếm" id='keyWord' />
                    <div className="input-group-prepend">
                      <button type="button" className="btn btn-outline-primary" onClick={this.searchIkaraBeat}>
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="top-hit-page">
              <div className="row">
                <div className='col-6'>
                  <div className="row list-song">
                    {
                      (songs && songs.length) ? songs.map((song, index) => (

                        <div key={index} className="col-6 col-lg-4 col-xl-3 item item-0">
                          <button
                            className="btn btn-danger ml-sm-2 btnDelete"
                            onClick={this.removeCategoryFromSong}
                            name={song.name}
                          >
                            Xóa
                          </button>
                          {song.ikara ?
                            <button
                              className="btn btn-info ml-sm-2"
                            >
                              Ikara
                            </button> :
                            <button
                              className="btn btn-warning ml-sm-2"
                            >
                              Yokara
                            </button>
                          }
                          <div className="images">
                            <span className="overlay" />
                            <img
                              src={song.thumbnailUrl}
                              className="img-item"
                              alt={song.songName}
                            />
                          </div>
                          <h5 className="song-name font-weight-bold">
                            {song.songName}
                          </h5>
                        </div>
                      )) : <h3 className="text-center m-50"> <strong className='text-danger'>Chưa có Beat trong Chủ đề này</strong></h3>
                    }
                  </div>
                </div>
                <div className='col-6'>
                  <table className="table table-hover">
                    <thead>
                      <tr className="text-center">
                        <th className="align-middle" scope="col">#</th>
                        <th className="align-middle" scope="col">ID</th>
                        <th className="align-middle" scope="col">Tên Beat</th>
                        <th className="align-middle" scope="col">Mp3 Link</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        searchList && searchList.length ? searchList.map((song, index) => (
                          <tr key={index}>
                            <td className='align-middle text-center'>{index + 1}</td>
                            <td className='align-middle'>{song.songId}</td>
                            <td className='align-middle'>{song.songName}</td>
                            <td className='align-middle text-center'>
                              {/* <a href={song.songUrl} target="_blank">
                                Link
                              </a> */}
                              <button className='btn btn-outline-warning' onClick={() => window.open(song.songUrl, '_blank', 'noopener,noreferrer')}>
                                Link
                              </button>
                              {/* <audio controls>
                                <source src={song.songUrl} type="audio/mp3" />
                              </audio> */}
                            </td>
                            <td>
                              {
                                song.available ? <button
                                  className="btn btn-primary"
                                  onClick={this.addIkaraBeat}
                                  name={song.name}
                                >
                                  Chọn
                                </button> : ''
                              }

                            </td>
                          </tr>
                        )) : ''
                      }
                    </tbody>
                  </table>
                  {
                    statusButtonLoadPage ?
                      (<div onClick={this.loadMoreSearchIkaraBeat} className="view_more w-100 text-center mb-lg-4">
                        <a className="d-none d-md-block">
                          <span>{loading ? "ĐANG TẢI..." : "TẢI THÊM"}</span>
                        </a>
                        <a className="view_more_mobile d-md-none">
                          <i className="fa fa-angle-down" aria-hidden="true" />
                        </a>
                      </div>) :
                      ('')
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Category

