import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import bindModel from '../../../common/bindModel.js'

const $ = window.$;

export default class ModalBanFeature extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            banType: "ban",
            recordingComment: this.props.featureBanStatus.recordingComment,
            message: this.props.featureBanStatus.message,
            roomMessage: this.props.featureBanStatus.roomMessage,
            roomEntry: this.props.featureBanStatus.roomEntry,
            imageUpload: this.props.featureBanStatus.imageUpload,
            signature: this.props.featureBanStatus.signature,
            avatar: this.props.featureBanStatus.avatar,
            useIcoin: this.props.featureBanStatus.useIcoin,
            publicRecording: this.props.featureBanStatus.publicRecording,
            initialState: {} // Lưu trạng thái ban đầu
        };

        this.banFeature = this.banFeature.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        // Lưu trạng thái ban đầu
        this.setState({
            initialState: {
                recordingComment: this.props.featureBanStatus.recordingComment,
                message: this.props.featureBanStatus.message,
                roomMessage: this.props.featureBanStatus.roomMessage,
                roomEntry: this.props.featureBanStatus.roomEntry,
                imageUpload: this.props.featureBanStatus.imageUpload,
                signature: this.props.featureBanStatus.signature,
                avatar: this.props.featureBanStatus.avatar,
                useIcoin: this.props.featureBanStatus.useIcoin,
                publicRecording: this.props.featureBanStatus.publicRecording
            }
        });
    }

    handleCheckboxChange(event) {
        const { id, checked } = event.target;
        this.setState({ [id]: checked });
    };

    handleCancel() {
        // Khôi phục lại trạng thái ban đầu khi nhấn Hủy
        this.setState(this.state.initialState);
        $("#modalBanFeature").modal("hide");
    }

    banFeature() {
        console.log('vao banFeature()');
        this.setState({
            loading: true
        });
        let json = {
            id: this.props.featureBanStatus.id,
            recordingComment: this.state.recordingComment,
            message: this.state.message,
            roomMessage: this.state.roomMessage,
            roomEntry: this.state.roomEntry,
            imageUpload: this.state.imageUpload,
            signature: this.state.signature,
            avatar: this.state.avatar,
            useIcoin: this.state.useIcoin,
            publicRecording: this.state.publicRecording
        }
        console.log("json")
        console.log(json)
        axios.post(global.config.apiDomain + '/rest/admin/banUserFeature', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                loading: false
            });
            if (res.data.status == "OK") {
                $("#modalBanFeature").modal("hide");
                toast.success(res.data.message);
                this.setState({
                    initialState: {
                        recordingComment: this.state.recordingComment,
                        message: this.state.message,
                        roomMessage: this.state.roomMessage,
                        roomEntry: this.state.roomEntry,
                        imageUpload: this.state.imageUpload,
                        signature: this.state.signature,
                        avatar: this.state.avatar,
                        useIcoin: this.state.useIcoin,
                        publicRecording: this.state.publicRecording
                    }
                });

                this.props.resetAccount();
            } else {
                toast.warning(res.data.message);
            }
        }, err => {
            this.setState({
                loading: false
            });
            toast.error("Khóa tính năng thất bại");
        })
    }

    render() {
        return (
            <div className="modal fade" id="modalBanFeature" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Khóa tính năng người dùng</h5>
                            <button type="button" className="close" onClick={this.handleCancel} aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <form>
                                    <input type="checkbox" style={{ display: "inline-block", width: "20px", height: "20px", verticalAlign: "middle", backgroundColor: "#fff", border: "2px solid #ccc", borderRadius: "4px", cursor: "pointer", transition: "background-color 0.3s ease" }} className='featureCheckbox' id="recordingComment" checked={this.state.recordingComment} onChange={this.handleCheckboxChange} />
                                    <label htmlFor="checkboxcomment" style={{ marginLeft: "5px", fontWeight: 600, textAlign:'center' }} className='featureLabel'> Chặn bình luận bài thu</label><br />
                                    <input type="checkbox" style={{ display: "inline-block", width: "20px", height: "20px", verticalAlign: "middle", backgroundColor: "#fff", border: "2px solid #ccc", borderRadius: "4px", cursor: "pointer", transition: "background-color 0.3s ease" }} className='featureCheckbox' id="message" checked={this.state.message} onChange={this.handleCheckboxChange} />
                                    <label htmlFor="checkboxmessenger" style={{ marginLeft: "5px", fontWeight: 600, textAlign:'center' }} className='featureLabel'> Chặn nhắn tin</label><br />
                                    <input type="checkbox" style={{ display: "inline-block", width: "20px", height: "20px", verticalAlign: "middle", backgroundColor: "#fff", border: "2px solid #ccc", borderRadius: "4px", cursor: "pointer", transition: "background-color 0.3s ease" }} className='featureCheckbox' id="roomMessage" checked={this.state.roomMessage} onChange={this.handleCheckboxChange} />
                                    <label htmlFor="checkboxroommessenger" style={{ marginLeft: "5px", fontWeight: 600, textAlign:'center' }} className='featureLabel'> Chặn tin nhắn trong phòng</label><br />
                                    <input type="checkbox" style={{ display: "inline-block", width: "20px", height: "20px", verticalAlign: "middle", backgroundColor: "#fff", border: "2px solid #ccc", borderRadius: "4px", cursor: "pointer", transition: "background-color 0.3s ease" }} className='featureCheckbox' id="roomEntry" checked={this.state.roomEntry} onChange={this.handleCheckboxChange} />
                                    <label htmlFor="checkboxenterroom" style={{ marginLeft: "5px", fontWeight: 600, textAlign:'center' }} className='featureLabel'> Chặn vào phòng</label><br />
                                    <input type="checkbox" style={{ display: "inline-block", width: "20px", height: "20px", verticalAlign: "middle", backgroundColor: "#fff", border: "2px solid #ccc", borderRadius: "4px", cursor: "pointer", transition: "background-color 0.3s ease" }} className='featureCheckbox' id="imageUpload" checked={this.state.imageUpload} onChange={this.handleCheckboxChange} />
                                    <label htmlFor="checkboxalbumupload" style={{ marginLeft: "5px", fontWeight: 600, textAlign:'center' }} className='featureLabel'> Chặn up ảnh album</label><br />
                                    <input type="checkbox" style={{ display: "inline-block", width: "20px", height: "20px", verticalAlign: "middle", backgroundColor: "#fff", border: "2px solid #ccc", borderRadius: "4px", cursor: "pointer", transition: "background-color 0.3s ease" }} className='featureCheckbox' id="signature" checked={this.state.signature} onChange={this.handleCheckboxChange} />
                                    <label htmlFor="checkboxstatus" style={{ marginLeft: "5px", fontWeight: 600, textAlign:'center' }} className='featureLabel'> Đổi trạng thái</label><br />
                                    <input type="checkbox" style={{ display: "inline-block", width: "20px", height: "20px", verticalAlign: "middle", backgroundColor: "#fff", border: "2px solid #ccc", borderRadius: "4px", cursor: "pointer", transition: "background-color 0.3s ease" }} className='featureCheckbox' id="avatar" checked={this.state.avatar} onChange={this.handleCheckboxChange} />
                                    <label htmlFor="checkboxavatar" style={{ marginLeft: "5px", fontWeight: 600, textAlign:'center' }} className='featureLabel'> Đổi avatar</label><br />
                                    <input type="checkbox" style={{ display: "inline-block", width: "20px", height: "20px", verticalAlign: "middle", backgroundColor: "#fff", border: "2px solid #ccc", borderRadius: "4px", cursor: "pointer", transition: "background-color 0.3s ease" }} className='featureCheckbox' id="useIcoin" checked={this.state.useIcoin} onChange={this.handleCheckboxChange} />
                                    <label htmlFor="checkboxmoney" style={{ marginLeft: "5px", fontWeight: 600, textAlign:'center' }} className='featureLabel'> Chặn tiêu tiền</label><br />
                                    <input type="checkbox" style={{ display: "inline-block", width: "20px", height: "20px", verticalAlign: "middle", backgroundColor: "#fff", border: "2px solid #ccc", borderRadius: "4px", cursor: "pointer", transition: "background-color 0.3s ease" }} className='featureCheckbox' id="publicRecording" checked={this.state.publicRecording} onChange={this.handleCheckboxChange} />
                                    <label htmlFor="checkboxrecording" style={{ marginLeft: "5px", fontWeight: 600, textAlign:'center' }} className='featureLabel'> Chặn public bài thu</label><br /><br />
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-red" onClick={this.handleCancel}>Hủy</button>
                            {this.state.loading ?
                                (
                                    <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Xác nhận...
                                    </button>
                                ) :
                                (
                                    <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.banFeature}>Xác nhận</button>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
