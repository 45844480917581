import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';

export default class ModalDeleteAppPolicy extends Component {
    constructor(props) {
        super(props);

        this.state = {
            appPolicyModel: null,
            loading: false
        }

        this.deleteOrActiveAppPolicy = this.deleteOrActiveAppPolicy.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            appPolicyModel : props.appPolicyModel
        })
    }

    deleteOrActiveAppPolicy() {
        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/app-policy/deleteOrActiveAppPolicy', this.state.appPolicyModel)
        .then(res => {
            this.setState({
                loading: false
            });

            if (res.data.status == "OK") {
                window.$("#modalDeleteAppPolicy").modal("hide");
                toast.success(res.data.message); 
                this.props.resetList();
            } else {
                toast.warning(res.data.message);
            }
        })
    }

    render() {
        const {appPolicyModel} = this.state;

        return (
            <div>
                <div className="modal fade" id="modalDeleteAppPolicy" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                            {(appPolicyModel && !appPolicyModel.deleted) ? (
                                 <h5 className="modal-title" id="exampleModalLabel">XÓA Thể lệ</h5>
                            ) : ""}
                            {(appPolicyModel && appPolicyModel.deleted) ? (
                                 <h5 className="modal-title" id="exampleModalLabel">HỦY XÓA Thể lệ</h5>
                            ) : ""}
                               
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {(appPolicyModel && !appPolicyModel.deleted) ? (
                                <div className="modal-body">
                                <h3 className="text-center text-danger">Bạn chắc chắn muốn XÓA Thể lệ: <span className="font-weight-bold">Level {appPolicyModel._id}</span> ?</h3>
                            </div>
                            ) : ""}
                            {(appPolicyModel && appPolicyModel.deleted) ? (
                                <div className="modal-body">
                                <h3 className="text-center text-info">Bạn muốn HỦY XÓA Thể lệ: <span className="font-weight-bold">Level {appPolicyModel._id}</span> ?</h3>
                            </div>
                            ) : ""}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ? 
                                (
                                <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Xác nhận...
                                </button>
                                ) :
                                (
                                <button 
                                    style={{width: "120px"}} 
                                    type="button" 
                                    className="btn btn-red"
                                    onClick={this.deleteOrActiveAppPolicy}>Xác nhận</button>    
                                )}                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}