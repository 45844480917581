import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import SVGA from 'svgaplayerweb'
import $ from 'jquery';
import bindModel from '../../../common/bindModel';

export default class ModalUpdateSinger extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            loading: false,
        }
        this.updateSinger = this.updateSinger.bind(this);
        this.uploadThumbnail = this.uploadThumbnail.bind(this);
    }
    updateSinger() {
        const { user } = this.state;
        this.setState({
            loading: true
        });
        let json = {
            facebookId: user.facebookId,
            uid: user.uid,
            name: user.name,
            profileImageLink: user.profileImageLink,
            signature: user.signature
        }
        console.log("nhap user", json);

        axios.post(global.config.apiDomain + "/rest/userPost/updateSinger", JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                this.setState({
                    loading: false
                });
                if (res.data && res.data.status == "OK") {
                    window.$("#modalUpdateSinger").modal("hide");
                    toast.success(res.data.message);
                } else {
                    toast.success(res.data.message)
                }
            })
    }

    componentWillReceiveProps(props) {
        const { user } = props;
        console.log("user", user);

        if (user == null) return
        this.setState({
            user: user
        })
    }
    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
    uploadThumbnail(event) {
        var target = event.target;
        if (target.files[0].size > 10495760) {
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }
        const { user } = this.state;

        this.setState({
            loading: true
        });

        var bucketName = "ikara-data/images/userPost";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";
        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    user.profileImageLink = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        loading: false
                    });

                    this.setState({
                        user: user
                    });
                }.bind(this)
            });
        }.bind(this);
        fileReader.readAsArrayBuffer(target.files[0]);
    }


    render() {
        const bind = bindModel(this);
        const { user } = this.state;
        return (
            <div>
                <div className="modal fade" id="modalUpdateSinger" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật thông tin Singer</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>ID</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="id"
                                            {...bind('user.uid')}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>Tên SINGER</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            placeholder="Nhập tên singer"
                                            {...bind('user.name')} />
                                    </div>
                                </div>
                                <div id="thumbnailImageUrl" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ảnh avatar</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="custom-file-input"
                                            type="file"
                                            placeholder="Chọn hình mô tả"
                                            onChange={this.uploadThumbnail} />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                                    </div>
                                </div>
                                {user && user.profileImageLink ?
                                    <div id="reviewThumbnailUrl" className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div id="show-thumbnailUrl" className="col-9" style={{ maxHeight: "300px" }}>
                                            <img src={user.profileImageLink} width="200px" />
                                        </div>
                                    </div> : ''}


                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>Trạng thái</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            placeholder="Nhập tên trạng thái"
                                            {...bind('user.signature')} />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red"
                                            onClick={this.updateSinger}
                                        >Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}