import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import bindModel from '../../../../../common/bindModel.js';
import ShowGameQuestions from './ShowGameQuestions.js';
import DetailQuestion from './DetailQuestion.js';
import axios from 'axios';

const $ = window.$;
export default class ShowGameTypes extends Component {
    constructor(props) {
        super(props);
        
        let newGameQuestion = {
            content: "",
            description: "",
            imageQuestion: "",
            imageAnswer: "",
            audioQuestion: "",
            answerId: null,
            gameAnswers: []
        }

        this.state = {
            gameTypesAdded: props.gameTypesAdded,
            gameQuestionSelected: newGameQuestion,
            newGameQuestion: newGameQuestion,
            iGameQuestionSelected: null,
            iGameTypeSelected: null,
            typeModalGameQuestion: "CREATE", //CREATE, UPDATE
            loadingRemoveGameQuestion: false
        }

        this.selectGameQuestion = this.selectGameQuestion.bind(this);
        this.addGameQuestion = this.addGameQuestion.bind(this);
        this.removeGameQuestion = this.removeGameQuestion.bind(this);
        this.updateGameQuestion = this.updateGameQuestion.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            gameTypesAdded: props.gameTypesAdded
        })
    }   

    // call from component DetailQuestion
    selectGameQuestion(gameQuestion, iGameQuestion, iGameType) {
        let typeModalGameQuestion = "CREATE";
        if(iGameQuestion != null) {
            typeModalGameQuestion = "UPDATE"
        }
        this.setState({
            gameQuestionSelected: gameQuestion,
            iGameQuestionSelected: iGameQuestion,
            iGameTypeSelected: iGameType,
            typeModalGameQuestion: typeModalGameQuestion
        });
    }

    // call from component DetailQuestion
    addGameQuestion(gameQuestion, iGameType) {
        let {gameTypesAdded} = this.state;

        if(gameTypesAdded[iGameType].gameQuestions.length < 3) {

            let newGameQuestion = {
                content: "",
                description: "",
                imageQuestion: "",
                imageAnswer: "",
                audioQuestion: "",
                answerId: null,
                gameAnswers: []
            }

            gameTypesAdded[iGameType].gameQuestions.push(gameQuestion);
            this.setState({
                gameTypesAdded: gameTypesAdded,
                newGameQuestion: newGameQuestion
            })

            $("#DetailQuestion").modal("hide");
        } else {
            toast.warn("Không thể thêm quá 3 câu hỏi")
        }
    }

    // call from component DetailQuestion
    removeGameQuestion(iGameQuestion, iGameType) {
        let {gameTypesAdded} = this.state;

        gameTypesAdded[iGameType].gameQuestions.splice(iGameQuestion, 1);
        this.setState({
            gameTypesAdded: gameTypesAdded
        });
        $("#DetailQuestion").modal("hide");

    }

    // call from component DetailQuestion
    updateGameQuestion(gameQuestion, iGameQuestion, iGameType) {
        let {gameTypesAdded} = this.state;
        gameTypesAdded[iGameType].gameQuestions[iGameQuestion] = gameQuestion;
        this.setState({
            gameTypesAdded: gameTypesAdded
        });
        $("#DetailQuestion").modal("hide");
    }

    render() {
        const model = bindModel(this);
        const {gameTypesAdded, gameTypes, removeGameType} = this.props;
        const {gameQuestionSelected, iGameQuestionSelected, iGameTypeSelected, typeModalGameQuestion, newGameQuestion, loadingRemoveGameQuestion} = this.state;
        return (
            <>
                <DetailQuestion 
                    gameQuestion={gameQuestionSelected}
                    addGameQuestion={this.addGameQuestion}
                    removeGameQuestion={this.removeGameQuestion}
                    updateGameQuestion={this.updateGameQuestion}
                    iGameType={iGameTypeSelected}
                    iGameQuestion={iGameQuestionSelected}
                    type={typeModalGameQuestion}
                    loadingRemoveGameQuestion={loadingRemoveGameQuestion}
                >
                </DetailQuestion>
                {gameTypesAdded != null &&
                    gameTypesAdded.map((gameType, i) => {
                        return (
                            <div key={i}>
                                <div className="row justify-content-center mt-2">
                                    <div className="col-4 col-lg-2 my-auto">
                                        <label className="m-0 font-weight-bold">Thể loại game {i + 1}:</label>
                                    </div>
                                    <div className="col-6 col-lg-4">
                                        <input className="form-control"  key={i} value={gameType.name} readOnly/>
                                    </div>
                                    <div className="col-2 col-lg-1">
                                        <button style={{width: "100px"}} className='btn btn-danger' onClick={() => removeGameType(i)}>
                                            Xóa <i className="fa fa-trash" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                                <ShowGameQuestions 
                                    selectGameQuestion={this.selectGameQuestion} 
                                    iGameType={i} 
                                    gameQuestions={gameType.gameQuestions}
                                    newGameQuestion={newGameQuestion}
                                >
                                </ShowGameQuestions>
                            </div>
                        )
                    })
                }
            </>
        )
    }

}
