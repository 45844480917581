import React, { Component } from 'react';
import axios from 'axios';
import MenuInProfile from '../../components/MenuInProfile';
import { withTranslation } from 'react-i18next';
import ModalSellGift from './components/ModalSellGift';
class MyGiftsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gifts: [],
            giftSelected: null
        }

        this.getMyGifts = this.getMyGifts.bind(this);
    }

    componentDidMount() {
        this.getMyGifts();
    }

    getMyGifts() {
        axios.post(global.config.apiDomain + '/rest/profile/getGifts')
            .then(res => {
                const gifts = res.data;
                this.setState({
                    gifts: gifts
                })
        });
    }

    render() {
        const { gifts } = this.state;
        console.log(gifts)
        const { t } = this.props;
        return (
            <>
                <ModalSellGift resetList={this.getMyGifts} gift={this.state.giftSelected}></ModalSellGift>
                <div id="content">
                    <div className="container">
                        <h2 className="text-center mb-2 mb-md-3 mt-xl-2">{t("my_gifts_page.gift", "Quà tặng")}</h2>
                        <hr />
                        <div className="row">
                            <div className="col-lg-3">
                                <MenuInProfile></MenuInProfile>
                            </div>
                            <div className="col-lg-9">

                                <div className="row">
                                    {gifts.map((gift, index) => {
                                        return (
                                            <div
                                                key={gift.id}
                                                className="col-md-2 col-sm-3 col-4 p-2"
                                                data-toggle="modal"
                                                data-target="#modalSellGift"
                                                onClick={() => this.setState({ giftSelected: gift })}>
                                                <div
                                                    className="border rounded border-danger text-center p-2 gift-profile">
                                                    <img className="bg-secondary border rounded w-100" src={gift.url} />
                                                    <p className="text-center mb-1">{gift.name}</p>
                                                    <p className="text-center mb-1">x{gift.noItem}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default withTranslation()(MyGiftsPage)
