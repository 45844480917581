import React, { Component, PureComponent } from 'react'
import HeaderCommentBox from './HeaderCommentBox'
import MainCommentBox from './MainCommentBox'
import Firebase from '../../../components/Firebase.js'
import LoadMoreComments from './LoadMoreComments.js'
import CommentAndGiftCommentLine from './CommentAndGiftCommentLine.js'
import LikeHeader from './LikeHeader.js'
import LikeLine from './LikeLine.js'
import GiftHeader from './GiftHeader.js'
import GiftLine from './GiftLine.js'
import MyContext from '../../../common/MyContext.js'
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import ModalDonate from './ModalDonate';
import GiftButton from './GiftButton';
import { Redirect } from 'react-router'
import {withTranslation} from 'react-i18next';
import { detectApp } from '../../../common/utils.js';
import {toast} from 'react-toastify';
const $ = window.$;
class LikeCommentGiftInRecordingPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
             isFirst : true,
             liked : false,
             comments : [],
             likes : [],
             gifts : [],
             totalLike: 0,
             totalGift: 0,
             totalComment: 0,
             loadMore : true,
             globalLastOne : null,
             toggleComment: false,
             toggleCommentId: 0,
             toggleGiftStatus: false,
             giftSelected: null,
             giftListTemp: [],
             startIndex: 0
        }
        this.ikaraNode = Firebase.database().ref("ikara");
        this.loadComments = this.loadComments.bind(this);
        this.loadLikes = this.loadLikes.bind(this);
        this.loadGifts = this.loadGifts.bind(this);
        this.submitLike = this.submitLike.bind(this);
        this.redirectToLogin = this.redirectToLogin.bind(this);
        this.addComment = this.addComment.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleModalDonate = this.toggleModalDonate.bind(this);
        this.sendGift = this.sendGift.bind(this);
        this.loadUser = this.loadUser.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
             isFirst : true,
             liked : false,
             comments : [],
             likes : [],
             gifts : [],
             totalLike: 0,
             totalGift: 0,
             totalComment: 0,
             loadMore : true,
             globalLastOne : null,
             toggleComment: false,
             toggleCommentId: 0,
             toggleGiftStatus: false,
             giftSelected: null,
             giftListTemp: [],
             startIndex: 0
        });

        // this.loadComments();
        // this.loadLikes();
        // this.loadGifts();
        // console.log("next props", nextProps)
    }

    addComment(param) {
        var {recording} = this.props;
        var self = this;

        if(param) {
            console.log(param);
            this.ikaraNode.child('recordingComments').child(recording._id).child(param).get().then(snapshot => {
                var childs = snapshot.val().childs;
                var newChilds = [];
                for(let i in childs) {
                    childs[i].key = i
                    newChilds.push(childs[i]);   
                }

                for(let i = 0; i < self.state.comments.length; i++) {
                    if(self.state.comments[i].key == param) {
                        self.state.comments[i].childs = newChilds;
                        console.log("suceess")
                    }
                    console.log("error")
                }

                self.setState({
                    comments: self.state.comments
                });

                console.log(self.state.comments);


            });

        } else {
            function loadSnapshot(snapshot) {
            snapshot.forEach(function(commentSnapshot) {
                try{
                    var comment = commentSnapshot.val();
                    comment.userProfile = comment.userProfile.replace("http://", window.protocol+'//');
                    comment.key = commentSnapshot.key;
                    self.state.comments.unshift(comment);    
                } catch (e) {}
                }
            )
            self.setState({
            comments : self.state.comments
            });
        }

        this.ikaraNode.child('recordingComments').child(this.props.recording._id).orderByChild('dateTime').limitToLast(1).once('value').then(loadSnapshot);
        }
    }

   

    redirectToLogin = () => {
        const { history } = this.props;
        if(history) history.push('/login');
    }
    
    componentDidUpdate(){
        const {recording} = this.props;
        const {isFirst} = this.state;
        if (recording != null && isFirst == true){
            this.state.isFirst = false;
            this.loadComments();
            this.loadLikes();
            this.loadGifts();
        }
    }

    componentDidMount() {
        $("#exampleModalDonate").on("hidden.bs.modal", function () {
            this.setState({
                giftSelected: null
            });
        }.bind(this));

        this.setState({
            
        })
    }


    loadGifts(){
        const {recording} = this.props;
        var self = this;
        const {location} = window;

        this.ikaraNode.child('recordingGifts').child(recording._id).child("totalScore").on('value', function(snapshot){
            if (snapshot.val() == null){
                self.setState({
                    totalGift: 0
                })  
            } else {
                self.setState({
                    totalGift: snapshot.val()
                }) 
            }
        });

        this.ikaraNode.child('recordingGifts').child(recording._id).child("users").orderByChild('totalScore').limitToLast(8).once('value').then(function(snapshot){
            self.state.gifts = [];

            snapshot.forEach(function(giftSnapshot) {
                var gift = giftSnapshot.val();
                gift.key = giftSnapshot.key;
                gift.userProfile = gift.userProfile.replace("http://", location.protocol+'//');
                self.state.gifts.unshift(gift);
            });
            self.setState({
                gifts : self.state.gifts,
            });
        });
    }


    loadLikes(){
        const {recording} = this.props;
        const {user} = this.context;        

        var self = this;
        const {location} = window;
        this.ikaraNode.child('recordingLikes').child(recording._id).orderByChild('dateTime').limitToLast(20).once('value').then(function(snapshot){
            snapshot.forEach(function(likeSnapshot) {
                var like = likeSnapshot.val();
                like.key = likeSnapshot.key;
                like.userProfile = like.userProfile.replace("http://", location.protocol+'//');
                if(!self.state.likes.some(o => o.key == like.key)) {
                    self.state.likes.unshift(like);    
                }
                            
            });
            self.setState({
                likes : self.state.likes,
            });
        });

        this.ikaraNode.child('recordings').child(recording._id).child("likeCounter").on('value', function(snapshot){
            if (snapshot.val() == null){
                self.setState({
                    totalLike: 0
                })  
            } else {
                self.setState({
                    totalLike: snapshot.val()
                }) 
            }
                     
        });

        if (user != null){
            this.ikaraNode.child('recordingLikes').child(recording._id).child(user.facebookId).once('value').then(function(snapshot){
				if (snapshot.val() == null){
					self.setState({
                        liked: false
                    }) 
				}else{
					self.setState({
                        liked: true
                    }) 
				}			
			});
        }
    }

    

    submitLike(){
        const {liked} = this.state;
        const {user} = this.context;
        const {recording} = this.props;
        
        if (!liked){
            if (user != null){      
                this.setState({
                    liked :true
                });
                var json = {
                    userId: user.facebookId,
                    language: 'vi',
                    recordingId: recording._id
                } 
                axios.post(global.config.apiDomain + '/rest/like', JSON.stringify(json), {
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  }).then(res => {
                      console.log(res);
                  });
            } else {
                this.redirectToLogin();
            }
        }
    }

    loadComments(){
        const {globalLastOne} = this.state;
        const {recording, noCommentPerRequest} = this.props;
        const {location} = window;
        var self = this;
        function loadSnapshot(snapshot) {
            var i = 0;
            var newComments = []
            snapshot.forEach(function(commentSnapshot) {
                try{
                    var comment = commentSnapshot.val();
                    comment.userProfile = comment.userProfile.replace("http://", location.protocol+'//');
                    comment.key = commentSnapshot.key;
                
                    var commentChildList = [];
                    if(comment.childs != null) {
                        for(let i in comment.childs) {
                            comment.childs[i].key = i;
                            commentChildList.push(comment.childs[i])
                        }
                        comment.childs = commentChildList;
                    }
                    if(!self.state.comments.some(o => o.key == comment.key)) {
                        newComments.unshift(comment);
                    }

                    if (i == 0)
                        self.state.globalLastOne = comment.dateTime;
                        i++;
                   
                } catch (e){}
                }
            );

            self.state.comments = self.state.comments.concat(newComments);
              
            if (i == noCommentPerRequest + 1){
                self.state.loadMore = true;
            } else {
                self.state.loadMore = false;
            }
            self.setState({
                comments : self.state.comments,
                loadMore : self.state.loadMore
            }); 
        }
        this.ikaraNode.child('recordings').child(recording._id).child("commentCounter").on('value', function(snapshot){
            if (snapshot.val() == null){
                self.setState({
                    totalComment: 0
                })  
            } else {
                self.setState({
                    totalComment: snapshot.val()
                }) 
            }
                     
        });


        if (globalLastOne == null)
            this.ikaraNode.child('recordingComments').child(recording._id).orderByChild('dateTime').limitToLast(noCommentPerRequest + 1).once('value').then(loadSnapshot);
        else
            this.ikaraNode.child('recordingComments').child(recording._id).orderByChild('dateTime').endAt(globalLastOne - 1).limitToLast(noCommentPerRequest + 1).once('value').then(loadSnapshot);
    };

    toggle(param) {
        if(param !== this.state.toggleCommentId && this.state.toggleComment) {
            this.setState({
                toggleCommentId : param
            })
        } else {
            this.setState({
                toggleComment : !this.state.toggleComment,
                toggleCommentId : param
            })
        }
       
    }

    // người dùng chọn gift
    toggleModalDonate(param) {
        this.setState({
            giftSelected: param
        })
    }

    // closeModalSelectGift() {
    //     this.setState({
    //         giftSelected : null
    //     });
    // }

    sendGift() {
        var {user} = this.context;
        const {recording} = this.props;
        const {giftSelected} = this.state;

        let json = {
            userId : user.facebookId,
            language: recording.language,
            toFacebookId: recording.owner.facebookId,
            giftId : giftSelected.id,
            noItem: 1,
            recordingId: recording._id,
            relativeTime: new Date().getTime()
        }

        axios.post(global.config.apiDomain + '/rest/gift', JSON.stringify(json), {
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(res => {
                // this.loadUser();
                if(res.data.status == "OK") {
                    user.totalIcoin = user.totalIcoin - giftSelected.buyPrice;
                    this.context.updateUser(user);
                    this.addComment();
                    this.loadGifts();
                    this.setState({
                        giftSelected: null
                    });
                } else {
                    toast.warning(res.data.message);
                }

              
          });
    }

    loadUser() {
        const json = JSON.stringify({ domain: window.location.hostname});

		axios.post(global.config.apiDomain + '/rest/loginuser', json, {
			headers: {
			  'Content-Type': 'application/json'
			}
		  }).then(res => {
			const {user} = res.data;
			this.context.updateUser(user); 
		  })
    }

    
    render() {
        const {recording, giftList, t} = this.props;
        const {user} = this.context;
        const {comments, likes, gifts, totalLike, totalGift, totalComment, liked, toggleComment, toggleCommentId, loadMore, giftSelected} = this.state;

        return (
            <div>
                <ModalDonate
                    giftSelected={giftSelected}
                    giftList={giftList}
                    toggleModalDonate={this.toggleModalDonate}
                    user={user}
                    sendGift={this.sendGift}
                 ></ModalDonate>
                {/* <table className="uiGrid _51mz" cellSpacing={0} cellPadding={0} id="u_0_0" style={{marginLeft: '8px'}}>
                    <tbody>
                        <tr className="_51mx">
                        <td className="_51m- _51mw like_header">
                            <LikeHeader totalLike={totalLike} liked={liked} submitLikeFunction={this.submitLike}></LikeHeader>
                        </td>
                        </tr>
                        <tr className="_51mx">
                        <td className="_51m- _2pic _51mw">
                            <div className="_43qm pluginFacepile pluginFacepileConnectConnected _43qc" id="u_0_5">
                                <ul className="uiList _4cg3 _509- _4ki">
                                    {
                                        likes.map((like, index)=>(
                                            <LikeLine key ={like.key} like = {like}></LikeLine>
                                        ))
                                    }
                                </ul>
                            </div>
                        </td>
                        </tr>
                    </tbody>
                </table> */}
                <div className={detectApp("ikarayokara") ? "DIV_1" : "DIV_1 d-none"}> 
                    <div className="DIV_2 clearfix mb-3">
                        <GiftButton user={user}></GiftButton>
                    </div>
                    <div className="DIV_7">
                        <ul className="UL_8 row align-items-center">
                            <GiftHeader user={user} totalGift={totalGift}></GiftHeader>
                            {
                                gifts.map((gift, index)=>(
                                    <GiftLine key ={gift.key} gift = {gift} index={index}></GiftLine>
                                ))
                            }
                        </ul>
                    </div>
                </div>
                <div className="commentSection">
                    <div className="_2pi8">
                        <div className="_491z clearfix">
                            <HeaderCommentBox totalComment={totalComment}></HeaderCommentBox>
                        </div>
                        <div className="_4uyl _1zz8 _2392 clearfix" direction="left">
                            <MainCommentBox 
                                    addNewComment={this.addComment} 
                                    recording={recording} 
                                    user={user}></MainCommentBox>
                        </div>
                        <div className="_4k-6">
                        {
                            comments.map((comment)=>(
                                <CommentAndGiftCommentLine 
                                        key ={comment.key} 
                                        comment = {comment} 
                                        recording={recording} 
                                        user={user}
                                        toggleComment={toggleComment}
                                        toggleCommentId={toggleCommentId}
                                        toggleFunc={this.toggle}
                                        addNewComment={this.addComment} 
                                ></CommentAndGiftCommentLine>
                            ))
                        }
                        <div className="_4k-6">
                            {   loadMore ? 
                                (<LoadMoreComments loadMoreCommentsFunction={this.loadComments}></LoadMoreComments>) : 
                                ('')
                            }
                        </div>
                        </div>
                        <div className="_5lm5 _2pi3 _3-8y">
                        <div direction="left" className="clearfix">
                            <div className="_ohe lfloat">
                            <i alt="" className="img _8o _8r img sp_Zf93eLkohoS sx_97c3ab">
                            </i>
                            </div>
                            <div>
                            <div className="_42ef _8u">
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

LikeCommentGiftInRecordingPage.contextType = MyContext;

export default withRouter(withTranslation()(LikeCommentGiftInRecordingPage))
