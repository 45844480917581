import React, { Component } from 'react'
import CommentLine from './CommentLine.js';
import GiftCommentLine from './GiftCommentLine.js'
export default class CommentAndGiftCommentLine extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
    }

 
    toggle(param) {
        this.props.toggleFunc(param);
    }


    render() {
        const {comment, recording, user,toggleComment, toggleCommentId } = this.props;

        if (!comment.giftUrl){
            return (
                <CommentLine
                        key={comment.key} 
                        recording={recording} 
                        user={user} 
                        comment = {comment}
                        toggleComment = {toggleComment}
                        toggleCommentId = {toggleCommentId}
                        toggle = {this.toggle}
                        addNewComment={this.props.addNewComment} 
                ></CommentLine>
            )
        } else {
            return (
                <GiftCommentLine 
                        comment = {comment}
                        recording={recording} 
                        user={user} 
                        toggleComment = {toggleComment}
                        toggleCommentId = {toggleCommentId}
                        toggle = {this.toggle}
                        addNewComment={this.props.addNewComment} 
                ></GiftCommentLine>
            )
        }
    }
}
