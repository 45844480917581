import React, { Component } from 'react';
import bindModel from '../../common/bindModel.js';
import axios from 'axios';
import lodash from 'lodash';
import ModalUpdateGift from './components/ModalUpdateGift';
import ModalSetGift from './components/ModalSetGift';
import { toast } from 'react-toastify';
import { Parser, Player } from 'svga';
import { Base64 } from 'js-base64';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import SVGA from "svgaplayerweb";

import '../../assets/css/giftmanager.css';
import ModalShowSVGA from './components/ModalShowSVGA';
import ModalShowGiftAnimation from './components/ModalShowGiftAnimation';
import ModalDialogCupList from './components/ModalDialogCupList';
import $ from 'jquery';
import ModalSetAvatarFrame from './components/ModalSetAvatarFrame.js';
import ModalUpdateEvent from './components/ModalUpdateEvent';
import ModalUpdateDecoration from './components/ModalUpdateDecoration.js';

export default class UpdateCupPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            frames:[],
            gifts: [],
            giftsTemp: [],
            gift: null,
            giftShow: null,
            cupShow: null,
            nameSearch: "",
            idSearch: "",
            buyPriceSort: "",
            sellPriceSort: "",
            orderSort: "",
            status: "",
            show: "",
            type: "",
            tag: "",
            updateRecording: false,
            updateRoom: false,
            updateFamily: false,
            idEventUpdate: false,
            eventUpdate:null,
            eventChange: null,
            eventNameChange:null,
            recordingHots: [],
            liveRooms: [],
            familys: [],
            idCupSelected: null,
            urlCup: null,
            keyCup:null,
            cup: null,
            eventRecordingHotNames: [],
            recordingFrames: [],
            recordingMedals: [],
            addFrame: null,
            addMedal: null,
            cupRecordingHotForEvents: [],
            eventLiveRoomNames: [],
            cupLiveRoomForEvents: [],
            eventFamilyNames: [],
            cupFamilyForEvents: [],
            displayCupStart: new Date(),
            displayCupEnd: new Date(),
            playingStart: new Date(),
            playingEnd: new Date(),
            cupTop: null,
            decorationTop1: null,
            frameTop: null,
            frameUrlRecoding1: null,
            frameIdString1:null,
            frameUrlRecoding2: null,
            frameIdString2:null,
            frameUrlRecoding3: null,
            frameIdString3:null,
            frameUrlLiveRoom1: null,
            frameUrlLiveRoom2: null,
            frameUrlLiveRoom3: null,
            frameUrlFamily1: null,
            frameUrlFamily2: null,
            frameUrlFamily3: null,
            frameTop1: null,
            medalTop1: null
        }

        this.getFrame = this.getFrame.bind(this);
        this.getGifts = this.getGifts.bind(this);
        this.search = this.search.bind(this);
        this.reset = this.reset.bind(this);
        this.orderByBuyPrice = this.orderByBuyPrice.bind(this);
        this.orderBySellPrice = this.orderBySellPrice.bind(this);
        this.orderByOrder = this.orderByOrder.bind(this);
        this.newGift = this.newGift.bind(this);
        this.showCupSelected = this.showCupSelected.bind(this);
        this.displayCupStartDate = this.displayCupStartDate.bind(this);
        this.displayCupEndDate = this.displayCupEndDate.bind(this);
        this.playingStartDate = this.playingStartDate.bind(this);
        this.playingEndDate = this.playingEndDate.bind(this);
        this.addEventRecodingHot = this.addEventRecodingHot.bind(this);
        this.addEventLiveRoom = this.addEventLiveRoom.bind(this);
        this.addEventFamily = this.addEventFamily.bind(this);
        this.uploadThumbnailToS3 = this.uploadThumbnailToS3.bind(this);
        this.showAvatarFrameSelectedForEvent = this.showAvatarFrameSelectedForEvent.bind(this);
        this.loadSVGA4 = this.loadSVGA4.bind(this);
        this.handleDeleted = this.handleDeleted.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.handleUpdateEvent = this.handleUpdateEvent.bind(this);
        this.cancelUpdate = this.cancelUpdate.bind(this);
        this.getDecorationItemType = this.getDecorationItemType.bind(this);
        this.handleSetFrame = this.handleSetFrame.bind(this);
        this.handleSetMedal = this.handleSetMedal.bind(this);
        this.handleAddRewardToDefaultEvent = this.handleAddRewardToDefaultEvent.bind(this);

        this.getCups();
    }

    componentWillMount() {
        this.getGifts();
        this.getFrame();
        this.getDecorationItemType();
    }

    cancelUpdate(){
        
    }

    handleAddRewardToDefaultEvent(){
        const { addMedal, addFrame } = this.state;
        if(addMedal == null && addFrame == null){
            toast.warning("vui lòng chọn hiệu ứng được thêm");
            return;
        }
        let json = {
            addMedal: addMedal,
            addFrame: addFrame
        }

        let url = global.config.apiDomain + "/rest/decorationItemsType/add2EventDefault";
        axios.post(url, json)
        .then(res =>{
            if(res.data.status == "OK"){
                toast.success("Thêm thành công");
                $("#recordingFramedf").value = "-1";
                $("#recordingMedaldf").value = "-1";
            }else{
                toast.warning("Thêm thất bại");
            }
        })

    }

    handleSetMedal(event){
        const { recordingMedals } = this.state;

        let idTarget = event.target.value;
        for(let i = 0 ; i < recordingMedals.length; i++){
            if(recordingMedals[i].id == idTarget){
                this.setState({
                    addMedal: recordingMedals[i]
                });

                if (recordingMedals[i].resourceUrl != null) {
                    $("#show-medal_resourceUrl").children().replaceWith("<img style=\"max-width: 100px !important\" src=\"" + recordingMedals[i].resourceUrl + "\"  />");
                }
                return;
            }
        } 
    }

    handleSetFrame(event){
        const { recordingFrames } = this.state;

        let idTarget = event.target.value;
        for(let i = 0 ; i < recordingFrames.length; i++){
            if(recordingFrames[i].id == idTarget){
                this.setState({
                    addFrame: recordingFrames[i]
                });

                if (recordingFrames[i].resourceUrl != null) {
                    $("#show-frame_resourceUrl").children().replaceWith("<img style=\"max-width: 100px !important\" src=\"" + recordingFrames[i].resourceUrl + "\"  />");
                }
                return;
            }
        } 
    }
   
    getDecorationItemType(){

        let url = global.config.apiDomain + "/rest/decorationItemsType/getAllItems";
        let medals=[];
        let frames = [];
        axios.post(url)
        .then(res =>{
            for(let i =0; i < res.data.decorationItemTypes.length ; i++){
                if(res.data.decorationItemTypes[i].type === "MEDAL"){
                    medals.push(res.data.decorationItemTypes[i]);
                }else{
                    frames.push(res.data.decorationItemTypes[i]);
                }
            }
            this.setState({
                recordingFrames : frames,
                recordingMedals : medals,
            })
        })
        
    }

    componentDidMount() {
        $("#modalUpdateGift").on("hidden.bs.modal", function () {
            this.setState({
                gift: null
            });
        }.bind(this));

        $("#modalShowGift").on("hidden.bs.modal", function () {
            this.setState({
                giftShow: null
            });
        }.bind(this));

        $("#modalShowGiftAnimation").on("hidden.bs.modal", function () {
            this.setState({
                giftShow: null
            });
        }.bind(this));

    }

    async loadSVGA(id, url) {
        const parser = new Parser()
        const svga = await parser.load(url)

        const player = new Player(document.getElementById(id))
        await player.mount(svga)

        player.onStart = () => console.log('onStart')

        player.start()
    }

    handleUpdateEvent(keyName, name , event){
        let img1;
        let img2;
        let img3;
        let cupTop1;
        let cupTop2;
        let cupTop3;
        let frameTop1;
        let frameTop2;
        let frameTop3;
        let timeStart;
        let timeEnd;
        let inputNameEvent;
        let key;
        let language;

        const dataReq = {
            id: keyName,
            status: "updateRecording"
        }

            axios.post(global.config.apiDomain+"/rest/contentEvent/getEvent", dataReq)
            .then(res =>{
                this.setState({
                    eventUpdate: res.data
                })
                cupTop1 = res.data.cupTop1;
                cupTop2 = res.data.cupTop2;
                cupTop3 = res.data.cupTop3;
                frameTop1 = res.data.frameTop1Id;
                frameTop2 = res.data.frameTop2Id;
                frameTop3 = res.data.frameTop3Id;
                timeStart = res.data.startDate;
                timeEnd = res.data.endDate;
                img1 = res.data.cupTop1Url;
                img2 = res.data.cupTop2Url;
                img3 = res.data.cupTop3Url;
                inputNameEvent =  res.data.eventName;
                key = res.data.idEventString;
                window.scrollTo(0,0);
                if(event = "recording"){
                    document.getElementById("inputTenSuKien").value = inputNameEvent ;
                    if(key.search("en.yokara") > -1){
                        language = "en.yokara";
                        document.getElementById("appSelectedRecording").value = "IKARA";
                    }
                    if(key.search("vi") > -1){
                        language = "vi";
                        document.getElementById("appSelectedRecording").value = "YOKARA";
                    }
                    if(key.search("all") > -1){
                        language = "all";
                        document.getElementById("appSelectedRecording").value = "ALL";
                    }
                    document.getElementById("cupRec1Img").setAttribute("src",img1);
                    document.getElementById("cupRec2Img").setAttribute("src",img2);
                    document.getElementById("cupRec3Img").setAttribute("src",img3);
                    this.loadSVGA4(res.data.frameTop1Url, "frameUrlRecording1");
                    this.loadSVGA4(res.data.frameTop2Url, "frameUrlRecording2");
                    this.loadSVGA4(res.data.frameTop3Url, "frameUrlRecording3");
    
                }else if (event = "family"){
    
                    document.getElementById("inputTenSuKienGiaToc").value =inputNameEvent ;
                    if(key.search("en.yokara") > -1){
                        language = "en.yokara";
                        document.getElementById("appSelectedFamily").value = "IKARA";
                    }
                    if(key.search("vi") > -1){
                        language = "vi";
                        document.getElementById("appSelectedFamily").value = "YOKARA";
                    }
                    if(key.search("all") > -1){
                        language = "all";
                        document.getElementById("appSelectedFamily").value = "ALL";
                    }
                    document.getElementById("cupFamily1Img").setAttribute("src",img1);
                    document.getElementById("cupFamily2Img").setAttribute("src",img2);
                    document.getElementById("cupFamily3Img").setAttribute("src",img3);
    
                }else if (event = "liveRoom"){
    
                    document.getElementById("inputTenSuKienPhongKara").value =inputNameEvent ;
                    if(key.search("en.yokara") > -1){
                        language = "en.yokara";
                        document.getElementById("appSelectedLiveRoom").value = "IKARA";
                    }
                    if(key.search("vi") > -1){
                        language = "vi";
                        document.getElementById("appSelectedLiveRoom").value = "YOKARA";
                    }
                    if(key.search("all") > -1){
                        language = "all";
                        document.getElementById("appSelectedLiveRoom").value = "ALL";
                    }
                    document.getElementById("cupRoom1Img").setAttribute("src",img1);
                    document.getElementById("cupRoom2Img").setAttribute("src",img2);
                    document.getElementById("cupRoom3Img").setAttribute("src",img3);
                }
            }).catch(err =>{
                console.log(keyName, name , event)
            })
    }

    loadSVGA4(url, canvasId) {
        var parser = new SVGA.Parser("#" + canvasId);
        parser.load(url, function (videoItem) {
          var player = new SVGA.Player("#" + canvasId);
          player.setVideoItem(videoItem);
          // player.setFrame(0, 0, 750, 750);
          player.setContentMode("AspectFill");
          player.startAnimation();
        });
      }

    getGifts() {
        axios.post(global.config.apiDomain + '/rest/gifts')
            .then(res => {
                this.setState({
                    gifts: res.data,
                    giftsTemp: res.data
                });
            });
            
    }

    getFrame() {
        axios
          .post(global.config.apiDomain + "/rest/contentEvent/getFrame")
          .then((res) => {
            this.setState({
              frames: res.data,
            });
          });
      }

    newGift() {
        axios.post(global.config.apiDomain + '/rest/gifts/newGift')
            .then(res => {
                this.setState({
                    gift: res.data
                })
            });
    }

    search() {
        let { gifts, giftsTemp, nameSearch, idSearch, show, type, tag } = this.state;

        gifts = giftsTemp.filter(e => e.name.includes(nameSearch));
        gifts = gifts.filter(e => e.id.includes(idSearch));

        if (show.startsWith("!")) {
            gifts = gifts.filter(e => !e.show.includes(show.replace("!", "")) && !e.show.includes("NONE"));
        } else {
            gifts = gifts.filter(e => e.show.includes(show));
        }
        gifts = gifts.filter(e => e.type.includes(type));
        gifts = gifts.filter(e => e.tag.includes(tag));

        this.setState({
            gifts: gifts,
            giftsTemp: giftsTemp
        });
    }

    reset() {
        this.setState({
            nameSearch: "",
            idSearch: "",
            status: "",
            type: "",
            tag: "",
            gifts: this.state.giftsTemp
        });
    }

    componentDidUpdate(){
        const { cupRecordingHotForEvents,frameUrlRecoding1, frameUrlRecoding2, frameUrlRecoding3 } = this.state;
        cupRecordingHotForEvents.map((cup, index) => {
            let idResource = `${cup.eventName}${index}`;
            if (cup.frameUrl != null && cup.frameUrl.endsWith("svga")) {
                // $("#" + idResource).children().replaceWith("<div style=\" height: 40px !important\" id=\" " + idResource + "\"></div>");
                this.loadSVGA4(cup.frameUrl, idResource);

            } else if (cup.frameUrl != null) {
                $(`#${idResource}`).replaceWith("<img style=\"height: 50px; width: 50px; align: center; \" src=\"" + cup.frameUrl + "\"  />");
            }
        })    
        
        if(frameUrlRecoding1 && frameUrlRecoding1.endsWith("svga")){
            this.loadSVGA4(frameUrlRecoding1, "frameUrlRecoding1");
        }else if (frameUrlRecoding1 != null){
            $("#frameUrlRecoding1").replaceWith("<img style=\"height: 50px; width: 50px; align: center; \" src=\"" + frameUrlRecoding1 + "\"  />");
        }
        if(frameUrlRecoding2 && frameUrlRecoding2.endsWith("svga")){
            this.loadSVGA4(frameUrlRecoding2, "frameUrlRecoding2");
        }else if (frameUrlRecoding2 != null){
            $("#frameUrlRecoding2").replaceWith("<img style=\"height: 50px; width: 50px; align: center; \" src=\"" + frameUrlRecoding2 + "\"  />");
        }
        if(frameUrlRecoding3 && frameUrlRecoding3.endsWith("svga")){
            this.loadSVGA4(frameUrlRecoding3, "frameUrlRecoding3");
        } else if (frameUrlRecoding3 != null){
            $("#frameUrlRecoding3").replaceWith("<img style=\"height: 50px; width: 50px; align: center; \" src=\"" + frameUrlRecoding3 + "\"  />");
        }
                                    
    }

    orderByBuyPrice() {
        let { buyPriceSort, gifts } = this.state

        if (buyPriceSort === "ASC") {
            gifts = lodash.orderBy(gifts, "buyPrice", 'desc');
            buyPriceSort = "DESC";
        } else {
            gifts = lodash.orderBy(gifts, "buyPrice", 'asc');
            buyPriceSort = "ASC";
        }

        this.setState({
            buyPriceSort: buyPriceSort,
            gifts: gifts
        });
    }

    orderByOrder() {
        let { orderSort, gifts } = this.state

        if (orderSort === "ASC") {
            gifts = lodash.orderBy(gifts, "order", 'desc');
            orderSort = "DESC";
        } else {
            gifts = lodash.orderBy(gifts, "order", 'asc');
            orderSort = "ASC";
        }

        this.setState({
            orderSort: orderSort,
            gifts: gifts
        });
    }

    orderBySellPrice() {
        let { sellPriceSort, gifts } = this.state

        if (sellPriceSort === "ASC") {
            gifts = lodash.orderBy(gifts, "sellPrice", 'desc');
            sellPriceSort = "DESC";
        } else {
            gifts = lodash.orderBy(gifts, "sellPrice", 'asc');
            sellPriceSort = "ASC";
        }

        this.setState({
            sellPriceSort: sellPriceSort,
            gifts: gifts
        });
    }
    
    /////////////////////////////////////////////////////////////////
    newGetKey(data, password, password2) {
        var listOfNumbers = "" + password2;
        for (var i = 0 ; i < data.length; i++) {
            if (data.charAt(i) >= '0' && data.charAt(i) <= '9'){
                listOfNumbers = [listOfNumbers.slice(0, 0), data.charAt(i), listOfNumbers.slice(0)].join('');
            }
        }
        var originalKey = null;
      if (listOfNumbers.length > 15) 
        originalKey = listOfNumbers.substring(0,15);
      else
        originalKey = listOfNumbers;
      return Number(originalKey) + Number(password);
    }

    getCups() {
        var json = {
            cupFor: "BAITHUHOT,PHONGKARA,GIATOC"
        }
        const jsonBase64 = Base64.encode(JSON.stringify(json));
        var pass = this.newGetKey(jsonBase64, 7123358024, 3564);
        var keys = jsonBase64 + "-" + pass;
        var url = "/cup/getCups?parameters="+keys;
        axios.post(url, json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.data.status == "OK") {
                this.setState({
                    recordingHots: res.data.cupRecordingHotModels,
                    liveRooms: res.data.cupLiveRoomModels,
                    familys: res.data.cupFamilyModels,
                    cupRecordingHotForEvents: res.data.cupRecordingHotForEvents,
                    eventRecordingHotNames:res.data.eventRecordingHotNames,
                    cupLiveRoomForEvents: res.data.cupLiveRoomForEvents,
                    eventLiveRoomNames:res.data.eventLiveRoomNames,
                    cupFamilyForEvents:res.data.cupFamilyForEvents,
                    eventFamilyNames:res.data.eventFamilyNames,
                })
                // toast.success(res.data.message);

            } else {
                // toast.error(res.data.message);
            }
        }, err => {
        });

    }

    handleDeleted(eventId){
        let data = {
            id: eventId,
            status: "delete"
        };
        axios.post(global.config.apiDomain + "/rest/contentEvent/deleteCup",data)
        .then(res =>{
            if(res.data.status = "OK"){
                toast.success(res.data.message)
            }
            this.getCups();
        })
    }

    showCupSelected(id, cupUrlForEvent, keyCupForEvent) {
        this.setState({
            idCupSelected: id,
            cupUrlForEvent: cupUrlForEvent
        });

        let item = id;

        if(keyCupForEvent != null && (keyCupForEvent != "frameTop1" && keyCupForEvent != 'medalTop1')) {
            document.getElementsByClassName(keyCupForEvent).item(0).getElementsByTagName('img').item(0).setAttribute('src', cupUrlForEvent);
            document.getElementsByClassName(keyCupForEvent).item(0).getElementsByTagName('span').item(0).setAttribute('class', id.replaceAll(" ", ""));
            $("." + id.replaceAll(" ", "")).text(id);
        }else {
            document.getElementsByClassName(keyCupForEvent).item(0).getElementsByTagName('img').item(0).setAttribute('src', cupUrlForEvent);
            // $("." + id.id.replaceAll(" ", "")).text(id.id);
            if(keyCupForEvent == "frameTop1"){
                this.setState({
                    frameTop1: item
                })
            }else{
                this.setState({
                    medalTop1: item
                })
            }
        }
    }
    showCupSelectedForEvent(id, cupUrlForEvent, cupTop) {
        if(id != null) {
            document.getElementsByClassName(cupTop).item(0).getElementsByTagName('img').item(0).setAttribute('src', cupUrlForEvent);
            document.getElementsByClassName(cupTop).item(0).getElementsByTagName('span').item(0).setAttribute('class', id.replaceAll(" ", ""));
            $("." + id.replaceAll(" ", "")).text(id);
        }
    }
    showAvatarFrameSelectedForEvent(id, avatarFrame, avatarFrameForTop) {
        if(avatarFrameForTop == "frameTop1"){
            this.loadSVGA4(avatarFrame, "frameUrlRecoding1");
            this.setState({
                frameUrlRecoding1 : avatarFrame,
                frameIdString1 : id
            })
        }
        if(avatarFrameForTop == "frameTop2"){
            this.loadSVGA4(avatarFrame, "frameUrlRecoding2");
            this.setState({
                frameUrlRecoding2 : avatarFrame,
                frameIdString2 : id
            })
        }
        if(avatarFrameForTop == "frameTop3"){
            this.loadSVGA4(avatarFrame, "frameUrlRecoding3");
            this.setState({
                frameUrlRecoding3 : avatarFrame,
                frameIdString3 : id
            })
        }
    }

    displayCupStartDate(props) {
        this.setState({
            displayCupStart: props.value,
        });
    }
    displayCupEndDate(props) {
        this.setState({
            displayCupEnd: props.value,
        });
    }
    playingStartDate(props) {
        this.setState({
            playingStart: props.value,
        });
    }
    playingEndDate(props) {
        this.setState({
            playingEnd: props.value,
        });
    }
    addEventRecodingHot() {
        let id = document.getElementById('inputIdSuKien').value;
        let name = document.getElementById('inputTenSuKien').value;
        let displayCupStart = this.state.playingStart;
        let displayCupEnd = this.state.playingEnd;
        let playingStart = this.state.playingStart;
        let playingEnd = this.state.playingEnd;
        let keyCupTop1 = document.getElementsByClassName("cuptop1").item(0).getElementsByTagName('span').item(0).textContent;
        let cupUrlTop1 = document.getElementsByClassName("cuptop1").item(0).getElementsByTagName('img').item(0).getAttribute('src');
        let keyCupTop2 = document.getElementsByClassName("cuptop2").item(0).getElementsByTagName('span').item(0).textContent;
        let cupUrlTop2 = document.getElementsByClassName("cuptop2").item(0).getElementsByTagName('img').item(0).getAttribute('src');
        let keyCupTop3 = document.getElementsByClassName("cuptop3").item(0).getElementsByTagName('span').item(0).textContent;
        let cupUrlTop3 = document.getElementsByClassName("cuptop3").item(0).getElementsByTagName('img').item(0).getAttribute('src');

        var appSelected = $('#appSelectedRecording :selected').val();
        var language = '';
        if(appSelected == '') {
            language = '';
        } else if(appSelected == 'IKARA') {
            language = 'vi';
        } else if(appSelected == 'YOKARA') {
            language = 'en.yokara';
        } else if(appSelected == 'ALL') {
            language = 'all';
        } else {
            toast.error('APP chọn không chính xác!');
            return;
        }

        if(id == null || id == '') {
            toast.error('Bạn cần phải nhập Id cho Sự kiện!');
            return;
        } else if(name == null || name == '') {
            toast.error('Bạn cần phải nhập Tên cho Sự kiện!');
            return;
        } else if(language == '') {
            toast.error('Hãy chọn APP của Sự kiện!');
            return;
        } else if(displayCupStart == null || displayCupEnd == null || playingStart == null || playingEnd == null) {
            toast.error('Nhập đầy đủ Thời gian Sự kiện!');
            return;
        }

        if(keyCupTop1 == null || cupUrlTop1 == null){
            toast.error('Nhập Cup TOP 1 cho Sự kiện!');
            return;
        }else if(keyCupTop2 == null || cupUrlTop2 == null){
            toast.error('Nhập Cup TOP 2 cho Sự kiện!');
            return;
        }if(keyCupTop3 == null || cupUrlTop3 == null){
            toast.error('Nhập Cup TOP 3 cho Sự kiện!');
            return;
        }

        if((this.state.frameUrlRecoding1 == null && this.state.frameUrlRecoding2 == null && this.state.frameUrlRecoding3 == null ) || 
        (this.state.frameUrlRecoding1 != null && this.state.frameUrlRecoding2 != null && this.state.frameUrlRecoding3 != null )){

        }else {
            toast.error('Nhập đầy đủ Khung cho Sự kiện!');
            return;
        }

        if(keyCupTop1 == null || cupUrlTop1 == null){
            keyCupTop1 = 'FIRSTPRIZE';
            cupUrlTop1 = 'https://s3.us-west-002.backblazeb2.com/ikara-data/images/gifts/FIRSTPRIZE.png';
        }
        if(keyCupTop2 == null || cupUrlTop2 == null){
            keyCupTop2 = 'SECONDPRIZE';
            cupUrlTop2 = 'https://s3.us-west-002.backblazeb2.com/ikara-data/images/gifts/SECONDPRIZE.png';
        }
        if(keyCupTop3 == null || cupUrlTop3 == null){
            keyCupTop3 = 'THIRDPRIZE';
            cupUrlTop3 = 'https://s3.us-west-002.backblazeb2.com/ikara-data/images/gifts/THIRDPRIZE.png';
        }

        var frameUrlRecoding1 = this.state.frameUrlRecoding1;
        var frameUrlRecoding2 = this.state.frameUrlRecoding2;
        var frameUrlRecoding3 = this.state.frameUrlRecoding3;
        var frameIdString1 = this.state.frameIdString1;
        var frameIdString2 = this.state.frameIdString2;
        var frameIdString3 = this.state.frameIdString3;
        var frameTop1 = this.state.frameTop1;
        var medalTop1 = this.state.medalTop1;
        if(frameUrlRecoding1 == null) {
            // frameUrlRecoding1 = 'https://storage.googleapis.com/ikara-storage/ikara/frame/top_1_fan_icon.webp'
            frameUrlRecoding1 = '';
        }
        if(frameUrlRecoding2 == null) {
            frameUrlRecoding2 = '';
            // frameUrlRecoding2 = 'https://storage.googleapis.com/ikara-storage/ikara/frame/top_2_fan_icon.webp'
        }
        if(frameUrlRecoding3 == null) {
            frameUrlRecoding3 = '';
            // frameUrlRecoding3 = 'https://storage.googleapis.com/ikara-storage/ikara/frame/top_3_fan_icon.webp'
        }

        var notificationUrl = "https://ikara-data.s3.us-west-002.backblazeb2.com/images/giftitems/systemnotification.png";
        var notificationMessage = "Thông báo toàn hệ thống";
        var frameMessageTop1 = "Khung top 1\nTrong 1 ngày";
        var frameMessageTop2 = "Khung top 2\nTrong 1 ngày";
        var frameMessageTop3 = "Khung top 3\nTrong 1 ngày";

        var messageCupTop1 = "Cup TOP 1\n20000 iCoin";
        var messageCupTop2 = "Cup TOP 2\n10000 iCoin";
        var messageCupTop3 = "Cup TOP 3\n5000 iCoin";

        var keyCup4 = 'BOUQUET';
        var cupUrl4 = 'https://s3.us-west-002.backblazeb2.com/ikara-data/images/gifts/BOUQUET.png';
        var message4 = '1 Bó hoa\n25 iCoin';

        let gifts_1 = {cupTop: '1', keyCup: keyCupTop1, cupUrl: cupUrlTop1,language: language, message: messageCupTop1, frameUrl: frameUrlRecoding1,frameIdString : frameIdString1, frameMessage: frameMessageTop1,
         notificationUrl: notificationUrl, notificationMessage: notificationMessage, recordingMedal: medalTop1, recordingFrame: frameTop1};
        let gifts_2 = {cupTop: '2', keyCup: keyCupTop2, cupUrl: cupUrlTop2,language: language, message: messageCupTop2, frameUrl: frameUrlRecoding2,frameIdString : frameIdString2, frameMessage: frameMessageTop2};
        let gifts_3 = {cupTop: '3', keyCup: keyCupTop3, cupUrl: cupUrlTop3,language: language, message: messageCupTop3, frameUrl: frameUrlRecoding3,frameIdString : frameIdString3, frameMessage: frameMessageTop3};
        let gifts_4 = {cupTop: '4', keyCup: keyCup4, cupUrl: cupUrl4, message: message4};
        let json = {
            cupForDb: 'Recording',
            typeString: 0,
            name: id,
            eventName: name,
            language: language,
            timeShowStart: displayCupStart,
            timeShowEnd: displayCupEnd,
            timeActiveStart: playingStart,
            timeActiveEnd: playingEnd,
            gifts_1: gifts_1,
            gifts_2: gifts_2,
            gifts_3: gifts_3,
            gifts_4_10: gifts_4,
        };

        const jsonBase64 = Base64.encode(JSON.stringify(json));
        var pass = this.newGetKey(jsonBase64, 7123358024, 3564);
        var keys = jsonBase64 + "-" + pass;
        var url = global.config.apiDomain + "/v35.AddEvent?parameters=" + keys;
        axios.post(url, json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.data.status == "OK") {
                // this.setState({
                //     recordingHots: res.data.cupRecordingHotModels,
                //     liveRooms: res.data.cupLiveRoomModels,
                //     familys: res.data.cupFamilyModels
                // })
                // document.getElementsByClassName(keyCup).item(0).getElementsByTagName('img').item(0).removeAttribute('src');
                // document.getElementsByClassName(keyCup).item(0).getElementsByTagName('span').item(0).replaceChildren('');
                // document.getElementsByClassName(keyCup).item(0).getElementsByTagName('span').item(0).removeAttribute('class');

                this.getCups();
                toast.success(res.data.message);

            } else {
                toast.error(res.data.message);
            }
        }, err => {
            toast.error("Đã có lỗi xảy ra, vui lòng thử lại!");
        });
    }
    addEventLiveRoom() {
        let id = document.getElementById('inputIdSuKienPhongKara').value;
        let name = document.getElementById('inputTenSuKienPhongKara').value;
        let displayCupStart = this.state.playingStart;
        let displayCupEnd = this.state.playingEnd;
        let playingStart = this.state.playingStart;
        let playingEnd = this.state.playingEnd;
        let keyCupTop1 = document.getElementsByClassName("cuptop1PhongKara").item(0).getElementsByTagName('span').item(0).textContent;
        let cupUrlTop1 = document.getElementsByClassName("cuptop1PhongKara").item(0).getElementsByTagName('img').item(0).getAttribute('src');
        let keyCupTop2 = document.getElementsByClassName("cuptop2PhongKara").item(0).getElementsByTagName('span').item(0).textContent;
        let cupUrlTop2 = document.getElementsByClassName("cuptop2PhongKara").item(0).getElementsByTagName('img').item(0).getAttribute('src');
        let keyCupTop3 = document.getElementsByClassName("cuptop3PhongKara").item(0).getElementsByTagName('span').item(0).textContent;
        let cupUrlTop3 = document.getElementsByClassName("cuptop3PhongKara").item(0).getElementsByTagName('img').item(0).getAttribute('src');

        var appSelected = $('#appSelectedLiveRoom :selected').val();
        var language = '';
        if(appSelected == '') {
            language = '';
        } else if(appSelected == 'IKARA') {
            language = 'vi';
        } else if(appSelected == 'YOKARA') {
            language = 'en.yokara';
        } else if(appSelected == 'ALL') {
            language = 'all';
        } else {
            toast.error('APP chọn không chính xác!');
            return;
        }

        if(id == null || id == '') {
            toast.error('Bạn cần phải nhập Id cho Sự kiện!');
            return;
        } else if(name == null || name == '') {
            toast.error('Bạn cần phải nhập Tên cho Sự kiện!');
            return;
        } else if(language == '') {
            toast.error('Hãy chọn APP của Sự kiện!');
            return;
        } else if(displayCupStart == null || displayCupEnd == null || playingStart == null || playingEnd == null) {
            toast.error('Nhập đầy đủ Thời gian Sự kiện!');
            return;
        }
        if(displayCupStart.getTime() > displayCupEnd.getTime() ) {
            toast.error('Thời gian kết thúc phải sau thời gian bắt đầu!');
            return;
        }

        if(keyCupTop1 == null || cupUrlTop1 == null){
            toast.error('Nhập Cup TOP 1 cho Sự kiện!');
            return;
        }else if(keyCupTop2 == null || cupUrlTop2 == null){
            toast.error('Nhập Cup TOP 2 cho Sự kiện!');
            return;
        }if(keyCupTop3 == null || cupUrlTop3 == null){
            toast.error('Nhập Cup TOP 3 cho Sự kiện!');
            return;
        }
        

        if(keyCupTop1 == null || cupUrlTop1 == null){
            keyCupTop1 = 'FIRSTPRIZELIVEROOMIKARA';
            cupUrlTop1 = 'https://data4.ikara.co/data/minio/ikara-data/giftEvent/cupRank/3089cb81-b4d2-56e7-f826-265ad4df2cec.png';
        }
        if(keyCupTop2 == null || cupUrlTop2 == null){
            keyCupTop2 = 'SECONDPRIZELIVEROOMIKARA';
            cupUrlTop2 = 'https://data4.ikara.co/data/minio/ikara-data/giftEvent/cupRank/68710ffc-a2dc-f561-671b-8ce0cd0db35e.png';
        }
        if(keyCupTop3 == null || cupUrlTop3 == null){
            keyCupTop3 = 'THIRDPRIZELIVEROOMIKARA';
            cupUrlTop3 = 'https://data4.ikara.co/data/minio/ikara-data/giftEvent/cupRank/aa4fd303-6b65-4678-b42f-f0c07b2c648d.png';
        }

        var frameMessageTop1 = null;
        var frameMessageTop2 = null;
        var frameMessageTop3 = null;
        if(this.state.frameUrlLiveRoom1 != null) {
            frameMessageTop1 = "Khung top 1\nTrong 1 ngày";
        }
        if(this.state.frameUrlLiveRoom2 != null) {
            frameMessageTop2 = "Khung top 2\nTrong 1 ngày";
        }
        if(this.state.frameUrlLiveRoom3 != null) {
            frameMessageTop3 = "Khung top 3\nTrong 1 ngày";
        }

        var messageCupTop1 = "Cup TOP 1\n20000 iCoin";
        var messageCupTop2 = "Cup TOP 2\n10000 iCoin";
        var messageCupTop3 = "Cup TOP 3\n5000 iCoin";

        var notificationUrl = "https://ikara-data.s3.us-west-002.backblazeb2.com/images/giftitems/systemnotification.png";
        var notificationMessage = "Thông báo toàn hệ thống";
        let gifts_1 = {cupTop: '1', keyCup: keyCupTop1, cupUrl: cupUrlTop1, message: messageCupTop1, frameUrl: this.state.frameUrlLiveRoom1, frameMessage: frameMessageTop1, notificationUrl: notificationUrl, notificationMessage: notificationMessage};
        let gifts_2 = {cupTop: '2', keyCup: keyCupTop2, cupUrl: cupUrlTop2, message: messageCupTop2, frameUrl: this.state.frameUrlLiveRoom2, frameMessage: frameMessageTop2};
        let gifts_3 = {cupTop: '3', keyCup: keyCupTop3, cupUrl: cupUrlTop3, message: messageCupTop3, frameUrl: this.state.frameUrlLiveRoom3, frameMessage: frameMessageTop3};
        let json = {
            cupForDb: 'Kara',
            typeString: 1,
            name: id,
            eventName: name,
            language: language,
            timeShowStart: displayCupStart,
            timeShowEnd: displayCupEnd,
            timeActiveStart: playingStart,
            timeActiveEnd: playingEnd,
            gifts_1: gifts_1,
            gifts_2: gifts_2,
            gifts_3: gifts_3,
        };

        const jsonBase64 = Base64.encode(JSON.stringify(json));
        var pass = this.newGetKey(jsonBase64, 7123358024, 3564);
        var keys = jsonBase64 + "-" + pass;
        var url = global.config.apiDomain + "/v36.AddEvent?parameters=" + keys;
        axios.post(url, json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.data.status == "OK") {
                this.getCups();
                toast.success(res.data.message);

            } else {
                toast.error(res.data.message);
            }
        }, err => {
            toast.error("Đã có lỗi xảy ra, vui lòng thử lại!");
        });
    }

    
    addEventFamily() {
        let id = document.getElementById('inputIdSuKienGiaToc').value;
        let name = document.getElementById('inputTenSuKienGiaToc').value;
        let displayCupStart = this.state.playingStart;
        let displayCupEnd = this.state.playingEnd;
        let playingStart = this.state.playingStart;
        let playingEnd = this.state.playingEnd;
        let keyCupTop1 = document.getElementsByClassName("cuptop1GiaToc").item(0).getElementsByTagName('span').item(0).textContent;
        let cupUrlTop1 = document.getElementsByClassName("cuptop1GiaToc").item(0).getElementsByTagName('img').item(0).getAttribute('src');
        let keyCupTop2 = document.getElementsByClassName("cuptop2GiaToc").item(0).getElementsByTagName('span').item(0).textContent;
        let cupUrlTop2 = document.getElementsByClassName("cuptop2GiaToc").item(0).getElementsByTagName('img').item(0).getAttribute('src');
        let keyCupTop3 = document.getElementsByClassName("cuptop3GiaToc").item(0).getElementsByTagName('span').item(0).textContent;
        let cupUrlTop3 = document.getElementsByClassName("cuptop3GiaToc").item(0).getElementsByTagName('img').item(0).getAttribute('src');

        var appSelected = $('#appSelectedFamily :selected').val();
        var language = '';
        if(appSelected == '') {
            language = '';
        } else if(appSelected == 'IKARA') {
            language = 'vi';
        } else if(appSelected == 'YOKARA') {
            language = 'en.yokara';
        } else if(appSelected == 'ALL') {
            language = 'all';
        } else {
            toast.error('APP chọn không chính xác!');
            return;
        }

        if(id == null || id == '') {
            toast.error('Bạn cần phải nhập Id cho Sự kiện!');
            return;
        } else if(name == null || name == '') {
            toast.error('Bạn cần phải nhập Tên cho Sự kiện!');
            return;
        } else if(language == '') {
            toast.error('Hãy chọn APP của Sự kiện!');
            return;
        } else if(displayCupStart == null || displayCupEnd == null || playingStart == null || playingEnd == null) {
            toast.error('Nhập đầy đủ Thời gian Sự kiện!');
            return;
        }

        if(keyCupTop1 == null || cupUrlTop1 == null){
            toast.error('Nhập Cup TOP 1 cho Sự kiện!');
            return;
        }else if(keyCupTop2 == null || cupUrlTop2 == null){
            toast.error('Nhập Cup TOP 2 cho Sự kiện!');
            return;
        }if(keyCupTop3 == null || cupUrlTop3 == null){
            toast.error('Nhập Cup TOP 3 cho Sự kiện!');
            return;
        }

        if(keyCupTop1 == null || cupUrlTop1 == null){
            keyCupTop1 = 'FIRSTPRIZEFAMILY';
            cupUrlTop1 = 'https://data4.ikara.co/data/minio/ikara-data/giftEvent/cupRank/64c86dda-3ddd-cda9-74e3-7adeb96e7ed6.png';
        }
        if(keyCupTop2 == null || cupUrlTop2 == null){
            keyCupTop2 = 'SECONDPRIZEFAMILY';
            cupUrlTop2 = 'https://data4.ikara.co/data/minio/ikara-data/giftEvent/cupRank/6f9db3d2-34b9-a35f-bbdc-e2f13216aabb.png';
        }
        if(keyCupTop3 == null || cupUrlTop3 == null){
            keyCupTop3 = 'THIRDPRIZEFAMILY';
            cupUrlTop3 = 'https://data4.ikara.co/data/minio/ikara-data/giftEvent/cupRank/2cc2a2b6-b8e1-5d24-3ab1-deb8a23b4ed4.png';
        }

        var frameMessageTop1 = null;
        var frameMessageTop2 = null;
        var frameMessageTop3 = null;
        if(this.state.frameUrlFamily1 != null) {
            frameMessageTop1 = "Khung top 1\nTrong 1 ngày";
        }
        if(this.state.frameUrlFamily2 != null) {
            frameMessageTop2 = "Khung top 2\nTrong 1 ngày";
        }
        if(this.state.frameUrlFamily2 != null) {
            frameMessageTop3 = "Khung top 3\nTrong 1 ngày";
        }

        var messageCupTop1 = "Cup TOP 1\n30000 iCoin";
        var messageCupTop2 = "Cup TOP 3\n20000 iCoin";
        var messageCupTop3 = "Cup TOP 3\n10000 iCoin";

        var notificationUrl = "https://ikara-data.s3.us-west-002.backblazeb2.com/images/giftitems/systemnotification.png";
        var notificationMessage = "Thông báo toàn hệ thống";
        let gifts_1 = {cupTop: '1', keyCup: keyCupTop1, cupUrl: cupUrlTop1, message: messageCupTop1, frameUrl: this.state.frameUrlFamily1, frameMessage: frameMessageTop1, notificationUrl: notificationUrl, notificationMessage: notificationMessage};
        let gifts_2 = {cupTop: '2', keyCup: keyCupTop2, cupUrl: cupUrlTop2, message: messageCupTop2, frameUrl: this.state.frameUrlFamily2, frameMessage: frameMessageTop2};
        let gifts_3 = {cupTop: '3', keyCup: keyCupTop3, cupUrl: cupUrlTop3, message: messageCupTop3, frameUrl: this.state.frameUrlFamily3, frameMessage: frameMessageTop3};
        let json = {
            cupForDb: 'Family',
            typeString: 2,
            name: id,
            eventName: name,
            language: language,
            timeShowStart: displayCupStart,
            timeShowEnd: displayCupEnd,
            timeActiveStart: playingStart,
            timeActiveEnd: playingEnd,
            gifts_1: gifts_1,
            gifts_2: gifts_2,
            gifts_3: gifts_3,
        };

        const jsonBase64 = Base64.encode(JSON.stringify(json));
        var pass = this.newGetKey(jsonBase64, 7123358024, 3564);
        var keys = jsonBase64 + "-" + pass;
        var url = global.config.apiDomain + "/v35.AddEvent?parameters=" + keys;
        axios.post(url, json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.data.status == "OK") {
                this.getCups();
                toast.success(res.data.message);

            } else {
                toast.error(res.data.message);
            }
        }, err => {
            toast.error("Đã có lỗi xảy ra, vui lòng thử lại!");
        });
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
    uploadThumbnailToS3(event) {
        var target = event.target;
        if (target.files[0].size > 512999){
            toast.error("Kích thước file phải dưới 500kb.");
            return;
        }

        const fileReader1 = new FileReader();
        fileReader1.readAsDataURL(target.files[0]);

        fileReader1.onload =  (fe) => {
          const image = new Image();
          image.src = fe.currentTarget.result;
          image.onload = function(ie){
            let width = 0, height = 0;
            width = this.naturalWidth;
            height = this.naturalHeight;
            if (width != height || height > 256){
                toast.error("Kích thước ảnh tối đa 256px và Width = Height");
                return;
            }

            this.setState({
                            loading: true
                        });
                
                        var frameUrl = "";
                        var bucketName = "ikara-data/images/cup";
                        var extension = target.files[0].name.split('.').pop().toLowerCase();
                        var keyName = this.guid() + "." + extension;
                        var contentType = "image/jpeg";
                        if (extension == "png") contentType = "image/png";
                
                        var fileReader = new FileReader();
                
                        fileReader.onload = function () {
                            var fd = new FormData();
                            fd.append('file', target.files[0]);
                            fd.append('bucketName', bucketName);
                            fd.append('objectKey', keyName);
                            fd.append('contentType', contentType)
                            $.ajax({
                                url: global.config.apiDomain + '/web.UploadFile',
                                data: fd,
                                processData: false,
                                contentType: false,
                                type: 'POST',
                                success: function (data) {
                                    this.setState({
                                        loading: false
                                    });
                
                                    frameUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                                    if(target.id == 'inputGroupFileRecording01') {
                                        this.setState({
                                            frameUrlRecoding1: frameUrl
                                        });
                                    } else if(target.id == 'inputGroupFileRecording02') {
                                        this.setState({
                                            frameUrlRecoding2: frameUrl
                                        });
                                    } else if(target.id == 'inputGroupFileRecording03') {
                                        this.setState({
                                            frameUrlRecoding3: frameUrl
                                        });
                                    }

                                    if(target.id == 'inputGroupFileLiveRoom01') {
                                        this.setState({
                                            frameUrlLiveRoom1: frameUrl
                                        });
                                    } else if(target.id == 'inputGroupFileLiveRoom02') {
                                        this.setState({
                                            frameUrlLiveRoom2: frameUrl
                                        });
                                    } else if(target.id == 'inputGroupFileLiveRoom03') {
                                        this.setState({
                                            frameUrlLiveRoom3: frameUrl
                                        });
                                    }

                                    if(target.id == 'inputGroupFileFamily01') {
                                        this.setState({
                                            frameUrlFamily1: frameUrl
                                        });
                                    } else if(target.id == 'inputGroupFileFamily02') {
                                        this.setState({
                                            frameUrlFamily2: frameUrl
                                        });
                                    } else if(target.id == 'inputGroupFileFamily03') {
                                        this.setState({
                                            frameUrlFamily3: frameUrl
                                        });
                                    }
                                }.bind(this),
                                error: function (request, status, error) {
                                    // frameUrl = 'https://s3.us-west-002.backblazeb2.com/ikara-data/images/gifts/e3bc14ac-cbc2-745c-cb9b-26c424a78a91.png';
                                    // if(target.id == 'inputGroupFile01'){
                                    //     this.setState({
                                    //         frameUrlRecoding1: frameUrl
                                    //     });
                                    // } else if(target.id == 'inputGroupFile02') {
                                    //     this.setState({
                                    //         frameUrlRecoding2: frameUrl
                                    //     });
                                    // } else if(target.id == 'inputGroupFile03') {
                                    //     this.setState({
                                    //         frameUrlRecoding3: frameUrl
                                    //     });
                                    // }
                                    toast.error("Đang xảy ra lỗi, xin hãy thử lại!");
                                }.bind(this),
                            });
                
                        }.bind(this);
                        fileReader.readAsArrayBuffer(target.files[0]);
            }.bind(this);
        }
    }

    render() {
        const model = bindModel(this);
        const { gifts, recordingFrames, recordingMedals } = this.state;
        //const { recordingHots } = this.getCups;
        return (
            <>
                <ModalDialogCupList gift={this.state.cupShow}></ModalDialogCupList>
                <ModalUpdateGift resetList={this.showCupSelected} cup={this.state.cup}></ModalUpdateGift>
                <ModalUpdateDecoration resetList={this.showCupSelected} decorationTop1={this.state.decorationTop1}></ModalUpdateDecoration>
                <ModalSetGift resetList={this.showCupSelectedForEvent} cupTop={this.state.cupTop}></ModalSetGift>
                <ModalShowGiftAnimation gift={this.state.giftShow}></ModalShowGiftAnimation>
                <ModalShowSVGA gift={this.state.giftShow}></ModalShowSVGA>
                <ModalSetAvatarFrame resetList = {this.showAvatarFrameSelectedForEvent} frameTop={this.state.frameTop} ></ModalSetAvatarFrame>
                <ModalUpdateEvent 
                    eventChange = {this.state.eventChange}
                    eventNameChange = {this.state.eventNameChange}
                    ></ModalUpdateEvent>
                <div id="content">
                    <div className="container">
                        {/* <h1 className="text-center">Thay Đổi CUP</h1>
                        <div>
                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <a className="nav-item nav-link active" id="nav-baithuhot-tab" data-toggle="tab" href="#nav-baithuhot" role="tab" aria-controls="nav-home" aria-selected="true">Bài thu HOT</a>
                                    <a className="nav-item nav-link" id="nav-phongkara-tab" data-toggle="tab" href="#nav-phongkara" role="tab" aria-controls="nav-profile" aria-selected="false">Phòng Kara</a>
                                    <a className="nav-item nav-link " id="nav-giatoc-tab" data-toggle="tab" href="#nav-giatoc" role="tab" aria-controls="nav-profile" aria-selected="false">Gia tộc</a>
                                </div>
                            </nav>
                            <br/><br/>
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-baithuhot" role="tabpanel" aria-labelledby="nav-baithuhot-tab">
                                    {this.state.recordingHots.map((cup, index) => {
                                        return (
                                            <>
                                            <div className='row'>
                                                <div className='col-md-2'>
                                                    {cup.cupTop}
                                                </div>
                                                <div className='col-md-2'>
                                                    {cup.keyCup}
                                                    <img class="gift-image" src={cup.cupUrl}></img>
                                                </div>
                                                <div className='col-md-1'>
                                                    <img class="gift-image" src="https://img.icons8.com/ios/344/right--v1.png"></img>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className={cup.keyCup}>
                                                        <span></span>
                                                        <img class="gift-image"></img>
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <button className="btn btn-red"
                                                        onClick={() => {
                                                            this.setState({
                                                                cup: cup
                                                            })
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#modalUpdateGift"
                                                        >Chọn</button>&ensp;
                                                    <button
                                                        className="btn btn-red"
                                                        onClick={() => {
                                                            this.setState({
                                                                cup: cup
                                                            });
                                                            this.updateCups(cup.keyCup, cup.cupUrl, cup.cupTop);
                                                        }}>Sửa</button>
                                                </div>
                                            </div><hr/>
                                            </>
                                        )
                                    })}<br/><br/><br/>
                                </div>
                                <div className="tab-pane fade" id="nav-phongkara" role="tabpanel" aria-labelledby="nav-phongkara-tab">
                                    {this.state.liveRooms.map((cup, index) => {
                                        return (
                                            <>
                                            <div className='row'>
                                                <div className='col-md-2'>
                                                    {cup.cupTop}
                                                </div>
                                                <div className='col-md-3'>
                                                    {cup.keyCup}
                                                    <img class="gift-image" src={cup.cupUrl}></img>
                                                </div>
                                                <div className='col-md-1'>
                                                    <img class="gift-image" src="https://img.icons8.com/ios/344/right--v1.png"></img>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className={cup.keyCup}>
                                                        <span></span>
                                                        <img class="gift-image"></img>
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <button className="btn btn-red"
                                                        onClick={() => {
                                                            this.setState({
                                                                cup: cup
                                                            })
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#modalUpdateGift">Chọn</button>&ensp;
                                                    <button
                                                        className="btn btn-red"
                                                        onClick={() => {
                                                            this.setState({
                                                                cup: cup
                                                            });
                                                            this.updateCups(cup.keyCup, cup.cupUrl, cup.cupTop);
                                                        }}>Sửa</button>
                                                </div>
                                            </div><hr/>
                                            </>
                                        )
                                    })}<br/><br/><br/>
                                </div>
                                <div className="tab-pane fade" id="nav-giatoc" role="tabpanel" aria-labelledby="nav-giatoc-tab">
                                    {this.state.familys.map((cup, index) => {
                                        return (
                                            <>
                                            <div className='row'>
                                                <div className='col-md-2'>
                                                    {cup.cupTop}
                                                </div>
                                                <div className='col-md-3'>
                                                    {cup.keyCup}
                                                    <img class="gift-image" src={cup.cupUrl}></img>
                                                </div>
                                                <div className='col-md-1'>
                                                    <img class="gift-image" src="https://img.icons8.com/ios/344/right--v1.png"></img>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className={cup.keyCup}>
                                                        <span></span>
                                                        <img class="gift-image"></img>
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <button className="btn btn-red"
                                                        onClick={() => {
                                                            this.setState({
                                                                cup: cup
                                                            })
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#modalUpdateGift">Chọn</button>&ensp;
                                                    <button
                                                        className="btn btn-red"
                                                        onClick={() => {
                                                            this.setState({
                                                                cup: cup
                                                            });
                                                            this.updateCups(cup.keyCup, cup.cupUrl, cup.cupTop);
                                                        }}>Sửa</button>
                                                </div>
                                            </div><hr/>
                                            </>
                                        )
                                    })}<br/><br/><br/>
                                </div>
                            </div>
                        </div> */}



                        <h1 className="text-center">Set CUP Cho Sự Kiện</h1><br/>
                        <div>
                            {/* <div className='row'>
                                <div className='col-md-6'>
                                    <h5>Bắt đầu hiển thị CUP</h5>
                                    <DateTimePickerComponent 
                                        change= {this.displayCupStartDate}
                                        value = {new Date(this.state.displayCupStart)}
                                    >    
                                    </DateTimePickerComponent>
                                </div>
                                <div className='col-md-6'>
                                    <h5>Kết thúc hiển thị CUP</h5>
                                    <DateTimePickerComponent 
                                        change= {this.displayCupEndDate}
                                        value = {new Date(this.state.displayCupEnd)}
                                    >    
                                    </DateTimePickerComponent>
                                </div>
                            </div><hr/> */}
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h5>Thời gian bắt đầu</h5> 
                                    <DateTimePickerComponent 
                                        change= {this.playingStartDate}
                                        value = {new Date(this.state.playingStart)}
                                    >    
                                    </DateTimePickerComponent>
                                </div>
                                <div className='col-md-6'>
                                    <h5>Thời gian kết thúc</h5>
                                    <DateTimePickerComponent 
                                        change= {this.playingEndDate}
                                        value = {new Date(this.state.playingEnd)}
                                    >    
                                    </DateTimePickerComponent>
                                </div>
                            </div><br/>
                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <a className="nav-item nav-link active" id="nav-baithuhot-sukien-tab" data-toggle="tab" href="#nav-baithuhot-sukien" role="tab" aria-controls="nav-home" aria-selected="true">Bài thu HOT</a>
                                    <a className="nav-item nav-link" id="nav-phongkara-sukien-tab" data-toggle="tab" href="#nav-phongkara-sukien" role="tab" aria-controls="nav-profile" aria-selected="false">Phòng Kara</a>
                                    <a className="nav-item nav-link " id="nav-giatoc-sukien-tab" data-toggle="tab" href="#nav-giatoc-sukien" role="tab" aria-controls="nav-profile" aria-selected="false">Gia tộc</a>
                                </div>
                            </nav>
                            <br/><br/>
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-baithuhot-sukien" role="tabpanel" aria-labelledby="nav-baithuhot-sukien-tab">
                                    <div className='row'>
                                        <div className='col-md-2'>
                                            <button class="btn btn-success">Thêm Sự Kiện</button>&ensp;&ensp;
                                        </div>
                                        <div className='col-md-2'>
                                            <input id='inputIdSuKien'
                                                class="form-control" placeholder="Nhập ID sự kiện" 
                                                aria-describedby="basic-addon1"
                                                type="text"
                                                {...model('id')}></input>
                                        </div>
                                        <div className='col-md-4'>
                                            <input id='inputTenSuKien'
                                                class="form-control" placeholder="Nhập tên sự kiện" 
                                                aria-label="Username" aria-describedby="basic-addon1"
                                                type="text"
                                                //name="platform"
                                                //readOnly={false ? false : true}
                                                {...model('name')}></input>
                                        </div>
                                        <div className='col-md-2'>
                                                <select id='appSelectedRecording'
                                                    className="form-control"
                                                    type="text"
                                                    name="app"
                                                    {...model('app')} >
                                                    <option value="">Chọn APP</option>
                                                    <option value="IKARA">IKARA</option>
                                                    <option value="YOKARA">YOKARA</option>
                                                    <option value="ALL">ALL</option>
                                                </select>
                                        </div>
                                        <div className='col-md-2'>
                                            <button class="btn btn-primary"
                                            onClick={this.addEventRecodingHot}
                                            >Lưu</button>
                                            {
                                                this.updateRecording ? <button class="btn btn-primary"
                                            onClick={this.cancelUpdate}
                                            >Hủy</button> : <br></br>
                                            }
                                        </div>
                                    </div>
{/* Form Thêm Sự Kiện */}
<div className='row'>
    <div className='col-md-6'>
        <div className='row'>
            <div className='col-md-3'>
                CUP TOP 1
            </div>
            <div className='col-md-6'>
                <div className="cuptop1">
                    <span></span>
                    <img class="gift-image" id="cupRec1Img"></img>
                </div>
            </div>
            <div className='col-md-3'>
                <button class="btn btn-info"
                    onClick={() => {
                        this.setState({
                            cupTop: 'cuptop1'
                        })
                    }}
                    data-toggle="modal"
                    data-target="#modalSetGift">Chọn</button>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-3'>
                TRANG TRÍ 1
            </div>
            <div className='col-md-6'>
                <div className="frameTop1">
                    <span></span>
                    <img class="gift-image" id="fraRec1Img"></img>
                </div>
            </div>
            <div className='col-md-3'>
                <button class="btn btn-info"
                    onClick={() => {
                        this.setState({
                            decorationTop1: 'frameTop1'
                        })
                    }}
                    data-toggle="modal"
                    data-target="#modalUpdateDecoration">Chọn</button>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-3'>
                CUP TOP 2
            </div>
            <div className='col-md-6'>
                <div className="cuptop2">
                    <span></span>
                    <img class="gift-image" id="cupRec2Img"></img>
                </div>
            </div>
            <div className='col-md-3'>
                <button class="btn btn-info"
                    onClick={() => {
                        this.setState({
                            cupTop: 'cuptop2'
                        })
                    }}
                    data-toggle="modal"
                    data-target="#modalSetGift">Chọn</button>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-3'>
                CUP TOP 3
            </div>
            <div className='col-md-6'>
                <div className="cuptop3">
                    <span></span>
                    <img class="gift-image" id="cupRec3Img"></img>
                </div>
            </div>
            <div className='col-md-3'>
                <button class="btn btn-info"
                    onClick={() => {
                        this.setState({
                            cupTop: 'cuptop3'
                        })
                    }}
                    data-toggle="modal"
                    data-target="#modalSetGift">Chọn</button>
            </div>
        </div>
        {/* <div className='row'>
            <div className='col-md-3'>
                CUP TOP 4-10
            </div>
            <div className='col-md-6'>
                <div className="cuptop4">
                    <span></span>
                    <img class="gift-image"></img>
                </div>
            </div>
            <div className='col-md-3'>
                <button class="btn btn-info"
                    onClick={() => {
                        this.setState({
                            cupTop: 'cuptop4'
                        })
                    }}
                    data-toggle="modal"
                    data-target="#modalSetGift">Chọn</button>
            </div>
        </div> */}
    </div>
    <div className='col-md-6'>
        <div className='row'>
            <div className='col-md-3'>
                KHUNG TOP 1
            </div>
            <div className='col-md-6'>
                    <div className='frameTop1'>
                        <span></span>
                        <div >
                            <div id='frameUrlRecoding1'  style={{ maxHeight: "50px",  maxWidth: "50px", align: 'center'}} className="gift-image">
                                <div width={50} height={50} id="canvas"></div>
                            </div>
                        </div>

                    </div>
            </div>
            <div className='col-md-3'>
                <button class="btn btn-info"
                    onClick={() => {
                        this.setState({
                            frameTop: 'frameTop1'
                        })
                    }}
                    data-toggle="modal"
                    data-target="#modalSetAvatarFrame">Chọn</button>
            </div>
            
            {/* <div className='col-md-3'>
                    <input
                        type="file"
                        className="custom-file-input"
                        id="inputGroupFileRecording01"
                        onChange={this.uploadThumbnailToS3}
                    />
                    <button className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFileRecording01">Chọn</button>

            </div> */}
        </div>
        <div className='row'>
            <div className='col-md-3'>
                HUY HIỆU 1
            </div>
            <div className='col-md-6'>
                    <div className='medalTop1'>
                        <span></span>
                    <img id="medRec1Img"></img>
                    </div>
            </div>
            <div className='col-md-3'>
                <button class="btn btn-info"
                    onClick={() => {
                        this.setState({
                            decorationTop1: 'medalTop1'
                        })
                    }}
                    data-toggle="modal"
                    data-target="#modalUpdateDecoration">Chọn</button>
            </div>
            
            {/* <div className='col-md-3'>
                    <input
                        type="file"
                        className="custom-file-input"
                        id="inputGroupFileRecording01"
                        onChange={this.uploadThumbnailToS3}
                    />
                    <button className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFileRecording01">Chọn</button>

            </div> */}
        </div>
        <div className='row'>
            <div className='col-md-3'>
                KHUNG TOP 2
            </div>
            <div className='col-md-6'>
                    <div className='frameTop2'>
                        <span></span>
                        <div >
                            <div id='frameUrlRecoding2'  style={{ maxHeight: "50px",  maxWidth: "50px", align: 'center'}} className="gift-image">
                                <div width={50} height={50} id="canvas"></div>
                            </div>
                        </div>
                    </div>
            </div>
            {/* <div className='col-md-3'>
                <input
                        type="file"
                        className="custom-file-input"
                        id="inputGroupFileRecording02"
                        onChange={this.uploadThumbnailToS3}
                    />
                <button className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFileRecording02">Chọn</button>
            </div> */}
            <div className='col-md-3'>
                <button class="btn btn-info"
                    onClick={() => {
                        this.setState({
                            frameTop: 'frameTop2'
                        })
                    }}
                    data-toggle="modal"
                    data-target="#modalSetAvatarFrame">Chọn</button>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-3'>
                KHUNG TOP 3
            </div>
            <div className='col-md-6'>
                {/* <div className="cuptop3">
                    <span></span>
                    <img class="gift-image"></img>
                </div> */}
                <div className='frameTop3'>
                        <span></span>
                        <div >
                            <div id='frameUrlRecoding3'  style={{ maxHeight: "50px",  maxWidth: "50px", align: 'center'}} className="gift-image">
                                <div width={50} height={50} id="canvas"></div>
                            </div>
                        </div>
                    </div>
            </div>
            {/* <div className='col-md-3'>
                <input
                    type="file"
                    className="custom-file-input"
                    id="inputGroupFileRecording03"
                    onChange={this.uploadThumbnailToS3}
                />
                <button className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFileRecording03">Chọn</button>
            </div> */}
            <div className='col-md-3'>
                <button class="btn btn-info"
                    onClick={() => {
                        this.setState({
                            frameTop: 'frameTop3'
                        })
                    }}
                    data-toggle="modal"
                    data-target="#modalSetAvatarFrame">Chọn</button>
            </div>
        </div>
    </div>
</div>
                                            
                                            <br/><br/>

                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <button class="btn btn-success">Thêm Khung và Huy Hiệu</button>&ensp;&ensp;
                                        </div>
                                        <div className='col-md-4 row'>
                                            <div className='col-6'>Chọn Khung</div>
                                            <div className='col-6'>
                                                <select name="recordingFramedf" id="recordingFramedf"
                                                onChange={this.handleSetFrame}
                                                >
                                                    <option value="-1" key="-1">Chọn Khung</option>
                                                    {
                                                        recordingFrames.length > 0 &&
                                                        recordingFrames.map((item,index)=>{
                                                            return <option value={item.id} key={index}>{item.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-4 row'>
                                            <div className='col-6'>Chọn Huy Hiệu</div>
                                            <div className='col-6'>
                                                <select name="recordingMedaldf" id="recordingMedaldf"
                                                onChange={this.handleSetMedal}
                                                >
                                                    <option value="-1" key="-1">Chọn Huy Hiệu</option>
                                                    {
                                                        recordingMedals.length > 0 &&
                                                        recordingMedals.map((item,index)=>{
                                                            return <option value={item.id} key={index}>{item.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-1'>
                                            <button class="btn btn-primary"
                                            onClick={this.handleAddRewardToDefaultEvent}>Lưu</button>&ensp;&ensp;
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'>                                                   
                                        </div>
                                        <div className='col-4'>
                                            <div id="show-frame_resourceUrl" className="col-9" style={{ maxWidth: "300px" }}>
                                                <div width={500} height={500} id="canvas"></div>
                                            </div>
                                        </div>                                                   
                                        <div className='col-4'>  
                                            <div id="show-medal_resourceUrl" className="col-9" style={{ maxWidth: "300px" }}>
                                                <div width={500} height={500} id="canvas"></div>
                                            </div>                                                                                                   
                                        </div>
                                    </div>

                                    <br/><br/>
{/* Form Thêm Sự Kiện END */}

    {this.state.eventRecordingHotNames.map((event, index) => {
        return (<><hr/>
            <div className='row'>
                <div className='col-md-9'>
                    <div scope="row"><strong>{event.name}</strong></div>
                </div>
                <div className='col-md-2'>
                <button class="btn btn-danger ml-3" 
                onClick={()=>{
                    if(window.confirm("Bạn có chắc chắn xóa sự kiện này")){
                        this.handleDeleted(event.keyName)
                    }
                }}
                >Xóa</button>
                    <button className='btn btn-primary ml-3'
                    onClick={()=>{
                        this.setState({
                            updateRecording : true,
                            updateFamily:false,
                            updateRoom: false
                        })
                        this.handleUpdateEvent(event.keyName, event.name, "recording");
                    }}
                    >Sửa 
                    </button>
                </div>
            </div>
            {this.state.cupRecordingHotForEvents.map((cup, index) => {
                let idResource = `${cup.eventName}${index}`;
                if(event.name == cup.eventName) {
                    return (<>
                    {
                        (cup.timeShowStart && cup.timeShowEnd && (cup.cupTop == "TOP 1")) ?
                        <div className='row'>
                            <div className='col-md-2'>
                            <b>Thời gian sự kiện</b>
                            </div>
                            <div className='col-md-9'>
                                {new Date(cup.timeShowStart).toLocaleDateString()} {new Date(cup.timeShowStart).toLocaleTimeString()}  -
                              {new Date(cup.timeShowEnd).toLocaleDateString()} {new Date(cup.timeShowStart).toLocaleTimeString()}</div>
                        </div>
                        :<></>
                        }
                        <div className='row'>
                            <div className='col-md-2'>
                                {cup.cupTop}
                            </div>
                            <div className='col-md-4'>
                                <div className={cup.keyCup}>
                                    <span>{cup.keyCup}</span>
                                    <img class="gift-image" src={cup.cupUrl} />
                                </div>
                            </div>
                            <div className='col-md-5'>
                                <div>
                                {/* <div className={cup.keyCup}> */}
                                    {/* <span>{cup.keyCup}</span> */}
                                    <span>Khung  </span>
                                    <div >
                                        <div id={idResource}  style={{ maxHeight: "50px",  maxWidth: "50px", align: 'center'}}>
                                            <div width={50} height={50} id="canvas"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><br/>
                    </>
                        )
                } else {

                }
            })}</>
        )
        
    })}
                                   
                                      
                                </div>
                                <div className="tab-pane fade" id="nav-phongkara-sukien" role="tabpanel" aria-labelledby="nav-phongkara-sukien-tab">
                                    <div className='row'>
                                        <div className='col-md-2'>
                                            <button class="btn btn-success">Thêm Sự Kiện</button>&ensp;&ensp;
                                        </div>
                                        <div className='col-md-2'>
                                            <input id='inputIdSuKienPhongKara'
                                                class="form-control" placeholder="Nhập ID sự kiện" 
                                                aria-describedby="basic-addon1"
                                                type="text"
                                                {...model('idSuKienPhongKara')}></input>
                                        </div>
                                        <div className='col-md-4'>
                                            <input id='inputTenSuKienPhongKara'
                                                class="form-control" placeholder="Nhập tên sự kiện" 
                                                aria-label="Username" aria-describedby="basic-addon1"
                                                type="text"
                                                {...model('nameSuKienPhongKara')}></input>
                                        </div>
                                        <div className='col-md-2'>
                                                <select id='appSelectedLiveRoom'
                                                    className="form-control"
                                                    type="text"
                                                    name="app2"
                                                    {...model('app2')} >
                                                    <option value="">Chọn APP</option>
                                                    <option value="IKARA">IKARA</option>
                                                    <option value="YOKARA">YOKARA</option>
                                                    <option value="ALL">ALL</option>
                                                </select>
                                        </div>
                                        <div className='col-md-2'>
                                            <button class="btn btn-primary"
                                            onClick={this.addEventLiveRoom}
                                            >Lưu</button><br/><br/>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    CUP TOP 1
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="cuptop1PhongKara">
                                                        <span></span>
                                                        <img class="gift-image" id="cupRoom1Img"></img>
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <button class="btn btn-info"
                                                        onClick={() => {
                                                            this.setState({
                                                                cupTop: 'cuptop1PhongKara'
                                                            })
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#modalSetGift">Chọn</button>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    CUP TOP 2
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="cuptop2PhongKara">
                                                        <span></span>
                                                        <img class="gift-image" id="cupRoom2Img"></img>
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <button class="btn btn-info"
                                                        onClick={() => {
                                                            this.setState({
                                                                cupTop: 'cuptop2PhongKara'
                                                            })
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#modalSetGift">Chọn</button>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    CUP TOP 3
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="cuptop3PhongKara">
                                                        <span></span>
                                                        <img class="gift-image" id="cupRoom2Img"></img>
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <button class="btn btn-info"
                                                        onClick={() => {
                                                            this.setState({
                                                                cupTop: 'cuptop3PhongKara'
                                                            })
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#modalSetGift">Chọn</button>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    KHUNG TOP 1
                                                </div>
                                                <div className='col-md-6'>
                                                    {this.state.frameUrlLiveRoom1 ? (
                                                        <div>
                                                            <span></span>
                                                            <img class="gift-image" src={this.state.frameUrlLiveRoom1}></img>
                                                        </div>
                                                        ) : (
                                                            <div>
                                                                <span></span>
                                                                <img class="gift-image"></img>
                                                            </div>
                                                    )}
                                                </div>
                                                
                                                <div className='col-md-3'>
                                                        <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="inputGroupFileLiveRoom01"
                                                            onChange={this.uploadThumbnailToS3}
                                                        />
                                                        <button className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFileLiveRoom01">Chọn</button>
                                                        


                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    KHUNG TOP 2
                                                </div>
                                                <div className='col-md-6'>
                                                    {this.state.frameUrlLiveRoom2 ? (
                                                        <div>
                                                            <span></span>
                                                            <img class="gift-image" src={this.state.frameUrlLiveRoom2}></img>
                                                        </div>
                                                        ) : (
                                                            <div>
                                                                <span></span>
                                                                <img class="gift-image"></img>
                                                            </div>
                                                    )}
                                                </div>
                                                <div className='col-md-3'>
                                                    <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="inputGroupFileLiveRoom02"
                                                            onChange={this.uploadThumbnailToS3}
                                                        />
                                                    <button className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFileLiveRoom02">Chọn</button>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    KHUNG TOP 3
                                                </div>
                                                <div className='col-md-6'>
                                                    {this.state.frameUrlLiveRoom3 ? (
                                                        <div>
                                                            <span></span>
                                                            <img class="gift-image" src={this.state.frameUrlLiveRoom3}></img>
                                                        </div>
                                                        ) : (
                                                            <div>
                                                                <span></span>
                                                                <img class="gift-image"></img>
                                                            </div>
                                                    )}
                                                </div>
                                                <div className='col-md-3'>
                                                    <input
                                                        type="file"
                                                        className="custom-file-input"
                                                        id="inputGroupFileLiveRoom03"
                                                        onChange={this.uploadThumbnailToS3}
                                                    />
                                                    <button className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFileLiveRoom03">Chọn</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
    {this.state.eventLiveRoomNames.map((liveRoom, index) => {
        return (<><hr/>
            <div className='row'>
                <div className='col-md-9'>
                    <div scope="row"><strong>{liveRoom.name}</strong></div>
                </div>
                <div className='col-md-2'>
                <button class="btn btn-danger ml-3" 
                onClick={()=>{
                    if(window.confirm("Bạn có chắc chắn xóa sự kiện này")){
                        this.handleDeleted(liveRoom.keyName)
                    }
                }}
                >Xóa</button>
                    <button className='btn btn-primary ml-3'
                    onClick={()=>{
                        this.setState({
                            updateRecording : false,
                            updateFamily:false,
                            updateRoom: true
                        })
                        this.handleUpdateEvent(liveRoom.keyName, liveRoom.name, "liveRoom");
                    }}
                    >Sửa 
                    </button>
                </div>
            </div>
            {this.state.cupLiveRoomForEvents.map((cup, index) => {
                let idResource = `${cup.eventName}${index}`;
                if(liveRoom.name == cup.eventName) {
                    return (<>
                        {
                        (cup.timeShowStart && cup.timeShowEnd && (cup.cupTop == "TOP 1")) ?
                        <div className='row'>
                            <div className='col-md-2'>
                                <b>Thời gian sự kiện</b>
                            </div>
                            <div className='col-md-9'>
                                {new Date(cup.timeShowStart).toLocaleDateString()} {new Date(cup.timeShowStart).toLocaleTimeString()}  -
                              {new Date(cup.timeShowEnd).toLocaleDateString()} {new Date(cup.timeShowStart).toLocaleTimeString()}</div>
                        </div>
                        :<></>
                        }
                        <div className='row'>
                            <div className='col-md-2'>
                                {cup.cupTop}
                            </div>
                            <div className='col-md-3'>
                                <div className={cup.keyCup}>
                                    <span>{cup.keyCup}</span>
                                    <img class="gift-image" src={cup.cupUrl} />
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div>
                                    <span>Khung  </span>
                                    <img class="gift-image" src={cup.frameUrl}></img>
                                </div>
                            </div>
                        </div><br/>
                    </>
                        )
                } else {

                }
            })}</>
        )
        
    })}<br/><br/><br/>
                                </div>
                                <div className="tab-pane fade" id="nav-giatoc-sukien" role="tabpanel" aria-labelledby="nav-giatoc-sukien-tab">
                                    {/* {this.state.familys.map((cup, index) => {
                                        return (
                                            <>
                                            <div className='row'>
                                                <div className='col-md-2'>
                                                    {cup.cupTop}
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className={cup.keyCup}>
                                                        <span></span>
                                                        <img class="gift-image"></img>
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <button className="btn btn-red"
                                                        onClick={() => {
                                                            this.setState({
                                                                cup: cup
                                                            })
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#modalSetGift">Chọn</button>&ensp;
                                                    <button
                                                        className="btn btn-red"
                                                        onClick={() => {
                                                            this.setState({
                                                                cup: cup
                                                            });
                                                            this.setCupForEvent(cup.keyCup, cup.cupUrl, cup.cupTop, 'GIATOC');
                                                        }}>Set</button>
                                                </div>
                                            </div><hr/>
                                            </>
                                        )
                                    })} */}





                                    <div className='row'>
                                        <div className='col-md-2'>
                                            <button class="btn btn-success">Thêm Sự Kiện</button>&ensp;&ensp;
                                        </div>
                                        <div className='col-md-2'>
                                            <input id='inputIdSuKienGiaToc'
                                                class="form-control" placeholder="Nhập ID sự kiện" 
                                                aria-describedby="basic-addon1"
                                                type="text"
                                                {...model('inputIdSuKienGiaToc')}></input>
                                        </div>
                                        <div className='col-md-4'>
                                            <input id='inputTenSuKienGiaToc'
                                                class="form-control" placeholder="Nhập tên sự kiện" 
                                                aria-label="Username" aria-describedby="basic-addon1"
                                                type="text"
                                                {...model('nameSuKienGiaToc')}></input>
                                        </div>
                                        <div className='col-md-2'>
                                                <select id='appSelectedFamily'
                                                    className="form-control"
                                                    type="text"
                                                    name="app3"
                                                    {...model('app3')} >
                                                    <option value="">Chọn APP</option>
                                                    <option value="IKARA">IKARA</option>
                                                    <option value="YOKARA">YOKARA</option>
                                                    <option value="ALL">ALL</option>
                                                </select>
                                        </div>
                                        <div className='col-md-2'>
                                            <button class="btn btn-primary"
                                            onClick={this.addEventFamily}
                                            >Lưu</button><br/><br/>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    CUP TOP 1
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="cuptop1GiaToc">
                                                        <span></span>
                                                        <img class="gift-image" id="cupFamily1Img"></img>
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <button class="btn btn-info"
                                                        onClick={() => {
                                                            this.setState({
                                                                cupTop: 'cuptop1GiaToc'
                                                            })
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#modalSetGift">Chọn</button>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    CUP TOP 2
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="cuptop2GiaToc">
                                                        <span></span>
                                                        <img class="gift-image" id="cupFamily2Img"></img>
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <button class="btn btn-info"
                                                        onClick={() => {
                                                            this.setState({
                                                                cupTop: 'cuptop2GiaToc'
                                                            })
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#modalSetGift">Chọn</button>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    CUP TOP 3
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="cuptop3GiaToc">
                                                        <span></span>
                                                        <img class="gift-image" id="cupFamily3Img"></img>
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <button class="btn btn-info"
                                                        onClick={() => {
                                                            this.setState({
                                                                cupTop: 'cuptop3GiaToc'
                                                            })
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#modalSetGift">Chọn</button>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    KHUNG TOP 1
                                                </div>
                                                <div className='col-md-6'>
                                                    {this.state.frameUrlFamily1 ? (
                                                        <div>
                                                            <span></span>
                                                            <img class="gift-image" src={this.state.frameUrlFamily1}></img>
                                                        </div>
                                                        ) : (
                                                            <div>
                                                                <span></span>
                                                                <img class="gift-image"></img>
                                                            </div>
                                                    )}
                                                </div>
                                                
                                                <div className='col-md-3'>
                                                        <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="inputGroupFileFamily01"
                                                            onChange={this.uploadThumbnailToS3}
                                                        />
                                                        <button className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFileFamily01">Chọn</button>
                                                        


                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    KHUNG TOP 2
                                                </div>
                                                <div className='col-md-6'>
                                                    {this.state.frameUrlFamily2 ? (
                                                        <div>
                                                            <span></span>
                                                            <img class="gift-image" src={this.state.frameUrlFamily2}></img>
                                                        </div>
                                                        ) : (
                                                            <div>
                                                                <span></span>
                                                                <img class="gift-image"></img>
                                                            </div>
                                                    )}
                                                </div>
                                                <div className='col-md-3'>
                                                    <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="inputGroupFileFamily02"
                                                            onChange={this.uploadThumbnailToS3}
                                                        />
                                                    <button className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFileFamily02">Chọn</button>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    KHUNG TOP 3
                                                </div>
                                                <div className='col-md-6'>
                                                    {this.state.frameUrlFamily3 ? (
                                                        <div>
                                                            <span></span>
                                                            <img class="gift-image" src={this.state.frameUrlFamily3}></img>
                                                        </div>
                                                        ) : (
                                                            <div>
                                                                <span></span>
                                                                <img class="gift-image"></img>
                                                            </div>
                                                    )}
                                                </div>
                                                <div className='col-md-3'>
                                                    <input
                                                        type="file"
                                                        className="custom-file-input"
                                                        id="inputGroupFileFamily03"
                                                        onChange={this.uploadThumbnailToS3}
                                                    />
                                                    <button className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFileFamily03">Chọn</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    {this.state.eventFamilyNames.map((family, index) => {
        return (<><hr/>
            <div className='row'>
                <div className='col-md-9'>
                    <div scope="row"><strong>{family.name}</strong></div>
                </div>
                <div className='col-md-2'>
                <button class="btn btn-danger ml-3" 
                onClick={()=>{
                    if(window.confirm("Bạn có chắc chắn xóa sự kiện này")){
                        this.handleDeleted(family.keyName)
                    }
                }}
                >Xóa</button>
                    <button className='btn btn-primary ml-3'
                    onClick={()=>{
                        this.setState({
                            updateRecording : false,
                            updateFamily:true,
                            updateRoom: false
                        })
                        this.handleUpdateEvent(family.keyName, family.name, "family");
                    }}
                    >Sửa 
                    </button>
                </div>
            </div>
            {this.state.cupFamilyForEvents.map((cup, index) => {
                let idResource = `${cup.eventName}${index}`;
                if(family.name == cup.eventName) {
                    return (<>
                    {
                        (cup.timeShowStart && cup.timeShowEnd && (cup.cupTop == "TOP 1")) ?
                        <div className='row'>
                            <div className='col-md-2'>
                            <b>Thời gian sự kiện</b>
                            </div>
                            <div className='col-md-9'>
                                {new Date(cup.timeShowStart).toLocaleDateString()} {new Date(cup.timeShowStart).toLocaleTimeString()}  -
                              {new Date(cup.timeShowEnd).toLocaleDateString()} {new Date(cup.timeShowStart).toLocaleTimeString()}</div>
                        </div>
                        :<></>
                        }
                        <div className='row'>
                            <div className='col-md-2'>
                                {cup.cupTop}
                            </div>
                            <div className='col-md-3'>
                                <div className={cup.keyCup}>
                                    <span>{cup.keyCup}</span>
                                    <img class="gift-image" src={cup.cupUrl} />
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div>
                                    <span>Khung  </span>
                                    <img class="gift-image" src={cup.frameUrl}></img>
                                </div>
                            </div>
                        </div><br/>
                    </>
                        )
                } else {

                }
            })}</>
        )
        
    })}
                                    <br/><br/><br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}