import React, { Component } from 'react';

import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import firebase from '../../components/Firebase.js'
import bindModel from '../../common/bindModel.js';
import validateNumber, { validateEmpty } from '../../common/validate.js'

import ModalBanAcc from './components/ModalBanAcc.js'
import ModalBanOnllyAcc from './components/ModalBanOnllyAcc.js'
import ModalBanFeature from './components/ModalBanFeature.js'
import ModalUnbanAcc from './components/ModalUnbanAcc.js'
import ModalChangeAvatar from './components/ModalChangeAvatar.js'
import ModalAddIcoin from './components/ModalAddIcoin.js';
import ModalAddVip from './components/ModalAddVip.js';
import ModalDeleteImageForAccountManager from './components/ModalDeleteImageForAccountManager.js';
import ModalDeleteAllImageForAccountManager from './components/ModalDeleteAllImageForAccountManager.js';
import ModalEditPhone from './components/ModalEditPhone.js';
import ModalEditName from './components/ModalEditName.js';
import ModalEditSignature from './components/ModalEditSignature.js';
import ModalUpdateUID from './components/ModalUpdateUID.js';
import ShowMessage from './components/ShowMessage.js';

import MyContext from '../../common/MyContext'
import ModalTransferIcoin from './components/ModalTransferIcoin.js';
import ModalKickOutLiveRoom from './components/ModalKickOutLiveRoom.js';
import ModalShowRelated from './components/ModalShowRelated.js';
import ModalAddRelatedUser from './components/ModalAddRelatedUser.js';
import ModalAddScore from './components/ModalAddScore.js';
import ModalCreatePopupLuckyGift from './components/ModalCreatePopupLuckyGift.js';
import ModalVipScore from './components/ModalVipScore.js';
import ModalPaymentHistory from './components/ModalPaymentHistory.js';
import ModalReceiverGiftHistory from './components/ModalReceiverGiftHistory.js';
import ModalUpdateBirthday from './components/ModalUpdateBirthday.js';
import ModalLogoutAllDevices from './components/ModalLogoutAllDevices.js';
import SwapLiveRoom from './components/SwapLiveRoom.js';
import ModalShowDeviceDetail from './components/ModalShowDeviceDetail.js';
import ModalGiffts from './components/ModalGiffts.js';
import ModalHistoryGifts from './components/ModalHistoryGifts.js';
import ModalReceivingHistory from './components/ModalReceivingHistory.js';
import ModalHistoryScore from './components/ModalHistoryScore.js';
import ModalUserBag from './components/ModalUserBag';
import ModalUserMedal from './components/ModalUserMedal.js';
import ModalCommission from './components/ModalCommission.js';
import ModalNewUserOfUser from './components/ModalNewUserOfUser.js';
import ModalConvertToNewUser from './components/ModalConvertToNewUser.js';


{/* {account ? (
                                                account.mobileCards.map((mobileCard, index) => {
                                                    return (<tr key={index}>
                                                        <td>{new Date(mobileCard.addTime).toLocaleString()}</td>
                                                        <td>{mobileCard.cardId}</td>
                                                        <td>{mobileCard.pin}</td>
                                                        <td>{mobileCard.serial}</td>
                                                        <td>{mobileCard.amount}</td>
                                                        <td>{mobileCard.status}</td>
                                                    </tr>)
                                                })
                                            ) : <></>} */}
const $ = window.$;
var fakeCard = [
    {
        "addTime": 1662367722161,
        "cardId": "VIETTEL",
        "pin": "112020981699361",
        "serial": "10009493425663",
        "amount": 100000,
        "status": "SUCCESS"
    },
    {
        "addTime": 1662367547463,
        "cardId": "VIETTEL",
        "pin": "811644910155340",
        "serial": "10009493425664",
        "amount": 100000,
        "status": "FAIL"
    },
    {
        "addTime": 1662367311754,
        "cardId": "VIETTEL",
        "pin": "418367247269877",
        "serial": "10009493425665",
        "amount": 100000,
        "status": "FAIL&RECHECK"
    }
]

var payments = [{ id: '123', addTime: '10/28/2022, 5:26:03 PM', type: 'TRANSFER', status: 'PROCESSED', oldIcoin: 40885, newIcoin: '-1', totalIcoin: 40884 },
{ id: '12345', addTime: '10/23/2022, 1:31:14 PM', type: 'BUYLUCKYGIFT', status: 'PROCESSED', oldIcoin: 40879, newIcoin: '99', totalIcoin: 40888 },
{ id: '222', addTime: '10/29/2022, 5:26:03 PM', type: 'BUYLUCKYGIFT', status: 'PROCESSED', oldIcoin: 222, newIcoin: '2', totalIcoin: 224 },
{ id: '12345678', addTime: '11/02/2022, 5:26:03 PM', type: 'SELLGIFT', status: 'PROCESSED', oldIcoin: 1111, newIcoin: '11', totalIcoin: 1122 }];

var fakeInfo = {
    accountType: "GOOGLE",
    addTime: 1653358544240,
    appId: "Yokara",
    appUsing: "IKARA",
    avatar: "https://www.ikara.co/avatar/105023259549451008057?type=superlarge",
    banned: false,
    bannedExpired: null,
    birthday: 0,
    cursorPayment: "Cj8KFAoHYWRkVGltZRIJCNi507_H9_cCEiNqCXN-aWthcmE0bXIWCxIJSWNvaW5QbHVzGICA2KH4pJgLDBgAIAE",
    deviceId: "00000000-153d-d5ec-0000-0000153dd5ec",
    deviceName: "a51",
    model: "iPhone",
    versionSdk: "15.7",
    platform: "IOS",
    email: "ngochoanqbvn@gmail.com",
    firebaseToken: "eyJhbGciOiJSUzI1NiJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImV4cCI6MTY2MzA2MzY4MywiaWF0IjoxNjYzMDYwMDgzLCJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay01OHRhdkBpa2FyYTRtLmlhbS5nc2VydmljZWFjY291bnQuY29tIiwic3ViIjoiZmlyZWJhc2UtYWRtaW5zZGstNTh0YXZAaWthcmE0bS5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInVpZCI6IjEwNTAyMzI1OTU0OTQ1MTAwODA1NyJ9.YxnFm0uL_iwUkM5dzJrX3DVR3G4og43h3QatXIctA0Fgh_A9eDaFI4QUS15d4d8tVfaqTsG6n95lLvcX4zasgYx6M7AO_lyUb6lFAG6IvitA7JJdcAO2yv1H_00yU0ncLBaR2mJh5qmDUAGmemUthi4MCSM9He8peNKMCr-YAf9H0eAdtWbnX_lK0bWO9TnQlw5aBHEyip_PBw6Kss1_fmOOLpYX06i_72zeEgBkcI--A1TbkPNo4AqQ4i1WG-gwQ1eDBM2NVqKOl--i5jk0j2SOTxwPNMxl3zY1lZUjaWGBcrmQ2S053I19EQO78hObtgI6SoqZ3pmB_ygams_xpA",
    // id: "3779714836265541843",
    id: "100503286373199224276",
    ikaraExpiredDate: 1757060818605,
    ip: "2405:4802:724f:edd0:2842:f749:a1b6:c372",
    level: 10,
    message: null,
    minScoreOfCurrentLevel: 72900,
    minScoreOfNextLevel: 100000,
    mobileCards: [],
    name: "Ngọc Hoàn Nguyễn",
    payments: payments,
    phoneNumber: null,
    reason: null,
    relatedUsers: [],
    showRelated: null,
    status: "OK",
    totalIcoin: 56259,
    totalIcoinTest: 0,
    totalScore: 75630,
    uid: 4564094,
    version: "7.7.30",
    yokaraExpiredDate: 1661831077569,
    reviewed: false,
    hiddenLocation: false,
    vipStatus: {
        isVip: true,
        vipLevel: 4,
        expirationDate: 1679875200000,
        percentToNextLevel: 89.9,
        score: 2202,
        scoreNextLevel: 4000,
        scorePrevLevel: 2000,
        vipCardImage: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/vipCard/c6c8baf5-4d25-cdcb-94f0-e8ff838a2541.png",
        vipScoreLastMonth: 2202,
        vipScoreThisMonth: 0,
        vipLevelThisMonth: 1,
        percentToNextLevelThisMonth: 100.0,
        scoreNextLevelThisMonth: 500,
        scorePrevLevelThisMonth: 0
    }
};
var fakeReceivingHistorys = [
    { id: 6025702960136192, addTime: '10/28/2022, 5:26:03 PM', oldIcoin: 1234, newIcoin: 5678, link_uid: 'https://www.yokara.com/recording/recording-5404828326297600' },
    { id: 6025702960136192, addTime: '10/29/2022, 5:26:03 PM', oldIcoin: 1234, newIcoin: 5678, link_uid: 'https://www.yokara.com/recording/recording-5404828326297600' },
    { id: 6025702960136192, addTime: '10/30/2022, 5:26:03 PM', oldIcoin: 1234, newIcoin: 5678, link_uid: 'https://www.yokara.com/recording/recording-5404828326297600' },
    { id: 6025702960136192, addTime: '11/01/2022, 5:26:03 PM', oldIcoin: 1234, newIcoin: 5678, link_uid: 'https://www.yokara.com/recording/recording-5404828326297600' },
    { id: 6025702960136192, addTime: '11/01/2022, 9:26:03 PM', oldIcoin: 1234, newIcoin: 5678, link_uid: 'https://www.yokara.com/recording/recording-5404828326297600' },
];

class AccountManagerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: "",
            account: null,
            images: [],
            loading: false,
            app: "IKARA",
            imageSelected: null,
            imageSelecteds: null,
            isSearch: false,
            resetCursor: false,
            icoinPlusIdSeleted: null,
            cursorPayment: null,
            thisCursor: null,
            reviewed: false,
            hiddenLocation: false,
            moveIcoinInfos: [],
            fromDateSearch: null,
            toDateSearch: null,
            type: null,
            newIcoin: null,
            valueTypeSearch: "bang",
            accountTemp: null,
            typeArr: [],
            giftPlusIdSeleted: null,
            platform: null,
            versionSdk: null,
            model: null,
            enableSmartlook: false,
            lastSession: null,
            urlSession: null
        }
        this.setFakeValue();
        this.ikaraNode = firebase.database().ref("ikara");
        this.submit = this.submit.bind(this);
        this.getAccount = this.getAccount.bind(this);
        this.getImageList = this.getImageList.bind(this);
        this.loadMorePayment = this.loadMorePayment.bind(this);
        this.setReview = this.setReview.bind(this);
        this.unsetReview = this.unsetReview.bind(this);
        this.setHiddenLocation = this.setHiddenLocation.bind(this);
        this.setShowLocation = this.setShowLocation.bind(this);
        this.search = this.search.bind(this);
        this.loadMoreSearch = this.loadMoreSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.reset = this.reset.bind(this);
        this.handleDeleteRelatedUser = this.handleDeleteRelatedUser.bind(this);
        this.getEnableSmartlook = this.getEnableSmartlook.bind(this);
        this.switchSmartlook = this.switchSmartlook.bind(this);
        this.getSessionInfoSmartLook = this.getSessionInfoSmartLook.bind(this);
    }

    handleDeleteRelatedUser(facebookId) {
        if (window.confirm("Bạn có chắc là xóa tài khoản liên kết này ?")) {
            let json = {
                id: facebookId
            }
            axios.post(global.config.apiDomain + "/rest/admin/deleteRelatedUser", json)
                .then(res => {
                    if (res.data.status === "FAILED") {
                        toast.warning(res.data.message)
                    } else {
                        toast.success(res.data.message)
                    }
                })
                .catch(err => {
                    toast.warning(err)
                })
        }
    }

    gettypeArr() {
        const accountTemp = JSON.parse(JSON.stringify(this.state.account))
        let types = [];
        if (accountTemp != null) {
            accountTemp.payments.map((temp, index) => {
                types.push(temp.type);
            });
            const typeSet = new Set(types);
            const typeArr = [...typeSet];
            this.setState({
                typeArr: typeArr,
                accountTemp: accountTemp,
            })
        }
    }

    setFakeValue() {

    }

    getButton(status, seri, pin) {
        if (status == 'SUCCESS' || status == 'PROCESSING' || status == 'PENDING') {
            return (<td></td>);
        }
        return (<td>
            <button style={{ width: "120px" }} className="btnrecheck" onClick={() => this.reCheckCard(seri, pin)}>
                RECHECK
            </button>
        </td>);
    }



    reCheckCard(seri, pin) {
        console.log("Bạn vừa recheck " + seri + " " + pin);
        let parameters = { serial: seri, pin: pin }
        console.log(parameters);
        axios.post(global.config.apiDomain + '/AdminRecheckCard?serial=' + seri + '&pin=' + pin, parameters, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            console.log(res);
            alert(res.data.message + " (serial: " + res.data.serial + ", pin: " + res.data.pin + ")");
        })
    }

    componentDidMount() {
        let id = new URLSearchParams(this.props.location.search).get("id");
        this.state.id = id;
        // if (validateNumber(this.state.id)) {
        if (id !== "") {
            this.getAccount();
        }
    }

    loadMorePayment() {
        var { account, isSearch } = this.state;

        if (isSearch) {
            this.loadMoreSearch();
        } else {
            var json = {
                userId: account.id,
                cursorPayment: account.cursorPayment
            }
            axios.post(global.config.apiDomain + '/rest/admin/loadMorePayment', JSON.stringify(json), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {

                if (res.data.payments.length == 0) {
                    toast.success("đã hiện hết, không thể load thêm")
                    return;
                }

                account.payments = account.payments.concat(res.data.payments);
                account.cursorPayment = res.data.cursorPayment;

                this.setState({
                    account: account,
                    accountTemp: account,
                })
                this.gettypeArr();
            });
        }

    }

    // componentDidUpdate() {
    //     let id = this.props.match.params.id;
    //     if(id != this.state.id) {
    //         this.state.id = this.props.match.params.id;
    //         this.getAccount();
    //     }
    // }

    getAccount() {
        var id = this.state.id;
        if (id !== null) {
            id = id.trim().replace(" ", "+");
            this.state.id = id;
            var json = {
                id: id
            }

            axios.post(global.config.apiDomain + '/rest/admin/getAccount', JSON.stringify(json), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.setState({
                    loading: false
                });
                if (res.data.status == "OK") {
                    this.setState({
                        account: res.data,
                        reviewed: res.data.reviewed,
                        hiddenLocation: res.data.hiddenLocation,
                        accountTemp: res.data,
                    })
                    this.getImageList();
                    this.gettypeArr();
                    this.getEnableSmartlook();
                } else {
                    toast.error(res.data.message);
                }
            }, err => {
                this.setState({
                    loading: false,
                });
                toast.error("ERR");
            });

            var queryParams = new URLSearchParams(window.location.search);
            var toId = queryParams.get('id');

            if (toId !== null) {
                toId = toId.trim().replace(" ", "+");
            }

            var json2 = {
                fromFacebookId: toId
            }
            axios.post(global.config.apiDomain + '/rest/admin/getMoveIcoinInfo', JSON.stringify(json2), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.setState({
                    moveIcoinInfos: res.data.moveIcoinInfos
                })
            }, err => {
                toast.error("Đã xảy ra lỗi!");
            });
        }
    }

    getEnableSmartlook() {
        this.ikaraNode.child('logcat').child(this.state.account.uid).child("properties").child("smartLook").get().then(snapshot => {
            const value = snapshot.val();
            this.setState({
                enableSmartlook: value
            });
        });
    }

    switchSmartlook() {
        const enableSmartlook = this.state.enableSmartlook || false;
        const newValue = !enableSmartlook;
        // Cập nhật giá trị trong Firebase
        this.ikaraNode.child('logcat').child(this.state.account.uid).child("properties").child("smartLook").set(newValue)
            .then(() => {
                this.setState({
                    enableSmartlook: newValue
                });
            })
            .catch(error => {
                console.error("Error updating data:", error);
            });
    }

    getSessionInfoSmartLook(uid) {
        const obj = {
            "filters": [
                {
                    "name": "uid",
                    "operator": "is",
                    "value": uid.toString()
                }
            ],
            "sort": {
                "timeStart": "desc"
            }
        }
        axios.post(global.config.smartLook.getSessionSmartLook, obj, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer df1f697a8cc11ef62995c707668e5e79cc7ca29aa377caa1b82dd4b6262cf56a'
            },
            withCredentials: false
        }).then(res => {
            this.setState({
                loading: false
            });
            if (res.data.sessions[0]) {
                const lastSession = this.formatDate(res.data.sessions[0].startedAt);
                this.setState({
                    lastSession: lastSession,
                    urlSession: res.data.sessions[0].dashboardURL
                });
            } else {
                this.setState({
                    lastSession: "Chưa có thông tin",
                    urlSession: "Chưa có thông tin"
                });
            }
        }, err => {
            this.setState({
                loading: false
            });
            toast.error("Error, try later, api is limit");
        });
    }

    getImageList() {
        console.log("vao getImageList");
        this.ikaraNode.child('users').child(this.state.account.id).child("images").get().then(snapshot => {
            var value = snapshot.val();
            this.setState({
                images: value
            })
        });
    }
    formatDate(dateString) {
        // Chuyển đổi chuỗi ngày thành đối tượng Date
        const date = new Date(dateString);

        // Lấy các giá trị của tháng, ngày, năm, giờ, phút, giây
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        // Chuyển đổi giờ sang định dạng 12 giờ (AM/PM)
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');

        // Kết hợp các giá trị lại thành chuỗi định dạng mong muốn
        return `${month}/${day}/${year}, ${formattedHours}:${minutes}:${seconds} ${ampm}`;
    }
    submit(event) {
        if (event.key == 'Enter' || event.target.tagName == "BUTTON") {

            if (!validateEmpty(this.state.id)) {
                toast.warning("ID không được để trống");
                return;
            }

            // if (!validateNumber(this.state.id)) {
            //     toast.warning("Vui lòng nhập số");
            //     return;
            // }

            this.setState({
                loading: true
            });

            this.props.history.push({
                pathname: "/admin/account-manager",
                search: "?id=" + this.state.id
            });
            this.getAccount();
        }
    }

    setShowLocation() {
        var json = {
            facebookId: this.state.account.id,
            hiddenLocation: false
        }

        axios.post(global.config.apiDomain + '/rest/admin/setHiddenLocation', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                loading: false
            });
            if (res.data.status == "OK") {
                this.setState({
                    hiddenLocation: false
                })
                toast.success(res.data.message);
                console.log("res.data: ");
                console.log(res.data);
                //this.getImageList();

            } else {
                toast.error(res.data.message);
            }
        }, err => {
            this.setState({
                loading: false
            });
            toast.error("Set Review thất bại");
        });
    }

    setHiddenLocation() {
        var json = {
            facebookId: this.state.account.id,
            hiddenLocation: true
        }

        axios.post(global.config.apiDomain + '/rest/admin/setHiddenLocation', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                loading: false
            });
            if (res.data.status == "OK") {
                this.setState({
                    hiddenLocation: true
                })
                toast.success(res.data.message);
                console.log("res.data: ");
                console.log(res.data);
                //this.getImageList();

            } else {
                toast.error(res.data.message);
            }
        }, err => {
            this.setState({
                loading: false
            });
            toast.error("Set Review thất bại");
        });
    }

    setReview() {
        var json = {
            facebookId: this.state.account.id
        }

        axios.post(global.config.apiDomain + '/rest/admin/setReviewAccount', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                loading: false
            });
            if (res.data.status == "OK") {
                this.setState({
                    reviewed: true
                })
                toast.success(res.data.message);
                console.log("res.data: ");
                console.log(res.data);
                //this.getImageList();

            } else {
                toast.error(res.data.message);
            }
        }, err => {
            this.setState({
                loading: false
            });
            toast.error("Set Review thất bại");
        });
    }
    unsetReview() {
        var json = {
            facebookId: this.state.account.id
        }

        axios.post(global.config.apiDomain + '/rest/admin/unSetReviewAccount', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                loading: false
            });
            if (res.data.status == "OK") {
                this.setState({
                    reviewed: false
                })
                toast.success(res.data.message);
                console.log("res.data un:")
                console.log(res.data)
                //this.getImageList();

            } else {
                toast.error(res.data.message);
            }
        }, err => {
            this.setState({
                loading: false
            });
            toast.error("Unset Review thất bại");
        });
    }

    search() {
        let { account, accountTemp, thisCursor, fromDateSearch, toDateSearch, type, newIcoin, valueTypeSearch } = this.state;

        let thisAccount = account;

        let json = {
            userId: account.id,
            startDate: new Date(fromDateSearch).getTime(),
            endDate: new Date(toDateSearch).getTime(),
            type: type
        }

        axios.post(global.config.apiDomain + '/rest/admin/filterPayment', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            thisAccount.payments = res.data.payments
            this.setState({
                account: thisAccount,
                thisCursor: res.data.cursor,
                isSearch: true
            })
        })
    }

    loadMoreSearch() {
        let { account, accountTemp, thisCursor, fromDateSearch, toDateSearch, type, newIcoin, valueTypeSearch } = this.state;
        let thisAccount = account;

        let json = {
            userId: account.id,
            startDate: new Date(fromDateSearch).getTime(),
            endDate: new Date(toDateSearch).getTime(),
            type: type,
            cursor: thisCursor
        }

        axios.post(global.config.apiDomain + '/rest/admin/filterPayment', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.data.payments.length == 0) {
                toast.success("Đã lấy hết, không thể load thêm")
                return
            }
            thisAccount.payments = thisAccount.payments.concat(res.data.payments)
            this.setState({
                account: thisAccount,
                thisCursor: res.data.cursor
            })
        })
    }

    reset() {
        this.setState({
            fromDateSearch: "",
            toDateSearch: "",
            type: "",
            newIcoin: "",
            valueTypeSearch: "",
            cursor: ""
        });
        this.getAccount();
    }
    handleChange(event) {
        let target = event.target;
        if (target != null && target != undefined) {
            let name = target.name;
            let value = target.value;

            this.setState({
                [name]: value,
                resetCursor: true
            });
        }
    }

    render() {
        const model = bindModel(this);
        const { account, images, icoinPlusIdSeleted, reviewed, hiddenLocation, moveIcoinInfos, typeArr, giftPlusIdSeleted, enableSmartlook } = this.state;
        const { user } = this.context;
        const imageUrl = account && account.avatar ? account.avatar : ' is null';
        console.log("Account imageUrl:", imageUrl);
        return (
            <>
                {account ? (
                    <>
                        <ModalBanAcc resetAccount={this.getAccount} id={account.id}></ModalBanAcc>
                        <ModalBanOnllyAcc resetAccount={this.getAccount} id={account.id}></ModalBanOnllyAcc>
                        <ModalUnbanAcc resetAccount={this.getAccount} account={account}></ModalUnbanAcc>
                        <ModalAddIcoin resetAccount={this.getAccount} id={account.id}></ModalAddIcoin>
                        <ModalAddVip app={this.state.app} resetAccount={this.getAccount} account={account}></ModalAddVip>
                        <ModalDeleteImageForAccountManager resetImageList={this.getImageList} id={account.id} image={this.state.imageSelected}></ModalDeleteImageForAccountManager>
                        <ModalDeleteAllImageForAccountManager resetImageList={this.getImageList} id={account.id} images={this.state.imageSelecteds}></ModalDeleteAllImageForAccountManager>
                        <ModalEditPhone resetAccount={this.getAccount} account={account}></ModalEditPhone>
                        <ModalEditName resetAccount={this.getAccount} account={account}></ModalEditName>
                        <ModalEditSignature resetAccount={this.getAccount} account={account}></ModalEditSignature>
                        <ModalUpdateUID resetAccount={this.getAccount} account={account}></ModalUpdateUID>
                        <ModalTransferIcoin resetAccount={this.getAccount} id={account.id}></ModalTransferIcoin>
                        <ModalKickOutLiveRoom id={account.id}></ModalKickOutLiveRoom>
                        <ModalChangeAvatar resetAccount={this.getAccount} account={account}></ModalChangeAvatar>
                        <ModalShowRelated resetAccount={this.getAccount} id={account.id}></ModalShowRelated>
                        <ModalAddRelatedUser id={account.id}></ModalAddRelatedUser>
                        <ModalAddScore id={account.id}></ModalAddScore>
                        <ModalCreatePopupLuckyGift id={account.id}></ModalCreatePopupLuckyGift>
                        <ModalVipScore id={account.id}></ModalVipScore>
                        <ModalPaymentHistory icoinPlusId={icoinPlusIdSeleted}></ModalPaymentHistory>
                        <ModalReceiverGiftHistory giftPlusId={giftPlusIdSeleted}></ModalReceiverGiftHistory>
                        <ModalUpdateBirthday resetAccount={this.getAccount} account={account}></ModalUpdateBirthday>
                        <ModalLogoutAllDevices id={account.id}></ModalLogoutAllDevices>
                        <ModalConvertToNewUser id={account.id}></ModalConvertToNewUser>
                        <ModalShowDeviceDetail model={account.model} versionSdk={account.versionSdk} platform={account.platform}></ModalShowDeviceDetail>
                        <ModalBanFeature featureBanStatus={account.featureBanStatus}></ModalBanFeature>
                    </>
                ) : (
                    <></>
                )}

                <div id="content">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-center mb-3">
                            <input
                                type="text"
                                className="form-control col-6"
                                placeholder="Nhập ID"
                                {...model('id')}
                                onKeyUp={this.submit} />

                            {this.state.loading ?
                                (
                                    <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Xác nhận...
                                    </button>

                                ) :
                                (
                                    <button
                                        style={{ width: "120px" }}
                                        className="btn btn-red"
                                        onClick={this.submit}>Xác nhận</button>
                                )}
                        </div>
                        <div>
                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <a className="nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-user-information" role="tab" aria-controls="nav-user-information" aria-selected="true">User Information</a>
                                    <a className="nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-payment-history" role="tab" aria-controls="nav-payment-history" aria-selected="false">Payment History</a>
                                    <a className="nav-link" id="nav-receiving-tab" data-toggle="tab" href="#nav-receiving-history" role="tab" aria-controls="nav-receiving-history" aria-selected="false">Receiving Gifts History</a>
                                    <a className="nav-link" id="nav-history-moveicoin-tab" data-toggle="tab" href="#nav-moveicoin-history" role="tab" aria-controls="nav-moveicoin-history" aria-selected="false">Move iCoin History</a>
                                    <a className="nav-link" id="nav-mobile-tab" data-toggle="tab" href="#nav-mobile-card" role="tab" aria-controls="nav-mobile-card" aria-selected="false">Mobile Card</a>
                                    <a className="nav-link" id="nav-report-tab" data-toggle="tab" href="#nav-report" role="tab" aria-controls="nav-report" aria-selected="false">Report</a>
                                    <a className="nav-link" id="nav-images-tab" data-toggle="tab" href="#nav-images" role="tab" aria-controls="nav-images" aria-selected="false">Images</a>
                                    <a className="nav-link" id="nav-related-users-tab" data-toggle="tab" href="#nav-related-users" role="tab" aria-controls="nav-related-users" aria-selected="false">Related Users</a>
                                    {
                                        (user != null && user.role == "MANAGER") || window.location.hostname == "localhost"
                                            ? <a className="nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-show-message" role="tab" aria-controls="nav-show-message" aria-selected="false">Message</a>
                                            : <></>
                                    }
                                    <a className="nav-link" id="nav-swap-liveroom-tab" data-toggle="tab" href="#nav-swap-liveroom" role="tab" aria-controls="nav-swap-liveroom" aria-selected="false">Swap LiveRoom</a>
                                    <a className="nav-link" id="nav-gifts-tab" data-toggle="tab" href="#nav-gifts" role="tab" aria-controls="nav-gifts" aria-selected="false">Giỏ quà</a>
                                    <a className="nav-link" id="nav-history-gifts-tab" data-toggle="tab" href="#nav-history-gifts" role="tab" aria-controls="nav-history-gifts" aria-selected="false">Lịch sử nhận quà</a>
                                    <a className="nav-link" id="nav-history-score-tab" data-toggle="tab" href="#nav-history-score" role="tab" aria-controls="nav-history-score" aria-selected="false">Lịch sử điểm kinh nghiệm</a>
                                    <a className="nav-link" id="nav-user-bag-tab" data-toggle="tab" href="#nav-user-bag" role="tab" aria-controls="nav-user-bag" aria-selected="false">Vật phẩm trong túi</a>
                                    <a className="nav-link" id="nav-user-medal-tab" data-toggle="tab" href="#nav-user-medal" role="tab" aria-controls="nav-user-medal" aria-selected="false">huy Hiệu trong túi</a>
                                    <a className="nav-link" id="nav-new-user-of-seller-tab" data-toggle="tab" href="#nav-new-user-of-seller" role="tab" aria-controls="nav-new-user-of-seller" aria-selected="false">Mời user mới</a>
                                    <a className="nav-link" id="nav-commission-tab" data-toggle="tab" href="#nav-commission" role="tab" aria-controls="nav-commission" aria-selected="false">Hoa hồng</a>

                                </div>
                            </nav>
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-user-information" role="tabpanel" aria-labelledby="nav-home-tab">

                                    {account ? (
                                        <div className="row">
                                            <div className="col-4">

                                                <img className="card-img-top" src={imageUrl} alt="Card image cap" />
                                                <button
                                                    className="btn btn-red m-1"
                                                    data-toggle="modal"
                                                    data-target="#modalChangeAvatar">Đổi Avatar</button>

                                                {account.banned ?
                                                    (<><button
                                                        className="btn btn-red m-1"
                                                        data-toggle="modal"
                                                        data-target="#modalUnbanAcc">Mở khóa tài khoản</button></>) :
                                                    (<><button
                                                        className="btn btn-red m-1"
                                                        data-toggle="modal"
                                                        data-target="#modalBanAcc">Khóa all tài khoản</button>
                                                        <button
                                                            className="btn btn-red m-1"
                                                            data-toggle="modal"
                                                            data-target="#modalBanOnllyAcc">Hạn chế tài khoản này</button>
                                                        <button
                                                            className="btn btn-red m-1"
                                                            data-toggle="modal"
                                                            data-target="#modalBanFeature">Chặn tính năng</button></>)}

                                                {/* {reviewed ? (
                                                    <button
                                                        className="btn btn-red m-1"
                                                        onClick={this.unsetReview}>UnTester</button>
                                                ) : (
                                                    <button
                                                        className="btn btn-red m-1"
                                                        onClick={this.setReview}>SetTester</button>
                                                )} */}
                                                {hiddenLocation ? (
                                                    <button
                                                        className="btn btn-red m-1"
                                                        onClick={this.setShowLocation}>Bỏ ẩn vị trí</button>
                                                ) : (
                                                    <button
                                                        className="btn btn-red m-1"
                                                        onClick={this.setHiddenLocation}>Ẩn vị trí</button>
                                                )}

                                                <button
                                                    className="btn btn-red m-1"
                                                    data-toggle="modal"
                                                    data-target="#modalKickOutLiveRoom">Kick ra khỏi phòng live</button>
                                                <button
                                                    className="btn btn-red m-1"
                                                    data-toggle="modal"
                                                    data-target="#modalAddRelatedUser">Add Related</button>
                                                <button
                                                    className="btn btn-red m-1"
                                                    data-toggle="modal"
                                                    data-target="#modalAddScore">Add Score</button>
                                                <button
                                                    className="btn btn-red m-1"
                                                    data-toggle="modal"
                                                    data-target="#modalVipScore">Add Vip Score</button>
                                                <button
                                                    className="btn btn-red m-1"
                                                    data-toggle="modal"
                                                    data-target="#modalCreatePopupLuckyGift">Tạo TB lì xì</button>

                                                <button
                                                    className="btn btn-red m-1"
                                                    data-toggle="modal"
                                                    data-target="#modalLogoutAllDevices">Đăng xuất</button>

                                                <button
                                                    className="btn btn-red m-1"
                                                    data-toggle="modal"
                                                    data-target="#modalConvertToNewUser">Chuyển thành NewUser</button>

                                                {/* {
                                                    enableSmartlook ? (
                                                        <button
                                                            className="btn btn-danger m-1"
                                                            onClick={this.switchSmartlook}>Tắt Smartlook</button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-success m-1"
                                                            onClick={this.switchSmartlook}>Bật Smartlook</button>
                                                    )
                                                } */}

                                            </div>
                                            <div className="col-6">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">FacebookID</th>
                                                            <td scope="col">{account.id}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">DeviceID</th>
                                                            <td scope="col">{account.deviceId}&ensp;&ensp;
                                                                <button
                                                                    onClick={() => this.setState({
                                                                        platform: account.platform,
                                                                        versionSdk: account.versionSdk,
                                                                        model: account.model
                                                                    })}
                                                                    data-toggle="modal"
                                                                    data-target="#modalShowDeviceDetail"
                                                                    className="btn btn-outline-primary"><i className="fa fa-eye"></i></button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">UID</th>
                                                            <td scope="col">{account.uid}
                                                                <button
                                                                    data-toggle="modal"
                                                                    data-target="#modalUpdateUID"
                                                                    className="btn-red float-right"><i className="fa fa-edit"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Thời gian quay gần nhất</th>
                                                            <td scope="col">{this.state.lastSession}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Link Session SmartLook</th>
                                                            <td scope="col">
                                                                <a href={this.state.urlSession} target="_blank">
                                                                    {this.state.urlSession}
                                                                </a>
                                                                <button
                                                                    className="btn btn-success float-right" onClick={() => this.getSessionInfoSmartLook(account.uid)}>Get Info SmartLook
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Tên</th>
                                                            <td scope="col">{account.name}
                                                                <button

                                                                    data-toggle="modal"
                                                                    data-target="#modalEditName"
                                                                    className="btn-red float-right"><i className="fa fa-edit"></i></button></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Email</th>
                                                            <td scope="col">{account.email}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Số điện thoại</th>
                                                            <td scope="col">{account.phoneNumber}
                                                                <button

                                                                    data-toggle="modal"
                                                                    data-target="#modalEditPhone"
                                                                    className="btn-red float-right"><i className="fa fa-edit"></i></button></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Ngày sinh</th>
                                                            <td scope="col">{account.birthday && new Date(account.birthday).toLocaleDateString()}
                                                                <button

                                                                    data-toggle="modal"
                                                                    data-target="#modalUpdateBirthday"
                                                                    className="btn-red float-right"><i className="fa fa-edit"></i></button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Trạng thái</th>
                                                            <td scope="col">{account.signature}
                                                                <button
                                                                    data-toggle="modal"
                                                                    data-target="#modalEditSignature"
                                                                    className="btn-red float-right"><i className="fa fa-edit"></i></button></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Ngày tham gia</th>
                                                            <td scope="col">{new Date(account.addTime).toLocaleDateString()}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Đăng nhập gần nhất</th>
                                                            <td scope="col">{this.formatDate(account.lastLogin)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Phương thức đăng nhập</th>
                                                            <td scope="col">{account.accountType}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Ứng dụng đang sử dụng</th>
                                                            <td scope="col">{account.appUsing}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">App version</th>
                                                            <td scope="col">{account.version}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Total Score</th>
                                                            <td scope="col">{account.totalScore}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Level</th>
                                                            <td scope="col">
                                                                {account.level}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Progress</th>
                                                            <td scope="col">
                                                                <div className="progress">
                                                                    <div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: ((account.totalScore - account.minScoreOfCurrentLevel) / (account.minScoreOfNextLevel - account.minScoreOfCurrentLevel)) * 100 + "%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                                        {account.totalScore - account.minScoreOfCurrentLevel} / {account.minScoreOfNextLevel - account.minScoreOfCurrentLevel}

                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Tổng Icoin</th>
                                                            <td scope="col">
                                                                {account.totalIcoin}
                                                                {account.canAddIcoin ? (
                                                                    <>
                                                                        <button
                                                                            data-toggle="modal"
                                                                            data-target="#modalAddIcoin"
                                                                            className="btn-red float-right"><i className="fa fa-plus"></i></button>
                                                                        <button
                                                                            data-toggle="modal"
                                                                            data-target="#modalTransferIcoin"
                                                                            className="btn-red float-right mr-2"><i className="fa fa-share"></i></button>
                                                                    </>) : <></>}

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">IP</th>
                                                            <td scope="col">{account.ip}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Level Vip hiện tại</th>
                                                            <td scope="col">
                                                                {account.vipStatus.vipLevel}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Level Vip tháng này</th>
                                                            <td scope="col">
                                                                {account.vipStatus.vipLevelThisMonth}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Thông tin Vip Tháng này</th>
                                                            <td scope="col">
                                                                <div className="progress">
                                                                    <div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: ((account.vipStatus.vipScoreThisMonth - account.vipStatus.scorePrevLevelThisMonth) / (account.vipStatus.scoreNextLevelThisMonth - account.vipStatus.scorePrevLevelThisMonth)) * 100 + "%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                                        {account.vipStatus.vipScoreThisMonth} / {account.vipStatus.scoreNextLevelThisMonth}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">IKara Expired Date</th>
                                                            <td scope="col">
                                                                {new Date(account.ikaraExpiredDate).toLocaleString()}
                                                                <button
                                                                    onClick={() => { this.setState({ app: "IKARA" }) }}
                                                                    data-toggle="modal"
                                                                    data-target="#modalAddVip"
                                                                    className="btn-red float-right"><i className="fa fa-plus"></i></button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Yokara Expired Date</th>
                                                            <td scope="col">
                                                                {new Date(account.yokaraExpiredDate).toLocaleString()}
                                                                <button
                                                                    onClick={() => { this.setState({ app: "YOKARA" }) }}
                                                                    data-toggle="modal"
                                                                    data-target="#modalAddVip"
                                                                    className="btn-red float-right"><i className="fa fa-plus"></i></button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Show related</th>
                                                            <td scope="col">{account.showRelated ? "SHOW" : "NOT SHOW"}
                                                                <button
                                                                    // onClick={() => { this.setState({ app: "YOKARA" }) }}
                                                                    data-toggle="modal"
                                                                    data-target="#modalShowRelated"
                                                                    className="btn-red float-right"><i className="fa fa-edit"></i></button>
                                                            </td>
                                                        </tr>
                                                    </thead>

                                                </table>
                                            </div>
                                        </div>
                                    ) : <></>}



                                    {/* {fakeInfo ? (
                                        <div className="row">
                                            <div className="col-4">
                                                <img className="card-img-top" src={fakeInfo.avatar} alt="Card image cap" />
                                                {fakeInfo.banned ?
                                                    (<><button
                                                        className="btn btn-red m-1"
                                                        data-toggle="modal"
                                                        data-target="#modalUnbanAcc">Mở khóa tài khoản</button></>) :
                                                    (<>
                                                    <button
                                                        className="btn btn-red m-1"
                                                        data-toggle="modal"
                                                        data-target="#modalBanAcc">Khóa all tk</button>
                                                    <button
                                                        className="btn btn-red m-1"
                                                        data-toggle="modal"
                                                        data-target="#modalBanOnllyAcc">Khóa tk này</button></>)}

                                                {fakeInfo.reviewed ? (
                                                        <button
                                                        className="btn btn-red m-1"
                                                        onClick={this.unsetReview}>UnReview</button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-red m-1"
                                                            onClick={this.setReview}>SetReview</button>
                                                ) }
                                                   {fakeInfo.hiddenLocation ? (
                                                        <button
                                                        className="btn btn-red m-1"
                                                        onClick={this.setShowLocation}>Bỏ ẩn vị trí</button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-red m-1"
                                                            onClick={this.setHiddenLocation}>Ẩn vị trí</button>
                                                ) } 
                                                    





                                                <button
                                                    className="btn btn-red m-1"
                                                    data-toggle="modal"
                                                    data-target="#modalKickOutLiveRoom">Kick ra khỏi phòng live</button>
                                                <button
                                                    className="btn btn-red m-1"
                                                    data-toggle="modal"
                                                    data-target="#modalAddRelatedUser">Add Related</button>
                                                <button
                                                    className="btn btn-red m-1"
                                                    data-toggle="modal"
                                                    data-target="#modalAddScore">Add Score</button>
                                                    <button
                                                    className="btn btn-red m-1"
                                                    data-toggle="modal"
                                                    data-target="#modalCreatePopupLuckyGift">Tạo TB lì xì</button>
                                                <button
                                                    className="btn btn-danger m-1"
                                                    data-toggle="modal"
                                                    data-target="#modalAddVipScore">Add Vip Score</button>
                                                <button
                                                    className="btn btn-red m-1"
                                                    data-toggle="modal"
                                                    data-target="#modalLogoutAllDevices">Đăng xuất</button>
                                            </div>
                                            <div className="col-6">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">FacebookID</th>
                                                            <td scope="col">{fakeInfo.id}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">DeviceID</th>
                                                            <td scope="col">{fakeInfo.deviceId}&ensp;&ensp;
                                                                <button
                                                                onClick={
                                                                    () => this.setState({
                                                                    platform: fakeInfo.platform,
                                                                    versionSdk: fakeInfo.versionSdk,
                                                                    model: fakeInfo.model
                                                                })
                                                                }
                                                                data-toggle="modal"
                                                                data-target="#modalShowDeviceDetail"
                                                                className="btn btn-outline-primary"><i className="fa fa-eye"></i></button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">UID</th>
                                                            <td scope="col">{fakeInfo.uid}
                                                                <button
                                                                    data-toggle="modal"
                                                                    data-target="#modalUpdateUID"
                                                                    className="btn-red float-right"><i className="fa fa-edit"></i></button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Tên</th>
                                                            <td scope="col">{fakeInfo.name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Email</th>
                                                            <td scope="col">{fakeInfo.email}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Số điện thoại</th>
                                                            <td scope="col">{fakeInfo.phoneNumber}
                                                                <button
                                                                    data-toggle="modal"
                                                                    data-target="#modalEditPhone"
                                                                    className="btn-red float-right"><i className="fa fa-edit"></i></button></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Ngày sinh</th>
                                                            <td scope="col">{fakeInfo.birthday && new Date(fakeInfo.birthday).toLocaleDateString()}
                                                                <button
                                                                    data-toggle="modal"
                                                                    data-target="#modalUpdateBirthday"
                                                                    className="btn-red float-right"><i className="fa fa-edit"></i></button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Ngày tham gia</th>
                                                            <td scope="col">{new Date(fakeInfo.addTime).toLocaleDateString()}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Phương thức đăng nhập</th>
                                                            <td scope="col">{fakeInfo.accountType}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Ứng dụng đang sử dụng</th>
                                                            <td scope="col">{fakeInfo.appUsing}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">App version</th>
                                                            <td scope="col">{fakeInfo.version}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Total Score</th>
                                                            <td scope="col">{fakeInfo.totalScore}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Level</th>
                                                            <td scope="col">
                                                                {fakeInfo.level}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Progress</th>
                                                            <td scope="col">
                                                                <div className="progress">
                                                                    <div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: ((fakeInfo.totalScore - fakeInfo.minScoreOfCurrentLevel) / (fakeInfo.minScoreOfNextLevel - fakeInfo.minScoreOfCurrentLevel)) * 100 + "%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                                        {fakeInfo.totalScore - fakeInfo.minScoreOfCurrentLevel} / {fakeInfo.minScoreOfNextLevel - fakeInfo.minScoreOfCurrentLevel}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Tổng Icoin</th>
                                                            <td scope="col">
                                                                {fakeInfo.totalIcoin}
                                                                <button
                                                                    data-toggle="modal"
                                                                    data-target="#modalAddIcoin"
                                                                    className="btn-red float-right"><i className="fa fa-plus"></i></button>
                                                                <button
                                                                    data-toggle="modal"
                                                                    data-target="#modalTransferIcoin"
                                                                    className="btn-red float-right mr-2"><i className="fa fa-share"></i></button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">IP</th>
                                                            <td scope="col">{fakeInfo.ip}</td>
                                                        </tr>
                            
                                                        <tr>
                                                            <th scope="col">Vip Level</th>
                                                            <td scope="col">
                                                                {fakeInfo.vipStatus.vipLevel}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Vip Info</th>
                                                            <td scope="col">                                                                    
                                                                <div className="progress">
                                                                    <div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: ((fakeInfo.vipStatus.vipScoreThisMonth - fakeInfo.vipStatus.scorePrevLevelThisMonth) / (fakeInfo.vipStatus.scoreNextLevelThisMonth - fakeInfo.vipStatus.scorePrevLevelThisMonth)) * 100 + "%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                                        {fakeInfo.vipStatus.vipScoreThisMonth} / {fakeInfo.vipStatus.scoreNextLevelThisMonth}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">IKara Expired Date</th>
                                                            <td scope="col">
                                                                {new Date(fakeInfo.ikaraExpiredDate).toLocaleString()}
                                                                <button
                                                                    onClick={() => { this.setState({ app: "IKARA" }) }}
                                                                    data-toggle="modal"
                                                                    data-target="#modalAddVip"
                                                                    className="btn-red float-right"><i className="fa fa-plus"></i></button>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="col">Yokara Expired Date</th>
                                                            <td scope="col">
                                                                {new Date(fakeInfo.yokaraExpiredDate).toLocaleString()}
                                                                <button
                                                                    onClick={() => { this.setState({ app: "YOKARA" }) }}
                                                                    data-toggle="modal"
                                                                    data-target="#modalAddVip"
                                                                    className="btn-red float-right"><i className="fa fa-plus"></i></button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Show related</th>
                                                            <td scope="col">{fakeInfo.showRelated ? "SHOW" : "NOT SHOW"}
                                                                <button
                                                                    // onClick={() => { this.setState({ app: "YOKARA" }) }}
                                                                    data-toggle="modal"
                                                                    data-target="#modalShowRelated"
                                                                    className="btn-red float-right"><i className="fa fa-edit"></i></button>
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                        </div>
                                    ) : <></>} */}



                                </div>

                                <div className="tab-pane fade" id="nav-payment-history" role="tabpanel" aria-labelledby="nav-profile-tab">
                                    <br /><i>(Hãy click "Xem thêm" để search được nhiều kết quả hơn!)</i>
                                    <div className="input-group">
                                        <input
                                            name="fromDateSearch"
                                            value={this.state.fromDateSearch}
                                            onChange={this.handleChange}
                                            onKeyPress={(event) => {
                                                if (event.key === "Enter") {
                                                    this.search()
                                                }
                                            }}
                                            type="date"
                                            className="form-control col-md-2 col-sm-6"
                                            placeholder="Từ ngày"
                                            aria-label="Recipient's username"
                                            aria-describedby="button-addon2" />
                                        <input
                                            name="toDateSearch"
                                            value={this.state.toDateSearch}
                                            onChange={this.handleChange}
                                            onKeyPress={(event) => {
                                                if (event.key === "Enter") {
                                                    this.search()
                                                }
                                            }}
                                            type="date"
                                            className="form-control col-md-3 col-sm-6 "
                                            placeholder="Đến ngày"
                                            aria-label="Recipient's username"
                                            aria-describedby="button-addon2" />
                                        <select
                                            name="type"
                                            value={this.state.type}
                                            className="form-control col-md-2 col-3"
                                            onChange={this.handleChange}>
                                            <option value="">Hành Động</option>
                                            {typeArr ? (
                                                typeArr.map((payment, index) => {
                                                    return (<option value={payment}>{payment}</option>)
                                                })
                                            ) : <></>}
                                        </select>
                                        {/* <input
                                            name="newIcoin"
                                            value={this.state.newIcoin}
                                            onChange={this.handleChange}
                                            onKeyPress={(event) => {
                                                if (event.key === "Enter") {
                                                    this.search()
                                                }
                                            }}
                                            type="text"
                                            className="form-control col-md-3 col-sm-6 "
                                            placeholder="Nhập giá trị"
                                            aria-label="Recipient's username"
                                            aria-describedby="button-addon2" /> */}
                                        <select
                                            name="valueTypeSearch"
                                            value={this.state.valueTypeSearch}
                                            className="form-control col-md-2 col-3"
                                            onChange={this.handleChange}>
                                            <option value="bang">Search bằng</option>
                                            <option value="lonHon">Search lớn hơn</option>
                                            <option value="beHon">Search bé hơn</option>
                                        </select>
                                        <div className="input-group-append">
                                            <button
                                                className="btn btn-outline-success"
                                                type="button"
                                                id="button-addon2"
                                                onClick={this.search}>
                                                <i className="fa fa-search"></i>
                                            </button>
                                            <button
                                                className="btn btn-outline-danger"
                                                type="button"
                                                id="button-addon2"
                                                onClick={this.reset}>
                                                <i className="fas fa-sync"></i>
                                            </button>
                                        </div>
                                    </div><br />


                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">ID</th>
                                                <th scope="col">Ngày Tháng</th>
                                                <th scope="col">Hành Động</th>
                                                <th scope="col">Trạng thái</th>
                                                <th scope="col">Số Dư Trước GD</th>
                                                <th scope="col">Giá Trị</th>
                                                <th scope="col">Số Dư Sau GD</th>
                                                <th scope="col">Chi tiết</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {account ? (
                                                account.payments.map((payment, index) => {
                                                    return (<tr key={index}>
                                                        <td>{payment.id}</td>
                                                        <td>{new Date(payment.addTime).toLocaleString()}</td>
                                                        <td>{payment.type}</td>
                                                        <td>{payment.status}</td>
                                                        <td>{payment.oldIcoin}</td>
                                                        <td>{payment.newIcoin}</td>
                                                        <td>{payment.totalIcoin}</td>
                                                        <td><button
                                                            onClick={() => this.setState({
                                                                icoinPlusIdSeleted: payment.id
                                                            })}
                                                            data-toggle="modal"
                                                            data-target="#modalPaymentHistory"
                                                            className="btn btn-outline-primary"><i className="fa fa-eye"></i></button></td>
                                                    </tr>)
                                                })
                                            ) : <></>}

                                        </tbody>
                                    </table>
                                    <div className='text-center'>
                                        {account && account.payments.length > 0 ?
                                            <button onClick={this.loadMorePayment} className="btn-red">Xem thêm</button> :
                                            ""}
                                    </div>
                                </div>





                                <div className="tab-pane fade" id="nav-moveicoin-history" role="tabpanel" aria-labelledby="nav-history-moveicoin-tab">
                                    <br /><h3>Lịch Sử Chuyển iCoin của Admin</h3><br />
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">STT</th>
                                                <th scope="col">Thời gian</th>
                                                <th scope="col">Id gửi</th>
                                                <th scope="col">Id nhận</th>
                                                <th scope="col">Số lượng</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {moveIcoinInfos ? (
                                                moveIcoinInfos.map((record, index) => {
                                                    return (<tr key={index + 1}>
                                                        <td>{index + 1}</td>
                                                        <td>{record.time}</td>
                                                        <td>{record.fromUid}</td>
                                                        <td>{record.toUid}</td>
                                                        <td>{record.icoinNumber}</td>
                                                    </tr>)
                                                })
                                            ) : <></>}

                                        </tbody>
                                    </table>
                                    {/* <div className='text-center'><button onClick={this.loadMorePayment} className="btn-red">Xem thêm</button></div> */}
                                </div>

                                <div className="tab-pane fade" id="nav-mobile-card" role="tabpanel" aria-labelledby="nav-contact-tab">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Ngày Tháng</th>
                                                <th scope="col">Nhà mạng</th>
                                                <th scope="col">Mã nạp thẻ</th>
                                                <th scope="col">Số serial</th>
                                                <th scope="col">Mệnh giá</th>
                                                <th scope="col">Trạng thái</th>
                                                <th scope="col">Kiểm tra lại</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {account ? (
                                                account.mobileCards.map((mobileCard, index) => {

                                                    return (<tr key={index}>
                                                        <td>{new Date(mobileCard.addTime).toLocaleString()}</td>
                                                        <td>{mobileCard.cardId}</td>
                                                        <td>{mobileCard.pin}</td>
                                                        <td>{mobileCard.serial}</td>
                                                        <td>{mobileCard.amount}</td>
                                                        <td>{mobileCard.status}</td>
                                                        {this.getButton(mobileCard.status, mobileCard.serial, mobileCard.pin)}
                                                    </tr>)
                                                })
                                            ) : <></>}
                                            {/* {
                                            fakeCard ? (
                                                fakeCard.map((mobileCard, index) => {
                                                    return (<tr key={index}>
                                                        <td>{new Date(mobileCard.addTime).toLocaleString()}</td>
                                                        <td>{mobileCard.cardId}</td>
                                                        <td>{mobileCard.pin}</td>
                                                        <td>{mobileCard.serial}</td>
                                                        <td>{mobileCard.amount}</td>
                                                        <td>{mobileCard.status}</td>
                                                        {this.getButton(mobileCard.status, mobileCard.serial, mobileCard.pin)}
                                                    </tr>)
                                                })
                                            ) : <></>} */}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="tab-pane fade" id="nav-report" role="tabpanel" aria-labelledby="nav-contact-tab">
                                    {account ? (
                                        <>
                                            <div className='row'>
                                                <iframe
                                                    width={1200}
                                                    height={900}
                                                    src={"https://datastudio.google.com/embed/reporting/63ba7c12-8e6e-4643-bee3-960a8c6a621b/page/kLZKC?params=" + encodeURIComponent(JSON.stringify({ "ds6.facebook_id": account.id }))}
                                                    frameBorder={0}
                                                    style={{ border: 0 }}
                                                    allowFullScreen />
                                            </div>

                                            <div className='row'>
                                                <iframe
                                                    width={1200}
                                                    height={900}
                                                    src={"https://datastudio.google.com/embed/reporting/dcc98f62-dc15-4edb-9596-7d9ceb4bfa82/page/8LeLD?params=" + encodeURIComponent(JSON.stringify({ "ds0.facebookId": account.id, "ds4.facebookId": account.id }))}
                                                    frameBorder={0}
                                                    style={{ border: 0 }}
                                                    allowFullScreen />
                                            </div>
                                        </>

                                    ) : ""}
                                </div>
                                <div className="tab-pane fade" id="nav-images" role="tabpanel" aria-labelledby="nav-contact-tab">
                                    <div className="row">
                                        {images && images.length > 0 ? (
                                            <button type="button"
                                                className="btn btn-red m-1"
                                                onClick={() => this.setState({ imageSelecteds: images })}
                                                data-toggle="modal"
                                                data-target="#modalDeleteAllImage"
                                            >Xoá tất cả ảnh</button>
                                        ) : <></>}
                                    </div>
                                    <div className="row">
                                        {images && images.length > 0 ? (
                                            images.map((image, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="col-lg-3 col-sm-4 col-6 text-center p-2"
                                                        onClick={() => this.setState({ imageSelected: image })}
                                                        data-toggle="modal"
                                                        data-target="#modalDeleteImage" >
                                                        <img className="img-user hover-pointer rounded" key={index} src={image.imageUrl} />
                                                    </div>
                                                )
                                            })
                                        ) : <></>}

                                    </div>
                                </div>

                                <div className="tab-pane fade" id="nav-related-users" role="tabpanel" aria-labelledby="nav-contact-tab">
                                    <div className="row">
                                        {account != null ? (
                                            account.relatedUsers.map((user, index) => {
                                                return (
                                                    <div key={index} className="col-xl-4 col-6 p-2">
                                                        <div className="card" style={{ width: '18rem' }}>
                                                            <div className="card-img-top justify-content-between d-flex" >
                                                                <img width={50} height={50} style={{ "width": "50px", "height": "50px" }} src={user.profileImageLink} alt="Card image cap" />
                                                                <button className='btn btn-outline-danger'
                                                                    onClick={() => {
                                                                        this.handleDeleteRelatedUser(user.uid)
                                                                    }}
                                                                >X</button>
                                                            </div>
                                                            <div className="card-body">
                                                                <h5 className="card-title">Tên: {user.name}</h5>
                                                                <h5>UID: {user.uid}</h5>
                                                                {/* <Link to={"/account-manager/" + user.uid} className="btn btn-primary">Go somewhere</Link> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        ) : <></>}
                                    </div>
                                </div>
                                {(user != null && (user.role == "MANAGER" || user.uid == 4505482 || user.uid == 4183826)) || window.location.hostname == "localhost" ?
                                    <div className="tab-pane fade" id="nav-show-message" role="tabpanel" aria-labelledby="nav-contact-tab">
                                        <ShowMessage account={account}></ShowMessage>
                                    </div> : <></>}
                                <div className="tab-pane fade" id="nav-swap-liveroom" role="tabpanel" aria-labelledby="nav-swap-liveroom-tab">
                                    <SwapLiveRoom />
                                </div>
                                <div className="tab-pane fade" id="nav-gifts" role="tabpanel" aria-labelledby="nav-gifts-tab">
                                    <ModalGiffts account={account}></ModalGiffts>
                                </div>
                                <div className="tab-pane fade" id="nav-history-gifts" role="tabpanel" aria-labelledby="nav-history-gifts-tab">
                                    <ModalHistoryGifts account={account}></ModalHistoryGifts>
                                </div>
                                <div className="tab-pane fade" id="nav-history-score" role="tabpanel" aria-labelledby="nav-history-score-tab">
                                    <ModalHistoryScore account={account}></ModalHistoryScore>
                                </div>
                                <div className="tab-pane fade" id="nav-user-bag" role="tabpanel" aria-labelledby="nav-user-bag-tab">
                                    <ModalUserBag account={account}></ModalUserBag>
                                </div>

                                <div className="tab-pane fade" id="nav-receiving-history" role="tabpanel" aria-labelledby="nav-receiving-tab">
                                    <ModalReceivingHistory account={account}></ModalReceivingHistory>
                                </div>
                                <div className="tab-pane fade" id="nav-user-medal" role="tabpanel" aria-labelledby="nav-receiving-tab">
                                    <ModalUserMedal account={account}></ModalUserMedal>
                                </div>
                                <div className="tab-pane fade" id="nav-new-user-of-seller" role="tabpanel" aria-labelledby="nav-receiving-tab">
                                    <ModalNewUserOfUser account={account}></ModalNewUserOfUser>
                                </div>
                                <div className="tab-pane fade" id="nav-commission" role="tabpanel" aria-labelledby="nav-receiving-tab">
                                    <ModalCommission account={account}></ModalCommission>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

AccountManagerPage.contextType = MyContext;
// export default withRouter(withTranslation()(AccountManagerPage))
export default withRouter(AccountManagerPage);
