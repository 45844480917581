import React, { Component} from 'react'

export default class PageIcon extends Component {

    constructor(props) {
        super(props);

        this.getIndex = this.getIndex.bind(this);
    }

    getIndex() {
        this.props.loadPage(this.props.index);
    }

    render() {
        return  (
            <button onClick={this.getIndex} className={this.props.index == this.props.indexSelected ? 'btn btn-primary ml-1 mr-1' : 'btn btn-outline-primary ml-1 mr-1'}>
                {this.props.index + 1}                
            </button>
        )
    }
}