import React, { Component } from 'react';
import {Link} from "react-router-dom";
class TopUsersItem extends Component {
	render() {
		const {facebookId, profileImageLink, name, url} = this.props.user;
		return (
			<div className="col-4 col-md-3 col-xl-2 item item-0">
				<Link to={url} title={name} className="image">
					<span className="overlay" />
					<img src={profileImageLink} alt={name} onError={(e)=>{e.target.onerror = null; e.target.src=`https://picsum.photos/seed/${facebookId}/270/150`}}/>
				</Link>
				<h4 className="text-center singer_name">
					<Link to={url} title={name}>{name.length > 15 ? name.substring(0, 12) + '...' : name}</Link>
				</h4>
			</div>
		);
	}
}

export default TopUsersItem;