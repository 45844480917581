import React, { Component } from 'react';
import { Link } from "react-router-dom";

export default class LikeLine extends Component {
    render() {
        const {like} = this.props;
        const key = like.key;
        let fbName = like.userName;
        let fbLink = "#";
        let fbAvatar = like.userProfile;
        let randomFbAvatar = "https://storage.googleapis.com/ikara-storage/randomavatar/" + (like.key%100) + ".jpg";
        return (
            <li className="_43q7 pluginFacepileConnectFirst">
                <Link className="link" title={fbName} to={`/user/`+ key} data-jsid="anchor" target="_blank">
                    <img onError={(e)=>{e.target.onerror = null; e.target.src=randomFbAvatar}} className="_s0 _54ru img" src={fbAvatar} alt={fbName} data-jsid="img" />
                </Link>
            </li>
        )
    }
}
