import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';

export default class ModalHide extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stickerModel: null,
            loading: false
        }

        this.hideOrActiveFilter = this.hideOrActiveFilter.bind(this);
    }

    componentWillReceiveProps(props) {
        const { stickerModel } = props;

        this.setState({
            stickerModel: stickerModel
        })
    }

    hideOrActiveFilter() {
        // console.log("this.state.stickerModel");
        // console.log(this.state.stickerModel);
        // return
        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/deepAREffect/hideOrActive', this.state.stickerModel)
        .then(res => {
            this.setState({
                loading: false
            });

            if (res.data.status == "OK") {
                window.$("#modalHide").modal("hide");
                toast.success(res.data.message); 
                this.props.resetList();
            } else {
                toast.warning(res.data.message);
            }
        })
    }

    render() {
        const {stickerModel} = this.state;

        return (
            <div>
                <div className="modal fade" id="modalHide" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                            {(stickerModel && stickerModel.enable) ? (
                                 <h5 className="modal-title" id="exampleModalLabel">ẨN Sticker</h5>
                            ) : ""}
                            {(stickerModel && !stickerModel.enable) ? (
                                 <h5 className="modal-title" id="exampleModalLabel">HIỆN Sticker</h5>
                            ) : ""}
                               
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {(stickerModel && stickerModel.enable) ? (
                                <div className="modal-body">
                                <h3 className="text-center text-danger">Bạn muốn ẨN Sticker: <span className="font-weight-bold">{stickerModel.name}</span> ?</h3>
                            </div>
                            ) : ""}
                            {(stickerModel && !stickerModel.enable) ? (
                                <div className="modal-body">
                                <h3 className="text-center text-info">Bạn muốn HIỆN Sticker: <span className="font-weight-bold">{stickerModel.name}</span> ?</h3>
                            </div>
                            ) : ""}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ? 
                                (
                                <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Xác nhận...
                                </button>
                                ) :
                                (
                                <button 
                                    style={{width: "120px"}} 
                                    type="button" 
                                    className="btn btn-red"
                                    onClick={this.hideOrActiveFilter}>Xác nhận</button>    
                                )}                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}