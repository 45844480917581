import React, { Component } from 'react';
import axios from 'axios';

import Banner from './components/Banner.js';
import TopRecordings from './components/TopRecordings.js';
import TopUsers from "./components/TopUsers";
import TopDuetRecordings from './components/TopDuetRecordings.js'
import { withTranslation } from 'react-i18next';
import MyContext from '../../common/MyContext'
import { Helmet } from 'react-helmet';
import './css/homepage.css';
import { setCookie } from '../../common/cookie.js';
import '../../common/Configure';
import qs, { stringify } from 'querystring';

class HomePage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			banners: [],
			topRecordings: [],
			topDuetRecordings: [],
			topUsers: []
		};

		this.accessUserMocom = this.accessUserMocom.bind(this);

	}

	componentDidMount() {
		const { user } = this.context;
		let search = this.props.location.search;
		let param = new URLSearchParams(search).get("param");
		let uuid = new URLSearchParams(search).get("uuid");
		let mcuid = new URLSearchParams(search).get("mcuid");

		if (uuid != null && user == null) {
			this.accessUserMocom(param, uuid, mcuid);
		}

		const json = JSON.stringify({ domain: window.location.hostname });
		
		axios.post(global.config.apiDomain + '/rest/homepage', json, {
			headers: {
				'Content-Type': 'application/json'
			}
		}).then(res => {
			const { topRecordings, topDuetRecordings, topUsers, banners } = res.data;
			this.setState(
				{
					banners: banners,
					topRecordings: topRecordings,
					topDuetRecordings: topDuetRecordings,
					topUsers: topUsers
				});
		})
	}

	accessUserMocom(param, uuid, mcuid) {
		const json = {
			domain: window.location.hostname,
			uuid: uuid
		}

		axios.post(global.config.apiDomain + '/rest/okara/accessUserMocom', JSON.stringify(json), {
			headers: {
				'Content-Type': 'application/json'
			}
		}).then(res => {
			const { user, message, status } = res.data;
			if (status == "OK" && user != null) {

				window.localStorage.setItem("login", true);
				window.localStorage.setItem("userId", user.facebookId);
				window.localStorage.setItem("profileImageLink", user.profileImageLink);
				window.localStorage.setItem("name", user.name);

				setCookie("login", "true", 1);
				setCookie("userId", user.facebookId, 1);
				setCookie("profileImageLink", user.profileImageLink, 1);
				setCookie("name", user.name, 1);

				this.context.updateUser(user);
			}
		})
	}

	render() {
		const { topRecordings, topDuetRecordings, topUsers, banners } = this.state;
		const { t } = this.props;
		return (
			<div id="content"
				style={{ paddingTop: '70px' }}
			>
				<Helmet>
					<title> {t("home_page.home_page", "Trang Chủ")} </title>
				</Helmet>
				<Banner banners={banners}></Banner>
				<div className="container">
					<TopRecordings topRecordings={topRecordings}></TopRecordings>
					<TopDuetRecordings topDuetRecordings={topDuetRecordings}></TopDuetRecordings>
					<TopUsers topUsers={topUsers}></TopUsers>
				</div>
			</div>
		);
	}
}


HomePage.contextType = MyContext;
export default withTranslation()(HomePage)
