import React, { Component } from 'react';
import axios from 'axios';
import './../../assets/css/newDevice.css';
import MidiPlayer from 'midi-player-js';
import * as Tone from 'tone';
import MIDISounds from 'midi-sounds-react';

export default class MidiRecordingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            midiRecording: [],
            page: 0,
            statusButtonNextPage: false,
            isLoading: false,
            isInitialLoading: true,
            error: null,
            countMidiRecording: 0,
        };
        this.midiSoundsRef = React.createRef();
        this.player = new MidiPlayer.Player();
        this.synth = new Tone.PolySynth().toDestination();
        this.getMidiRecordingList = this.getMidiRecordingList.bind(this);
        this.getCountMidiRecording = this.getCountMidiRecording.bind(this);
        this.handleMidi = this.handleMidi.bind(this);
    }

    componentDidMount() {
        this.getMidiRecordingList();
        this.getCountMidiRecording();
    }

    getCountMidiRecording() {
        this.setState({
            loading: true
        });
        axios.get(global.config.apiDomain + '/rest/midi-score/count-midi-recording')
            .then(res => {
                const countMidiRecording = res.data;
                this.setState({
                    countMidiRecording: countMidiRecording
                });
            })
            .catch(error => {
                console.error('Lỗi: ', error);
                this.setState({ loading: false });
            });
    }

    getMidiRecordingList() {
        const { isInitialLoading } = this.state;

        if (!isInitialLoading) {
            this.setState({
                isLoading: true
            });
        }
        let apiUrl = global.config.apiDomain + `/rest/midi-score/get-list-midi-youtube-song/${this.state.page}`;
        axios.get(apiUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            let midiRecording = res.data.models;
            let status = midiRecording.length > 0;

            this.setState((prevState) => ({
                midiRecording: prevState.midiRecording.concat(midiRecording),
                page: prevState.page + 1,
                statusButtonNextPage: status,
                isLoading: false,
                isInitialLoading: false,
                error: null
            }));
        })
        .catch(error => {
            this.setState({
                isLoading: false,
                isInitialLoading: false
            });
            console.error("Error:", error);
        });
    }

    stopMidiPlayback = async () => {
        try {
            this.player.stop();
            this.synth.releaseAll();
        } catch (error) {
            console.error('Lỗi khi dừng phát lại hoặc giải phóng MIDI:', error.message);
        }
    }

    handleMidi = async (midiPath) => {
        console.log("handleMidi được gọi với đường dẫn:", midiPath);
        try {
            await this.stopMidiPlayback();
            const response = await fetch(midiPath);
            const arrayBuffer = await response.arrayBuffer();
            const midiArray = new Uint8Array(arrayBuffer);
            this.player.loadArrayBuffer(midiArray);

            this.player.on('midiEvent', (event) => {
                console.log("Sự kiện MIDI:", event);
                if (event.name === 'Note on') {
                    console.log("Note On Event:", event);
                    const { noteNumber, velocity } = event;
                    const noteFrequency = Tone.Frequency(noteNumber, 'midi').toFrequency();
                    this.synth.triggerAttackRelease(noteFrequency, '8n');
                }
            });

            this.player.play();
        } catch (error) {
            console.error("Error loading or playing MIDI file:", error);
        }
    }

    render() {
        const { midiRecording, statusButtonNextPage, isLoading, isInitialLoading, countMidiRecording } = this.state;
    
        return (
            <>
                <div id="content">   
                    <div className="container-fluid">
                        <div className="container-fluid">
                            <h1 className="text-center">Danh sách chấm điểm bài thu</h1>
                        </div>
                        <div className="search-container">
                            <div style={{ minWidth: '80px' }} className="quantity">Số lượng: {countMidiRecording}</div>
                        </div>
                        {isInitialLoading ? (
                            <div className="spinner-container">
                                <div className="spinner"></div>
                            </div>
                        ) : midiRecording.length > 0 ? (
                            <table className="table table-hover">
                                <thead>
                                    <tr className="text-center">
                                        <th className="align-middle" scope="col">Id</th>
                                        <th className="align-middle" scope="col">Tên bài hát</th>
                                        <th className="align-middle" scope="col">File Midi</th>
                                        <th className="align-middle" scope="col">Link nhạc</th>
                                        <th className="align-middle" scope="col">Phát</th>
                                        <th className="align-middle" scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {midiRecording.map((item) => {
                                        return (
                                            <tr key={item.uid}>
                                                <td className="align-middle text-center" scope="col">{item.id}</td>
                                                <td className="align-middle text-center" scope="col">{item.songName}</td>
                                                <td className="align-middle text-center" scope="col">{item.midi}</td>
                                                <td className="align-middle text-center" scope="col">
                                                    <a href={item.songUrl} target="_blank" rel="noopener noreferrer">Nghe nhạc</a>
                                                </td>
                                                <td className="align-middle text-center" scope="col">
                                                    <div className="d-flex justify-content-center">
                                                        {item.midi && 
                                                            <button 
                                                                className="btn btn-primary mr-2" 
                                                                onClick={() => {
                                                                    console.log("click button");
                                                                    this.handleMidi(item.midi)
                                                                }}
                                                            >
                                                                Play
                                                            </button>
                                                        }
                                                        {item.midi && 
                                                            <button 
                                                                className="btn btn-danger mr-2" 
                                                                onClick={() => {
                                                                    this.stopMidiPlayback()
                                                                }}
                                                            >
                                                                Stop
                                                            </button>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <div className="d-flex justify-content-center">
                                                        <button
                                                            className='btn btn-success ml-2 mr-2'
                                                            onClick={() => window.open(`/admin/midi-recording/${item.id}`, '_blank')}
                                                        >
                                                            Danh sách bài thu có midi
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <MIDISounds ref={this.midiSoundsRef} appElementName="root" instruments={[3]} />
                                </tbody>
                            </table>
                        ) : (
                            <div className="text-center">
                                <p>Không có dữ liệu.</p>
                            </div>
                        )}
                        {
                            statusButtonNextPage &&
                            (<div onClick={this.getMidiRecordingList} className="view_more w-100 text-center mb-lg-4">
                                <a className="d-none d-md-block">
                                    <span>{isLoading ? "ĐANG TẢI..." : "TẢI THÊM"}</span>
                                </a>
                            </div>)
                        }
                    </div>
                </div>
            </>
        );              
    }    
}
