import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import ModalShowImage from './components/ModalShowImage';
import ModalShowSVGA from './components/ModalShowSVGA';
import ModalCreate from './components/ModalCreate';
import ModalHide from './components/ModalHide';
import ModalDelete from './components/ModalDelete';

import { toast } from 'react-toastify';

export default class DeepAREffectPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deepAREffectModels: [],
            stickerModel: null,
            showImage: null,
            register:false
        };

        this.loadItem = this.loadItem.bind(this);
        this.hideOrActive = this.hideOrActive.bind(this);

    }

    loadItem(){
        axios.post(global.config.apiDomain + "/rest/deepAREffect/getAll")
        .then(res =>{
            if(res.data.status == "OK"){
                // console.log('getAll');
                // console.log(res.data.deepAREffectModels);
                this.setState({
                    deepAREffectModels: res.data.deepAREffectModels
                })
            }
        })

    }

    componentDidMount() {      
        this.loadItem();
    }

    hideOrActive(event) {
        let index = event.target.name;
        let stickerModel = null;
        if (index === "-1") {
            stickerModel = {
                _id: '',
                name: '',
                thumbnailUrl: '',
                resourceUrl: '',
                minVipLevel: 0,
                minLevel: 0,
                order: 0,
                colorFilter: {
                    _id: '',
                    name: '',
                    value: 0
                },
                beautyFilters: []
            }
        } else {
            stickerModel = this.state.deepAREffectModels[index];
        }

        this.setState({
            stickerModel: stickerModel
        })
    }

    render() {
        const { deepAREffectModels, stickerModel, showImage, register } = this.state;

        return (
            <>
                <ModalCreate 
                    stickerModel={stickerModel} 
                    register={register}
                    resetList={this.loadItem}
                ></ModalCreate>
                <ModalHide 
                    stickerModel={stickerModel} 
                    register={register} 
                    resetList={this.loadItem}
                ></ModalHide>
                <ModalDelete
                    stickerModel={stickerModel} 
                    register={register} 
                    resetList={this.loadItem}
                ></ModalDelete>
                <ModalShowImage image={showImage}></ModalShowImage>
                <ModalShowSVGA image={showImage}></ModalShowSVGA>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí Hiệu ứng DeepAR</h1>
                        <button
                            className="btn btn-info mb-1"
                            data-toggle="modal"
                            data-target="#modalCreateDeepAr"
                            name="-1"
                            onClick={this.hideOrActive}
                        >+ Tạo Mới Vật Phẩm
                        </button>
                        <table className="table table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th className="align-middle" scope="col">Ưu tiên</th>
                                    <th className="align-middle" scope="col">ID</th>
                                    <th className="align-middle" scope="col">Tên hiệu ứng</th>
                                    <th className="align-middle" scope="col">Ảnh Mô tả</th>
                                    <th className="align-middle" scope="col">Ảnh Vật phẩm</th>
                                    <th className="align-middle" scope="col">Level</th>
                                    <th className="align-middle" scope="col">Level Vip</th>
                                    <th className="align-middle" scope="col">Bộ lọc màu</th>
                                    <th className="align-middle" scope="col" colSpan={3}>Bộ lọc trang điểm</th>
                                    <th className="align-middle" scope="col" colSpan={2}>Tùy chỉnh</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    deepAREffectModels && deepAREffectModels.map((stickerModel, index) => {
                                        return (
                                            <tr>
                                                <td className="align-middle text-center" scope="col">{stickerModel.enable ? stickerModel.order : <del>{stickerModel.order}</del>}</td>
                                                <td className="align-middle text-center" scope="col">{stickerModel.enable ? stickerModel._id : <del>{stickerModel._id}</del>}</td>
                                                <td className="align-middle text-center" scope="col">{stickerModel.enable ? stickerModel.name : <del>{stickerModel.name}</del>}</td>
                                                <td className="align-middle text-center" scope="col"><img src={stickerModel.thumbnailUrl} width={100} alt="" /></td>
                                                <td className="align-middle text-center" scope="col" style={{ width: '150px' }}>
                                                    {stickerModel.resourceUrl.substring(stickerModel.resourceUrl.lastIndexOf("/"), stickerModel.resourceUrl.length)}
                                                </td>
                                                <td className="align-middle text-center" scope="col">{stickerModel.enable ? stickerModel.minLevel : <del>{stickerModel.minLevel}</del>}</td>
                                                <td className="align-middle text-center" scope="col">{stickerModel.enable ? stickerModel.minVipLevel : <del>{stickerModel.minVipLevel}</del>}</td>

                                                <td className="align-middle text-center" scope="col">
                                                    <p className="text-left text-danger">{stickerModel.colorFilter ? stickerModel.colorFilter.name : ''}</p>
                                                </td>
                                                <td className="align-middle text-center" scope="col">
                                                    {stickerModel.beautyFilters? stickerModel.beautyFilters.map(filter =>(<p className="text-left text-secondary" style={{ height: '18px', width: '110px' }}>{filter.name}</p>)) : ''}
                                                </td>
                                                <td className="align-middle text-center" scope="col">
                                                    {stickerModel.beautyFilters? stickerModel.beautyFilters.map(filter =>(<p className="text-right text-success" style={{ height: '18px' }}>{filter.value}</p>)) : ''}
                                                </td>
                                                <td className="align-middle text-center" scope="col">
                                                    {stickerModel.beautyFilters? stickerModel.beautyFilters.map(filter =>{
                                                        const regexColor = /^#(?:[0-9a-fA-F]{3}){1,2}$/g;
                                                        if (regexColor.test('#' + filter.extraProperty)) {
                                                            return <p style={{ height: '18px', width: '70px', backgroundColor: '#'+filter.extraProperty}}></p>
                                                        } else if (filter.extraProperty) {
                                                            return <a href={filter.extraProperty} style={{ height: '18px', width: '70px'}}>{filter.extraProperty}</a>
                                                        } else {
                                                            return <p style={{ height: '18px', width: '70px'}}></p>
                                                        }
                                                    }) : <p style={{ height: '18px', width: '70px'}}></p>}
                                                </td>

                                                <td className="align-middle text-center" scope="col">
                                                    {
                                                        !stickerModel.enable ? (
                                                            <button
                                                                className="btn btn-warning"
                                                                onClick={this.hideOrActive}
                                                                name={index}
                                                                data-toggle="modal"
                                                                data-target="#modalHide"
                                                            >
                                                                HIỆN
                                                            </button>
                                                        ) : (
                                                            
                                                             <button className='btn btn-success ml-2 mr-2'
                                                                data-toggle="modal"
                                                                data-target="#modalCreateDeepAr"
                                                                onClick={this.hideOrActive}
                                                                name={index}
                                                            >
                                                                SỬA
                                                            </button>
                                                        )
                                                    }
                                                </td>
                                                <td className="align-middle text-center" scope="col">
                                                    {
                                                        !stickerModel.enable ? (
                                                            <button
                                                                className="btn btn-danger"
                                                                onClick={this.hideOrActive}
                                                                name={index}
                                                                data-toggle="modal"
                                                                data-target="#modalDelete"
                                                            >
                                                                XÓA LUÔN
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="btn btn-warning"
                                                                onClick={this.hideOrActive}
                                                                name={index}
                                                                data-toggle="modal"
                                                                data-target="#modalHide"
                                                            >
                                                                ẨN
                                                            </button>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })

                                }

                            </tbody>
                        </table>
                    </div>
                </div>

            </>
        );
    }
}

