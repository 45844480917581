import React, {Component} from 'react';
import axios from 'axios';
import lodash from 'lodash';
import ModalUpdateluckyGift from './components/ModalUpdateLuckyGift';

export default class LuckyGiftManagerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            luckyGifts: [],
            luckyGiftSelected: null
        }

        this.getNewluckyGift = this.getNewluckyGift.bind(this);
        this.getAllLuckyGifts = this.getAllLuckyGifts.bind(this);
    }

    componentDidMount() {
        this.getAllLuckyGifts();

        window.$('#modalUpdateluckyGift').on('hide.bs.modal', function (e) {
            console.log(123)
            this.setState({
                luckyGiftSelected: null
            })
        }.bind(this))
    }

    getAllLuckyGifts() {
        axios.post(global.config.apiDomain + '/rest/luckyGifts')
        .then(res => {
            let luckyGifts = res.data;

            this.setState({
                luckyGifts: luckyGifts
            })
        });
    }

    getNewluckyGift() {
        axios.post(global.config.apiDomain + '/rest/luckyGifts/new', JSON.stringify(this.state.luckyGift), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                luckyGiftSelected: res.data
            })
        });
    }

    render() {
        const {luckyGifts, luckyGiftSelected} = this.state;

        return (
            <>
                <ModalUpdateluckyGift resetList={this.getAllLuckyGifts} luckyGift={luckyGiftSelected}></ModalUpdateluckyGift>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí bao lì xì</h1>
                        <button 
                            className="btn btn-red mb-1" 
                            data-toggle="modal" 
                            data-target="#modalUpdateluckyGift"
                            onClick={this.getNewluckyGift}
                            >+ Tạo lì xì
                        </button>
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th width={200} scope="col">Id</th>
                                <th scope="col">Background</th>
                                <th scope="col">Start date</th>
                                <th scope="col">End date</th>
                                <th scope="col">Counter</th>
                                <th scope="col">ID được hiển thị</th>
                                <th scope="col">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {luckyGifts.map((luckyGift, index) => {
                                return (
                                    <tr key={luckyGift.id}>
                                        <th scope="row">{luckyGift.id}</th>
                                        <td><img width={50} src={luckyGift.backgroundUrl} /></td>
                                        <td> {new Date(luckyGift.startDate).toLocaleDateString()} </td>
                                        <td> {new Date(luckyGift.endDate).toLocaleDateString()} </td>
                                        <td> {luckyGift.counter} </td>
                                        <td> {luckyGift.idAllowed} </td>
                                        <td>
                                            <button 
                                                onClick={() => {this.setState({luckyGiftSelected: luckyGift})}}
                                                className="btn btn-red"
                                                data-toggle="modal" 
                                                data-target="#modalUpdateluckyGift"
                                                >Sửa</button>
                                            {/* <button 
                                                className="btn btn-red ml-sm-2"
                                                data-toggle="modal" 
                                                data-target="#modalDeleteluckyGift"
                                                onClick={() => {this.setState({luckyGiftSelected: luckyGift})}}
                                                >Xóa</button> */}
                                        </td>
                                    </tr>                                
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}