import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';


import bindModel from '../../../common/bindModel.js';

export default class ModalUpdatebannerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            banner: null,
            previewImg: null,
            loading: false,
            ikaraStoreProduct:[],
        }
        this.saveBanner = this.saveBanner.bind(this);
        this.uploadThumbnailForWasabi = this.uploadThumbnailForWasabi.bind(this);
        this.handleChangeStartDay = this.handleChangeStartDay.bind(this);
        this.handleChangeEndDay = this.handleChangeEndDay.bind(this);
    }

    componentWillReceiveProps(props) {
        let banner = JSON.parse(JSON.stringify(props.banner));
        this.setState({
            banner : banner,
            previewImg: (banner && banner.thumbnail) ? banner.thumbnail : '1'
        })
        let ikaraStoreProduct = JSON.parse(JSON.stringify(props.ikaraStoreProduct));
        this.setState({
            ikaraStoreProduct: ikaraStoreProduct
        })
    }

  

    saveBanner() {
        let obj = {
            deleted: false,
            promoBannerId: this.state.banner.promoBannerId,
            thumbnail: this.state.banner.thumbnail,
            bannerTitle: this.state.banner.bannerTitle,
            notifyDay: this.state.banner.notifyDay,
            startDay: this.state.banner.startDay,
            endDay: this.state.banner.endDay,
            productName: this.state.banner.productName,
            price: this.state.banner.price,
            promoPrice: this.state.banner.promoPrice,
            bonus: this.state.banner.bonus,
            deepLink: this.state.banner.deepLink,
            productId: this.state.banner.productId,
        };

        if (obj.endDay === '') {
            obj.endDay = new Date().getTime();
        }

        if (obj.startDay === '') {
            obj.startDay = new Date().getTime();
        }

        if (obj.notifyDay === '') {
            obj.notifyDay = new Date().getTime();
        }
        
        console.table(obj);

        if (obj == null || 
            obj.bannerTitle == '' || 
            obj.thumbnail == '' ||
            obj.notifyDay == '' || 
            obj.startDay == '' || 
            obj.endDay == '' || 
            obj.productName == '' || 
            obj.price == '' || 
            obj.promoPrice == '' || 
            obj.bonus == '' || 
            obj.deepLink == '' ||
            obj.productId == ''
        ){
            toast.error("Vui lòng điền đầy đủ thông tin.");
            return;
        }

        if (obj.notifyDay > obj.startDay || obj.notifyDay > obj.endDay || obj.startDay > obj.endDay) {
            toast.error("Sai thứ tự ngày");
            return;
        }

        console.table(obj);

        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/promoBannerSellMic/createPromoBannerSellMic', obj)
        .then(res => {
            this.setState({
                loading: false
            });

            if (res.data.status == "OK") {
                window.$("#modalUpdateBanner").modal("hide");
                this.props.resetList();
                toast.success(res.data.message);
            } else {
                toast.warning(res.data.message);
            }
        }, error => {
            this.setState({
                loading: false
            });
            toast.error(error);
        })
    }
    
    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadThumbnailForWasabi(event) {

        this.setState({
            loading: true
        });

        var banner = this.state.banner;
        var target = event.target;
	    var bucketName = "ikara-data/images/promoBannerSellMic";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
		var keyName = this.guid() + "." + extension;
		var contentType = "image/jpeg";
		if (extension == "png") contentType = "image/png";
        let file = event.target.files[0];
        console.log(file);

		var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', file);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType);
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                   console.log("data "+data);
                   console.log("banner"+this.state.banner);
                    this.setState({
                        loading: false
                    });

                       this.state.banner.thumbnail = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        banner : this.state.banner,
                        previewImg : this.state.banner.thumbnail
                    });
                }.bind(this),
            });
        }.bind(this);
        fileReader.readAsArrayBuffer(file);
    }

    handleChangeStartDay(value) {
        let time = Date.parse(value);
        console.log("step 1: " + time);
        this.setState(prevState => ({
            banner: {
                ...prevState.banner,
                startDay: time
            }
        }), () => {
            console.log("new data: " + time);
            console.log("new date: " + this.state.banner.startDay);
        });
        
    }

    handleChangeEndDay(value) {
        let time = Date.parse(value);
        this.setState(prevState => ({
            banner: {
                ...prevState.banner,
                endDay: time
            }
        }), () => {
            console.log("new data: " + time);
            console.log("new date: " + this.state.banner.endDay);
        });
        
    }

    handleChangeNotifyDay(value) {
        let time = Date.parse(value);
        console.log("step 1: " + time);
        this.setState(prevState => ({
            banner: {
                ...prevState.banner,
                notifyDay: time
            }
        }), () => {
            console.log("new data: " + time);
            console.log("new date: " + this.state.banner.notifyDay);
        });
        
    }

    productNameHandler(value) {
        console.log("value: " + value);
        this.setState(prevState => ({
            banner: {
                ...prevState.banner,
                productName: value
            }
        }), () => {

        });
        let product = this.state.ikaraStoreProduct.filter(item => item.name === value);
        if (product && product.length === 0) {
            this.setState(prevState => ({
                banner: {
                    ...prevState.banner,
                    productName: "",
                    price: "",
                    productId: "",
                }
            }), () => {
    
            });
        } else {
            this.setState(prevState => ({
                banner: {
                    ...prevState.banner,
                    price: product[0].price,
                    productId: product[0].product_id,
                }
            }), () => {
    
            });
        }

        
    }

    render() {
        const model = bindModel(this);
        const {banner,loading} = this.state;
        const startDate = banner && banner.startDay ? new Date(banner.startDay) : new Date();
        const startDateString = startDate.toLocaleString("sv", { timeZone: "Asia/Ho_Chi_Minh" }).replace(" ", "T");
        const endDate = banner && banner.endDay ? new Date(banner.endDay) : new Date();
        const endDateString = endDate.toLocaleString("sv", { timeZone: "Asia/Ho_Chi_Minh" }).replace(" ", "T");
        const notifyDate = banner && banner.notifyDay ? new Date(banner.notifyDay) : new Date();
        const notifyDateString = notifyDate.toLocaleString("sv", { timeZone: "Asia/Ho_Chi_Minh" }).replace(" ", "T");

        return (
        <div>
            <div className="modal fade" id="modalUpdateBanner" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật Banner Khuyến Mãi</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div id="thumbnail" className="row mb-2">
                                <div className="col-3">
                                    <label>Thumbnail</label>
                                </div>
                                <div className={(this.state.banner && this.state.banner.thumbnail) ? "col-3" : "col-9"}>
                                    <input
                                        className="custom-file-input"
                                        type="file"
                                        name="thumbnail"
                                        onChange= {this.uploadThumbnailForWasabi}
                                    />
                                    <label className="custom-file-label ml-3 mr-3" htmlFor="thumbnail">Choose file</label>
                                </div>
                                {(this.state.banner && this.state.banner.thumbnail)
                                    ? 
                                    <div className="col-6">                                     
                                        <img width={200} src={this.state.banner.thumbnail} alt="#" />
                                    </div> 
                                    :<></>
                                }
                            </div>
                            <div id="url" className="row mb-2">
                                <div className="col-3">
                                    <label>Tiêu đề Banner</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        className="form-control mb-2"
                                        type="text"
                                        name="bannerTitle"
                                        placeholder="Nhập tiêu đề Banner..."
                                        {...model('banner.bannerTitle')}
                                    />
                                </div>
                            </div>
                            <div id="url" className="row mb-2">
                                <div className="col-3">
                                    <label>Ngày Thông báo</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        className="form-control mb-2"
                                        type="datetime-local"
                                        name="notifyDay"
                                        value={notifyDateString}
                                        onChange={(e) =>this.handleChangeNotifyDay(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div id="url" className="row mb-2">
                                <div className="col-3">
                                    <label>Ngày bắt đầu</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        className="form-control mb-2"
                                        type="datetime-local"
                                        name="startDay"
                                        value={startDateString}
                                        onChange={(e) => this.handleChangeStartDay(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div id="url" className="row mb-2">
                                <div className="col-3">
                                    <label>Ngày kết thúc</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        className="form-control mb-2"
                                        type="datetime-local"
                                        name="endDay"
                                        value={endDateString}
                                        onChange={(e) => this.handleChangeEndDay(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div id="url" className="row mb-2">
                                <div className="col-3">
                                    <label>Tên vật phẩm</label>
                                </div>
                                <div className="col-9">
                                    <select
                                        className="form-control mb-2"
                                        name="productName"
                                        onChange={(e) => this.productNameHandler(e.target.value)}
                                    >
                                        <option value="">{(banner && banner.productName) ? banner.productName : "Chọn vật phẩm..."}</option>
                                        {this.state.ikaraStoreProduct.map((item, index) => (
                                            !banner || !banner.productName || banner.productName !== item.name ? (
                                                <option key={index} value={item.name}>{item.name}</option>
                                            ) : null
                                        ))}
                                    </select>

                                </div>
                            </div>
                            <div id="url" className="row mb-2">
                                <div className="col-3">
                                    <label>Giá</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        className="form-control mb-2"
                                        type="number"
                                        name="price"
                                        disabled={true}
                                        placeholder="Nhập giá..."
                                        {...model('banner.price')}
                                    />
                                </div>
                            </div>
                            <div id="url" className="row mb-2">
                                <div className="col-3">
                                    <label>Giá Khuyến mãi</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        className="form-control mb-2"
                                        type="number"
                                        name="promoPrice"
                                        placeholder="Nhập giá khuyến mãi..."
                                        {...model('banner.promoPrice')}
                                    />
                                </div>
                            </div>
                            <div id="url" className="row mb-2">
                                <div className="col-3">
                                    <label>Khuyến mãi kèm theo</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        className="form-control mb-2"
                                        type="text"
                                        name="bonus"
                                        placeholder="Nhập khuyến mãi kèm theo..."
                                        {...model('banner.bonus')}
                                    />
                                </div>
                            </div>
                            <div id="url" className="row mb-2">
                                <div className="col-3">
                                    <label>DeepLink</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        className="form-control mb-2"
                                        type="url"
                                        name="deepLink"
                                        placeholder="Nhập deepLink..."
                                        {...model('banner.deepLink')}
                                    />
                                </div>
                            </div>
                           
                        </div> 
                        <div className="modal-footer">
                            <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                            {loading ?
                                (
                                    <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Xác nhận...
                                    </button>
                                ) :
                                (
                                    <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.saveBanner}>Xác nhận</button>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}