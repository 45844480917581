import React, { Component } from 'react';
import TopUsersItem from  './TopUsersItem.js'
import {Link} from "react-router-dom";

import {withTranslation} from 'react-i18next';

class TopUsers extends Component {
	render() {
		const {topUsers, t} = this.props;
		return (
			<div className="chuyenmuc top-artist-home">
				<h2 className="subtitle">
					<Link to="/top-users">{t("home_page.top_users","Danh ca")}</Link>
					<i className="fa fa-angle-right" aria-hidden="true" />
				</h2>
				<div className="row casihot-content list-singer">
					{topUsers && topUsers.map((user) => (
						<TopUsersItem 
							key={user.facebookId} 
							user={user}
						></TopUsersItem>
					))}
					
				</div>
			</div>
		);
	}
}

export default withTranslation()(TopUsers)
