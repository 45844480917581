import React, {Component} from 'react';
import bindModel from '../../../common/bindModel.js'
import axios from 'axios';
import {toast} from 'react-toastify';
import validateNumber from '../../../common/validate.js'

const $ = window.$;
export default class ModalHandleErrorTransaction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            uid: ""
        }
        this.handleRecharge = this.handleRecharge.bind(this);
    }


    handleRecharge() {
        if(!validateNumber(this.state.uid)) {
            toast.warning("Vui lòng nhập số");
            return;
        }


        this.setState({
            loading: true
          });
        let json = {
            idSms: this.props.id,
            sender: this.props.sender,
            uid: this.state.uid
        }
  
        axios.post(global.config.apiDomain + '/rest/recharge/handle', JSON.stringify(json), {
              headers: {
                  'Content-Type': 'application/json'
              }
        }).then(res => {
              this.setState({
                loading: false
              });
              if(res.data.status == "OK") {
                    $('#modalHandleRecharge').modal('hide');
                    toast.success(res.data.message);
                    this.props.resetList();
              } else {
                  toast.warning(res.data.message);
              }
          }, err => {
            //   $('#modalHandleRecharge').modal('hide');
              this.setState({
                loading: false
              });
              toast.error("Xử lí thất bại");
          })
    }

    render() {
        const model = bindModel(this);

        return (
            <div>
                <div className="modal fade" id="modalHandleRecharge" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-center" id="exampleModalLabel">Nhập ID khách hàng</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <input {...model('uid')} type="text" className="form-control" placeholder="Nhập ID khách hàng" />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                        {this.state.loading ? 
                        (
                        <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Xác nhận...
                        </button>
                        ) :
                        (
                        <button 
                            style={{width: "120px"}} 
                            type="button" 
                            className="btn btn-red"
                            onClick={this.handleRecharge}>Xác nhận</button>
                        )}
                    </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}