import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

export default class YoutubeMidiPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      songs: [],
      cursor: null,
      loading: false,
      statusButtonLoadPage: false
    }

    this.getSongs = this.getSongs.bind(this);
    this.changeStatusProcessing = this.changeStatusProcessing.bind(this);
  }

  // componentWillReceiveProps() {
  //   this.getSongs();
  // }
  
  componentDidMount() {
    this.getSongs();
  }

  getSongs() {
    const { loading } = this.state;
    if (!loading) {
      this.setState({
        loading: true
      });
      let json = {
        cursor: this.state.cursor
      }
      axios.post(global.config.apiDomain + '/rest/youtubemidi/list', json)
        .then(res => {
          let status = true;
          if (res.data.cursor == "END") {
            status = false;
          }

          this.setState({
            songs: this.state.songs.concat(res.data.songs),
            cursor: res.data.cursor,
            statusButtonLoadPage: status,
            loading: false
          })
         
        });
    }
  }

  changeStatusProcessing(recordingId) {
    axios.post(global.config.apiDomain + '/rest/youtubemidi/processing/' + recordingId)
        .then((res) => {});
  }
 
  getStatus(status) {
    switch(status) {
        case 'NEW':
            return <i className="fa-solid fa-ban fa-xl text-danger"></i>
        case 'PROCESSED':
            return <i class="fa-solid fa-circle-check fa-xl text-success"></i>
        case 'PROCESSING':
          return <i class="fas fa-cog fa-spin fa-xl text-primary"></i>
        case 'REVERTED':
            return <i className="fa-solid fa-refresh fa-xl text-secondary"></i>
        case 'CANCELED':
          return <i className="fa-solid fa-trash fa-xl text-secondary"></i>
        default:
            return <i className="fa-solid fa-ban fa-xl text-danger"></i>
    }
   
}

  render() {
    const { songs, statusButtonLoadPage, loading } = this.state;
    
    return (
      <>
        <div id="content">
          <div className="container-fluid">
          <h1 className="text-center">Danh sách Beat</h1>  
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Trạng thái</th>
                  <th scope="col">Thời gian</th>
                  <th scope="col">ID</th>
                  <th scope="col">Tên Beat</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {songs && songs.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="align-middle">{item.uid}</td>
                      <td className="align-middle">{this.getStatus(item.midiStatus)}</td>
                      <td className="align-middle">{new Date(item.addTime).toLocaleString()}</td>
                      <td className="align-middle"><strong>{item.videoId}</strong></td>
                      <td className="align-middle">{item.songName}</td>
                      <td className="align-middle" style={{width: "100px"}}>
                        <Link  to={'/admin/youtube-midi/detail/' + item.videoId}>
                          <button
                            onClick={() => this.changeStatusProcessing(item.videoId)} 
                            className='btn btn-success'
                          >
                            Xử lý
                          </button>
                        </Link>

                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {
              statusButtonLoadPage ?
                (<div onClick={this.getSongs} className="view_more w-100 text-center mb-lg-4">
                  <a className="d-none d-md-block">
                    <span>{loading ? "ĐANG TẢI ..." : "TẢI THÊM"}</span>
                  </a>
                  <a className="view_more_mobile d-md-none">
                    <i className="fa fa-angle-down" aria-hidden="true" />
                  </a>
                </div>) :
                ('')
            }
          </div>
        </div>
      </>
    );
  }
}