import React, { Component } from 'react';
import axios from 'axios';
import lodash from 'lodash';
import { Parser, Player } from 'svga'
import SVGA from 'svgaplayerweb'
import '../../../assets/css/giftmanager.css';
import $ from 'jquery';
import { Link } from "react-router-dom";
import ModalUpdateCommentFrame from './ModalUpdateCommentFrame';
import ModalShowSVGA from '../components/ModalShowSVGA';
import ModalShowGiftAnimation from '../components/ModalShowGiftAnimation';
import ModalDeleteShopItem from '../components/ModalDeleteShopItem';
import { toast } from 'react-toastify';


export default class CommentFrame extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gifts: [],
            gift: null,
            status: "",
            show: "",
            type: "",
            tag: "",
            currentPage: 1,
            itemPerPage: 12,
            // cursor: null,
            // statusButtonLoadPage: false,
            // loading: false
        }

        this.getGifts = this.getGifts.bind(this);
        this.loadMoreGifts = this.loadMoreGifts.bind(this);
    }

    componentWillMount() {
        this.getGifts();
    }

    componentDidMount() {
        $("#modalUpdateCommentFrame").on("hidden.bs.modal", function () {
            this.setState({
                gift: null
            });
        }.bind(this));

        console.log("Did mount")
        $("#modalShowGift").on("hidden.bs.modal", function () {
            this.setState({
                giftShow: null
            });
        }.bind(this));

        $("#modalShowGiftAnimation").on("hidden.bs.modal", function () {
            this.setState({
                giftShow: null
            });
        }.bind(this));

    }

    async loadSVGA(id, url) {
        const parser = new Parser()
        const svga = await parser.load(url)

        const player = new Player(document.getElementById(id))
        await player.mount(svga)

        player.onStart = () => console.log('onStart')

        player.start()
    }

    getGifts(reset) {
       
        let obj = {
            // cursor: this.state.cursor,
            typeString: "CommentFrame"
        }
        // if (reset == true) {
        //     obj.cursor = null
        // }
        axios.post(global.config.apiDomain + '/rest/shop-items/getItemWithPage', obj)
            .then(res => {
                // console.log('/rest/shop-items/getItemWithPage');
                // console.log(res);
                // let status = true;

                // if (res.data.cursor === "END") {
                //     status = false;
                // }

                this.setState({
                    gifts: res.data.listItem,
                    // cursor: res.data.cursor,
                    // statusButtonLoadPage: status,
                    // loading: false
                });
            });
    }

    transferMedalData(itemShop){
        let url = global.config.apiDomain + "/rest/shop-items/transferDataItemShop"
        this.setState({
            loading : true
        })

        axios.post(url,itemShop)
        .then(res =>{
            if(res.data.status == "OK"){
                toast.success(res.data.message)
            }else{
                toast.warning("Error: " + res.data.message)
            }
        })
    }

    loadMoreGifts() {
        // const { loading } = this.state;
        // if (!loading) {
        //     this.setState({
        //         loading: true
        //     });
        //     this.getGifts();
        // }
    }

    loadSVGA4(url, canvasId) {
        var parser = new SVGA.Parser('#' + canvasId);
        parser.load(url, function (videoItem) {
            var player = new SVGA.Player('#' + canvasId);
            console.log("videoItem")
            console.log(canvasId)
            console.log(player)
            console.log(videoItem)
            player.setVideoItem(videoItem);
            // player.setFrame(0, 0, 750, 750);
            player.setContentMode("AspectFill")
            player.startAnimation();
        })
    }

    componentDidUpdate() {
        const { gifts, currentPage, itemPerPage } = this.state;
        const indexOfLastItem = currentPage * itemPerPage;
        const indexOfFirstItem = indexOfLastItem - itemPerPage;
        const currentItems = gifts.slice(indexOfFirstItem, indexOfLastItem);

        currentItems.map((gift, index) => {
            let idResource = "show-animation_resourceUrl" + ( (currentPage -1) * itemPerPage + index);
            if (gift.resourceUrl != null && gift.resourceUrl.endsWith("svga")) {
                // $("#" + idResource).children().replaceWith("<div style=\" height: 40px !important\" id=\" " + canvasIdResource + "\"></div>");
                this.loadSVGA4(gift.resourceUrl, idResource);
            } else if (gift.resourceUrl != null) {
                $("#" + idResource).replaceWith("<img style=\"height: 50px; width: 50px; align: center; \" src=\"" + gift.resourceUrl + "\"  />");
            } else {
                $("#" + idResource).replaceWith("<img style=\"height: 50px; width: 50px; text-align: center;\" src=\"" + gift.resourceUrl + "\" onerror=\"this.onerror=null; this.src='error_image_url'; this.alt='Error Image';\" />");

            }
        })
    }

    render() {
        // const { gifts, statusButtonLoadPage, loading } = this.state;
        const { gifts, currentPage, itemPerPage } = this.state;

        const indexOfLastItem = currentPage * itemPerPage;
        const indexOfFirstItem = indexOfLastItem - itemPerPage;
        const currentItems = gifts.slice(indexOfFirstItem, indexOfLastItem);

        return (
            <>
                <ModalUpdateCommentFrame resetList={this.getGifts} gift={this.state.gift} ></ModalUpdateCommentFrame>
                <ModalShowGiftAnimation gift={this.state.giftShow}></ModalShowGiftAnimation>
                <ModalShowSVGA gift={this.state.giftShow}></ModalShowSVGA>
                <ModalDeleteShopItem resetList={this.getGifts} gift={this.state.gift}></ModalDeleteShopItem>

                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí Shop khung bình luận</h1>
                        <div>
                            <button
                                className="btn btn-red"
                                data-toggle="modal"
                                data-target="#modalUpdateCommentFrame"
                                onClick={() => {
                                    this.setState({
                                        gift: {
                                            dateEnd: new Date(),
                                            dateStart: new Date()
                                        },
                                    })
                                }}
                            >Thêm mới Item</button>

                            <Link className="btn btn-red ml-2" to="/admin/rank-items">
                                Biểu đồ tiêu thụ Item
                            </Link>


                        </div>
                        <table className="table table-hover">
                            <thead>
                                <tr className="align-middle">
                                    <th className="align-middle" scope="col">Độ ưu tiên</th>
                                    <th className="align-middle" scope="col">ID</th>
                                    <th className="align-middle" scope="col">Tên</th>
                                    <th className="align-middle" scope="col">Thời gian sử dụng</th>
                                    <th className="align-middle" scope="col">Mã màu của khung</th>
                                    <th className="align-middle" scope="col">Hình vật phẩm</th>
                                    <th className="align-middle" scope="col">Loại</th>
                                    <th className="align-middle" scope="col">Giá bán</th>
                                    <th className="align-middle" scope="col">Ngày bắt đầu</th>
                                    <th className="align-middle" scope="col">Ngày kết thúc</th>
                                    <th className="align-middle" scope="col">Level thường</th>
                                    <th className="align-middle" scope="col">LevelVip</th>
                                    <th className="align-middle" scope="col">ID được mua</th>
                                    <th className="align-middle" scope="col">Hành động</th>
                                    <th className="align-middle" scope="col">Chuyển đổi dữ liệu</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((gift, index) => {
                                    let idResource = "show-animation_resourceUrl" + ((currentPage -1) * itemPerPage + index);
                                    let result = (
                                        <tr key={gift._id}>
                                            <td><strong>{gift.order}</strong></td>
                                            <td>{gift._id}</td>
                                            <td>{gift.name}</td>
                                            <td>{gift.expiry}</td>
                                            <td>{gift.colorCode}</td>
                                            <td style={{ maxHeight: "50px", maxWidth: "50px" }}>
                                                <div >
                                                    <div id={idResource} style={{ maxHeight: "50px", maxWidth: "50px", align: 'center' }}>
                                                        <div width={50} height={50} id="canvas"></div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{gift.sourceType}</td>
                                            <td>{gift.price}</td>
                                            <td>{(new Date(gift.dateStart)).toLocaleString('en-GB')}</td>
                                            <td>{gift.dateEnd.toLocaleString() === "253,402,214,400,000" ? "VÔ HẠN" : (new Date(gift.dateEnd)).toLocaleString('en-GB')}</td>
                                            <td>{gift.lvRequire}</td>
                                            <td>{gift.vipRequire}</td>
                                            <td>{gift.idAllowed}</td>

                                            <td>
                                                <button
                                                    onClick={() => {
                                                        this.setState({
                                                            gift: gift,
                                                        })
                                                    }}
                                                    className="btn btn-info"
                                                    data-toggle="modal"
                                                    data-target="#modalUpdateCommentFrame"
                                                >Sửa</button>
                                                <button
                                                    onClick={() => {
                                                        this.setState({
                                                            gift: gift,
                                                        })
                                                    }}
                                                    className="btn btn-warning ml-sm-2"
                                                    data-toggle="modal"
                                                    data-target="#modalDeleteShopItem"
                                                >Xóa</button>
                                            </td>
                                            <td>
                                            <button
                                                    onClick={() => {
                                                        if(window.confirm("Bạn có chắc là muốn chuyển dữ liệu: " + gift.name + " ?")){
                                                            this.transferMedalData(gift)
                                                        }
                                                    }}
                                                    className="btn btn-warning ml-sm-2"
                                                >Chuyển</button>
                                            </td>
                                        </tr>
                                    )

                                    return result;
                                })}
                            </tbody>
                        </table>
                        <div className="pagination">
                            <button
                                onClick={() => this.setState({ currentPage: currentPage - 1 })}
                                disabled={currentPage === 1}
                            >
                                Prev
                            </button>
                            <span>{currentPage}</span>
                            <button
                                onClick={() => this.setState({ currentPage: currentPage + 1 })}
                                disabled={indexOfLastItem >= gifts.length}
                            >
                                Next
                            </button>
                        </div>
                        {/* {
                            statusButtonLoadPage ?
                                (<div onClick={this.loadMoreGifts} className="view_more w-100 text-center mb-lg-4">
                                    <a className="d-none d-md-block">
                                        <span>{loading ? "ĐANG TẢI ..." : "TẢI THÊM"}</span>
                                    </a>
                                    <a className="view_more_mobile d-md-none">
                                        <i className="fa fa-angle-down" aria-hidden="true" />
                                    </a>
                                </div>) :
                                ('')
                        } */}
                    </div>
                </div>
            </>
        );
    }
}
