import { options } from 'numeral';
import React, { Component } from 'react';
import Firebase from '../../components/Firebase.js';
export default class SubscribeConsoleLogPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            server: "data5",
            logs: [],
            cursor: null,
            lastPage: false,
            listen: null,
            isFirst: true,
            dateTime: "999999999999999"
        }

        this.ikaraNode = Firebase.database().ref("ikara");
        this.getLogs = this.getLogs.bind(this);
        this.listenNodeLogFirebase = this.listenNodeLogFirebase.bind(this);
        this.removeAllLogs = this.removeAllLogs.bind(this);

    }

    componentWillMount() {
        this.getLogs();
        // this.listenNodeLogFirebase();


    }

    removeAllLogs() {
        var adaRef = this.ikaraNode
            .child("console")
            .child("Subscribe")
            .child(this.state.server);
        adaRef.remove()
            .then(function () {
                console.log("Remove succeeded.")
            })
    }

    listenNodeLogFirebase() {
        this.state.listen = this.ikaraNode
            .child("console")
            .child("Subscribe")
            .child(this.state.server)
            .limitToLast(1);


        this.state.listen.on("child_added", snapshot => {
            let logs = [];

            let log = snapshot.val();
            log.key = snapshot.key;
            console.log(snapshot.val());

            if (!this.state.logs.some(o => o.key == log.key)) {
                logs.unshift(log);
            }
            // logs.unshift(log);
            this.setState({
                logs: logs.concat(this.state.logs)
            })

        })
    }


    getLogs() {
        let { cursor, lastPage, dateTime } = this.state;
        let results;
        // let count = 0;
        // if (cursor == null) {
        //     results = this.ikaraNode
        //         .child("console")
        //         .child("Subscribe")
        //         .child(this.state.server)
        //         .orderByKey()
        //         .limitToFirst(20000)
        //         .get();
        // } else {
        //     results = this.ikaraNode
        //         .child("console")
        //         .child("Subscribe")
        //         .child(this.state.server)
        //         .orderByKey()
        //         .startAt(cursor)
        //         // .endAt(cursor)
        //         .limitToLast(1000)
        //         .get();
        // }

        // results
        //     .then(snapshot => {
        //         console.log(snapshot.val())
        //         const { logs } = this.state;
        //         snapshot.forEach(value => {
        //             Firebase.database().ref("ikara/console/Subscribe/data5/" + value.key).remove();
        //             console.log(count++);
        //             var log = value.val();
        //             log.key = value.key;
        //             cursor = log.key;

        //             if (!this.state.logs.some(o => o.key == log.key)) {
        //                 logs.unshift(log);
        //             }

        //         });
        //         if (this.state.cursor != null) {
        //             logs.splice(0, 1);
        //         }

        //         this.setState({
        //             logs: logs,
        //             cursor: cursor
        //         });
        //         if(this.state.isFirst) {
        //             this.state.isFirst = false;
        //             this.listenNodeLogFirebase();
        //         }
        //     });;




        results = this.ikaraNode
            .child("console")
            .child("Subscribe")
            .child(this.state.server)
            .orderByChild("dateTime")
            .endAt(dateTime)
            .limitToLast(200);

        console.log(dateTime);


        results.on("value", snapshot => {
            console.log(snapshot.val())
            const logs = [];
            snapshot.forEach(value => {
                var log = value.val();
                log.key = value.key;
                // Firebase.database().ref("ikara/console/Subscribe/data5/" + value.key).remove();

                dateTime = log.dateTime;
                if (!this.state.logs.some(o => o.key == log.key)) {
                    logs.unshift(log);
                }
            });

            console.log(logs)

            if (logs.length > 0) {
                this.state.dateTime = logs[logs.length - 1].dateTime;
            }


            this.setState({
                logs: this.state.logs.concat(logs),
                cursor: cursor
            });

            if (this.state.isFirst) {
                this.state.isFirst = false;
                this.listenNodeLogFirebase();
            }

            results.off();
        });

    }

    render() {
        return (
            <div id="content">
                <div className="container">
                    <h2 className="text-center">Subscribe console</h2>
                    <div>
                        <button className='btn btn-red' onClick={this.removeAllLogs}>Remove all logs</button>
                    </div>
                    <div className="row justify-content-center m-2">

                    </div>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Date time</th>
                                <th scope="col">Content</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.logs.map(log => {
                                return (
                                    <tr key={log.key}>
                                        <td >{new Date(log.dateTime).toLocaleString()}</td>
                                        <td style={{ "wordBreak": "break-all" }}>{log.text}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="text-center mb-2">
                        <button className="btn btn-primary text-center" onClick={this.getLogs}>Load more</button>
                    </div>
                </div>
            </div>
        );

    }
}