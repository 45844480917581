import React, {Component} from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';

export default class GameLevel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }

        this.removeGameLevel = this.removeGameLevel.bind(this);
        this.setLoading = this.setLoading.bind(this);
    }

    setLoading(value) {
        this.setState({
            loading: value
        })
    }

    removeGameLevel(gameLevelId) {
        if(window.confirm("Bạn có chắc chắn muốn xóa câu hỏi này không")) {
            this.setLoading(true);
            axios.post(global.config.apiDomain + '/rest/game/level/delete', JSON.stringify(gameLevelId), {
                headers : {
                  'Content-Type': 'application/json'
                }}).then((res) => {
                    const {status, message} = res.data;
                    
                    if(status == "OK") {
                        this.props.getGameLevels();
                        toast.success(message);
                    } else {
                        toast.error(message);
                    }
                    this.setLoading(false);
                }
            )
        }
    }


    render() {
        const {gameLevels} = this.props;
        const {loading} = this.state;
        console.log(gameLevels)
        return (
            <table className="table">
                <thead>
                    <tr>
                    <th scope="col">Level</th>
                    <th scope="col">Thể loại</th>
                    <th scope="col">Xem chi tiết</th>
                    </tr>
                </thead>
                <tbody>
                    {gameLevels.map((gameLevel, i) => {
                        return (
                            <tr>
                                <th scope="row">{gameLevel.level}</th>
                                <td>
                                    {
                                        gameLevel.gameTypes.map((gameType, i) => {
                                            return <>{gameLevel.gameTypes.length - 1 == i ? gameType.name : gameType.name + ", "}</>
                                        })
                                    }
                                </td>
                                <td>
                                    <Link
                                        style={{ width: "120px" }}
                                        to={"/admin/game-manager/level/" + gameLevel.id}
                                        className="btn btn-primary">
                                        Chi tiết <i className="fa fa-refresh" aria-hidden="true"></i>
                                    </Link>
                                </td>
                                <td>
                                {loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-danger" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xóa <i className="fa fa-trash" aria-hidden="true"></i>
                                        </button>
                                    ) :
                                    (
                                        <button
                                            onClick={() => this.removeGameLevel(gameLevel.id)}
                                            style={{ width: "120px" }}
                                            className="btn btn-danger">
                                            Xóa <i className="fa fa-trash" aria-hidden="true"></i>
                                        </button>
                                    )
                                }
                                </td>
                            </tr>
                        )
                    })
                    }
                </tbody>
            </table>
        );
    }
}