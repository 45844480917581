import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { update } from 'lodash';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Parser, Player } from 'svga'
import SVGA from 'svgaplayerweb'
// import ModalUpdateVipPrivilege from './component/CreateAndUpdateEvenLevelPrivilege';
import ModalCreate from './CreateOrupdate'
class BotRecording extends Component {

    constructor(props) {
        super(props);
        const { id } = this.props.match.params;
        this.state = {
            recordings: [],
            recording:null,
            update:0,
            user:id
        }
        this.getListRecording = this.getListRecording.bind(this);
        this.addNewRecording = this.addNewRecording.bind(this);
        this.updateRecording = this.updateRecording.bind(this);
    }
    componentDidMount() {
        this.getListRecording();
    }
//global.config.apiDomain + 
addNewRecording() {
    var recording = {
        id:0,
        thumbnail:'',
        addTime:'',
        status:2,
        mixedVideoUrl:'',
        recordingType:'',
        performanceType:'',
        message:'',
        songId:''
        
    };
    var json = {
        id: this.state.user
    }

    axios.post(global.config.apiDomain + '/rest/admin/getAccount', JSON.stringify(json), {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => {
        recording.thumbnail = res.data && res.data.avatar ? res.data.avatar : null;
        console.log()
        if (res.data.status == "OK") {
            recording.thumbnail = res.data.avatar
        } else {
            toast.error(res.data.message);
        }
    }, err => {
        this.setState({
            loading: false,
        });
        toast.error("ERR");
    });


    this.setState({
        recording : recording,
        update:0,
    })
}
updateRecording(event) {
    let id =event.target.name
    axios.post(global.config.apiDomain + '/rest/idol-recording/detail',{
        id:id,
    })
    .then(res => {
            this.setState({
                recording: res.data,
                update:1,
                
            })
            
        
    })
}

    getListRecording() {
        axios.post(global.config.apiDomain + '/rest/idol-recording/get-bot-recording',{
        userId :this.state.user
        })
        .then(res => {
            if(res.data.status=="OK"){
                this.setState({
                    recordings: res.data.recordings,
                    update:0,
                    
                })
            }
        })
    }
    deleteRecording=(event) => {  
        let index = event.target.name;
        let id =event.target.value;
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn có chắc chắn muốn tiếp tục không?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'Hủy bỏ',
            allowOutsideClick: false,
            preConfirm: (code) =>{
                axios.post(global.config.apiDomain + '/rest/idol-recording/delete',{
                    id:id,
                })
                .then(res => {
                    if(res.data.status=="OK"){
                    toast.success("Xóa thành công") 
                    const recordings = [...this.state.recordings]; 
                    recordings.splice(index, 1); 
                    this.setState({recordings}); 
                    }
                    else{
                        toast.error("Đã xảy ra lỗi")
                    } 
                })
           
            }
    })
    }
render() {
    const {update , recordings , recording ,user} = this.state;
    return (
        <>
            <ModalCreate
                recording={recording}
                update={update}
                user={user}
                resetList={this.getListRecording}
            >                
            </ModalCreate>
            <div id="content">
                <div className="container-fluid">
                    <h1 className="text-center">Danh sách bài thu</h1>
                    <button 
                        className="btn btn-red mb-1" 
                        data-toggle="modal" 
                        data-target="#modalUpdateVipPrivilege"
                        onClick={this.addNewRecording}
                        >+ Thêm Recording
                    </button>
                    <table className="table table-hover">
                        <thead>
                        <tr className="">
                        <th className="align-middle text-center" scope="col-3"style={{width:'8%'}}>ID</th>
                            <th className="align-middle text-center" scope="col-3"style={{width:'15%'}}>ThumbNail</th>
                            <th className="align-middle text-center" scope="col-3"style={{width:'15%'}}>Ngày Đăng</th>  
                            <th className="align-middle text-center" scope="col-6" style={{width:'15%'}}>message</th>  
                            <th className="align-middle text-center" scope="col-6" style={{width:'8%'}}>PerformanceType</th>  
                            <th className="align-middle text-center" scope="col-3" style={{width:'15%'}}>Link bài thu</th>  
                            <th className="align-middle text-center" scope="col">Hành Động</th>
                        </tr>
                        </thead>
                        <tbody>                            {
                            recordings && recordings.map((item,index) => {
                                return (
                                    <>      
                                                    <tr >
                                                          <td className='align-middle text-center'>{item.id}</td>
                                                            <td className='align-middle text-center'><img style={{height:'50px', width:'50px'}} src={item.thumbnail}></img></td> 
                                                            <td className='align-middle text-center'>{item.addTime}</td> 
                                                            <td className='align-middle text-center'>{item.message}</td> 
                                                            <td className='align-middle text-center' >{item.performanceType}</td>
                                                            <td className='align-middle text-center' >{item.mixedVideoUrl}</td>
                                                            
                                                                <td  className='align-middle text-center'>
                                                        <button 
                                                            className="btn btn-success mr-2"
                                                            onClick={this.updateRecording}
                                                            data-toggle="modal" 
                                                            name={item.id}
                                                            data-target="#modalUpdateVipPrivilege"
                                                            
                                                        >
                                                            Sửa
                                                        </button>
                                                        <button 
                                                            className="btn btn-danger"
                                                            onClick={this.deleteRecording}
                                                            name={index} 
                                                            value={item.id}                                                                
                                                        >
                                                            Xoá
                                                        </button>
                                                        </td>
                                                    </tr>                               
                                </>
                        
                                )
                        }
                            )    
                      }

                        </tbody>
                    </table>
                </div>
            </div>

        </>
    );
}
}
export default BotRecording