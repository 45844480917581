import React, { Component } from 'react';
import {Link} from "react-router-dom";
import {withTranslation} from 'react-i18next';

import TopRecordingsItem from './TopRecordingsItem.js';



class TopRecordings extends Component {

	render() {
		const {topRecordings} = this.props;
		const {t} = this.props;
		return (
			<div className="chuyenmuc top-record-home">
				<h2 className="subtitle">
					<Link to="/top-recordings"> {t("home_page.top_recordings","Bảng xếp hạng bài thu")} </Link>
					<i className="fa fa-angle-right" aria-hidden="true" />
				</h2>
				<div className="wrap-top-record-home">
					<div className="row no-gutters">
						<div className="col-lg-6">
							<div className="list-top top-5">
								{topRecordings && topRecordings.slice(0, 5).map((recording, index) => (
									<TopRecordingsItem 
										key={recording._id}
										index={index + 1} 
										recording={recording}
									>
									</TopRecordingsItem>
								))}
								
							</div>
						</div>
						<div className="col-lg-6">
							<div className="list-top">
								{topRecordings && topRecordings.slice(5, 10).map((recording, index) => (
									<TopRecordingsItem 
										key={`${index} - 5 - ${recording.recordingUrl}`}
										index={6+index} 
										recording={recording}
									>
									</TopRecordingsItem>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(TopRecordings)
