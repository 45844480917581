import React, { Component } from 'react';
import axios from 'axios';
import ModalUpdateCategory from './ModalUpdateCategory'
import ModalDeleteCategory from './ModalDeleteCategory';
import { toast } from 'react-toastify';


class Categories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: null,
            category: null,
            showDeleted: false,
        };

        this.getCategory = this.getCategory.bind(this);
        this.changeCategory = this.changeCategory.bind(this);
        this.getCategories = this.getCategories.bind(this);
    }

    getCategories(data) {
        let modal = {
            isDelete: data
        };

        axios.post(global.config.apiDomain + '/rest/categories/getAllCategory', modal).then((res) => {
            console.log('getCategories');
            console.log(res);
            this.setState({
                categories: res.data.categories
            });
        }).catch((error) => {
            console.log('error get categories ====');
            console.log(error);
        });
    }

    getCategory(event) {
        let index = event.target.dataset.id;
        console.log('getCategory');
        let category = {
            id: "",
            name: "",
            rank: "",
            description: "",
            previewImg: "",
        }
        if (index != -1) {
            category = this.state.categories[index]
        } 

        this.setState({
            category: category
        });
    }

    changeCategory(event) {
        this.props.updateShowCategory({
            show: false,
            categoryId: event.target.dataset.id
        })
    }

    showEditGroup() {
        let editGroup = document.getElementsByClassName("editGroup");
        for (let i = 0; i < editGroup.length; i++) {
            if (editGroup[i].style.display === "none") {
                editGroup[i].style.display = "block";
            } else {
                editGroup[i].style.display = "none";
            }
        }
    }

    componentDidMount() {
        this.getCategories(false);
    }

    render() {
        const { categories, category, showDeleted } = this.state;

        return (
            <>
                <div id="content">
                    <ModalUpdateCategory
                        category={category}
                        resetCategories={this.getCategories}
                    ></ModalUpdateCategory>
                    <ModalDeleteCategory
                        category={category}
                        resetCategories={this.getCategories}
                    ></ModalDeleteCategory>

                    <div className="container">
                        <h1 className="text-center">Quản lí Chủ đề Beat</h1>
                        <button
                            className="btn btn-primary mb-1"
                            data-toggle="modal"
                            data-target="#modalUpdateCategory"
                            data-id= '-1'
                            onClick={this.getCategory}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard2-plus" viewBox="0 0 16 16">
                                <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z"></path>
                                <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z"></path>
                                <path d="M8.5 6.5a.5.5 0 0 0-1 0V8H6a.5.5 0 0 0 0 1h1.5v1.5a.5.5 0 0 0 1 0V9H10a.5.5 0 0 0 0-1H8.5V6.5Z"></path>
                            </svg>
                            Tạo Chủ đề mới
                        </button>

                        <button
                            style={{ marginLeft: '30px' }}
                            type="button"
                            className="btn btn-info mb-1"
                            onClick={this.showEditGroup}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"></path>
                            </svg>
                            Thay đổi
                        </button>

                        {!showDeleted ? 
                            <button
                                style={{ marginLeft: '30px' }}
                                type="button"
                                className="btn btn-danger mb-1"
                                onClick={() => {
                                    this.setState({
                                        showDeleted: true
                                    })
                                    this.getCategories(true)
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="fas fa-archive" viewBox="0 0 16 16">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"></path>
                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"></path>
                                </svg>
                                Đã xóa
                            </button>
                            :
                            <button
                                style={{ marginLeft: '30px' }}
                                type="button"
                                className="btn btn-success mb-1"
                                onClick={() => {
                                    this.setState({
                                        showDeleted: false
                                    })
                                    this.getCategories(false)
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="fas fa-archive" viewBox="0 0 16 16">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"></path>
                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"></path>
                                </svg>
                                Hiện tại
                            </button>
                        }

                        <div className="row">
                            <div className="col-md-12">
                                <div id="homepage-deal" className="group-deal-2 filter fillter-home">
                                    <div className="row pt-2">
                                        {
                                            categories && categories.map((value, index) => (
                                                <div key={index} className="col-6 col-xs-6 col-sm-6 col-md-2 col-lg-2">
                                                    {
                                                        <div
                                                            className='editGroup'
                                                            style={{ display: 'none' }}
                                                        >
                                                            <button
                                                                className="btn btn-success"
                                                                onClick={this.getCategory}
                                                                data-id={index}
                                                                data-toggle="modal"
                                                                data-target="#modalUpdateCategory"
                                                            >
                                                                Sửa
                                                            </button>
                                                            <button
                                                                className="btn btn-danger ml-sm-2"
                                                                onClick={this.getCategory}
                                                                data-id={index}
                                                                data-toggle="modal"
                                                                data-target="#modalDeleteCategory"
                                                            >
                                                                {showDeleted ? "Khôi phục" : "Ẩn"}
                                                            </button>

                                                        </div>}
                                                    <div>
                                                        <img
                                                            onClick={this.changeCategory}
                                                            className="img-thumbnail"
                                                            src={value.previewImg}
                                                            alt={value.name}
                                                            data-id={value.id}
                                                        />
                                                        <h3
                                                            onClick={this.changeCategory}
                                                            data-id={value.id}
                                                            className="text-center"
                                                        >
                                                            {value.name}
                                                        </h3>

                                                        <h5
                                                            onClick={this.changeCategory}
                                                            data-id={value.id} >{value.description}</h5>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );

    }
}

export default Categories
