import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import bindModel from '../../../common/bindModel.js'; 

import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';

import $ from 'jquery';
import AWS from 'aws-sdk';

export default class ModalUpdateluckyGift extends Component {
    constructor(props) {
        super(props);

        this.state = {
            luckyGift: null,
            loading: false,
            giftId: "",
            isNew: true
        }

        this.saveluckyGift = this.saveluckyGift.bind(this);
        this.removeGift = this.removeGift.bind(this);
        this.addGift = this.addGift.bind(this);
        this.uploadBackground = this.uploadBackground.bind(this);
        this.uploadButton = this.uploadButton.bind(this);

        this.updateStartDate = this.updateStartDate.bind(this);
        this.updateEndDate = this.updateEndDate.bind(this);
    }

    componentWillReceiveProps(props) {
        var luckyGift = props.luckyGift;
        var isNew = true;
        // if (luckyGift != null) {
        //     luckyGift.startDate = new Date(luckyGift.startDate).toISOString().substr(0, 10);
        //     luckyGift.endDate = new Date(luckyGift.endDate).toISOString().substr(0, 10);
        // }

        if (luckyGift && luckyGift.id) {
            isNew = false;
        }

        this.setState({
            luckyGift: luckyGift,
            isNew: isNew
        })
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }




    saveluckyGift() {


        this.setState({
            loading: true
        });

        var request;

        if (this.state.isNew) {
            request = axios.post(global.config.apiDomain + '/rest/luckyGifts/create', JSON.stringify(this.state.luckyGift), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        } else {
            request = axios.post(global.config.apiDomain + '/rest/luckyGifts/save', JSON.stringify(this.state.luckyGift), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        }

        request.then(res => {
            this.setState({
                loading: false
            });

            if (res.data.status == "OK") {
                window.$("#modalUpdateluckyGift").modal("hide");
                toast.success(res.data.message);
                this.props.resetList();
            } else {
                toast.error(res.data.message);
            }
        }, error => {
            toast.error(error)
        })

    }

    uploadBackground(event) {
        this.setState({
            loading: true
        });

        var { luckyGift } = this.state;
        var target = event.target;
        var bucketName = "ikara-data/images/luckyMoneys";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/jpeg";
        if (extension == "png") contentType = "image/png";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data)
                    this.setState({
                        loading: false
                    });

                    luckyGift.backgroundUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        luckyGift: luckyGift
                    });

                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);
    }

    uploadButton(event) {
        this.setState({
            loading: true
        });

        var { luckyGift } = this.state;
        var target = event.target;
        var bucketName = "ikara-data/images/luckyMoneys";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/jpeg";
        if (extension == "png") contentType = "image/png";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data)
                    this.setState({
                        loading: false
                    });

                    luckyGift.buttonUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        luckyGift: luckyGift
                    });

                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);
    }

    addGift() {
        axios.post(global.config.apiDomain + '/rest/gifts/getDetail', this.state.giftId, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.data.status == "OK") {
                var { luckyGift } = this.state;
                var gifts = luckyGift.gifts;
                gifts.unshift(res.data.gift);
                luckyGift.gifts = gifts;

                this.setState({
                    luckyGift: luckyGift,
                    giftId: ""
                })
            } else {
                toast.error(res.data.message)
            }
        });
    }

    removeGift(index) {
        console.log(index)
        var { luckyGift } = this.state;
        var gifts = luckyGift.gifts;

        gifts.splice(index, 1)

        luckyGift.gifts = gifts;

        this.setState({
            luckyGift: luckyGift
        })
    }

    updateStartDate(props) {
        let {luckyGift} = this.state;
        let currentTime = props.value
        if(luckyGift) {
            luckyGift.startDate = currentTime;
            this.setState({
                luckyGift: luckyGift
            });
        }
        
    }

    updateEndDate(props) {
        let {luckyGift} = this.state;
        let currentTime = props.value
        if(luckyGift) {
            luckyGift.endDate = currentTime;
            this.setState({
                luckyGift: luckyGift
            });
        }
        
    }


    render() {
        const model = bindModel(this);
        const { luckyGift, isNew } = this.state;
        console.log(luckyGift)

        return (
            <>
                <div className="modal fade" id="modalUpdateluckyGift" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">{isNew ? "Tạo mới bao lì xì" : "Cập nhật bao lì xì"}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {luckyGift ? (<div className="modal-body">
                                <div id="id" className="row">
                                    <div className="col-3">
                                        <label>ID</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="startDate"
                                            readOnly={!isNew}
                                            {...model('luckyGift.id')} />
                                    </div>
                                </div>
                                <div id="name" className="row">
                                    <div className="col-3">
                                        <label>Name</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="name"
                                            {...model('luckyGift.name')} />
                                    </div>
                                </div>
                                <div id="backgroundUrl" className="row mb-2">
                                    <div className="col-3">
                                        <label>Background</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="inputGroupFile03"
                                            onChange={this.uploadBackground} />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                                    </div>
                                </div>
                                {luckyGift.backgroundUrl ? (
                                    <div id="background" className="row mb-2">
                                        <div className="col-3">

                                        </div>
                                        <div className="col-9">
                                            <img src={luckyGift.backgroundUrl} />

                                        </div>
                                    </div>
                                ) : ""}

                                <div id="buttonUrl" className="row mb-2">
                                    <div className="col-3">
                                        <label>Button</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="inputGroupFile03"
                                            onChange={this.uploadButton} />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                                    </div>
                                </div>
                                {luckyGift.buttonUrl ? (
                                    <div id="buttonUrl" className="row mb-2">
                                        <div className="col-3">

                                        </div>
                                        <div className="col-9">
                                            <img src={luckyGift.buttonUrl} />

                                        </div>
                                    </div>
                                ) : ""}


                                <div id="buyPrice" className="row mb-2">
                                    <div className="col-3">
                                        <label>Buy Price</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="title"
                                            {...model('luckyGift.buyPrice')} />
                                    </div>
                                </div>
                                <div id="startDate" className="row ">
                                    <div className="col-3">
                                        <label>Ngày bắt đầu</label>
                                    </div>
                                    <div className="col-9">
                                        {/* <input
                                            className="form-control mb-2"
                                            type="date"
                                            name="startDate"
                                            {...model('luckyGift.startDate')} /> */}
                                        <DateTimePickerComponent 
                                            change= {this.updateStartDate}
                                            value = {new Date(luckyGift.startDate)}
                                        >    
                                        </DateTimePickerComponent>
                                    </div>
                                </div>
                                <div id="endDate" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ngày kết thúc</label>
                                    </div>
                                    <div className="col-9">
                                        {/* <input
                                            className="form-control"
                                            type="date"
                                            name="endDate"
                                            {...model('luckyGift.endDate')} /> */}
                                        <DateTimePickerComponent 
                                            change= {this.updateEndDate}
                                            value = {new Date(luckyGift.endDate)}
                                        >    
                                        </DateTimePickerComponent>
                                    </div>
                                </div>
                                <div id="order" className="row mb-2">
                                    <div className="col-3">
                                        <label>Title</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="title"
                                            {...model('luckyGift.title')} />
                                    </div>
                                </div>
                                <div id="description" className="row mb-2">
                                    <div className="col-3">
                                        <label>Description</label>
                                    </div>
                                    <div className="col-9">
                                        <textarea
                                            className="form-control"
                                            type="number"
                                            name="description"
                                            rows={3}
                                            {...model('luckyGift.description')} />
                                    </div>
                                </div>
                                <div id="status" className="row mb-2">
                                    <div className="col-3">
                                        <label>Status</label>
                                    </div>
                                    <div className="col-9">
                                        <select
                                            className="form-control"
                                            type="text"
                                            name="luckyGift.status"
                                            {...model('luckyGift.status')} >
                                            <option value="ENABLED">ENABLED</option>
                                            <option value="DISABLE">DISABLE</option>
                                        </select>
                                    </div>
                                </div>
                                <div id="quantityNotify" className="row mb-2">
                                    <div className="col-3">
                                        <label>Số lượng thông báo</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="quantityNotify"
                                            {...model('luckyGift.quantityNotify')} />
                                    </div>
                                </div>
                                <div id="order" className="row mb-2">
                                    <div className="col-3">
                                        <label>Order</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="order"
                                            {...model('luckyGift.order')} />
                                    </div>
                                </div>

                                <div id="idAllowed" className="row mb-2">
                                    <div className="col-3">
                                        <label>ID được hiển thị</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="title"
                                            placeholder="Nhập id được hiển thị"
                                            {...model('luckyGift.idAllowed')} />
                                    </div>
                                </div>

                                <div id="" className="row mb-2">
                                    <div className="col-3">
                                        <label>Nhập ID quà</label>
                                    </div>
                                    <div className="col-9">
                                        <div class="input-group">
                                            <input
                                                onKeyPress={(event) => {
                                                    if (event.key == "Enter") {
                                                        this.addGift()
                                                    }
                                                }}
                                                type="text"
                                                className="form-control"
                                                placeholder="Nhập ID quà tặng"
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                                {...model('giftId')}
                                            />
                                            <div className="input-group-append">
                                                <button
                                                    className="btn btn-outline-success"
                                                    type="button"
                                                    onClick={this.addGift}
                                                >Xác nhận</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="gifts" className="row mb-2">
                                    <div className="col-3">
                                        <label>Danh sách phần thưởng</label>
                                    </div>
                                    <div className="col-9">
                                        {luckyGift.gifts.map((gift, index) => {
                                            return (
                                                <div className="input-group input-group-lg">
                                                    <div>
                                                        <img className="input-group-text" style={{ "width": "60px", "height": "60px" }} src={gift.url} />
                                                    </div>
                                                    <input

                                                        min={1}
                                                        className="form-control"
                                                        type="number"
                                                        name="order"
                                                        {...model('luckyGift.gifts[' + index + '].noItem')} />
                                                    <div class="input-group-append">
                                                        <button
                                                            class="btn btn-outline-secondary"
                                                            onClick={() => this.removeGift(index)}
                                                        >x</button>
                                                    </div>
                                                </div>)
                                        })}
                                    </div>
                                </div>

                            </div>) : ''}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.saveluckyGift}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}