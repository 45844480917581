import React, { Component } from 'react';
import ModalBuyIconForMomo from './components/ModalBuyIconForMomo';
import ModalBuyIconForBanking from './components/ModaiBuyIconForBanking';
import ModalBuyIconForMobileCard from './components/ModalBuyIcoinForMobileCard';
import { Helmet } from 'react-helmet';

import MyContext from '../../common/MyContext.js';
class BuyICoinPage extends Component {

    constructor(props) {
        super(props);

        this.redirectLogin = this.redirectLogin.bind(this);
    }

    redirectLogin() {
        const { user } = this.context;
        if (user == null) {
            this.props.history.push("/login");
        }
    }

    render() {
        const { user } = this.context;


        if (user != null && user.level < 4) {
            return (
                <div id="content">
                    <div className="container">
                        <div className="text-center">
                            <h1>Vui lòng nạp tiền qua ứng dụng</h1>
                        </div>
                    </div>
                </div>
            )
        }


        return (
            <>
                {/* <Helmet>
					<link rel="shortcut icon" href="./static/media/favicon.png"></link>
					<meta property="og:url" content={"https://www.ikara.co/react/"} />
					<meta property="og:title" content="Mua iCoin" />
					<meta property="og:image" content="./static/media/ikara-web.png" />
					<meta property="og:description" content="Mua iCoin" />
			</Helmet> */}
                <ModalBuyIconForMomo></ModalBuyIconForMomo>
                <ModalBuyIconForBanking></ModalBuyIconForBanking>
                {user != null ? (
                    <ModalBuyIconForMobileCard id={user.facebookId}></ModalBuyIconForMobileCard>
                ) : <></>}

                <div id="content">
                    <Helmet>
                        <title>Nạp iCoin</title>
                    </Helmet>
                    <div className="container">
                        <div className="d-sm-flex align-items-center mt-3 mt-xl-0">
                            <h4 className="mr-auto text-center">Chọn phương thức nạp</h4>
                            {user != null ? (
                                <span className="">
                                    Số coin hiện tại: <span className="font-weight-bold text-light bg-secondary rounded p-1">{user.totalIcoin}<img height="15px" width="15px" src={require('../../assets/images/icoin.png')}></img></span>
                                </span>
                            ) : <></>}
                        </div>
                        <hr className="m-2" />
                        <div className="row">
                            <div className="col-lg-2 col-4 p-2">
                                <div
                                    onClick={this.redirectLogin}
                                    className="buy-icoin border rounded border-danger p-3 h-100"
                                    data-toggle="modal"
                                    data-target="#modalBuyIconForMobileCard">
                                    <img className="" src={require('../../assets/images/mobilecard.png')} />
                                </div>
                            </div>

                            <div className="col-lg-2 col-4 p-2">
                                <div
                                    className="buy-icoin border rounded border-danger p-3"
                                    data-toggle="modal"
                                    data-target="#modalBuyIconForBanking">
                                    <img src={require('../../assets/images/bank.png')} />
                                </div>
                            </div>
                            <div className="col-lg-2 col-4 p-2">
                                <div

                                    className="buy-icoin border rounded border-danger p-3"
                                    data-toggle="modal"
                                    data-target="#modalBuyIconForMomo">
                                    <img src={require('../../assets/images/momo.png')} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default BuyICoinPage;
BuyICoinPage.contextType = MyContext;