import React, {Component} from 'react';

export default class ModalShowImage extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const { image } = this.props;

        return (
            <div className="modal fade" id="modalShowImage" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                {image != null ? (
                                    <img width={300} src={image} />
                                )
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}