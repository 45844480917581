import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';
import { Base64 } from 'js-base64'
export default class ModalDeleteIconEvent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: null,
            nameIcon : null,
            loading: false
        }

        this.deleteIconEvent = this.deleteIconEvent.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            id : props.id,
            nameIcon : props.nameIcon
        })
    }
    newGetKey(data, password, password2) {
        var listOfNumbers = "" + password2;
        for (var i = 0 ; i < data.length; i++) {
            if (data.charAt(i) >= '0' && data.charAt(i) <= '9'){
                listOfNumbers = [listOfNumbers.slice(0, 0), data.charAt(i), listOfNumbers.slice(0)].join('');
            }
        }

        var originalKey = null;
      if (listOfNumbers.length > 15) 
        originalKey = listOfNumbers.substring(0,15);
      else
        originalKey = listOfNumbers;
      return Number(originalKey) + Number(password);
    }

    deleteIconEvent() {
        this.setState({
            loading: true
        });

        let json = {
            eventId: this.state.id,
        }
        const jsonBase64 = Base64.encode(JSON.stringify(json));
        var pass = this.newGetKey(jsonBase64, 7123358024, 3564);
        var keys = jsonBase64 + "-" + pass;
        var url = global.config.apiDomain + "/web.v5.DeleteIconEvent?parameters=" + keys;
        console.log("url DeleteIconEvent: ");
        console.log(url);
        axios.post(url, json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                loading: false
            });
            window.$("#modalDeleteIconEvent").modal("hide");
            if (res.data.status == "OK") {
                this.props.showEventInfo();
                toast.success(res.data.message);
            } else {
                toast.error(res.data.message);
            }
        }, err => {
            console.log("err nen set tam5: ");
            console.log(err);
            toast.error("Đã có lỗi xảy ra, vui lòng thử lại!");
        });
    }

    render() {

        const {id} = this.state;

        return (
            <div>
                <div className="modal fade" id="modalDeleteIconEvent" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Xóa Icon Event</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {id ? (
                                <div className="modal-body">
                                <h3 className="text-center text-danger">Bạn chắc chắn muốn xóa Icon Event: <span className="font-weight-bold">{this.state.nameIcon}</span> ?</h3>
                            </div>
                            ) : ""}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ? 
                                (
                                <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Xác nhận...
                                </button>
                                ) :
                                (
                                <button 
                                    style={{width: "120px"}} 
                                    type="button" 
                                    className="btn btn-red"
                                    onClick={this.deleteIconEvent}>Xóa</button>    
                                )}                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}