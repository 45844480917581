import React, {Component} from 'react'
import { Link } from 'react-router-dom';
import {withTranslation} from 'react-i18next';

class GiftButton extends Component {
    render() {
        const {user, t} = this.props;
        return (
            <table className="uiGrid _51mz" cellSpacing={0} cellPadding={0}>
                <tbody>
                    <tr>
                        <td className="vTop hCent">
                            <div>
                                {(user) ?
                                (
                                <>
                                    <button data-toggle="modal" data-target="#exampleModalDonate" className={`inlineBlock _2tga _49ve btn-red`} title="Thích" id="u_0_6">
                                        <div>
                                            <span className="_3jn- inlineBlock">
                                                <span className="_3jn_" />
                                                <span className="_49vg">
                                                    <img className="_1pbs inlineBlock img mb-1" src={require('../../../assets/images/gift_animation.gif')} alt="" width={25} height={25} />
                                                </span>
                                            </span>
                                            <span className="_49vh _2pi7">{t("recording_page.send_gift", "Tặng quà")}</span>
                                        </div>
                                    </button>
                                    <span className="ml-1" id="u_0_3">{t("recording_page.send_gift_description","Tặng quà để ủng hộ tôi")}</span>
                                </>
                                ) :
                                (
                                <a data-toggle="modal" data-target="#loginDialog">
                                    <button className={`inlineBlock _2tga _49ve btn-red`} title={t("recording_page.like", "Thích")} id="u_0_6">
                                        <div>
                                            <span className="_3jn- inlineBlock">
                                                <span className="_3jn_" />
                                                <span className="_49vg">
                                                    <img className="_1pbs inlineBlock img mb-1" src={require('../../../assets/images/gift_animation.gif')} alt="" width={25} height={25} />
                                                </span>
                                            </span>
                                            <span className="_49vh _2pi7">{t("recording_page.send_gift", "Tặng quà")}</span>
                                        </div>
                                    </button>
                                </a>
                                )}
                                
                            </div>
                        </td>
                        <td className="_2pir">
                            <div><span id="u_0_3"></span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
}

export default withTranslation()(GiftButton)
