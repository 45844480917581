import React, { Component } from "react";
import axios from "axios";
import lodash from "lodash";

export default class ModalGiftInBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      luckyGifts: [],
      giftsInLuckyGift: [],
    };
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
  }

  componentWillReceiveProps(props) {
    const { luckyGiftId, giftsMap } = props;
    const url = global.config.apiDomain + "/rest/getListUseLuckyGift";
    const json = {
      luckyGiftId: luckyGiftId,
    };

    let gifts = [];

    let obj = JSON.parse(giftsMap);

    for(let i in obj){
        let json = {
            value: obj[i],
            name: i
        }
        gifts.push(json);
    }

    // axios
    //   .post(url, json)
    //   .then((res) => {
        this.setState({
          giftsInLuckyGift: gifts,
        });
    //   })
    //   .catch((err) => console.log(err));
  }

  render() {
    const { giftsInLuckyGift } = this.state;
    return (
    <div className="modal fade" id="modalGiftInBox" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Danh sách quà có trong túi lì xì</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <table className="table table-hover">
                    <thead>
                        <tr>
                            <th width={100} scope="col">STT</th>
                            <th scope="col">Tên Quà</th>
                            <th scope="col">Số lượng</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            giftsInLuckyGift.map((item,index)=>{
                                return (
                                    <tr>
                                        <td>{index +1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.value}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>  
    )  
  }
}
