import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

class LikeHeader extends Component {
    render() {
        const {totalLike, liked, submitLikeFunction, t} = this.props;
        let likeHeaderContent = '';
        let className = 'deactive';
        let buttonText = t("recording_page.like", "Thích")
        if (liked) {
            className = 'active';
            buttonText = t("recording_page.liked", "Đã thích")
        }
        if (totalLike === null || totalLike === 0){
            likeHeaderContent = t("recording_page.first_like", "Hãy là người đầu tiên thích bài thu này");
        } else {
            likeHeaderContent = totalLike+ " " + t("recording_page.like_description", "người đã thích bài thu này");
        }
        return (
            <table className="uiGrid _51mz" cellSpacing={0} cellPadding={0}>
                <tbody>
                    <tr>
                        <td className="vTop hCent">
                            <div>
                                <button onClick={submitLikeFunction} className={`inlineBlock _2tga _49ve btn-red ${className}`} title="Thích" id="u_0_6">
                                    <div>
                                        <span className="_3jn- inlineBlock">
                                            <span className="_3jn_" />
                                            <span className="_49vg">
                                                <img className="_1pbs inlineBlock img mb-1" src={require("../../../assets/images/facebook-like.png")} alt="" width={16} height={16} /></span>
                                            <span className="_5n2y">
                                                <img className="_1pbs inlineBlock img mb-1" src="https://www.facebook.com/rsrc.php/v3/yx/r/Rnf8y1qsswj.png" alt="" width={16} height={16} /></span>
                                        </span>
                                        <span className="_49vh _2pi7">{buttonText}</span>
                                    </div>
                                </button>
                            </div>
                        </td>
                        <td className="_2pir">
                            <div><span id="u_0_3">{likeHeaderContent}</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
}

export default withTranslation()(LikeHeader)

