import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

export default function ModalGiftIcoin({ showModalGiftIcoin, uid }) {
    const [showModalGiftIcoinSet, setShowModalGiftIcoin] = useState(showModalGiftIcoin);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [chartData, setChartData] = useState(null);
    const [partner, setPartner] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setShowModalGiftIcoin(showModalGiftIcoin);
    }, [showModalGiftIcoin]);

    useEffect(() => {
        if (showModalGiftIcoinSet || uid) {
            handleSubmitGiftIcoin();
        }
    }, [showModalGiftIcoinSet, uid]);

    const handleSubmitGiftIcoin = () => {
        const currentDate = new Date();
        const defaultFromDate = fromDate ? new Date(fromDate) : new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const finalFromDate = fromDate
            ? new Date(fromDate).toISOString().split('T')[0]
            : `${defaultFromDate.getFullYear()}-${(defaultFromDate.getMonth() + 1).toString().padStart(2, '0')}-01`;

        const finalToDate = toDate ? new Date(toDate).toISOString().split('T')[0] : currentDate.toISOString().split('T')[0];
        
        const fromTimestamp = Date.parse(finalFromDate);
        const toTimestamp = Date.parse(finalToDate);

        if (fromTimestamp > toTimestamp) {
            toast.error("Ngày kết thúc phải lớn hơn hoặc bằng ngày bắt đầu!");
            return;
        }

        setIsLoading(true);

        axios
            .post(
                global.config.apiDomain + '/rest/partner-duration/web/get-partner-icoin-list',
                { fromDate: fromTimestamp, toDate: toTimestamp, uid: uid },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then(res => {
                const data = res.data.data || null;
                setPartner(data);
                setIsLoading(false);

                if (data && data.list) {
                    const dates = data.list.map(item => new Date(item.dateView).toLocaleDateString());
                    const icoin = data.list.map(item => item.icoin);

                    setChartData({
                        labels: dates,
                        datasets: [
                            {
                                label: 'Icoin quà',
                                data: icoin,
                                fill: false,
                                borderColor: '#36A2EB',
                                tension: 0.1
                            }
                        ]
                    });
                }
            })
            .catch(error => {
                console.error('Error fetching commissions:', error);
                setIsLoading(false);
            });
    };

    const resetFormGiftIcoin = () => {
        setFromDate('');
        setToDate('');
        setIsLoading(false);
        setPartner(null);
        setChartData(null);
        setShowModal(false);
        setShowModalGiftIcoin(!showModalGiftIcoinSet);
    };

    return (
        <div>
            <div className="modal fade" id="modalGiftIcoin" tabIndex="-1" role="dialog" aria-labelledby="modalCompareLabel" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{ maxWidth: '95%' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="resultModalLabel">Hiển thị biểu đồ</h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={resetFormGiftIcoin}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group d-flex" style={{ gap: '10px' }}>
                                <div style={{ flex: 1 }}>
                                    <label>From Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="fromDate"
                                        value={fromDate}
                                        onChange={e => setFromDate(e.target.value)}
                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <label>To Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="toDate"
                                        value={toDate}
                                        onChange={e => setToDate(e.target.value)}
                                    />
                                </div>
                            </div>

                            <button className="btn btn-primary" onClick={handleSubmitGiftIcoin}>
                                Hiển thị
                            </button>
                            {isLoading ? (
                                <p>Loading...</p>
                            ) : chartData ? (
                                <div style={{ width: '100%', height: '360px' }}>
                                    <Line data={chartData} options={{ maintainAspectRatio: false }} />
                                </div>
                            ) : (
                                <p>Không có dữ liệu</p>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary ml-2 mr-2" data-dismiss="modal" onClick={resetFormGiftIcoin}>
                                Hủy
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}