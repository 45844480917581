import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';

const configPRO = {
    apiKey: "AIzaSyCm5OlPGjDYLkj7v8eaBpXzDQDqCcr3xps",
    authDomain: "ikara4m.firebaseapp.com",
    databaseURL: "https://ikara4m.firebaseio.com",
    storageBucket: "ikara4m.appspot.com",
    messagingSenderId: "366287804041",
    projectId: "ikara4m"
};

const configDEV = {
    apiKey: "AIzaSyCXDqJJRkEb5L6e__n0Vmyk_Bn95u7hD9s",
    authDomain: "ikara-development.firebaseapp.com",
    databaseURL: "https://ikara-development-default-rtdb.firebaseio.com",
    projectId: "ikara-development",
    storageBucket: "ikara-development.appspot.com",
    messagingSenderId: "3268331263",
    appId: "1:3268331263:web:2e5ed0e495e4437266aeca",
    measurementId: "G-0ECG4DD0GD"
  };
const finalFirebaseConfig =  window.location.hostname.includes("ikara-development") || window.location.hostname.includes("localhost") ?  configDEV :configPRO ;

firebase.initializeApp(finalFirebaseConfig);
export default firebase;