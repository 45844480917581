import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import ModalCreate from './components/ModalCreate';
import ModalHide from './components/ModalHide';
import ModalDelete from './components/ModalDelete';

import { toast } from 'react-toastify';

export default class Item3DPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            models: [],
            model: null,
            category: "OUTFIT",
            categories: [],
            subCategories: []
        };

        this.changeCategory = this.changeCategory.bind(this);
        this.getAllByCategory = this.getAllByCategory.bind(this);
        this.hideOrActive = this.hideOrActive.bind(this);
        this.getCategories = this.getCategories.bind(this);
    }

    getAllByCategory(category) {
        axios.post(global.config.apiDomain + "/rest/item3D/getAllByCategory/" + category)
            .then(res => {
                if (res.data.status == "OK") {
                    console.log('getAllByCategory');
                    console.log(res.data.models);
                    this.setState({
                        models: res.data.models
                    })
                }
            })

    }

    getCategories() {
        axios.post(global.config.apiDomain + "/rest/item3D/getCategories")
            .then(res => {
                const {categories, subCategories} = res.data;
                this.setState({
                    categories: categories,
                    subCategories: subCategories
                })
            })
    }

    componentDidMount() {
        this.getAllByCategory(this.state.category);
        this.getCategories();
    }

    hideOrActive(event) {
        let index = event.target.name;
        let model = null;
        if (index === "-1") {
            model = {
                id: '',
                subCategory: '',
                name: '',
                buyPrice: '',
                thumbnailUrl: '',
                resourceUrl: '',
                gender: 'ALL',
                tag: '',
                category: this.state.category,
                expiry: 0,
                minLevel: 0,
                minVipLevel: 0,
                order: 0,
                sourceType: ''
            }
        } else {
            model = this.state.models[index];
        }

        this.setState({
            model: model
        })
    }

    changeCategory(event) {
        let category = event.target.value;
        this.setState({
            category: category
        })
        this.getAllByCategory(category);
    }

    render() {
        const { models, model, category, categories, subCategories } = this.state;

        return (
            <>
                <ModalCreate
                    model={model}
                    resetList={this.getAllByCategory}
                    categories={categories}
                    subCategories={subCategories}
                ></ModalCreate>
                <ModalHide
                    model={model}
                    resetList={this.getAllByCategory}
                ></ModalHide>
                <ModalDelete
                    model={model}
                    resetList={this.getAllByCategory}
                ></ModalDelete>

                <div id="content">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-4">
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <span className="btn btn-success">Danh mục</span>
                                    </div>
                                    <select
                                        className="form-control"
                                        name="category"
                                        onChange={this.changeCategory}
                                    >
                                        {categories.map(e => {
                                            return <option value={e.type}>{e.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <h1 className="text-center">Quản lí Vật phẩm 3D: <span className='text-info'>{category} </span></h1>
                        <button
                            className="btn btn-info mb-1"
                            data-toggle="modal"
                            data-target="#modalCreate"
                            name="-1"
                            onClick={this.hideOrActive}
                        >+ Tạo Mới Vật Phẩm
                        </button>
                      <table className="table table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th className="align-middle" scope="col">#</th>
                                    <th className="align-middle" scope="col">ID</th>
                                    <th className="align-middle" scope="col">Danh mục Phụ</th>
                                    <th className="align-middle" scope="col">Loại</th>
                                    <th className="align-middle" scope="col">Giới tính</th>
                                    <th className="align-middle" scope="col">Tag</th>
                                    <th className="align-middle" scope="col">Tên</th>
                                    <th className="align-middle" scope="col">Giá</th>
                                    <th className="align-middle" scope="col">Ảnh Mô tả</th>
                                    <th className="align-middle" scope="col">Vật phẩm</th>
                                    <th className="align-middle" scope="col">Thời hạn</th>
                                    <th className="align-middle" scope="col">Level</th>
                                    <th className="align-middle" scope="col">Level Vip</th>
                                    <th className="align-middle" scope="col" colSpan={2}>Tùy chỉnh</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    models && models.map((model, index) => {
                                        return (
                                            <tr>
                                                <td className="align-middle text-center" scope="col">{model.enable ? model.order : <del>{model.order}</del>}</td>
                                                <td className="align-middle text-center" scope="col">{model.enable ? model.id : <del>{model.id}</del>}</td>
                                                <td className="align-middle text-center" scope="col">{model.enable ? model.subCategory : <del>{model.subCategory}</del>}</td>
                                                <td className="align-middle text-center" scope="col">{model.enable ? model.sourceType : <del>{model.sourceType}</del>}</td>
                                                <td className="align-middle text-center" scope="col">{model.enable ? model.gender : <del>{model.gender}</del>}</td>
                                                <td className="align-middle text-center" scope="col">{model.enable ? model.tag : <del>{model.tag}</del>}</td>
                                                <td className="align-middle text-center" scope="col">{model.enable ? model.name : <del>{model.name}</del>}</td>
                                                <td className="align-middle text-center" scope="col">{model.enable ? model.buyPrice : <del>{model.buyPrice}</del>}</td>
                                                <td className="align-middle text-center" scope="col"><img src={model.thumbnailUrl} width={150} alt="" /></td>
                                                <td className="align-middle text-center" scope="col" style={{ width: '150px' }}>
                                                    {model.resourceUrl.substring(model.resourceUrl.lastIndexOf("/"), model.resourceUrl.length)}
                                                </td>
                                                <td className="align-middle text-center" scope="col">{(model.expiry == -1)? 'Mãi mãi' : model.expiry}</td>
                                                <td className="align-middle text-center" scope="col">{model.enable ? model.minLevel : <del>{model.minLevel}</del>}</td>
                                                <td className="align-middle text-center" scope="col">{model.enable ? model.minVipLevel : <del>{model.minVipLevel}</del>}</td>
                                                <td className="align-middle text-center" scope="col">
                                                    {
                                                        !model.enable ? (
                                                            <button
                                                                className="btn btn-warning"
                                                                onClick={this.hideOrActive}
                                                                name={index}
                                                                data-toggle="modal"
                                                                data-target="#modalHide"
                                                            >
                                                                HIỆN
                                                            </button>
                                                        ) : (

                                                            <button className='btn btn-success ml-2 mr-2'
                                                                data-toggle="modal"
                                                                data-target="#modalCreate"
                                                                onClick={this.hideOrActive}
                                                                name={index}
                                                            >
                                                                SỬA
                                                            </button>
                                                        )
                                                    }
                                                </td>
                                                <td className="align-middle text-center" scope="col">
                                                    {
                                                        !model.enable ? (
                                                            <button
                                                                className="btn btn-danger"
                                                                onClick={this.hideOrActive}
                                                                name={index}
                                                                data-toggle="modal"
                                                                data-target="#modalDelete"
                                                            >
                                                                XÓA LUÔN
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="btn btn-warning"
                                                                onClick={this.hideOrActive}
                                                                name={index}
                                                                data-toggle="modal"
                                                                data-target="#modalHide"
                                                            >
                                                                ẨN
                                                            </button>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })

                                }

                            </tbody>
                        </table>
                    </div>
                </div>

            </>
        );
    }
}

