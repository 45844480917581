import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';

export default class ModalDeleteDecorationItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            decorationItem: null,
            loading: false
        }

        this.deleteItem = this.deleteItem.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            decorationItem : props.decorationItemType
        })
    }

    deleteItem() {
        const { decorationItem } = this.state;
        let url = global.config.apiDomain + "/rest/decorationItemsType/delete";

        let json = {
            name: decorationItem.name,
            colorCode: decorationItem.colorCode,
            expiry: decorationItem.expiry,
            resourceUrl: decorationItem.resourceUrl,
            thumbnailUrl: decorationItem.thumbnailUrl,
            type: decorationItem.type,
            id: decorationItem.id,
            addTime: null
        }

        axios.post(url ,json)
        .then(res =>{
            if (res.data.status == "OK") {
                window.$("#modalDeleteDecorationItem").modal("hide");
                toast.success(res.data.message);
            } else {
                toast.warning(res.data.message);
            }
        })
    }

    render() {

        const { decorationItem } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalDeleteDecorationItem" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Xóa Item</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {decorationItem ? (
                                <div className="modal-body">
                                <h3 className="text-center text-danger">Bạn chắc chắn muốn xóa Item: <span className="font-weight-bold">{decorationItem.name}</span> ?</h3>
                            </div>
                            ) : ""}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ? 
                                (
                                <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Xác nhận...
                                </button>
                                ) :
                                (
                                <button 
                                    style={{width: "120px"}} 
                                    type="button" 
                                    className="btn btn-red"
                                    onClick={this.deleteItem}>Xóa</button>    
                                )}                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}