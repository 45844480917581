import React, { Component } from 'react';
import axios from 'axios';
import MyContext from '../../common/MyContext'
import {toast} from 'react-toastify';

export default class BeatManagerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            songs: []
        }

        this.deleteBeat = this.deleteBeat.bind(this);
    }


    getSongs() {
        const facebookId = window.localStorage.getItem("userId");

        axios.post(global.config.apiDomain + '/rest/beats', facebookId, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            console.log(res.data)
            this.setState({
                songs: res.data
            })
        });
    }

    deleteBeat(tempSongId) {
        const {user} = this.context;

        const json = {
            userId: user.facebookId,
            tempSongId: tempSongId
        }

        axios.post(global.config.apiDomain + '/rest/beats/delete', JSON.stringify(json), {
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            if(res.data.status == "OK") {
                toast.success(res.data.message); 
                this.getSongs();
            } else {
                toast.warning(res.data.message); 
            }
        });
    }

    componentDidMount() {
        this.getSongs()
    }

    render() {

        const { songs } = this.state;

        return (
            <div id="content">
                <div className="container">
                    <div>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">STT</th>
                                    <th scope="col">Bài hát</th>
                                    <th scope="col">Ca sĩ</th>
                                    <th scope="col">Trạng thái</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {songs != null && songs.map((song, index) => {
                                    var status = "Đã công khai";
                                    switch (song.status) {
                                        case 1:
                                            status = "Đã xóa";
                                            break;
                                        case 2:
                                            status = "Đang tải";
                                            break;
                                        case 3:
                                            status = "ERROR";
                                            break;
                                        default: status = "Đã công khai"
                                    }
                                    return (
                                        <tr key={song._id}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{song.songName}</td>
                                            <td>{song.singerName}</td>
                                            <td>{status}</td>
                                            <td>
                                                <button
                                                    onClick={() => this.deleteBeat(song._id)}
                                                    className="btn btn-red">Xóa
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

BeatManagerPage.contextType = MyContext;