import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const ModalMinOnChair = ({showModalOnChair, uid }) => {
    const [showModalOnChairSet, setShowModalOnChair] = useState(showModalOnChair);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [partner, setPartner] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [chartData, setChartData] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setShowModalOnChair(showModalOnChair);
    }, [showModalOnChair]);

    useEffect(() => {
        if (showModalOnChairSet || uid) {
            handleSubmitOnChair();
        }
    }, [showModalOnChairSet, uid]);

    const handleInputChangeOnChair = (event) => {
        const { name, value } = event.target;
        if (name === 'fromDate') {
            setFromDate(value);
        } else if (name === 'toDate') {
            setToDate(value);
        }
    };

    const handleSubmitOnChair = () => {
        const currentDate = new Date();

        const defaultFromDate = fromDate
            ? new Date(fromDate)
            : new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

        const finalFromDate = fromDate
            ? new Date(fromDate).toISOString().split('T')[0]
            : `${defaultFromDate.getFullYear()}-${(defaultFromDate.getMonth() + 1).toString().padStart(2, '0')}-01`;

        const finalToDate = toDate
            ? new Date(toDate).toISOString().split('T')[0]
            : currentDate.toISOString().split('T')[0];

        const fromTimestamp = Date.parse(finalFromDate);
        const toTimestamp = Date.parse(finalToDate);

        if (fromTimestamp > toTimestamp) {
            toast.error('Ngày kết thúc phải lớn hơn hoặc bằng ngày bắt đầu!');
            return;
        }

        setIsLoading(true);

        axios.post(global.config.apiDomain + '/rest/partner-duration/web/get-partner-timer-list', 
            { fromDate: fromTimestamp, toDate: toTimestamp, uid: uid, action: 'ROOM_CHAIR' },
            { headers: { 'Content-Type': 'application/json' } }
        ).then(res => {
            const data = res.data.data || null;
            setPartner(data);
            setIsLoading(false);

            if (data && data.list) {
                const dates = data.list.map(item => new Date(item.dateView).toLocaleDateString());
                const validTimers = data.list.map(item => item.validTimer);
                const totalTimers = data.list.map(item => item.totalTimer);

                setChartData({
                    labels: dates,
                    datasets: [
                        {
                            label: 'Thời gian Hợp lệ',
                            data: validTimers,
                            fill: false,
                            borderColor: '#36A2EB',
                            tension: 0.1
                        },
                        {
                            label: 'Thời gian Tổng',
                            data: totalTimers,
                            fill: false,
                            borderColor: '#4BC0C0',
                            tension: 0.1
                        }
                    ]
                });
            }
        }).catch(error => {
            console.error('Error fetching commissions:', error);
            setIsLoading(false);
        });
    };

    const resetFormOnChair = () => {
        setFromDate('');
        setToDate('');
        setIsLoading(false);
        setPartner(null);
        setChartData(null);
        setShowModal(false);
        setShowModalOnChair(!showModalOnChairSet);
    };

    return (
        <div>
            <div className="modal fade" id="modalMinOnChair" tabIndex="-1" role="dialog" aria-labelledby="modalCompareLabel" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{ maxWidth: '95%' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="resultModalLabel">Hiển thị biểu đồ</h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={resetFormOnChair}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group d-flex" style={{ gap: '10px' }}>
                                <div style={{ flex: 1 }}>
                                    <label>From Date</label>
                                    <input 
                                        type="date" 
                                        className="form-control" 
                                        name="fromDate" 
                                        value={fromDate} 
                                        onChange={handleInputChangeOnChair} 
                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <label>To Date</label>
                                    <input 
                                        type="date" 
                                        className="form-control" 
                                        name="toDate" 
                                        value={toDate} 
                                        onChange={handleInputChangeOnChair} 
                                    />
                                </div>
                            </div>

                            <button className="btn btn-primary" onClick={handleSubmitOnChair}>
                                Hiển thị
                            </button>
                            {isLoading ? (
                                <p>Loading...</p>
                            ) : chartData ? (
                                <div style={{ width: '100%', height: '360px' }}>
                                    <Line data={chartData} options={{ maintainAspectRatio: false }} />
                                </div>
                            ) : (
                                <p>Không có dữ liệu</p>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button className='btn btn-secondary ml-2 mr-2' data-dismiss="modal" onClick={resetFormOnChair}>Hủy</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalMinOnChair;