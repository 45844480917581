import React, { Component } from 'react';
import axios from 'axios';
import lodash from 'lodash';
import ModalUpdateGift from './components/ModalUpdateGift';
import { Parser, Player } from 'svga'
import { Link } from "react-router-dom";

import '../../assets/css/giftmanager.css';
import ModalShowSVGA from './components/ModalShowSVGA';
import ModalShowGiftAnimation from './components/ModalShowGiftAnimation';
import $ from 'jquery';
import { toast } from 'react-toastify';

export default class GiftManagerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gifts: [],
            giftsTemp: [],
            gift: null,
            giftShow: null,
            nameSearch: "",
            idSearch: "",
            buyPriceSort: "",
            sellPriceSort: "",
            orderSort: "",
            status: "",
            show: "",
            type: "",
            tag: "",
        }

        this.getGifts = this.getGifts.bind(this);
        this.search = this.search.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.reset = this.reset.bind(this);
        this.orderByBuyPrice = this.orderByBuyPrice.bind(this);
        this.orderBySellPrice = this.orderBySellPrice.bind(this);
        this.orderByOrder = this.orderByOrder.bind(this);
        this.newGift = this.newGift.bind(this);
        this.deleteGift = this.deleteGift.bind(this);
        this.updateList = this.updateList.bind(this);
    }

    componentDidMount() {
        this.getGifts();

        $("#modalUpdateGift").on("hidden.bs.modal", function () {
            this.setState({
                gift: null
            });
        }.bind(this));

        console.log("Did mount")
        $("#modalShowGift").on("hidden.bs.modal", function () {
            this.setState({
                giftShow: null
            });
        }.bind(this));

        $("#modalShowGiftAnimation").on("hidden.bs.modal", function () {
            this.setState({
                giftShow: null
            });
        }.bind(this));

    }

    async loadSVGA(id, url) {
        const parser = new Parser()
        const svga = await parser.load(url)

        const player = new Player(document.getElementById(id))
        await player.mount(svga)

        player.onStart = () => console.log('onStart')

        player.start()
    }

    getGifts() {
        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/gifts', {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                const gifts = res.data;
                this.setState({
                    gifts: gifts,
                    giftsTemp: gifts
                });
            });
    }

    transferMedalData(gift){
        let url = global.config.apiDomain + "/rest/gifts/transferDataGift"
        this.setState({
            loading : true
        })

        axios.post(url,gift)
        .then(res =>{
            if(res.data.status == "OK"){
                toast.success(res.data.message)
            }else{
                toast.warning("Error: " + res.data.message)
            }
        })
    }

    deleteGift(deleteGift){
        if(window.confirm("Bạn có chắc chắn muốn xóa")){
            let json = {
                userId: "",
                giftId: deleteGift,
                value: 0
            }
            axios.post(global.config.apiDomain + '/rest/gifts/deleteGift', json)
            .then(res => {
                if("OK" == res.data.status){
                    toast.success(res.data.message);
                    let gifts = this.state.gifts;

                    let index = gifts.findIndex(gift => gift.id === deleteGift.id);
                    if (index !== -1) {
                        gifts.splice(index, 1);
                    }
            
                    this.setState({
                        gifts: gifts,
                    })
                }else{
                    toast.warning(res.data.message)
                }
            });
        }
    }

    newGift() {
        this.setState({
            gift:  {
                id : "",
                name : "",
                buyPrice : 0,
                sellPrice : 0,
                order : 0,
                type : "STATIC",
                show : "ALL",
                tag : "NORMAL",
                minLevel : 1,
                maxLevel : 200,
                minVipLevel : 1,
                maxVipLevel : 20,
                minFamilyLevel : 1,
                maxFamilyLevel : 100,
                startDate : new Date(),
                endDate: new Date()
            }
        })
    }

    search() {
        let { gifts, giftsTemp, nameSearch, idSearch, show, type, tag } = this.state;

        gifts = giftsTemp.filter(e => e.name.includes(nameSearch));
        gifts = gifts.filter(e => e.id.includes(idSearch));

        if (show.startsWith("!")) {
            gifts = gifts.filter(e => !e.show.includes(show.replace("!", "")) && !e.show.includes("NONE"));
        } else {
            gifts = gifts.filter(e => e.show.includes(show));
        }
        gifts = gifts.filter(e => e.type.includes(type));
        gifts = gifts.filter(e => e.tag.includes(tag));

        this.setState({
            gifts: gifts,
            giftsTemp: giftsTemp
        });
    }

    reset() {
        this.setState({
            nameSearch: "",
            idSearch: "",
            status: "",
            type: "",
            tag: "",
            gifts: this.state.giftsTemp
        });
    }

    handleChange(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value;

        this.setState({
            [name]: value
        });
    }


    orderByBuyPrice() {
        let { buyPriceSort, gifts } = this.state

        if (buyPriceSort === "ASC") {
            gifts = lodash.orderBy(gifts, "buyPrice", 'desc');
            buyPriceSort = "DESC";
        } else {
            gifts = lodash.orderBy(gifts, "buyPrice", 'asc');
            buyPriceSort = "ASC";
        }

        this.setState({
            buyPriceSort: buyPriceSort,
            gifts: gifts
        });
    }

    orderByOrder() {
        let { orderSort, gifts } = this.state

        if (orderSort === "ASC") {
            gifts = lodash.orderBy(gifts, "order", 'desc');
            orderSort = "DESC";
        } else {
            gifts = lodash.orderBy(gifts, "order", 'asc');
            orderSort = "ASC";
        }

        this.setState({
            orderSort: orderSort,
            gifts: gifts
        });
    }

    orderBySellPrice() {
        let { sellPriceSort, gifts } = this.state

        if (sellPriceSort === "ASC") {
            gifts = lodash.orderBy(gifts, "sellPrice", 'desc');
            sellPriceSort = "DESC";
        } else {
            gifts = lodash.orderBy(gifts, "sellPrice", 'asc');
            sellPriceSort = "ASC";
        }

        this.setState({
            sellPriceSort: sellPriceSort,
            gifts: gifts
        });
    }

    updateList(newGift) {
        let gifts = this.state.gifts;

        let index = gifts.findIndex(gift => gift.id === newGift.id);
        if (index !== -1) {
            gifts[index] = newGift;
        } else {
            gifts.unshift(newGift);
        }

        this.setState({
            gifts: gifts,
        })
    }

    render() {
        const { gifts } = this.state;

        return (
            <>
                <ModalUpdateGift resetList={this.updateList} gift={this.state.gift}></ModalUpdateGift>
                <ModalShowGiftAnimation gift={this.state.giftShow}></ModalShowGiftAnimation>
                <ModalShowSVGA gift={this.state.giftShow}></ModalShowSVGA>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí gift</h1>
                        <div>
                            <button
                                className="btn btn-red"
                                onClick={this.newGift}
                                data-toggle="modal"
                                data-target="#modalUpdateGift"
                            >Thêm mới gift</button>

                            <Link className="btn btn-red ml-2" to="/admin/rank-gift">
                                Biểu đồ tiêu thụ gift
                            </Link>
                            <Link className="btn btn-danger ml-2" to="/admin/gift-deleted">
                                Danh sách quà đã xóa
                            </Link>
                        </div>
                        <div className="input-group mb-3">
                            <input
                                name="idSearch"
                                value={this.state.idSearch}
                                onChange={this.handleChange}
                                onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                        this.search()
                                    }
                                }}
                                type="text"
                                className="form-control col-md-2 col-sm-6"
                                placeholder="Nhập ID quà tặng"
                                aria-label="Recipient's username"
                                aria-describedby="button-addon2" />
                            <input
                                name="nameSearch"
                                value={this.state.nameSearch}
                                onChange={this.handleChange}
                                onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                        this.search()
                                    }
                                }}
                                type="text"
                                className="form-control col-md-2 col-sm-6 "
                                placeholder="Nhập tên quà tặng"
                                aria-label="Recipient's username"
                                aria-describedby="button-addon2" />
                            {/* <select
                                name="status"
                                value={this.state.status}
                                className="form-control col-md-1 col-3"
                                onChange={this.handleChange}>
                                <option value="">Trạng thái</option>
                                <option value="READY">READY</option>
                                <option value="ENABLED">ENABLED</option>
                                <option value="UNABLE">UNABLE</option>
                            </select> */}
                            <select
                                name="show"
                                value={this.state.show}
                                className="form-control col-md-1 col-3"
                                onChange={this.handleChange}>
                                <option value="">Show</option>
                                <option value="ALL">ALL</option>
                                <option value="RECORDING">RECORDING</option>
                                <option value="LIVEROOM">LIVEROOM</option>
                                <option value="!LIVEROOM">ALL RECORDING</option>
                                <option value="!RECORDING">ALL LIVEROOM</option>
                                <option value="NONE">NONE</option>
                            </select>
                            <select
                                name="type"
                                value={this.state.type}
                                className="form-control col-md-1 col-3"
                                onChange={this.handleChange}>
                                <option value="">Type</option>
                                <option value="ANIMATED">ANIMATED</option>
                                <option value="STATIC">STATIC</option>
                                <option value="COMBO">COMBO</option>
                                <option value="LUCKYBOX">LUCKYBOX</option>
                                <option value="DRAWABLE">DRAWABLE</option>
                            </select>
                            <select
                                name="tag"
                                value={this.state.tag}
                                className="form-control col-md-1 col-3"
                                onChange={this.handleChange}>
                                <option value="">Tag</option>
                                <option value="NORMAL">NORMAL</option>
                                <option value="EVENT">EVENT</option>
                                <option value="VIP">VIP</option>
                                <option value="SPECIAL">SPECIAL</option>
                                <option value="FAMILY">FAMILY</option>
                                <option value="USER">USER</option>
                                {/* <option value="LUCKYGIFT">LUCKYGIFT</option> */}
                            </select>
                            <div className="input-group-append">
                                <button
                                    className="btn btn-outline-success"
                                    type="button"
                                    id="button-addon2"
                                    onClick={this.search}>
                                    <i className="fa fa-search"></i>
                                </button>
                                <button
                                    className="btn btn-outline-danger"
                                    type="button"
                                    id="button-addon2"
                                    onClick={this.reset}>
                                    <i className="fas fa-sync"></i>
                                </button>
                            </div>
                        </div>
                        <table className="table table-hover">
                            <thead>
                                <tr className="align-middle">
                                    <th className="align-middle" scope="col">ID</th>
                                    <th className="align-middle" scope="col">Name</th>
                                    <th className="align-middle" scope="col">Image</th>
                                    <th className="align-middle" scope="col">Img Animation</th>
                                    <th className="align-middle" scope="col">Buy price
                                        <button
                                            className="btn btn-outline-secondary ml-1"
                                            onClick={this.orderByBuyPrice}>
                                            <i className="fas fa-sort" />
                                        </button>
                                    </th>
                                    <th className="align-middle" scope="col">Sell price
                                        <button
                                            className="btn btn-outline-secondary ml-1"
                                            onClick={this.orderBySellPrice}>
                                            <i className="fas fa-sort" />
                                        </button>
                                    </th>
                                    <th className="align-middle" scope="col">Order
                                        <button
                                            className="btn btn-outline-secondary ml-1"
                                            onClick={this.orderByOrder}>
                                            <i className="fas fa-sort" />
                                        </button>
                                    </th>
                                    {/* <th className="align-middle" scope="col">Counter</th> */}
                                    <th className="align-middle" scope="col">Action</th>
                                    <th className="align-middle" scope="col">Transfer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {gifts.map((gift, index) => {

                                    return (
                                        <tr key={gift.id}>
                                            <th scope="row">{gift.id}</th>
                                            <td>{gift.name}</td>
                                            <td> <img className="gift-image" src={gift.url}></img>  </td>
                                            <td>


                                                {gift.animatedUrl == null ? (
                                                    <span>NO IMAGE</span>
                                                ) : (

                                                    gift.animatedUrl.endsWith("svga") ? (
                                                        <button
                                                            style={{ width: "80px" }}
                                                            className="btn btn-outline-primary"
                                                            onClick={() => {
                                                                this.setState({
                                                                    giftShow: gift
                                                                })
                                                            }}
                                                            data-toggle="modal"
                                                            data-target="#modalShowGift"
                                                        >SVGA</button>
                                                    ) : (

                                                        gift.animatedUrl.endsWith("png") ? (
                                                            <button
                                                                style={{ width: "80px" }}
                                                                className="btn btn-outline-primary"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        giftShow: gift
                                                                    })
                                                                }}
                                                                data-toggle="modal"
                                                                data-target="#modalShowGiftAnimation"
                                                            >
                                                                PNG

                                                            </button>
                                                        ) : (
                                                            <button
                                                                style={{ width: "80px" }}
                                                                className="btn btn-outline-primary"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        giftShow: gift
                                                                    })
                                                                }}
                                                                data-toggle="modal"
                                                                data-target="#modalShowGiftAnimation"
                                                            >
                                                                WEBP

                                                            </button>
                                                        )

                                                    )

                                                )}

                                            </td>
                                            <td > {gift.buyPrice} </td>
                                            <td> {gift.sellPrice} </td>
                                            <td> {gift.order} </td>
                                            {/* <td> {gift.counter} </td> */}
                                            <td>
                                                <button
                                                    onClick={() => {
                                                        this.setState({
                                                            gift: gift
                                                        })
                                                    }}
                                                    className="btn btn-red"
                                                    data-toggle="modal"
                                                    data-target="#modalUpdateGift"
                                                >Sửa</button>
                                                <button
                                                    className="btn btn-red ml-sm-2"
                                                    
                                                    onClick={() => { this.deleteGift(gift.id) }}
                                                >Xóa</button>
                                            </td>
                                            <td>
                                                <button
                                                    onClick={() => {
                                                        if(window.confirm("Bạn có chắc là muốn chuyển dữ liệu: " + gift.name + " ?")){
                                                            this.transferMedalData(gift)
                                                        }
                                                    }}
                                                    className="btn btn-red"
                                                >Chuyển</button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}