import React, {Component} from 'react';
import axios from 'axios';

export default class ModalAdminPrivilegeInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            listRole: [],
            role: null
        }
        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    }

    componentWillReceiveProps(){
        this.setState({
            listRole: this.props.listRole
        })
    }

    render(){
        const { listRole, role } = this.state;
        return (
            <div>
                <div className="modal fade" id="ModalAdminPrivilegeInfo" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title text-center w-100" id="exampleModalCenterTitle">Privilege Information</h3>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th width={100} scope="col">Cấp độ</th>
                                            <th scope="col">Vai trò</th>
                                            <th scope="col">Quyền hạn</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listRole.map( (element, index)=>{
                                            return (
                                                <tr key={index}>
                                                    <td scope="col">{element.level}</td>
                                                    <td scope="col">{element.role}</td>
                                                    <td scope="col">{element.privilege}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}