import React, {Component} from 'react';
import axios from 'axios';
import ModalUpdatePackage from './components/ModalUpdatePackage';
import ModalDeletePackage from './components/ModalDeletePackage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

function mapLanguageToDisplay(language) {
    switch (language) {
        case "vi":
            return "Ikara";
        case "en.yokara":
            return "Yokara";
        case "lo.okara":
        case "mz.okara":
        case "tl.okara":
        case "ht.okara":
        case "pe.okara":
            return "Okara";
        default:
            return language;
    }
}

export default class PackageManagerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            packages: [],
            cPackage: null
        }

        this.addNewPackage = this.addNewPackage.bind(this);
        this.UpdatePackage = this.UpdatePackage.bind(this);
        this.getPackageList = this.getPackageList.bind(this);
        this.setMaxPackage = this.setMaxPackage.bind(this);
    }

    componentWillMount() {
        this.getPackageList();
    }

    getPackageList() {
        axios.post(global.config.apiDomain + '/rest/packages')
        .then(res => {
            this.setState({
                packages: res.data
            })
        })
    }

    addNewPackage() {
        var cPackage = {
            language: "",
            packageName: "",
            platform: "",
            url: "",
            maxVersion: ""
            // isForceUpdate: "",
            // apkUrl: ""
        }
        this.setState({
            cPackage : cPackage
        })
    }

    

    UpdatePackage(cPackage) {
        this.setState({
            cPackage: cPackage
        })
    }

    setMaxPackage(cPackage) {
        axios.post(global.config.apiDomain + '/rest/packages/setPackageName', cPackage)
            .then(res => {
                console.log(res);
                toast.success('Cập nhật thành công');
                this.getPackageList();
            })
            .catch(error => {
                console.error(error);
            });
    }

    render() {
        const {cPackage, packages} = this.state;
        return (
            <>
                <ModalUpdatePackage 
                    cPackage={cPackage}
                    resetList={this.getPackageList}></ModalUpdatePackage>
                <ModalDeletePackage
                    cPackage={cPackage}
                    resetList={this.getPackageList}></ModalDeletePackage>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí package</h1>
                        <button 
                            className="btn btn-red mb-1" 
                            data-toggle="modal" 
                            data-target="#modalUpdatePackage"
                            onClick={this.addNewPackage}
                            >+ Tạo package
                        </button>
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th scope="col">STT</th>
                                <th scope="col">Tên App</th>
                                <th scope="col">Platform</th>
                                <th scope="col">PackageName</th>
                                <th scope="col">MaxVersion</th>
                                <th scope="col">Link</th>
                                <th scope="col">Max package</th>
                                <th scope="col">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {packages.map((cPackage, index) => {
                                return (
                                    <tr key={cPackage.id}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{mapLanguageToDisplay(cPackage.language)}</td>
                                        <td>{cPackage.platform}</td>
                                        <td > {cPackage.packageName} </td>
                                        <td> {cPackage.maxVersion} </td>
                                        <td> {cPackage.url} </td>
                                        {cPackage.checkMaxPackage ? (
                                            <td><FontAwesomeIcon icon={faCheck}/></td>
                                        ) : (
                                            <td></td>
                                        )}
                                        <td>
                                            <button 
                                                onClick={() => {this.setMaxPackage(cPackage)}}
                                                className="btn btn-red"
                                                >Set Max Package</button>
                                            <button 
                                                onClick={() => {this.UpdatePackage(cPackage)}}
                                                className="btn btn-red ml-sm-2"
                                                data-toggle="modal" 
                                                data-target="#modalUpdatePackage"
                                                >Sửa</button>
                                            <button 
                                                className="btn btn-red ml-sm-2"
                                                data-toggle="modal" 
                                                data-target="#modalDeletePackage"
                                                onClick={() => {this.UpdatePackage(cPackage)}}
                                                >Xóa</button>
                                        </td>
                                    </tr>                                
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}