import React, { Component } from 'react';
import {
	Link
} from "react-router-dom";

const $ = window.$;

class TopRecordingsItem extends Component {

	constructor(props) {
		super(props);

		// this.state = {
		// 	avatar : this.props.recording.owner.profileImageLink
		// }
		

		this.handleImage = this.handleImage.bind(this);
	}

	handleImage(_id) {
		document.getElementById("avatar#"+_id).src = "https://picsum.photos/200";
	}

	render() {
		const {index, recording} = this.props;
		const {owner, _id, song, viewCounter, onlineRecordingUrl, owner2} = recording;
		if(recording == null) {
			return <></>
		}
		return (
			<div className={`item item_${index}`}>
				<div className="number">{index}</div>
				<div className="avatar">
					<img 
						id={"avatar"+ "#" +_id} 
						src={owner.profileImageLink == null || owner.profileImageLink == "" ? "https://picsum.photos/200" : owner.profileImageLink} 
						alt={`Avatar ${owner.name}`} 
						onError={() => this.handleImage(_id)}
						/>
				</div>
				<Link to={onlineRecordingUrl} className="phone" title={song.songName}>
				{song.songName}
                        <span className="d-none d-sm-block">
						<span className="song_name">{owner.name} {owner2 != null ? " & " + owner2.name : ""}</span></span>
					<span className="d-sm-none">
						<span className="song_name">{owner.name} {owner2 != null ? " & " + owner2.name : ""}</span>
					</span>
				</Link>
				<div className="listen-count">
					{viewCounter}
            	</div>
			</div>
		);
	}
}

export default TopRecordingsItem;