import Zalo from 'zalo-sdk';

const ZaloSocial = Zalo.ZaloSocial;

var zsConfig = {
    appId: '1229622658815182747',
    redirectUri: "https://www.yokara.com/login/zalo-callback",
    secretkey: 'K8CO880nUG2SeSlLGA3G'
};

if(window.location.hostname.includes("ikara")) {
    zsConfig = {
        appId: '259192588921010162',
        redirectUri: "https://www.ikara.co/login/zalo-callback",
        secretkey: 'W6x1aOEjt5V7VQ1Nvo6s'
    };
}

const ZSClient = new ZaloSocial(zsConfig);
export default ZSClient;