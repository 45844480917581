import React, { Component } from 'react';
import axios from 'axios';

export default class RecordingVipUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recordingVipUsers: [],
            cursor: null,
            loading: false,
            statusButtonLoadPage: false
        };
    }

    componentWillMount() {
        this.fetchRecoringVipUsers();
    }

    fetchRecoringVipUsers = () => {
        const { loading } = this.state;
        if (!loading) {
            this.setState({
                loading: true
            });
            let params = {
                cursor: this.state.cursor
            }
            axios.get(global.config.apiDomain + '/rest/recordingvip/user', { params })
                .then(res => {
                    let status = true;
                    console.log("res: ", res.data.recordingVipUsers)
                    if (res.data.recordingVipUsers.length < 5) {
                        status = false;
                    }

                    this.setState({
                        recordingVipUsers: this.state.recordingVipUsers.concat(res.data.recordingVipUsers),
                        cursor: res.data.cursor,
                        statusButtonLoadPage: status,
                        loading: false
                    })

                });
        }
    }

    render() {
        return (
            <div id="content">
                <div className="container-fluid">
                    <h1 className="text-center">Danh sách User có thẻ xử lý</h1>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">UID</th>
                                <th scope="col">Tên</th>
                                <th scope="col">Số lượng</th>
                                {/* <th scope="col">Thời gian</th> */}
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.recordingVipUsers && this.state.recordingVipUsers.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.uid}</td>
                                        <td>{item.username}</td>
                                        <td>{item.noItem}</td>
                                        {/* <td>{new Date(item.addTime).toLocaleString()}</td> */}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    {
                        this.state.statusButtonLoadPage ?
                            (<div onClick={this.fetchRecoringVipUsers} className="view_more w-100 text-center mb-lg-4">
                                <a className="d-none d-md-block">
                                    <span>{this.state.loading ? "ĐANG TẢI ..." : "TẢI THÊM"}</span>
                                </a>
                                <a className="view_more_mobile d-md-none">
                                    <i className="fa fa-angle-down" aria-hidden="true" />
                                </a>
                            </div>) :
                            ('')
                    }
                </div>
            </div>
        );
    }
}