import React, { Component } from 'react';



class VipPage extends Component {
    render() {
        return (
            <section id="content">
                <div className="package-intro-vip">
                    <div className="container">
                        <div className="intro-package">
                            <img src={require('../assets/images/15788860375e1be395754a3.png')} className="d-none d-md-block" alt="intro package vip" />
                            <img src={require('../assets/images/15788860445e1be39c5fd4b.png')} className="d-md-none" alt="intro package vip" />
                            <div className="info">
                                <div className="gradient-box">
                                    <div className="icon">
                                        <img src={require('../assets/images/vip-btn.svg')} alt="vip button" />
                                    </div>
                                    <h1 className="name">Gói VIP</h1>
                                    <h2 className="price">100.000 Đ</h2>
                                    <div className="line-star">
                                        <span>
                                            <img src={require('../assets/images/icon_star_3.svg')}  alt="star icon" />
                                        </span>
                                    </div>
                                    <ul className="sapo">
                                        <li className="d-flex align-items-center">
                                            <span className="sapo_icon nth-0" />
                                            <span>Nhận ngay 65 phút hát (06 Giftcode) sau khi gia hạn gói.
                      </span>
                                        </li>
                                        <hr />                                <li className="d-flex align-items-center">
                                            <span className="sapo_icon nth-1" />
                                            <span>Icon vương miện VIP ở avatar
                      </span>
                                        </li>
                                        <hr />                                <li className="d-flex align-items-center">
                                            <span className="sapo_icon nth-2" />
                                            <span>Thời hạn VIP: 30 ngày kể từ ngày mua (không áp dụng thứ 7, CN, ngày Lễ, Tết và các ngày cao điểm)</span>
                                        </li>
                                        <hr />
                                        <div className="upgrade-vip-btn" onclick="chooseMethodPayment()">
                                            <a onclick="return false;" className="d-flex align-items-center">
                                                <img src={require('../assets/images/vip-btn.svg')} alt="vip button" />
                                                <span>Nâng cấp VIP ngay</span>
                                            </a>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div><div className="modal fade" id="modalChooseMethodPayment" tabIndex={-1} role="dialog" aria-labelledby="modalChooseMethodPaymentLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body"><div className="wrap-cssload-thecube" id="wrap-cssload-thecube">
                                    <div className="lds-spinner">
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                    </div>
                                </div>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <h5 className="title">Chọn phương thức thanh toán</h5>
                                    <div className="list-mehod-payment">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="item zalopay" data-type="airpay" data-method="AIRPAY">
                                                    <img src="./vip_files/airpay.jpg" />
                                                    <span>AirPay</span>
                                                    <span className="text-promotion">Chỉ 70.000đ</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="item " data-type="viettelpay" data-method="VIETTEL_PAY">
                                                    <img src="./vip_files/viettelpay.jpg" />
                                                    <span>ViettelPay</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="item " data-type="vnpay" data-method="VNPAY">
                                                    <img src="./vip_files/vnpay.jpg" />
                                                    <span>VNPAY</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="item " data-type="momo" data-method="MOMO">
                                                    <img src="./vip_files/momo.jpg" />
                                                    <span>MoMo</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><div className="modal fade" id="modalsendPayment" tabIndex={-1} role="dialog" aria-labelledby="modalsendPaymentLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div id="resulte_payment">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="modalsendPaymentLabel">QR code - Đăng ký
                      gói Gói VIP</h5>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <p id="resulte_payment_error" className="text-danger">
                                                    Message Ajax
                        </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Hủy thanh toán</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default VipPage;