import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export default class ModalNewUserOfUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newUsers: null
        };
       
        this.getAllNewUserOfSeller = this.getAllNewUserOfSeller.bind(this);
    }

    getAllNewUserOfSeller() {
        let account = this.props.account;
        if(account) {
            var json = {
                id: account.uid,
            }
            axios.post(global.config.apiDomain + '/rest/seller/getAllCustomerOfSeller', json, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.setState({
                    newUsers: res.data.models
                });
            })
        }
    }

    handleDeleteUser(item) {
        var json = {
            id: item.id
        };
        axios.post(global.config.apiDomain + '/rest/admin/deleteNewUserInListSeller', json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if(res.data.status == "OK") {
                toast.success('Xóa thành công'); 
                this.getAllNewUserOfSeller();
            }
        }).catch(error => {
            toast.success('Xóa thất bại'); 
            console.error('Error deleting user:', error);
        });
    }

    componentDidUpdate() {
        const { newUsers } = this.state;
        if (newUsers == undefined || newUsers == null) {
            this.getAllNewUserOfSeller();
        }
    }

    render(){
        const { newUsers} = this.state;

        return (
            <>
                <table className="table table-hover">
                            <thead>
                            <tr className="">
                                <th className="align-middle" scope="col">Id user được mời</th>
                                <th className="align-middle" scope="col">Ngày đăng nhập</th>
                                <th className="align-middle" scope="col">Lv</th>
                                <th className="align-middle" scope="col">Lv VIP</th>
                                <th className="align-middle" scope="col">Thời gian hoạt động gần nhất</th>
                                <th className="align-middle" scope="col">Tổng icoin nạp</th>
                                <th className="align-middle" scope="col">Tổng số hoa hồng nhận được</th>
                                <th className="align-middle" scope="col">Xóa User được mời</th>
                            </tr>
                            </thead>
                            <tbody>
                                {newUsers && newUsers.map( (item)=>{
                                    let addTime = new Date(item.addTime);
                                    let lastAccess = new Date(item.lastAccess);
                                    if(!item.isDelete) {
                                        return (
                                            <tr>
                                                <td className="align-middle" scope="col">{item.uid}</td>
                                                <td className="align-middle" scope="col">{addTime.toLocaleString()}</td>
                                                <td className="align-middle" scope="col">{item.level}</td>
                                                <td className="align-middle" scope="col">{item.vipStatus.vipLevel}</td>
                                                <td className="align-middle" scope="col">{lastAccess.toLocaleString()}</td>
                                                <td className="align-middle" scope="col">{item.totalIcoin != null ? item.totalIcoin : 0}</td>
                                                <td className="align-middle" scope="col">{item.totalCommission != null ? item.totalCommission : 0}</td>
                                                <td className="align-middle" scope="col">
                                                    <button className='btn btn-danger'
                                                        onClick={()=>{
                                                            if(window.confirm("Bạn có chắc là muốn xóa ?")){
                                                                this.handleDeleteUser(item)
                                                            }
                                                        }}
                                                        >Xóa
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                })}
                            </tbody>
                </table>

            </>
        )
    }
}