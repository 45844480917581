import React, { Component } from 'react';
import axios from 'axios';
import { Base64 } from 'js-base64'
import { withTranslation } from 'react-i18next';

import '../../../assets/css/toastdownloadapp.css';
class ToastDownloadApp extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isIOS: false,
            recordingId: 0,
            universalLink: ""
        }

        this.closeToast = this.closeToast.bind(this);
        this.newGetKey = this.newGetKey.bind(this);
        this.dowloadOrOpenApp = this.dowloadOrOpenApp.bind(this);
        this.getInfo = this.getInfo.bind(this);
        this.getUniversalLink = this.getUniversalLink.bind(this);

    }

    componentDidMount() {
            //Kiểm tra platfom thiết bị hiện tại
            if (navigator && navigator.userAgent.match(/(iPhone|iPod|iPad)/)) {
                this.setState({
                    isIOS: true
                });
            }
            else {
                this.setState({
                    isIOS: false
                });
            }
            this.getInfo();
    }

    getInfo() {
        // Lấy recording ID từ URL hiện tại
        let currentUrl = window.location.href;

        // Xóa các param của Facebook nếu có
        const urlObj = new URL(currentUrl);
        urlObj.search = ''; // Xóa tất cả các query params

        // Lấy URL đã làm sạch
        currentUrl = urlObj.toString();
        console.log(currentUrl);
        let newRecordingId;

        // Trường hợp 1: URL có dạng /recording/tên-bài-hát-ID
        const longUrlMatch = currentUrl.match(/\/recording\/.*-(\d+)$/);
        if (longUrlMatch && longUrlMatch[1]) {
            newRecordingId = longUrlMatch[1];
        } else {
            // Trường hợp 2: URL có dạng /recording/ID
            const shortUrlMatch = currentUrl.match(/\/recording\/(\d+)$/);
            if (shortUrlMatch && shortUrlMatch[1]) {
                newRecordingId = shortUrlMatch[1];
            }
        }

        if (newRecordingId) {
            this.getUniversalLink(newRecordingId);
            this.setState({
                recordingId: newRecordingId
            });

        } else {
            console.error('Không tìm thấy recording ID trong URL');
            // Có thể xử lý thêm ở đây, ví dụ: chuyển hướng đến trang lỗi
        }
    }

    getUniversalLink(id) {
        let json = {
            uid: id,
            domain: window.location.hostname,
            type: "RECORDING",
            app: "YOKARA"
        }
        console.log("get univer:" + json.uid);
        const jsonBase64 = Base64.encode(JSON.stringify(json));
        var pass = this.newGetKey(jsonBase64, 7123358024, 3564);
        var keys = jsonBase64 + "-" + pass;

        var url = "/GetDeepLinkFromParam?parameters=" + keys;
        axios.post(url, json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            var universal = res.data;
            console.log(res.data);
            const domainUrl = "https://ikara-development.appspot.com/sharerecording";
            universal = universal.replace("yokara://recording", domainUrl);
            this.setState({
                universalLink: universal
            })
        }, err => {
            this.setState({
                universalLink: "https://apps.apple.com/vn/app/yokara-sing-karaoke-record/id894927596"
            })
        });
    }

    dowloadOrOpenApp() {
        const { recordingId } = this.state
        let json = {
            uid: recordingId,
            domain: window.location.hostname,
            type: "RECORDING",
            app: "YOKARA"
        }

        const jsonBase64 = Base64.encode(JSON.stringify(json));
        var pass = this.newGetKey(jsonBase64, 7123358024, 3564);
        var keys = jsonBase64 + "-" + pass;

        var url = "/GetDeepLinkFromParam?parameters=" + keys;
        axios.post(url, json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            var deeplink = res.data;
            console.log(res.data);

            // Kiểm tra xem navigator có hỗ trợ scheme URL không
            if (navigator && navigator.userAgent.match(/(Android)/)) {
                window.location.replace(deeplink);
                setTimeout(function () {
                    if (document.visibilityState === 'visible') {
                        var intentUrl = 'https://play.google.com/store/apps/details?id=com.yokara.v3'; // URL Scheme cho Android
                        window.location.href = intentUrl;
                    }
                }, 1000);
            } else {
                // Chuyển hướng người dùng đến trang Store nếu không hỗ trợ
                //window.location.href = 'https://play.google.com/store/apps/details?id=com.yokara.v3'; // Link đến Store trên Android
                window.location.href = 'https://play.google.com/store/apps/details?id=com.yokara.v3'; // Link đến App Store trên iOS
            }
        }, err => {
            console.log("err get deeplink:");
            console.log(err);
            window.location.replace("https://play.google.com/store/apps/details?id=com.yokara.v3");
        });
    }

    newGetKey(data, password, password2) {
        var listOfNumbers = "" + password2;
        for (var i = 0; i < data.length; i++) {
            if (data.charAt(i) >= '0' && data.charAt(i) <= '9') {
                listOfNumbers = [listOfNumbers.slice(0, 0), data.charAt(i), listOfNumbers.slice(0)].join('');
            }
        }
        var originalKey = null;
        if (listOfNumbers.length > 15)
            originalKey = listOfNumbers.substring(0, 15);
        else
            originalKey = listOfNumbers;
        return Number(originalKey) + Number(password);
    }

    closeToast() {
        this.setState({
            toogleToast: false
        })
    }

    render() {

        const { isIOS, universalLink } = this.state;

        return (
            <div className="recording-yokara-header col-md-8">
                <div className="recording-yokara-info">
                    <div className='recording-yokarasvg'>
                        <svg width={70} height={70} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Group 8644">
                                <g id="Clip path group">
                                    <mask id="mask0_555_282" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x={0} y={0} width={70} height={70}>
                                        <g id="clippath">
                                            <path id="Vector" d="M61.7969 0H8.20312C3.67266 0 0 3.67266 0 8.20312V61.7969C0 66.3273 3.67266 70 8.20312 70H61.7969C66.3273 70 70 66.3273 70 61.7969V8.20312C70 3.67266 66.3273 0 61.7969 0Z" fill="white" />
                                        </g>
                                    </mask>
                                    <g mask="url(#mask0_555_282)">
                                        <g id="Group">
                                            <path id="Vector_2" d="M70 8.20312C70 3.67266 66.3273 0 61.7969 0H8.20312C3.67266 0 0 3.67266 0 8.20312V61.7969C0 66.3273 3.67266 70 8.20312 70H61.7969C66.3273 70 70 66.3273 70 61.7969V8.20312Z" fill="url(#paint0_linear_555_282)" />
                                        </g>
                                    </g>
                                </g>
                                <g id="Group 8641">
                                    <g id="Group_2">
                                        <path id="Vector_3" d="M49.3701 12.276V15.5928L49.7461 15.9852V13.5119L52.3 12.5986V14.6973L52.6856 15.0445V11.0742L49.3688 12.2732L49.3701 12.276ZM52.3014 12.1775L49.7434 13.1182V12.5563L52.3014 11.6211V12.1789V12.1775Z" fill="white" />
                                        <path id="Vector_4" d="M49.0428 16.8761C49.576 16.5944 49.87 16.1036 49.7004 15.781C49.5295 15.4569 48.958 15.4228 48.4248 15.7044C47.8916 15.9861 47.5977 16.4769 47.7672 16.7995C47.9381 17.1236 48.5082 17.1577 49.0428 16.8761Z" fill="white" />
                                        <path id="Vector_5" d="M51.9823 15.9319C52.5155 15.6502 52.8094 15.1594 52.6399 14.8368C52.469 14.5127 51.8989 14.4786 51.3643 14.7602C50.8311 15.0418 50.5371 15.5327 50.7067 15.8553C50.8776 16.1793 51.4477 16.2135 51.9823 15.9319Z" fill="white" />
                                        <path id="Vector_6" d="M58.183 22.3883C58.4714 22.2366 58.63 21.9713 58.5384 21.795C58.4468 21.62 58.1378 21.6008 57.8494 21.754C57.5609 21.9057 57.4023 22.171 57.4939 22.3473C57.5869 22.5223 57.8945 22.5415 58.1843 22.3883H58.183Z" fill="white" />
                                        <path id="Vector_7" d="M59.1845 21.0627C59.1845 21.0627 59.2132 20.8563 58.9439 20.8152C58.8495 20.7879 58.6321 20.7373 58.5624 20.6156V21.9049L58.3464 21.6684V20.1016C58.3464 20.1016 58.6335 20.3463 58.7251 20.3996C58.8454 20.4789 59.2515 20.6115 59.1845 21.06V21.0627Z" fill="white" />
                                        <path id="Vector_8" d="M55.5713 18.5593C56.0853 18.2886 56.3684 17.8155 56.2043 17.5038C56.0402 17.1921 55.4893 17.1579 54.9752 17.43C54.4611 17.7007 54.1781 18.1737 54.3422 18.4854C54.5062 18.7972 55.0572 18.8313 55.5713 18.5593Z" fill="white" />
                                        <path id="Vector_9" d="M57.3581 16.2081C57.3581 16.2081 57.4101 15.8417 56.9274 15.7679C56.7593 15.7187 56.371 15.6298 56.2493 15.4138V17.712L55.8651 17.2909V14.4991C55.8651 14.4991 56.3765 14.9353 56.5392 15.031C56.7525 15.1718 57.4771 15.4097 57.3581 16.2081Z" fill="white" />
                                    </g>
                                    <g id="Group_3">
                                        <path id="Vector_10" d="M29.5399 63.3943C42.1591 57.7136 44.538 61.9054 53.247 63.6322C47.4761 61.9929 40.1179 55.6451 29.5399 63.3943Z" fill="white" />
                                        <path id="Vector_11" d="M17.7547 30.4808C17.3637 26.2903 17.026 22.0931 16.6022 17.9067C16.5557 17.4556 16.2413 11.9827 15.6219 11.995C14.3682 12.021 13.4536 23.355 15.0778 29.0698C16.7786 35.0567 17.3377 36.212 15.0094 31.111C12.1534 24.8521 14.7374 42.8798 23.7854 50.6345C23.9645 50.789 24.1477 50.9312 24.3323 51.0638C22.6547 49.0868 21.2807 46.8993 20.3155 44.6489C18.4397 40.2712 18.195 35.1921 17.7547 30.4808Z" fill="white" />
                                        <path id="Vector_12" d="M57.6838 26.3919C55.2174 27.1138 53.2842 28.3976 52.7222 28.816C52.8699 27.9792 55.6125 25.5019 56.6721 24.7664C55.5496 24.8046 54.259 25.9558 53.5002 26.1951C53.4236 26.2197 53.2855 26.273 53.2062 26.2757C53.1803 26.2757 53.1556 26.2771 53.131 26.2785C52.9615 26.273 52.7797 26.1459 52.7072 26.0898C52.6976 26.0816 52.6881 26.0734 52.6771 26.0652L52.673 26.0625C48.9037 24.765 43.3967 27.4214 42.009 34.241C41.6699 35.6 41.607 36.572 41.2926 39.0453C40.6445 42.6027 39.5808 44.7519 37.6312 47.1007C34.5017 50.8728 28.7035 54.2265 24.2971 51.0656C28.3658 55.8603 34.216 59.415 40.3506 59.6132C49.2605 59.9017 54.2207 53.4595 55.0724 45.5859C55.3049 43.4422 55.3431 41.4474 55.1162 38.9236C55.0424 38.1074 54.922 37.108 54.7717 36.2097C54.7211 35.8638 54.6869 35.5589 54.6199 35.2541C54.4941 34.6771 54.4217 34.2765 54.3697 33.9634C54.1961 32.9244 54.058 31.5189 54.2371 30.3814C54.2412 30.365 54.2439 30.3486 54.248 30.3308C54.2521 30.3103 54.2535 30.2912 54.2521 30.2707C54.2631 30.2064 54.274 30.1421 54.2863 30.082C54.6678 28.2595 57.0344 26.8035 57.6824 26.3933L57.6838 26.3919Z" fill="white" />
                                    </g>
                                </g>
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_555_282" x1="5.66973" y1="65.4172" x2="66.0064" y2="2.84512" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#F16041" />
                                    <stop offset={1} stopColor="#F02C6A" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className="recording-header-text">
                        <h2>Yokara</h2>
                        <p>Hát Karaoke Thu Âm</p>
                    </div>
                </div>
                {isIOS ? (
                    <a href={universalLink} className="recording-download-button">Tải Yokara</a>
                ) : (
                    <button className="recording-download-button-button" onClick={this.dowloadOrOpenApp} >Tải Yokara</button>
                )}
            </div>
        )
    }
}

export default withTranslation()(ToastDownloadApp)
