import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import '../../../assets/css/toastdownloadapp.css';
import { detectApp } from '../../../common/utils.js';
class ToastDownloadApp extends Component {
    constructor(props) {
        super(props)

        this.state = {
            toogleToast: true
        }

        this.closeToast = this.closeToast.bind(this);

    }

    closeToast() {
        this.setState({
            toogleToast: false
        })
    }

    render() {

        const { toogleToast } = this.state;
        const { t } = this.props;
        let toastStatus = "show";
        if (!toogleToast) {
            toastStatus = "hide";
        }

        return (
            <div className="px-xl-4 pb-4">
            <div className={"toast " + toastStatus} id="toastDownloadApp" role="alert" aria-live="assertive" aria-atomic="true">
                {/* <div className="toast-header">
                    <button className="float-right" onClick={this.closeToast} type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div> */}
                <div className="p-0 toast-body background">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="d-none d-xl-block">
                            <img className="head-banner"  src={require("../../../assets/images/headbanner.png")} />
                        </div>
                        <div className="icon-download p-2">
                            <a target="_blank" href={t("recording_page.toast.chplay_url", "https://play.google.com/store/apps/details?id=co.ikara.v2")}>
                                <img className="" src={require("../../../assets/images/downloadchplay.png")} />
                            </a>
                        </div>
                        {!detectApp("tl.okara") && !detectApp("ht.okara") && (
                            <div className="icon-download p-2">
                            <a target="_blank" href={t("recording_page.toast.appstore_url", "https://apps.apple.com/app/id711994026")}>
                                <img src={require("../../../assets/images/downloadappstore.png")} />
                            </a>
                        </div>
                        )}
                        
                        <div className="description p-2 d-none d-sm-block">
                            <p className="m-0">{t("recording_page.toast.app_name", "Ứng dụng IKARA")}</p>
                            <p className="m-0 icon-star"><i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i></p>
                            <p className="m-0">{t("recording_page.toast.download_free", "Tải miễn phí")}</p>
                        </div>
                        {/* <div className="d-none d-sm-block">
                            <img width={115} height={115} src={require("../assets/images/qr-android.png")} />
                        </div> */}

                        {/* <button onClick={this.closeToast} type="button mt-2" className="ml-2 mb-1 border-0 close">
                            <span style={{fontSize: "37px"}} >×</span>
                        </button> */} 
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

export default withTranslation()(ToastDownloadApp)
