import React ,{useEffect, useState}  from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';

function ClientLogDetailPage() {
    const [log, setLogDetail] = useState({}); 
    const [file, setFile] = useState([]);
    const [contentFile , setContentFile] = useState([]);

    const {id} = useParams();

    const FilterValue = {
        ALL: { text: 'ALL', color: '#000000' }, 
        VERBOSE: { text: 'VERBOSE', color: '#CCCCCC' }, 
        ERROR: { text: 'ERROR', color: '#FF0000' }, 
        INFO: { text: 'INFO', color: '#0000FF' }, 
        WARNING: { text: 'WARNING', color: '#FFA500' },
        DEBUG: { text: 'DEBUG', color: '#008000' }, 
    };

    const handleSelectChange = (e) => {
        if (e.target && e.target.value) {
            if(e.target.value == FilterValue.ALL.text){
                setFile(contentFile);
            } else {
                const filterInFile = contentFile.filter(item => item.level === e.target.value);  
                setFile(filterInFile.length > 0 ? filterInFile : []);
            }
        }
    };
    return (
        <div id="content">
            <h1 className="text-center"> Client Log Detail</h1>
            <div className="filter-container">
            <label>Filter : </label>
            <select onChange={handleSelectChange}>
            {Object.entries(FilterValue).map(([key, value]) => (
                <option key={key} value={key.text}>{value.text}</option>
            ))}
           </select>
            </div>
            <table className="table table-bordered table-hover">
                    <thead className="table-primary">
                        <tr>
                            <th>Level</th>
                            <th>Time </th>
                            <th>App Name</th>
                            <th>Tag</th>
                            <th>Text</th>
                        </tr>
                    </thead>
                    <tbody>
                            {
                                file  && file.length >0 &&  file.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{ textAlign: "center", verticalAlign: "middle" , color: FilterValue[item.level].color }}>{item.level}</td>
                                            <td style={{ textAlign: "center", verticalAlign: "middle" , color: FilterValue[item.level].color }}  >{new Date(item.date_time).toLocaleString()}</td>
                                            <td style={{ textAlign: "center", verticalAlign: "middle"  , color: FilterValue[item.level].color }} >{item.app_name}</td>
                                            <td style={{ textAlign: "center", verticalAlign: "middle" , color: FilterValue[item.level].color }} >{item.tag}</td>
                                            <td ><textarea readOnly style={{ width: '100%' , color: FilterValue[item.level].color , textAlign: "center" }} value={item.description} rows="4"  /></td>
                                        </tr>
                                    )
                                })
                            }
                    </tbody>
                </table>
        </div>
    );
}

export default ClientLogDetailPage;