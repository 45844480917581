import React, { Component } from 'react';
import axios from 'axios';
import bindModel from '../../common/bindModel.js';
import ModalUpdateGiftLevel from './components/ModalUpdateGiftLevel';
import ModalDeleteGiftLevel from './components/ModalDeleteGiftLevel';

export default class GiftLevelManagerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            uid: null,
            giftLevelList: [],
            giftLevel: null,
        }
        this.fetchGiftLevel = this.fetchGiftLevel.bind(this);
        this.handleGiftLevel = this.handleGiftLevel.bind(this);
    }

    fetchGiftLevel() {
        axios.get(global.config.apiDomain + '/rest/gift-level')
            .then(res => {
                this.setState({
                    giftLevelList: res.data
                })
            })
            .catch(error => {
                console.error("Error:", error);
            });;
    }

    componentDidMount() {
        this.fetchGiftLevel();
    }

    handleGiftLevel(giftLevel) {
        this.setState({
            giftLevel: giftLevel
        })
    }

    render() {
        const model = bindModel(this);
        return (
            <>
                <ModalUpdateGiftLevel 
                    giftLevel={this.state.giftLevel}
                    resetList={this.fetchGiftLevel}></ModalUpdateGiftLevel>
                <ModalDeleteGiftLevel 
                    giftLevel={this.state.giftLevel}
                    resetList={this.fetchGiftLevel}></ModalDeleteGiftLevel>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí quà level</h1>
                        <button 
                            className="btn btn-red mb-1" 
                            data-toggle="modal" 
                            data-target="#modalUpdateGiftLevel"
                            onClick={this.getCreateGiftLevelForm}>+ Tạo quà level
                        </button>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">STT</th>
                                    <th scope="col">Level</th>
                                    <th scope="col">Tên</th>
                                    <th scope="col">Quà tặng</th>
                                    <th scope="col">Icoin</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.giftLevelList.map((giftLevel, index) => {
                                    return (
                                        <tr key={giftLevel.id}>
                                            <th scope="row">{index + 1}</th>
                                            <td> {giftLevel.level} </td>
                                            <td> {giftLevel.name} </td>
                                            <td> <img src={giftLevel.image}></img></td>
                                            <td> {giftLevel.icoin} </td>
                                            <td>
                                                <button 
                                                    onClick={() => {this.handleGiftLevel(giftLevel)}}
                                                    className="btn btn-red"
                                                    data-toggle="modal" 
                                                    data-target="#modalUpdateGiftLevel">
                                                        Sửa
                                                </button>
                                                <button 
                                                    className="btn btn-red ml-sm-2"
                                                    data-toggle="modal" 
                                                    data-target="#modalDeleteGiftLevel"
                                                    onClick={() => {this.handleGiftLevel(giftLevel)}}>
                                                        Xóa
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}
