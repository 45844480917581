import React, { Component } from 'react';
import bindModel from '../../../common/bindModel.js';
import axios from 'axios';
import { toast } from 'react-toastify';
import SVGA from 'svgaplayerweb';
import { Parser, Player } from 'svga';
import lodash from 'lodash';
import $ from 'jquery';
import getImageSize from 'image-size-from-url';


import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';

export default class ModalSetGift extends Component {

    constructor(props) {
        super(props);

        this.state = {
            gift: null,
            loading: false,
            register: true,

            gifts: [],
            giftsTemp: [],
            giftShow: null,
            nameSearch: "",
            idSearch: "",
            buyPriceSort: "",
            sellPriceSort: "",
            orderSort: "",
            status: "",
            show: "",
            type: "",
            tag: "",
            keyCupForEvent: null,
            cupTop: null,
        }

        // this.saveGift = this.saveGift.bind(this);
        this.uploadThumbnailToS3 = this.uploadThumbnailToS3.bind(this);
        this.uploadAnimationToS3 = this.uploadAnimationToS3.bind(this);

        this.updateStartDate = this.updateStartDate.bind(this);
        this.updateEndDate = this.updateEndDate.bind(this);




// ////
        this.getGifts = this.getGifts.bind(this);
        this.search = this.search.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.reset = this.reset.bind(this);
        this.orderByBuyPrice = this.orderByBuyPrice.bind(this);
        // this.orderBySellPrice = this.orderBySellPrice.bind(this);
        // this.orderByOrder = this.orderByOrder.bind(this);
        this.newGift = this.newGift.bind(this);
        this.setDataInUrl = this.setDataInUrl.bind(this);
    }

    componentWillMount() {
        this.getGifts();
    }

    componentDidMount() {
        $("#modalSetGift").on("hidden.bs.modal", function () {
            this.setState({
                gift: null
            });
        }.bind(this));

        console.log("Did mount")
        $("#modalShowGift").on("hidden.bs.modal", function () {
            this.setState({
                giftShow: null
            });
        }.bind(this));

        $("#modalShowGiftAnimation").on("hidden.bs.modal", function () {
            this.setState({
                giftShow: null
            });
        }.bind(this));

    }

    async loadSVGA(id, url) {
        const parser = new Parser()
        const svga = await parser.load(url)

        const player = new Player(document.getElementById(id))
        await player.mount(svga)

        player.onStart = () => console.log('onStart')

        player.start()
    }

    getGifts() {
        axios.post(global.config.apiDomain + '/rest/gifts')
            .then(res => {
                this.setState({
                    gifts: res.data,
                    giftsTemp: res.data
                });
            });
    }

    newGift() {
        axios.post(global.config.apiDomain + '/rest/gifts/newGift')
            .then(res => {
                console.log(res.data);
                this.setState({
                    gift: res.data
                })
            });
    }

    search() {
        let { gifts, giftsTemp, nameSearch, idSearch, show, type, tag } = this.state;

        gifts = giftsTemp.filter(e => e.name.includes(nameSearch));
        gifts = gifts.filter(e => e.id.includes(idSearch));

        if (show.startsWith("!")) {
            gifts = gifts.filter(e => !e.show.includes(show.replace("!", "")) && !e.show.includes("NONE"));
        } else {
            gifts = gifts.filter(e => e.show.includes(show));
        }
        gifts = gifts.filter(e => e.type.includes(type));
        gifts = gifts.filter(e => e.tag.includes(tag));

        this.setState({
            gifts: gifts,
            giftsTemp: giftsTemp
        });
    }

    reset() {
        this.setState({
            nameSearch: "",
            idSearch: "",
            status: "",
            type: "",
            tag: "",
            gifts: this.state.giftsTemp
        });
    }

    handleChange(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value;

        this.setState({
            [name]: value
        });
    }


    orderByBuyPrice() {
        let { buyPriceSort, gifts } = this.state

        if (buyPriceSort === "ASC") {
            gifts = lodash.orderBy(gifts, "buyPrice", 'desc');
            buyPriceSort = "DESC";
        } else {
            gifts = lodash.orderBy(gifts, "buyPrice", 'asc');
            buyPriceSort = "ASC";
        }

        this.setState({
            buyPriceSort: buyPriceSort,
            gifts: gifts
        });
    }

    // orderByOrder() {
    //     let { orderSort, gifts } = this.state

    //     if (orderSort === "ASC") {
    //         gifts = lodash.orderBy(gifts, "order", 'desc');
    //         orderSort = "DESC";
    //     } else {
    //         gifts = lodash.orderBy(gifts, "order", 'asc');
    //         orderSort = "ASC";
    //     }

    //     this.setState({
    //         orderSort: orderSort,
    //         gifts: gifts
    //     });
    // }

    // orderBySellPrice() {
    //     let { sellPriceSort, gifts } = this.state

    //     if (sellPriceSort === "ASC") {
    //         gifts = lodash.orderBy(gifts, "sellPrice", 'desc');
    //         sellPriceSort = "DESC";
    //     } else {
    //         gifts = lodash.orderBy(gifts, "sellPrice", 'asc');
    //         sellPriceSort = "ASC";
    //     }

    //     this.setState({
    //         sellPriceSort: sellPriceSort,
    //         gifts: gifts
    //     });
    // }



    componentWillReceiveProps(props) {
        // $("#show-animation").children().replaceWith("<div></div>");
        let { cupTop } = props;
        if (cupTop != null) {
            this.setState({
                cupTop: cupTop,
            });
            //alert(this.state.keyCup);
        }
        // if (gift != null) {
        //     if (gift.id == "") {
        //         this.setState({
        //             gift: gift,
        //             register: true
        //         });
        //     } else {
        //         this.setState({
        //             gift: gift,
        //             register: false
        //         });

        //         if (gift.animatedUrl != null && gift.animatedUrl.endsWith("svga")) {
        //             $("#show-animation").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas2\"></div>");
        //             this.loadSVGA2(gift.animatedUrl);
        //         } else if (gift.animatedUrl != null) {
        //             $("#show-animation").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + gift.animatedUrl + "\"  />");
        //         }
        //     }
        // }
    }

    loadSVGA2(url) {
        var player = new SVGA.Player('#demoCanvas2');
        var parser = new SVGA.Parser('#demoCanvas2');
        parser.load(url, function (videoItem) {
            console.log(videoItem)
            player.setVideoItem(videoItem);
            player.startAnimation();
        })
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadAnimationToS3(event) {
        var target = event.target;
        //10495760
        if (target.files[0].size > 10495760 ){
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        this.setState({
            loading: true
        });

        var gift = this.state.gift;
        var bucketName = "ikara-data/images/cup";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    this.setState({
                        loading: false
                    });

                    gift.animatedUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.state.gift.animatedUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        gift: gift
                    });

                    $("#show-animation").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas\"></div>");
                    this.loadSVGA2(gift.animatedUrl);
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }

    uploadThumbnailToS3(event) {
        var target = event.target;
        if (target.files[0].size > 512999){
            toast.error("Kích thước file phải dưới 500kb.");
            return;
        }

        const fileReader1 = new FileReader();
        fileReader1.readAsDataURL(target.files[0]);

        fileReader1.onload =  (fe) => {
          const image = new Image();
          image.src = fe.currentTarget.result;
          image.onload = function(ie){
            let width = 0, height = 0;
            width = this.naturalWidth;
            height = this.naturalHeight;
            if (width != height || height > 256){
                toast.error("Kích thước ảnh tối đa 256px và Width = Height");
                return;
            }

            this.setState({
                            loading: true
                        });
                
                        var gift = this.state.gift;
                        var bucketName = "ikara-data/images/cup";
                        var extension = target.files[0].name.split('.').pop().toLowerCase();
                        var keyName = this.guid() + "." + extension;
                        var contentType = "image/jpeg";
                        if (extension == "png") contentType = "image/png";
                
                        var fileReader = new FileReader();
                
                        fileReader.onload = function () {
                            var fd = new FormData();
                            fd.append('file', target.files[0]);
                            fd.append('bucketName', bucketName);
                            fd.append('objectKey', keyName);
                            fd.append('contentType', contentType)
                            $.ajax({
                                url: global.config.apiDomain + '/web.UploadFile',
                                data: fd,
                                processData: false,
                                contentType: false,
                                type: 'POST',
                                success: function (data) {
                                    console.log(data)
                                    this.setState({
                                        loading: false
                                    });
                
                                    gift.url = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                                    this.setState({
                                        gift: gift
                                    });
                
                                }.bind(this),
                            });
                
                        }.bind(this);
                        fileReader.readAsArrayBuffer(target.files[0]);
            }.bind(this);
        }
    }

    // saveGift() {
    //     const { gift, register } = this.state;
        
    //     this.setState({
    //         loading: true
    //     });

    //     if (register) {
    //         axios.post(global.config.apiDomain + '/rest/gifts/create', JSON.stringify(gift), {
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         }).then(res => {
    //             this.setState({
    //                 loading: false
    //             });

    //             if (res.data.status == "OK") {
    //                 window.$("#modalUpdateGift").modal("hide");
    //                 this.props.resetList();
    //                 toast.success(res.data.message);
    //             } else {
    //                 toast.warning(res.data.message);
    //             }
    //         }, error => {
    //             this.setState({
    //                 loading: false
    //             });
    //             toast.error(error);
    //         })
    //     } else {
    //         axios.post(global.config.apiDomain + '/rest/gifts/save', JSON.stringify(gift), {
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         }).then(res => {
    //             this.setState({
    //                 loading: false
    //             });

    //             if (res.data.status == "OK") {
    //                 window.$("#modalUpdateGift").modal("hide");
    //                 this.props.resetList();
    //                 toast.success(res.data.message);
    //             } else {
    //                 toast.warning(res.data.message);
    //             }
    //         }, error => {
    //             this.setState({
    //                 loading: false
    //             });
    //             toast.error(error);
    //         })
    //     }
    // }

    updateStartDate(props) {
        let {gift} = this.state;
        let currentTime = props.value
        if(gift) {
            gift.startDate = currentTime;
            this.setState({
                gift: gift
            });
        }
        
    }

    updateEndDate(props) {
        let {gift} = this.state;
        let currentTime = props.value
        if(gift) {
            gift.endDate = currentTime;
            this.setState({
                gift: gift
            });
        }
        
    }
    setDataInUrl(id, cupUrlForEvent, cupTop){
        this.props.resetList(id, cupUrlForEvent, cupTop);
    }

    render() {
        const model = bindModel(this);
        const { gift } = this.state;
        const { gifts } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalSetGift" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Chọn CUP</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {true ? (
                                <>
                                <div className="input-group mb-3">
                                    <input
                                        name="idSearch"
                                        value={this.state.idSearch}
                                        onChange={this.handleChange}
                                        onKeyPress={(event) => {
                                            if (event.key === "Enter") {
                                                this.search()
                                            }
                                        }}
                                        type="text"
                                        className="form-control col-md-2 col-sm-6"
                                        placeholder="Nhập ID quà tặng"
                                        aria-label="Recipient's username"
                                        aria-describedby="button-addon2" />
                                    <input
                                        name="nameSearch"
                                        value={this.state.nameSearch}
                                        onChange={this.handleChange}
                                        onKeyPress={(event) => {
                                            if (event.key === "Enter") {
                                                this.search()
                                            }
                                        }}
                                        type="text"
                                        className="form-control col-md-3 col-sm-6 "
                                        placeholder="Nhập tên quà tặng"
                                        aria-label="Recipient's username"
                                        aria-describedby="button-addon2" />
                                    <select
                                        name="show"
                                        value={this.state.show}
                                        className="form-control col-md-2 col-3"
                                        onChange={this.handleChange}>
                                        <option value="">Show</option>
                                        <option value="ALL">ALL</option>
                                        <option value="RECORDING">RECORDING</option>
                                        <option value="LIVEROOM">LIVEROOM</option>
                                        <option value="!LIVEROOM">ALL RECORDING</option>
                                        <option value="!RECORDING">ALL LIVEROOM</option>
                                        <option value="NONE">NONE</option>
                                    </select>
                                    <select
                                        name="type"
                                        value={this.state.type}
                                        className="form-control col-md-2 col-3"
                                        onChange={this.handleChange}>
                                        <option value="">Type</option>
                                        <option value="ANIMATED">ANIMATED</option>
                                        <option value="STATIC">STATIC</option>
                                        <option value="COMBO">COMBO</option>
                                        <option value="LUCKYBOX">LUCKYBOX</option>
                                        <option value="DRAWABLE">DRAWABLE</option>
                                    </select>
                                    <select
                                        name="tag"
                                        value={this.state.tag}
                                        className="form-control col-md-2 col-3"
                                        onChange={this.handleChange}>
                                        <option value="">Tag</option>
                                        <option value="NORMAL">NORMAL</option>
                                        <option value="EVENT">EVENT</option>
                                        <option value="VIP">VIP</option>
                                        <option value="SPECIAL">SPECIAL</option>
                                        <option value="FAMILY">FAMILY</option>
                                        <option value="USER">USER</option>
                                    </select>
                                    <div className="input-group-append">
                                        <button
                                            className="btn btn-outline-success"
                                            type="button"
                                            id="button-addon2"
                                            onClick={this.search}>
                                            <i className="fa fa-search"></i>
                                        </button>
                                        <button
                                            className="btn btn-outline-danger"
                                            type="button"
                                            id="button-addon2"
                                            onClick={this.reset}>
                                            <i className="fas fa-sync"></i>
                                        </button>
                                    </div>
                                </div>
                                <table className="table table-hover">
                                    <thead>
                                        <tr className="align-middle">
                                            <th className="align-middle" scope="col">ID</th>
                                            <th className="align-middle" scope="col">Name</th>
                                            <th className="align-middle" scope="col">Image</th>
                                            <th className="align-middle" scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {gifts.map((gift, index) => {
        
                                            return (
                                                <tr key={gift.id}>
                                                    <th scope="row">{gift.id}</th>
                                                    <td>{gift.name}</td>
                                                    <td> <img className="gift-image" src={gift.url}></img>  </td>
                                                    <td>
                                                        <button
                                                            onClick={() => {
                                                                this.setState({
                                                                    gift: gift
                                                                })
                                                                this.setDataInUrl(gift.id, gift.url, this.state.cupTop)
                                                            }}
                                                            className="btn btn-red"
                                                            data-toggle="modal"
                                                            data-target="#modalSetGift"
                                                        >Chọn</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                </>
                            ) : ''}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.saveGift}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}