import React, { Component } from 'react'
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/vi';
import MainCommentBox from './MainCommentBox';
import CommentLine from './CommentLine';
import { withTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';

class GiftCommentLine extends Component {
    constructor(props) {
        super(props);
        this.toogle = this.toogle.bind(this);
    }

    toogle() {
        const {commentLv1Id} = this.props;

        if(this.props.commentLv1Id != null) {
            this.props.toggle(commentLv1Id); 
        } else {
            this.props.toggle(this.props.comment.key); 
        }
       
    }


    render() {
         const { comment, recording, user, toggleComment, toggleCommentId, t} = this.props;
        let userId = comment.userId;
        let fbLink = '#';
        let fbAvatar = comment.userProfile;
        let fbName = comment.userName;
        let giftUrl = comment.giftUrl;
        let giftNoItem = comment.giftNoItem;
        let commentId = comment.key;
        let dateTime = comment.dateTime/1000;
        let randomFbAvatar = 'https://storage.googleapis.com/ikara-storage/randomavatar/' + (comment.userId % 100) + ".jpg"
        return (
            <div data-userid={userId} data-commentid={commentId} className="comment_level1 _3-8y _5nz1 clearfix" direction="left">
                <div style={{width: "56px", height: "48px"}} className="_ohe lfloat">
                    <Link  to={"/user/" + userId} src={fbAvatar}  className="img _8o _8s UFIImageBlockImage">
                        <img className="_1ci img" src={fbAvatar} alt="" onError={(e)=>{e.target.onerror = null; e.target.src=randomFbAvatar}} />
                    </Link>
                </div>
                <div>
                    <div className="UFIImageBlockContent _42ef clearfix" direction="right">
                        <div className="_ohf rfloat">
                            <div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span>
                                    <Link to={"/user/" + userId} className=" UFICommentActorName" href="{fbLink}">
                                        {fbName}
                                    </Link>
                                </span>
                                <div className="_3-8m">
                                    <div className="_30o4">
                                        <span>
                                            <span className="_5mdd">
                                                <span>
                                                    <img src={giftUrl} style={{ float: 'left', width: '70px' }} />
                                                    <p style={{ float: 'left', paddingLeft: '12px', fontSize: '20px', color: 'yellow', textShadow: '-1px 0 brown, 0 1px brown, 1px 0 brown, 0 -1px brown' }}>
                                                        x{giftNoItem }
                                                    </p>
                                                    <div className="clearfix" />
                                                </span>
                                            </span>
                                        </span>
                                        <span>
                                        </span>
                                    </div>
                                </div>
                                <div className="_2vq9 fsm fwn fcg">
                                    <a style={{ color : "blue" }}  onClick={this.toogle}>
                                        <em className="_4qba" data-intl-translation="Phản hồi" data-intl-trid>
                                            {t("recording_page.reply", "Trả lời")}
                                        </em>
                                    </a>
                                    <span role="presentation" aria-hidden="true"> ·</span>
                                    <span>
                                        <abbr className="livetimestamp" data-utime={dateTime} data-shorten="true">
                                            <Moment unix fromNow tz="Asia/Ho_Chi_Minh" locale="vi">
                                                {dateTime}
                                            </Moment>
                                        </abbr>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {
                            comment.childs != null ? 
                            (comment.childs.map(e => (
                                <CommentLine 
                                        commentLv1Id={commentId} 
                                        key={e.key} 
                                        comment = {e} 
                                        recording={recording} 
                                        user={user}
                                        toggleComment ={toggleComment}
                                        toggleCommentId = {toggleCommentId}
                                        toggle={this.toogle}
                                        ></CommentLine>
                            ))) : 
                            ('')
                        }

                        {
                            (toggleComment == true && toggleCommentId == commentId) ?
                            (<MainCommentBox 
                                    recording={recording} 
                                    user={user} 
                                    commentLv1Id={commentId} 
                                    addNewComment={this.props.addNewComment} 
                                    ></MainCommentBox>) : 
                            ('')
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(GiftCommentLine)

