import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import PageIcon from './PageIcon.js';
import GiftChild from './GiftChild.js'
import ICoinIcon from '../../../components/iCoinIcon.js';

const $ = window.$;

export default class DialogDonate extends Component {

  constructor(props) {
    super(props);

    this.state = {
      indexSelected: 0
    }

    this.loadPage = this.loadPage.bind(this);
  }

  componentDidMount() {
    $("#exampleModalDonate").on("hidden.bs.modal", function () {
        this.state.indexSelected = 0;
    }.bind(this));
  } 



  loadPage(param) {
    this.setState({
      indexSelected: param
    });
  }


  dismissModal() {
    $("#exampleModalDonate").modal("hide");
  }

  render() {
    const { giftList, user, giftSelected } = this.props;
    const { indexSelected } = this.state;
    let giftListTemp = [];
    let pageIconsTemp = [];
    let startIndex = indexSelected * 8;

    let totalPageGift = 0;
    if (giftList) {
      for (let i = startIndex; i < startIndex + 8; i++) {
        if (giftList[i] != null) {
          giftListTemp.push(giftList[i]);
        }
      }
      totalPageGift = Math.ceil(giftList.length / 8);

      for (let i = 1; i <= totalPageGift; i++) {
        pageIconsTemp.push(i);
      }
    }

    return (
      <div className="modal fade" id="exampleModalDonate" data-backdrop="static" data-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: "#a4b0bb52" }}>
              <h5 className="modal-title" id="exampleModalLabel">Tặng quà</h5>
              <button onClick={this.closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            {
              (giftList) ?
                (
                  <div>
                    <div className="modal-body">

                      {
                        user ? (
                          <div className="clearfix">
                            <div className="float-left">
                              <span className="font-weight-bold">Chọn quà tặng:</span>
                            </div>
                            <div className="float-right">
                              <span className="">
                                Số iCoin hiện có: <span className="font-weight-bold text-light bg-secondary rounded p-1">{user.totalIcoin} <ICoinIcon /></span>
                              </span>
                            </div>
                          </div>
                        ) : ('')
                      }
                      <div className="row px-2 px-md-5 pb-5">
                        <div style={{ height: "20px" }} className="col-12 m-0 p-0">
                          {(giftSelected) ?
                            ((user.totalIcoin < giftSelected.buyPrice) ?
                              (<p className="modal-title text-center text-danger mt-2">
                                <strong className="">iCoin của bạn không đủ. </strong>
                                <Link
                                  to="/buy-icoin"
                                  onClick={this.dismissModal}
                                >Nạp iCoin</Link></p>) : '') : ''}
                        </div>
                        {
                          giftListTemp.map((gift, index) => (
                            <GiftChild
                              key={gift.id}
                              gift={gift}
                              toggleModalDonate={this.props.toggleModalDonate}
                              giftSelected={giftSelected}
                            ></GiftChild>
                          ))
                        }
                      </div>
                      <div className="row justify-content-center">
                        <button disabled={indexSelected == 0} onClick={() => {this.setState({indexSelected: this.state.indexSelected - 1})}} className="btn btn-primary float-left mr-1">❮</button>
                        <div className="">
                          {
                            pageIconsTemp.map((e, index) => (
                              <PageIcon loadPage={this.loadPage} key={index} indexSelected={indexSelected} index={index}  ></PageIcon>
                            ))
                          }
                        </div>
                        <button disabled={indexSelected == (totalPageGift - 1)} onClick={() => {this.setState({indexSelected: this.state.indexSelected + 1})}} className="btn btn-primary float-right ml-1">❯</button>
                      </div>
                    </div>
                    <div className="modal-footer" style={{ backgroundColor: "#a4b0bb52" }}>
                      <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                      <button disabled={(giftSelected == null) || (giftSelected.buyPrice > user.totalIcoin)} onClick={this.props.sendGift} type="button" className="btn btn-red" data-dismiss="modal">Tặng quà</button>
                    </div>
                  </div>
                ) :
                ('')
            }

            {/* <div className="modal-footer"> */}
            {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Thoát</button> */}
            {/* <button type="button" className="btn btn-primary">Chọn quà</button> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    )
  }
}