import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export default class ModalDeleteCategory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            category: null,
            loading: false,
        }

        this.deleteCategory = this.deleteCategory.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            category: props.category,
        })
    }

    deleteCategory() {
        this.setState({
            loading: true
        });

        let modal = {
            categoryId: this.state.category.id,
            deleted: !this.state.category.isDelete
        }

        axios.post(global.config.apiDomain + '/rest/categories/hideOrActive', modal).then(res => {
            this.setState({
                loading: false
            });

            window.$("#modalDeleteCategory").modal("hide");
            toast.success(res.data.message);
            this.props.resetCategories(false);
        })
    }

    render() {
        const { category, loading } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalDeleteCategory" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Ẩn Chủ đề</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {category ? (
                                <div className="modal-body">
                                    {category.isDelete ? <h3 className="text-center text-danger">Bạn chắc chắn muốn khôi phục chủ đề này: <span className="font-weight-bold">{category.name}</span> ?</h3> :
                                        <h3 className="text-center text-danger">Bạn chắc chắn muốn xóa Chủ đề: <span className="font-weight-bold">{category.name}</span> ?</h3>}
                                </div>
                            ) : ""}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {loading?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) : category?
                                    (
                                        <button
                                            style={{ width: "120px" }}
                                            type="button"
                                            className="btn btn-red"
                                            onClick={this.deleteCategory}>{!category.isDelete ? "Ẩn" : "Khôi phục"}</button>
                                    ): ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}