import React, { Component } from 'react';
import axios from 'axios';
import lodash from 'lodash';
import $ from 'jquery';
import { toast } from 'react-toastify';

export default class TopVipUserOfThisMonth extends Component {
    constructor(props) {
        super(props);

        this.state = {
            vipUsers: [],
            vipUser: null,
            filterByMonth: true,
            useFilter: false
        }

        this.getVipUserList = this.getVipUserList.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.getVipUserListByFilter = this.getVipUserListByFilter.bind(this);
        this.getVipUserListByMonth = this.getVipUserListByMonth.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    componentWillMount() {
        this.getVipUserList();
    }

    getVipUserList() {
        axios.post(global.config.apiDomain + '/rest/TopVipUser/find')
            .then(res => {

                let vipUsers = [];
                vipUsers = lodash.orderBy(res.data.TopVipUsers, "totalMoney", 'desc');

                this.setState({
                    vipUsers: vipUsers
                })
            });
    }

    getVipUserListByFilter(request) {
        axios.post(global.config.apiDomain + '/rest/TopVipUser/filter', request)
            .then(res => {
                let vipUsers = [];
                vipUsers = lodash.orderBy(res.data.TopVipUsers, "totalMoney", 'desc');

                this.setState({
                    vipUsers: vipUsers
                })
            });
    }

    getVipUserListByMonth(request) {
        axios.post(global.config.apiDomain + '/rest/TopVipUser/month', request)
            .then(res => {
                let vipUsers = [];
                vipUsers = lodash.orderBy(res.data.TopVipUsers, "totalMoney", 'desc');

                this.setState({
                    vipUsers: vipUsers
                })
            });
    }

    handleReset() {
        $("#timeStart").val(0);
        $("#timeEnd").val(0);
        this.setState({ useFilter: false });
        this.getVipUserList();
    }

    handleFilter() {
        const { filterByMonth } = this.state;
        this.setState({ useFilter: true });

        if (!filterByMonth) {
            let timeStart = $("#timeStart").val();
            let timeEnd = $("#timeEnd").val();
            let vipScore = $("#vipScoreSearch").val();

            if (!timeStart || !timeEnd) {
                toast.error("Chọn cả 2 giá trị Bắt đầu và Kết thúc");
                return;
            }
            if (new Date(timeEnd) < new Date(timeStart)) {
                toast.error("Ngày Bắt đầu trước Ngày Kết thúc");
                return;
            }
            if (new Date(timeEnd) > new Date()) {
                toast.error("Ngày Kết thúc không quá Ngày hiện tại");
                return;
            }
            if (isNaN(vipScore) || vipScore.trim() === "") {
                toast.error("Vip Score không hợp lệ");
                return;
            }

            let request = {
                fromDate: new Date(timeStart),
                toDate: new Date(timeEnd),
                vipScore: vipScore
            };

            this.getVipUserListByFilter(request);
        } else {
            let selectedMonth = $("#monthSelect").val();
            let vipScore = $("#vipScoreSearch").val();

            if (!selectedMonth) {
                toast.error("Chọn một tháng");
                return;
            }
            if (isNaN(vipScore) || vipScore.trim() === "") {
                toast.error("Vip Score không hợp lệ");
                return;
            }

            let request = {
                month: selectedMonth,
                vipScore: vipScore
            };

            this.getVipUserListByMonth(request);
        }
    }

    handleCheckboxChange(event) {
        this.setState({
            filterByMonth: !event.target.checked
        });
    }

    render() {
        const { vipUsers, filterByMonth, useFilter } = this.state;
        return (
            <>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Top VIP User Of Month</h1>
                        <div className='mt-2'>
                            <input type="checkbox" style={{ display: "inline-block", width: "20px", height: "20px", verticalAlign: "middle", backgroundColor: "#fff", border: "2px solid #ccc", borderRadius: "4px", cursor: "pointer", transition: "background-color 0.3s ease" }} onChange={this.handleCheckboxChange} defaultChecked={false} />
                            <label htmlFor="checkboxcomment" style={{ marginLeft: "5px", fontWeight: 600, textAlign: 'center' }} className='featureLabel'> Lọc theo ngày</label><br />
                            <div id="name" className="row mb-2">
                                {!filterByMonth ? (
                                    <>
                                        <div className='col-2'>
                                            Từ ngày
                                        </div>
                                        <div className='col-2'>
                                            Đến ngày
                                        </div>
                                    </>
                                ) : (
                                    <div className='col-2'>
                                        Chọn tháng
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='mt-2 mb-2 row'>
                            {!filterByMonth ? (
                                <>
                                    <div className='col-2'>
                                        <input
                                            className="form-control mb-2"
                                            type="dateTime-local"
                                            id="timeStart"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <input
                                            className="form-control mb-2"
                                            type="dateTime-local"
                                            id="timeEnd"
                                        />
                                    </div>
                                </>
                            ) : (
                                <div className='col-2'>
                                    <select className="form-control mb-2" id="monthSelect">
                                        {Array.from({ length: 12 }, (_, i) => (
                                            <option key={i + 1} value={i + 1}>
                                                {i + 1}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            <input
                                id="vipScoreSearch"
                                type="text"
                                className="form-control col-md-2 col-sm-6"
                                placeholder="Nhập Vip Score"
                                inputmode="numeric"
                            />
                            <div className='col-2'>
                                <button className='btn btn-success' onClick={this.handleFilter}>Lọc</button>
                                <button className='btn btn-primary ml-2 mr-2' onClick={this.handleReset}>Reset</button>
                            </div>
                        </div>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th width={200} scope="col">STT</th>
                                    <th width={200} scope="col">UID</th>
                                    <th scope="col">Tên Người Dùng</th>
                                    {!useFilter ? (
                                        <>
                                            <th scope="col">Số tiền đã nạp tháng trước</th>
                                            <th scope="col">Số tiền đã nạp tháng này</th>
                                        </>
                                    ) : (
                                        null
                                    )}
                                    <th scope="col">Tổng tiền đã nạp</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vipUsers.map((vipUser, index) => {
                                    return (
                                        <tr key={vipUser.uid}>
                                            <th scope="row">{index + 1}</th>
                                            <th scope="row">{vipUser.uid}</th>
                                            <td>{vipUser.fullName}</td>
                                            {!useFilter ? (
                                                <>
                                                    <td>{vipUser.moneyLastMonth.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })} </td>
                                                    <td>{vipUser.moneyThisMonth.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })} </td>
                                                </>
                                            ) : (
                                                null
                                            )}
                                            <td>{vipUser.totalMoney.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })} </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}