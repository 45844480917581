import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import bindModel from '../../../common/bindModel';

const ModalCreate = (props) => {
    const [model, setModel] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setModel(props.model);
    }, [props.model]);

    const handleDes = (e) => {
        setModel({ ...model, description: e.target.value });
    };
    const handleName = (e) => {
        setModel({ ...model, name: e.target.value });
    };
    const createOrUpdate = () => {
        let obj = { ...model };
        console.log(obj)
        if (!obj.name || !obj.description) {
            toast.warning("Nhập đầy đủ thông tin");
            return;
        }

        obj.addTime = Date.parse(obj.addTime);

        setLoading(true);

        axios.post(global.config.apiDomain + "/rest/admin/feature-request/createOrUpdate", JSON.stringify(obj), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                setLoading(false);
                if (res.data && res.data.status === "OK") {
                    window.$("#modalCreate").modal("hide");
                    toast.success(res.data.message);
                    props.resetList();
                } else {
                    toast.error(res.data.message);
                }
            });
    };

    // const bind = bindModel({ model, setModel});

    return (
        <div>
            <div className="modal fade" id="modalCreate" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">{model && model.resourceUrl ? "Cập nhật Đề xuất" : "Thêm mới Đề xuất"}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div id="id" className="row mb-2">
                                <div className="col-3">
                                    <label>ID</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        className="form-control mb-2"
                                        type="number"
                                        readOnly={true}
                                        value=
                                        {model && model.id ? model.id : ""}
                                    />
                                </div>
                            </div>
                            <div id="name" className="row mb-2">
                                <div className="col-3">
                                    <label>Tên</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        className="form-control mb-2"
                                        type="text"
                                        placeholder="Nhập tên"
                                        value=
                                        {model && model.name ? model.name : null}
                                        onChange={handleName}
                                    />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-3">
                                    <label>Thời gian</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        className="form-control mb-2"
                                        type="datetime-local"
                                        readOnly={true}
                                        value=
                                        {model && model.addTime ? model.addTime : ""}
                                    />
                                </div>
                            </div>
                            <div id="description" className="row mb-2">
                                <div className="col-3">
                                    <label>Mô tả</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        className="form-control mb-2"
                                        type="text"
                                        placeholder="Nhập mô tả..."
                                        value=
                                        {model && model.description ? model.description : null}
                                        onChange={handleDes}
                                    />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-3">
                                    <label>Trạng thái</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        className="form-control mb-2"
                                        type="text"
                                        readOnly={true}
                                        value=
                                        {model && model.status ? model.status : ""}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                            {loading ?
                                (
                                    <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Xác nhận...
                                    </button>
                                ) :
                                (
                                    <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={createOrUpdate}>Xác nhận</button>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCreate;
