import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';

import bindModel from '../../../common/bindModel.js';

const $ = window.$;

export default class ModalUpdatePackage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            cPackage: null,
            loading: false
        }

        this.savePackage = this.savePackage.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            cPackage : props.cPackage
        })
    }

    savePackage() {
        this.setState({
            loading: true
        });

        if(this.state.cPackage.id != null) {
            axios.post(global.config.apiDomain + '/rest/packages/save', JSON.stringify(this.state.cPackage), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.setState({
                    loading: true
                });

                $("#modalUpdatePackage").modal("hide");

                toast.success('Cập nhật thành công');
                this.props.resetList();
            })
        } else {
            axios.post(global.config.apiDomain + '/rest/packages/add', JSON.stringify(this.state.cPackage), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.setState({
                    loading: true
                });

                $("#modalUpdatePackage").modal("hide");

                toast.success('Cập nhật thành công');
                this.props.resetList();
            })
        }
    }

    render() {
        const model = bindModel(this);
        const {cPackage} = this.state;
        const options = ["vi", "lo.okara", "mz.okara", "tl.okara", "ht.okara", "en.yokara", "pe.okara"];

        return (
            <div>
                <div className="modal fade" id="modalUpdatePackage" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật Package</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {cPackage ? (<div className="modal-body">
                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>Tên App</label>
                                    </div>
                                    <div className="col-9">
                                        <select
                                            className="form-control mb-2"
                                            name="platform"
                                            {...model('cPackage.language')}>
                                            {options.map(option => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>Platform</label>
                                    </div>
                                    <div className="col-9">
                                        <input 
                                            className="form-control mb-2" 
                                            type="text" 
                                            name="platform"
                                            readOnly={cPackage.id == null ? false : true}
                                            placeholder="Nhập platform..."
                                            {...model('cPackage.platform')}/>
                                    </div>
                                </div>
                                <div id="title" className="row mb-2">
                                    <div className="col-3">
                                        <label>PackageName</label>
                                    </div>
                                    <div className="col-9">
                                        <input 
                                            className="form-control mb-2" 
                                            type="text" 
                                            name="title"
                                            placeholder="Nhập package name..."
                                            readOnly={cPackage.id == null ? false : true}
                                            {...model('cPackage.packageName')}/>
                                    </div>
                                </div>
                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>MaxVersion</label>
                                    </div>
                                    <div className="col-9">
                                        <input 
                                            className="form-control mb-2" 
                                            type="text" 
                                            name="maxVersion"
                                            placeholder="Nhập max version..."
                                            {...model('cPackage.maxVersion')}/>
                                    </div>
                                </div>
                                <div id="link" className="row mb-2">
                                    <div className="col-3">
                                        <label>Link</label>
                                    </div>
                                    <div className="col-9">
                                        <input 
                                            className="form-control" 
                                            type="text" 
                                            name="cPackage.url" 
                                            placeholder="Nhập url..."
                                            {...model('cPackage.url')}/>
                                    </div>
                                </div>
                            </div>) : ''}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ? 
                                (
                                <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Xác nhận...
                                </button>
                                ) :
                                (
                                <button style={{width: "120px"}} type="button" className="btn btn-red" onClick={this.savePackage}>Xác nhận</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}