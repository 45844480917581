import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Base64 } from 'js-base64';
import {
	Link
} from "react-router-dom";
import {withTranslation} from 'react-i18next';
class MainCommentBox extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      commentContent: '',
    }
  
    this.addComment = this.addComment.bind(this);
    this.loginAndAddComment = this.loginAndAddComment.bind(this);
    this.changeContent = this.changeContent.bind(this);
    this.newGetKey = this.newGetKey.bind(this);
  }

  changeContent(event) {
    this.setState({
      commentContent: event.target.value
    })
  }
  
  addComment(){
    if(this.state.commentContent == null || this.state.commentContent == '') {
      return;
    } else {
      const {user, recording, commentLv1Id} = this.props;
     

      let jsonCheckAllowComments = {
        facebookIdOfRecording: recording.owner.facebookId,
        facebookIdOfCommenter: user.facebookId,
      }
      const jsonBase64 = Base64.encode(JSON.stringify(jsonCheckAllowComments));
      var pass = this.newGetKey(jsonBase64, 7123358024, 3564);
      var keys = jsonBase64 + "-" + pass;
      var api = "/v35.CheckPermissionCommentOnWeb?parameters=";
      
      var url = global.config.apiDomain + api + keys;
      axios.post(url, jsonCheckAllowComments, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        if(res.data.status == "OK") {
          let json = {
            userId: user.facebookId,
            language: "vi",
            recordingId: recording._id,
            commentContent: this.state.commentContent,
            level1CommentId: commentLv1Id
          }
    
          axios.post(global.config.apiDomain + '/rest/comment', JSON.stringify(json), {
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(() => {
            if(commentLv1Id == null) {
              this.props.addNewComment();
            } else {
              this.props.addNewComment(commentLv1Id)
            }
          });
    
          this.setState({
            commentContent: ''
          });
        } else {
          toast.warning("Chủ bài thu đã giới hạn bình luận. Bạn không thể bình luận trong bài thu này");
        }
      });
    }
  }

  newGetKey(data, password, password2) {
    var listOfNumbers = "" + password2;
    for (var i = 0 ; i < data.length; i++) {
        if (data.charAt(i) >= '0' && data.charAt(i) <= '9'){
            listOfNumbers = [listOfNumbers.slice(0, 0), data.charAt(i), listOfNumbers.slice(0)].join('');
        }
    }
    var originalKey = null;
  if (listOfNumbers.length > 15) 
    originalKey = listOfNumbers.substring(0,15);
  else
    originalKey = listOfNumbers;
  return Number(originalKey) + Number(password);
  }

  loginAndAddComment(){
    

  }
    
    render() {
        const {user, t}  = this.props;
        let parentCommentId = this.props.commentLv1Id;
        let fbUser = user != null ? user.facebookId : null;
        let levelOfCommenter = user != null ? user.level : null;
        let cfbLink = '#';
        let cfbAvatar = fbUser != null ? 'https://www.ikara.co/avatar/' + fbUser : 'https://www.ikara.co/avatar/2';
        let buttonTitle = fbUser != null ? (parentCommentId == null ? t("recording_page.comment", "Bình luận") : t("recording_page.reply","Trả lời")) : t("recording_page.login_to_comment", "Đăng nhập và bình luận");
        let addCommentFunction =  fbUser != null? this.addComment : this.loginAndAddComment;

        let className = (fbUser != null && this.state.commentContent != '') ? ('submit_button selected _42ft rfloat _3-99 _4jy0 _4jy3 _4jy1 _51sy selected _42ft') : ('submit_button selected _42ft rfloat _3-99 _4jy0 _4jy3 _4jy1 _51sy selected _42ft _42fr');

        let shouldShowCommentBox = levelOfCommenter > 1;
        return (
          <div>
            {shouldShowCommentBox && (
            <div>
                <div style={{width: "56px", height: "48px"}} className="_ohe lfloat">
                  <a src={cfbAvatar} target="_blank" className="img _8o _8s UFIImageBlockImage">
                    <img className="_1ci img" src={cfbAvatar} alt="" onError={(e)=>{e.target.onerror = null; e.target.src='/img/facebook_user.jpg'}}/>
                  </a>
                </div>
                <div className="main_comment_box">
                  <div className="UFIImageBlockContent _42ef">
                    <div>
                      <div className="UFIInputContainer">
                        <div className="_1cb _5yk1">
                          <div className="_5yk2" tabIndex={-2}>
                            <div className="_5rp7">
                              <div className="_1p1t">
                                <div className="_1p1v" id="placeholder-7m27n">
                                  <em className="_4qba" data-intl-translation="Thêm bình luận..." data-intl-trid>{t("recording_page.add_comment","Thêm bình luận")}</em>
                                </div>
                              </div>
                              <div className="_5rpb">
                                <textarea 
                                  rows={1} 
                                  style={{border: '0px', resize: 'none', width: '100%', padding: '10px', fontSize: '14px'}} 
                                  placeholder={t("recording_page.add_comment","Thêm bình luận")}
                                  className="_5rpu comment_box_content"
                                  value={this.state.commentContent}
                                  onChange={this.changeContent}/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="UFICommentAttachmentButtons clearfix">
                        </div>
                      </div>
                      <div className="_4uym">
                        <div className="_5tr6 clearfix _2ph- clearfix">
                          <div className="_ohe lfloat">
                            <table cols={1} className="uiGrid _51mz" cellSpacing={0} cellPadding={0}>
                              <tbody>
                                <tr className="_51mx">
                                  <td className="_51mw _51m-">
                                  </td>
                                </tr>
                                <tr className="_51mx">
                                  <td className="_51mw _51m-">
                                    <span>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="_ohf rfloat">
                            <span>
                              {fbUser != null ? (
                              <button onClick={ addCommentFunction } className={className} type="submit">
                                <em className="_4qba" data-intl-translation="Bình luận" data-intl-trid>
                                  {buttonTitle}
                                </em>
                              </button>
                              ):(
                                <Link className="submit_button selected _42ft rfloat _3-99 _4jy0 _4jy3 _4jy1 _51sy selected _42ft _42fr" to="/login">
                                  <em className="_4qba" data-intl-translation="Bình luận" data-intl-trid>
                                    {buttonTitle}
                                  </em>
                                </Link>
                              )
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
    }
}

export default withTranslation()(MainCommentBox)

