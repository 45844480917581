import React, { Component } from 'react';
import axios from 'axios';
import '../../../assets/css/newDevice.css';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

export default class ListHotMV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            originalRecordings: [],
            recordings: [],
            selectedDate: '',
            inputValue: '',
            selectedRow: null,
            isLoading: false
        };

        this.getAllMvPinned = this.getAllMvPinned.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSelectDate = this.handleSelectDate.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

        this.handlePinnedStatusChange = this.handlePinnedStatusChange.bind(this);

        this.pinMv = this.pinMv.bind(this);
    }

    componentDidMount() {
        const today = new Date().toISOString().split('T')[0];
        this.setState({ selectedDate: today }, () => {
            this.getAllMvPinned();
        });
    }

    getAllMvPinned() {
        this.setState({ isLoading: true });
        axios.get(global.config.apiDomain + '/rest/recording/get-list-mv-pinned', {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            const models = res.data.models || [];
            this.setState({ 
                originalRecordings: models,
                recordings: models,
                isLoading: false
             });
        })
        .catch(error => {
            console.error('Error fetching commissions:', error);
            this.setState({ isLoading: false });
        });
    }

    pinMv(item) {
        Swal.fire({
            title: 'Bỏ ghim MV này?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Bỏ Ghim',
            cancelButtonText: 'Hủy'
        }).then((result) => {
            if (result.isConfirmed) {
                const json = {
                    id: item._id,
                    isPinnedMvByAd: false
                };
                axios.post(global.config.apiDomain + '/rest/recording/pin-mv', json, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(res => {
                    if (res.data.status === "OK") {
                        toast.success(res.data.message);
                        this.getAllMvPinned();
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(error => {
                    console.error('Lỗi khi ghim MV:', error);
                });
            }
        });
    }
    

    handleDateChange(event) {
        this.setState({ selectedDate: event.target.value });
    }

    handleSelectDate() {
        this.setState({ inputValue: '' }, () => {
            this.getAllMvPinned();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        this.setState({
            inputValue: value
        });
    }

    handleSearch() {
        const { inputValue, originalRecordings } = this.state;
        const [searchUid] = inputValue.split(',');
        const filteredRecordings = originalRecordings.filter(recording => {
            const isUidMatched = !searchUid || recording.uidUser.toString().toLowerCase().includes(searchUid.trim().toLowerCase());
    
            return isUidMatched;
        });
    
        this.setState({
            recordings: filteredRecordings
        });
    }

    handlePinnedStatusChange(event) {
        this.setState({ selectedPinnedStatus: event.target.value });
    }

    render() {
        const { recordings, inputValue, isLoading } = this.state;
        return (
            <>
                <div id="content">
                    <div className="container-fluid">
                        <div className="search-container" style={{marginTop: '-80px'}}>
                            <input type="date" onChange={this.handleDateChange}></input>
                            <button style={{minWidth: '90px'}} onClick={this.handleSelectDate}>Chọn ngày</button>

                            <input style={{marginLeft: '365px'}} 
                                type="number" 
                                value={inputValue} 
                                placeholder='Nhập Uid User' 
                                onChange={this.handleInputChange}
                            />
                            <button style={{minWidth: '80px'}} onClick={this.handleSearch}>Tìm kiếm</button>

                            <div style={{minWidth: '80px'}} className="quantity">Số lượng: {recordings.length}</div>
                        </div>
                        {isLoading ? (
                            <div className="spinner-container">
                                <div className="spinner"></div>
                            </div>
                        ) : recordings.length > 0 ? (
                            <table className="table table-hover">
                                <thead>
                                    <tr className="text-center">
                                        <th className="align-middle" scope="col">AddTime</th>
                                        <th className="align-middle" scope="col">Id MV</th>
                                        <th className="align-middle" scope="col">Tên bài hát</th>
                                        <th className="align-middle" scope="col">Link nhạc</th>
                                        <th className="align-middle" scope="col">Link video</th>
                                        <th className="align-middle" scope="col">Uid User</th>
                                        <th className="align-middle" scope="col">User name</th>
                                        <th className="align-middle" scope="col">Ghim Mv</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recordings.map((item) => {
                                        let addTime2 = new Date(item.addTime2)
                                        return (
                                            <tr key={item.uid}>
                                                <td className="align-middle text-center" scope="col">{addTime2.toLocaleString()}</td>
                                                <td className="align-middle text-center" scope="col">{item._id}</td>
                                                <td className="align-middle text-center" scope="col">{item.songName}</td>
                                                <td className="align-middle text-center" scope="col">
                                                    <a href={item.vocalUrl} target="_blank" rel="noopener noreferrer">Nghe nhạc</a>
                                                </td>
                                                <td className="align-middle text-center" scope="col">
                                                    <a href={item.mixedVideoUrl} target="_blank" rel="noopener noreferrer">Xem video</a>
                                                </td>
                                                <td className="align-middle text-center" scope="col">{item.uidUser}</td>
                                                <td className="align-middle text-center" scope="col">{item.userName}</td>
                                                <td className="align-middle text-center" scope="col">
                                                    <span style={{ color: '#27e75d'}}>{item.isPinnedMvByAd}</span> 
                                                    <i className="fa-solid fa-thumbtack mr-4" 
                                                       style={{ color: '#27e75d', fontSize: '26px', cursor: 'pointer' }}
                                                       onClick={() => this.pinMv(item)}
                                                    ></i>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <div className="text-center">
                                <p>Không có Mv nào.</p>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}
