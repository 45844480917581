import React, { Component } from 'react';
import axios from 'axios';

import { toast } from 'react-toastify';
import bindModel from '../../../common/bindModel.js';

const $ = window.$;


export default class SwapLiveRoom extends Component {
    constructor(props) {
        super(props);

        this.state = {
            oldLiveRoomUid: "",
            newLiveRoomUid: "",
            loading: false
        }

        this.swapLiveRoom = this.swapLiveRoom.bind(this);
    }

    swapLiveRoom(event) {
        if (event.key == 'Enter' || event.target.tagName == "BUTTON") {
            this.setState({
                loading: true
            });

            var json = {
                oldLiveRoomUid: this.state.oldLiveRoomUid,
                newLiveRoomUid: this.state.newLiveRoomUid
            }

            console.log(json)

            axios.post(global.config.apiDomain + '/rest/admin/swapLiveRoom', JSON.stringify(json), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.setState({
                    oldLiveRoomUid: "",
                    newLiveRoomUid: "",
                    loading: false
                });

                if (res.data.status == "OK") {
                    toast.success(res.data.message);
                } else {
                    toast.warning(res.data.message);
                }
            }, error => {
                this.setState({
                    loading: false
                });
                toast.error("ERROR");
            })
        }
    }


    render() {
        const model = bindModel(this);

        return (
            <div className='text-center p-5'>
                <div className='col-6 mx-auto'>
                    <input
                        className='form-control'
                        placeholder='Nhập id phòng live cũ'
                        {...model('oldLiveRoomUid')} />
                </div>
                <br />
                <div className='col-6 mx-auto'>
                    <input
                        className='form-control'
                        placeholder='Nhập id phòng live mới'
                        {...model('newLiveRoomUid')} />
                </div>
                <br />
                <div className='col-6 mx-auto'>
                    {this.state.loading ?
                        (
                            <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Xác nhận...
                            </button>
                        ) :
                        (
                            <button
                                style={{ width: "120px" }}
                                type="button"
                                className="btn btn-red"
                                onClick={this.swapLiveRoom}>Xác nhận</button>
                        )}
                </div>
            </div>
        )
    }
}