import React, { Component } from 'react';
import ModalChangeAvatar from './ModalChangeAvatar.js';

class AvatarChangePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
    }


    render() {
        return (
            <div style={{marginTop: '100px'}}>
                <button
                    className="btn btn-red m-1"
                    data-toggle="modal"
                    data-target="#modalChangeAvatar">Đổi Avatar</button>
                <ModalChangeAvatar></ModalChangeAvatar>
            </div>
        );
    }
}

export default AvatarChangePage;
