import React, { Component } from 'react';
import { Link } from "react-router-dom";

class RecordingItemInUserPage extends Component {
  render() {
    const { thumbnailImageUrl, onlineRecordingUrl, songName, song } = this.props.recording;
    return (
      <div className="col-lg-3 col-md-6 record-item" id={onlineRecordingUrl}>
        <div className="item">
          <div className="row">
            <div className="col-md-12 col-6">
              <Link to={'.' + onlineRecordingUrl}>
                <img src={song.thumbnailUrl} width="100%" alt={songName} />
              </Link>
            </div>
            <div className="col-md-12 col-6 info">
              <h2 className="title">
                <Link to={'.' + onlineRecordingUrl}>{song.songName}</Link>
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RecordingItemInUserPage;