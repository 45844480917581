import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import ModalUpdateLevelPrivilege from './components/ModalUpdateLevelPrivilege';
import ModalDeleteLevelPrivilege from './components/ModalDeleteLevelPrivilege';
import { set } from 'lodash';

const LevelPrivilege = () => {
    const [levelPrivileges, setLevelPrivileges] = useState([]);
    const [levelPrivilege, setLevelPrivilege] = useState(null);

    const getLevelPrivileges = () => {
        axios.post(global.config.apiDomain + '/rest/levelPrivilege/getAllLevelPrivilege')
            .then(res => {
                if (res.data.status === 'OK') {
                    setLevelPrivileges(res.data.levelPrivileges);
                }
            });
    };

    useEffect(() => {
        getLevelPrivileges();
    }, []);

    const deleteOrActiveLevelPrivilege = (event) => {
        let index = event.target.name;
        let levelPrivilege = levelPrivileges[index];

        setLevelPrivilege(levelPrivilege);
    };

    const updateLevelPrivilege = (event) => {
        let index = event.target.name;
        let levelPrivilege = levelPrivileges[index];
        levelPrivilege.isActive = levelPrivilege.isActive ? '1' : '0';

        setLevelPrivilege(levelPrivilege);
    };

    const addNewVipPrivilege = () => {
        // let levelPrivilege = {
        //     idTab: '',
        //     name: '',
        //     description: '',
        //     detail: '',
        //     icon: '',
        //     levelRequirement: 0,
        //     image: '',
        //     isActive: '',
        //     index: 0,
        //     app: ''
        // };

        setLevelPrivilege(null);;
    };

    return (
        <>
            <ModalUpdateLevelPrivilege
                levelPrivilege={levelPrivilege}
                resetList={getLevelPrivileges}
            >
            </ModalUpdateLevelPrivilege>
            <ModalDeleteLevelPrivilege
                levelPrivilege={levelPrivilege}
                resetList={getLevelPrivileges}
            >
            </ModalDeleteLevelPrivilege>
            <div id="content">
                <div className="container-fluid">
                    <h1 className="text-center">Quản lí Đặc quyền Level</h1>
                    <button
                        className="btn btn-red mb-1"
                        data-toggle="modal"
                        data-target="#modalUpdateLevelPrivilege"
                        onClick={addNewVipPrivilege}
                    >+ Tạo Mới Đặc quyền
                    </button>
                    <table className="table table-hover">
                        <thead>
                            <tr className="">
                                <th className="align-middle" scope="col">#</th>
                                <th className="align-middle" scope="col">idTab</th>
                                <th className="align-middle" scope="col">Level yêu cầu</th>
                                <th className="align-middle" scope="col">Tên</th>
                                <th className="align-middle text-center" scope="col">Icon</th>
                                <th className="align-middle text-center" scope="col" style={{ width: '200px' }}>Ảnh</th>
                                <th className="align-middle text-center" scope="col">Mô tả</th>
                                <th className="align-middle" scope="col">App</th>
                                <th className="align-middle" scope="col">Trạng thái</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {levelPrivileges && levelPrivileges.map((item, index) => (
                                <tr key={index}>
                                    <td className='align-middle'>{index + 1}</td>
                                    <td className='align-middle'><strong>{item.idTab}</strong></td>
                                    <td className='align-middle'>{item.levelRequirement}</td>
                                    <td className='align-middle'>{item.name}</td>
                                    <td>
                                        <img
                                            src={item.icon}
                                            style={{ width: '200px' }}
                                            className="img-item"
                                            alt={item.name}
                                        />
                                    </td>
                                    <td className='align-middle'>{item.description}</td>
                                    <td className='align-middle'>{item.app}</td>
                                    <td className='align-middle'>{item.isActive ? <span className='text-success'>Đang kích hoạt</span> : <span className='text-danger'>Đã Ẩn</span>}</td>
                                    <td className='align-middle'>
                                        {!item.isActive ? (
                                            <>
                                                <button
                                                    className="btn btn-info"
                                                    onClick={deleteOrActiveLevelPrivilege}
                                                    name={index}
                                                    data-toggle="modal"
                                                    data-target="#modalDeleteLevelPrivilege"
                                                >
                                                    Kích hoạt
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <button
                                                    className="btn btn-success"
                                                    onClick={updateLevelPrivilege}
                                                    name={index}
                                                    data-toggle="modal"
                                                    data-target="#modalUpdateLevelPrivilege"
                                                >
                                                    Sửa
                                                </button>&emsp;
                                                <button
                                                    className="btn btn-danger"
                                                    onClick={deleteOrActiveLevelPrivilege}
                                                    name={index}
                                                    data-toggle="modal"
                                                    data-target="#modalDeleteLevelPrivilege"
                                                >
                                                    Hủy
                                                </button>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default LevelPrivilege;
