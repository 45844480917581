import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import bindModel from '../../../common/bindModel.js';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars/index.js';

const $ = window.$;

export default class ModalVipScore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            type: '',
            language: '',
            money: 0,
            payment: '',
            enterDate: ''
        }

        this.addVipScore = this.addVipScore.bind(this);
        this.updateEnterDate = this.updateEnterDate.bind(this);
    }

    updateEnterDate(props) {
        let inputDate = props.value
            this.setState({
                enterDate: inputDate
            });
        }
        

    addVipScore(event) {
        console.log(this.state)
        if (event.key == 'Enter' || event.target.tagName == "BUTTON") {
            let type = this.state.type;
            let language = this.state.language;
            let money = this.state.money;
            let payment = this.state.payment;
            let enterDate = this.state.enterDate;
            let thisDate = new Date();
            let inputDate = new Date(enterDate);
            let thisMonth = thisDate.getMonth();
            let inputMonth = inputDate.getMonth();
            let thisYear = thisDate.getFullYear();
            let inputYear = inputDate.getFullYear();

            let dateString = `${inputMonth} 01 ${inputYear} 07:00:00 GMT+0700 (GMT+07:00)`;
            let firstDayOfMonth = new Date(dateString).getTime();

            if(thisDate.getDate() == 1){
                if(firstDayOfMonth > inputDate.getTime()){
                    return toast.error('Ngày đầu tháng thì thời gian phải sau 7h sáng');
                }
            }

            if((inputYear < thisYear-1)){
                return toast.error('Thời gian nhập không quá cách 1 tháng với thời gian hiện tại');
            }

            if((inputYear < thisYear) && (thisMonth != 1)){
                return toast.error('Thời gian nhập không quá cách 1 tháng với thời gian hiện tại');
            }

            if(thisMonth ==1 && (inputYear < thisYear-1)){
                return toast.error('Thời gian nhập không quá cách 1 tháng với thời gian hiện tại');
            }

            if((inputMonth < thisMonth-1) && (thisMonth != 1)){
                return toast.error('Thời gian nhập không quá cách 1 tháng với thời gian hiện tại');
            }

            if(inputDate.getTime() > thisDate.getTime()){
                return toast.error('Thời gian nhập không thể lớn hơn thời gian hiện tại');
            }

            if (type === '' || language === '' || money <= 0 || payment === '' || enterDate ===  null|| enterDate ===  '') {
                return toast.error('Nhập đầy đủ thông tin');
            }

            if(money < 0 ){
                return toast.error('Không nhập số âm');
            }

            this.setState({
                loading: true
            });

            var json = {
                facebookId: this.props.id,
                language: language,
                money: money*1000,
                payment: payment,
                enterDate: enterDate,
                type: type
            }

            axios.post(global.config.apiDomain + '/rest/admin/addVipScore', JSON.stringify(json), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.setState({
                    loading: false
                });

                if (res.data.status == "OK") {
                    $("#modalVipScore").modal("hide");
                    toast.success(res.data.message);
                } else {
                    toast.warning(res.data.message);
                }
            }, error => {
                this.setState({
                    loading: false
                });
                toast.error("ERROR");
            })
        }
    }

    render() {
        const model = bindModel(this);
        const { id } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalVipScore" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Update Vip Score</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div id="type" className="row mb-2">
                                    <div className="col-3">
                                        <label>Hành động</label>
                                    </div>
                                    <div className="col-9">
                                        <select
                                            className="form-control"
                                            type="text"
                                            name="type"
                                            {...model('type')} >
                                            <option value="">Chọn</option>
                                            <option value="+">Tăng điểm</option>
                                            <option value="-">Giảm điểm</option>
                                        </select>
                                    </div>
                                </div>

                                <div id="language" className="row mb-2">
                                    <div className="col-3">
                                        <label>App</label>
                                    </div>
                                    <div className="col-9">
                                        <select
                                            className="form-control"
                                            type="text"
                                            name="language"
                                            {...model('language')} >
                                            <option value="">Chọn</option>
                                            <option value="vi">Ikara </option>
                                            <option value="en.yokara">Yokara</option>
                                        </select>
                                    </div>
                                </div>

                                <div id="enterDate" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ngày Nạp</label>
                                    </div>
                                    <div className="col-9">
                                        {/* <input 
                                            className="form-control mb-2" 
                                            type="datetime-local" 
                                            name="startDate"
                                            {...model('banner.startDate')}/> */}
                                        <DateTimePickerComponent 
                                            change= {this.updateEnterDate}
                                        >    
                                        </DateTimePickerComponent>
                                    </div>
                                </div>
  
                                <div id="payment" className="row mb-2">
                                    <div className="col-3">
                                        <label>Thanh Toán</label>
                                    </div>
                                    <div className="col-9">
                                        <select
                                            className="form-control"
                                            type="text"
                                            name="payment"
                                            {...model('payment')} >
                                            <option value="">Chọn</option>
                                            <option value="BUYVIP">Mua vip</option>
                                            <option value="GOOGLEINAPP">Nạp qua google </option>
                                            <option value="APPLEINAPP">Nạp qua apple</option>
                                            <option value="CARD">Nạp qua thẻ ĐT</option>
                                            <option value="PAYPAL">Nạp qua paypal</option>
                                            <option value="MOMO">Nạp qua momo</option>
                                            <option value="VIETCOMBANK">Chuyển khoản VCB</option>
                                            <option value="BANK">Nạp qua VnPay</option>
                                            <option value="SMS">Nạp qua SMS</option>
                                            <option value="ADMIN">Admin</option>
                                        </select>
                                    </div>
                                </div>    

                                <div id="money" className="row mb-2">
                                    <div className="col-3">
                                        <label>Số điểm</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            onKeyUp={this.addVipScore}
                                            type="number"
                                            placeholder="Nhập số điểm"
                                            className="form-control"
                                            {...model('money')} />
                                    </div>
                                </div>                   
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button
                                            style={{ width: "120px" }}
                                            type="button"
                                            className="btn btn-red"
                                            onClick={this.addVipScore}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}