import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { update } from 'lodash';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Parser, Player } from 'svga'
import SVGA from 'svgaplayerweb'
import ModalUpdateVipPrivilege from './component/CreateAndUpdateEvenLevelPrivilege';
// import ModalUpdateVipPrivilege from './components/ModalUpdateVipPrivilege';

class EvenLevelPrivilege extends Component {
    constructor(props) {
        super(props);
        this.state = {
            evenLevelPrivileges: [],
            vipPrivilege:null,
            update:0
        };
        this.getEvenLevelPrivileges = this.getEvenLevelPrivileges.bind(this);
        this.addNewVipPrivilege = this.addNewVipPrivilege.bind(this);
        this.updateEvenPrivilege = this.updateEvenPrivilege.bind(this);
        
        // this.deleteEvenLevelPrivileges=this.deleteEvenLevelPrivileges(this);
        // this.deleteOrActiveVipPrivilege = this.deleteOrActiveVipPrivilege.bind(this);

    }

    componentDidMount() {
        this.getEvenLevelPrivileges();
    }
//global.config.apiDomain + 

    getEvenLevelPrivileges() {
        axios.post(global.config.apiDomain + '/rest/eventLevelPrivilege/listAll')
        .then(res => {
                this.setState({
                    evenLevelPrivileges: res.data,
                    update:0,
                    
                })
            
        })
    }

    deleteEvenLevelPrivileges=(event) => {  
        let index = event.target.name;
        let id =event.target.value;
        console.log(index)
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn có chắc chắn muốn tiếp tục không?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'Hủy bỏ',
            allowOutsideClick: false,
            preConfirm: (code) =>{
                axios.post(global.config.apiDomain + '/rest/eventLevelPrivilege/delete',{
                    levelRequirement:id,
                })
                .then(res => {
                    if(res.data.status=="OK"){
                    toast.success("Xóa thành công") 
                    const evenLevelPrivilege = [...this.state.evenLevelPrivileges]; 
                    evenLevelPrivilege.splice(index, 1); 
                    this.setState({evenLevelPrivileges:evenLevelPrivilege}); 
                    }
                    else{
                        toast.error("Đã xảy ra lỗi")
                    } 
                })
           
            }
    })
    }

    addNewVipPrivilege() {
        let vipPrivilege = {
            evenLevelRequirement:0,
            giftID:'',
            description: '',
            
        };

        this.setState({
            vipPrivilege : vipPrivilege,
            update:0,
        })
    }

    updateEvenPrivilege(event) {
        let id =event.target.name
        axios.post(global.config.apiDomain + '/rest/eventLevelPrivilege/evenLevelDetail',{
            levelRequirement:id,
        })
        .then(res => {
                this.setState({
                    vipPrivilege: res.data.evenLevelPrivilege,
                    update:1,
                    
                })
                
            
        })
    }
    loadSVGA4(url, canvasId) {
        var parser = new SVGA.Parser('#' + canvasId);
        parser.load(url, function (videoItem) {
            var player = new SVGA.Player('#' + canvasId);
            if (player === null && typeof player === 'undefined') return;
            if (videoItem === null && typeof videoItem === 'undefined') return;

            player.setVideoItem(videoItem);
            // player.setFrame(0, 0, 750, 750);
            player.setContentMode("AspectFill")
            player.startAnimation();
        })
    }

    componentDidUpdate() {
        const { evenLevelPrivileges } = this.state;

        evenLevelPrivileges.map((item, index) => {
             item.giftList && item.giftList.map((g,index1) => {
                let idResource = "show-animation_resourceUrl" + index + "gift" +index1;
            // console.log("check url: " + index + " - "+ + gift._id + " - "+ gift.resourceUrl);

            if (g.url !== null && g.url.endsWith(".svga")) {
                // $("#" + idResource).children().replaceWith("<div style=\" height: 40px !important\" id=\" " + canvasIdResource + "\"></div>");
                this.loadSVGA4(g.url, idResource);
            } else if (g.url !== null && g.url !== 'undefined' && g.url.endsWith("png")) {
                $("#" + idResource).replaceWith("<img style=\"height: 50px; width: 50px; align: center; \" src=\"" + g.url + "\"  />");
            } else {
                $("#" + idResource).replaceWith("<img style=\"height: 50px; width: 50px; text-align: center;\" src=\"" + g.url + "\" onerror=\"this.onerror=null; this.src='error_image_url'; this.alt='Error Image';\" />");
            }
             })
            
        })

    }
    render() {
        const {evenLevelPrivileges, vipPrivilege,update} = this.state;
        return (
            <>
                <ModalUpdateVipPrivilege
                    vipPrivilege={vipPrivilege}
                    update={update}
                    resetList={this.getEvenLevelPrivileges}
                >                
                </ModalUpdateVipPrivilege>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí Đặc quyền level</h1>
                        <button 
                            className="btn btn-red mb-1" 
                            data-toggle="modal" 
                            data-target="#modalUpdateVipPrivilege"
                            onClick={this.addNewVipPrivilege}
                            >+ Tạo Mới Quà level
                        </button>
                        <table className="table table-hover">
                            <thead>
                            <tr className="">
                          
                                <th className="align-middle text-center" scope="col-3"style={{width:'20%'}}>Level yêu cầu</th>
                                <th className="align-middle text-center" scope="col-3"style={{width:'20%'}}>Tên</th>  
                                <th className="align-middle text-center" scope="col-6" style={{width:'100px'}}>Ảnh</th>  
                                <th className="align-middle text-center" scope="col-3" style={{width:'20%'}}>Giá Quà </th>  
                                <th className="align-middle text-center" scope="col">Hành Động</th>
                            </tr>
                            </thead>
                            <tbody>                            {
                                evenLevelPrivileges && evenLevelPrivileges.map((item,index) => {
                                    return (
                                        <>
                                            
                                                { item.giftList && item.giftList.map((g,index1) => {
                                                    let idResource = "show-animation_resourceUrl" + index + "gift" +index1;
                                                     return(
                                                        
                                                        <tr >
                                                            {index1 === 0 && (<td className='align-middle text-center'style={{height:'50px !important'}} rowSpan={item.giftList.length}>{item.levelRequirement}</td>
                                                            )}
                                                                <td className='align-middle text-center'>{g.name}</td>
                                                                <td >
                                                                <div >
                                                    <div id={idResource} style={{ maxHeight: "50px", maxWidth: "50px", align: 'center' }}>
                                                        <div width={50} height={50} id="canvas"></div>
                                                    </div>
                                                </div>
                                                                </td><td className='align-middle text-center' >{g.buyPrice}</td>
                                                               
                                                                {index1 === 0 && (
                                                                    <td rowSpan={item.giftList.length} className='align-middle text-center'>
                                                            <button 
                                                                className="btn btn-success mr-2"
                                                                onClick={this.updateEvenPrivilege}
                                                                data-toggle="modal" 
                                                                name={item.levelRequirement}
                                                                data-target="#modalUpdateVipPrivilege"
                                                                
                                                            >
                                                                Sửa
                                                            </button>
                                                            <button 
                                                                className="btn btn-danger"
                                                                onClick={this.deleteEvenLevelPrivileges}
                                                                name={index} 
                                                                value={item.levelRequirement}                                                                
                                                            >
                                                                Xoá
                                                            </button>
                                                            </td>
                                                                )}
                                                                </tr>
                                                        )
                                                }
                                                )
                                                }    
                                        </>
                                    )
                                }
                                    
                                )
                            }

                            </tbody>
                        </table>
                    </div>
                </div>

            </>
        );
    }
}

export default EvenLevelPrivilege
