import React, { Component } from 'react';
import bindModel from '../../common/bindModel.js';
import axios from 'axios';
import { toast } from 'react-toastify';
import SVGA from "svgaplayerweb";

import '../../assets/css/giftmanager.css';
import $ from 'jquery';
import ModalSetCup from './components/ModalSetCup';
import ModalSetAvatarFrame from './components/ModalSetAvatarFrame.js';
import ModalSetDecoration from './components/ModalSetDecoration.js';

export default class ContentEventPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: 'Recording',
            models: [],
            model: {
                name: '',
                type: 'Recording',
                eventName: '',
                language: '',
                top1: {},
                top2: {},
                top3: {},
                top4: {},
                mainRule: '',
                otherRule: '',
                timeRule: '',
                timeActiveStart: this.formatDate(new Date()),
                timeActiveEnd: this.formatDate(new Date()),
                timeShowStart: this.formatDate(new Date()),
                timeShowEnd: this.formatDate(new Date()),
            },
            property: '',
            decorType: 'FRAME'
        }

        this.changeType = this.changeType.bind(this);
        this.updateState = this.updateState.bind(this);

        this.getAllContentEvent = this.getAllContentEvent.bind(this);
        this.createOrUpdate = this.createOrUpdate.bind(this);
        this.handlerCreateOrUpdate = this.handlerCreateOrUpdate.bind(this);
        this.handlerDelete = this.handlerDelete.bind(this);

        this.loadSVGA4 = this.loadSVGA4.bind(this);
    }

    componentWillMount() {
        this.getAllContentEvent(this.state.type);
    }

    getAllContentEvent(type) {
        axios.post(global.config.apiDomain + "/rest/contentEventRest/getAllContentEvent/" + type)
            .then(res => {
                if (res.data.status == "OK") {
                    let models = res.data.models;
                    this.setState({
                        models: models
                    })

                    // models.map((model, index) => {
                    //     let idResource1 = "show-animation-1" + index;
                    //     if (model.top1 != null && model.top1.frameUrl.endsWith("svga")) {
                    //         this.loadSVGA4(model.top1.frameUrl, idResource1);
                    //     } else if (model.top1.frameUrl != null) {
                    //         $("#" + idResource1).replaceWith(
                    //             '<img style="height: 50px; width: 50px; align: center; " src="' +
                    //             model.top1.frameUrl +
                    //             '"  />'
                    //         );
                    //     }

                    //     let idResource2 = "show-animation-2" + index;
                    //     if (model.top2 != null && model.top2.frameUrl.endsWith("svga")) {
                    //         this.loadSVGA4(model.top2.frameUrl, idResource2);
                    //     } else if (model.top2.frameUrl != null) {
                    //         $("#" + idResource2).replaceWith(
                    //             '<img style="height: 50px; width: 50px; align: center; " src="' +
                    //             model.top2.frameUrl +
                    //             '"  />'
                    //         );
                    //     }

                    //     let idResource3 = "show-animation-3" + index;
                    //     if (model.top3 != null && model.top3.frameUrl.endsWith("svga")) {
                    //         this.loadSVGA4(model.top3.frameUrl, idResource3);
                    //     } else if (model.top3.frameUrl != null) {
                    //         $("#" + idResource3).replaceWith(
                    //             '<img style="height: 50px; width: 50px; align: center; " src="' +
                    //             model.top3.frameUrl +
                    //             '"  />'
                    //         );

                    //     }
                    // })
                }
            })

    }

    changeType(event) {
        let type = event.target.value;
        this.setState({
            type: type,
            model: {
                name: '',
                type: type,
                eventName: '',
                language: '',
                top1: {},
                top2: {},
                top3: {},
                top4: {},
                mainRule: '',
                otherRule: '',
                timeRule: '',
                timeActiveStart: this.formatDate(new Date()),
                timeActiveEnd: this.formatDate(new Date()),
                timeShowStart: this.formatDate(new Date()),
                timeShowEnd: this.formatDate(new Date()),
            }
        })
        this.getAllContentEvent(type);
    }

    updateState(model) {
        this.setState({
            model: model
        })

        if (model) {
            let idResource1 = "update-animation1";
            if (model.top1 && model.top1.frameUrl && model.top1.frameUrl.endsWith("svga")) {
                this.loadSVGA4(model.top1.frameUrl, idResource1);
            } else if (model.top1.frameUrl != null) {
                $("#" + idResource1).replaceWith("<img style=\"height: 50px; width: 50px; align: center; \" src=\"" + model.top1.frameUrl + "\"  />");
            } else {
                $("#" + idResource1).html("");
            }

            let idResource2 = "update-animation2";
            if (model.top2 && model.top2.frameUrl && model.top2.frameUrl.endsWith("svga")) {
                this.loadSVGA4(model.top2.frameUrl, idResource2);
            } else if (model.top2.frameUrl != null) {
                $("#" + idResource2).replaceWith("<img style=\"height: 50px; width: 50px; align: center; \" src=\"" + model.top2.frameUrl + "\"  />");
            } else {
                $("#" + idResource2).html("");
            }

            let idResource3 = "update-animation3";
            if (model.top3 && model.top3.frameUrl && model.top3.frameUrl.endsWith("svga")) {
                this.loadSVGA4(model.top3.frameUrl, idResource3);
            } else if (model.top3.frameUrl != null) {
                $("#" + idResource3).replaceWith("<img style=\"height: 50px; width: 50px; align: center; \" src=\"" + model.top3.frameUrl + "\"  />");
            } else {
                $("#" + idResource3).html("");
            }
        }
    }

    loadSVGA4(url, canvasId) {
        var parser = new SVGA.Parser("#" + canvasId);
        parser.load(url, function (videoItem) {
            var player = new SVGA.Player("#" + canvasId);
            player.setVideoItem(videoItem);
            // player.setFrame(0, 0, 750, 750);z
            player.setContentMode("AspectFill");
            player.startAnimation();
        });
    }

    createOrUpdate(event) {
        let index = event.target.name;
        let model = null;
        if (index === "-1") {
            model = {
                name: '',
                type: this.state.type,
                eventName: '',
                language: '',
                top1: {},
                top2: {},
                top3: {},
                top4: {},
                mainRule: '',
                otherRule: '',
                timeRule: '',
                timeActiveStart: this.formatDate(new Date()),
                timeActiveEnd: this.formatDate(new Date()),
                timeShowStart: this.formatDate(new Date()),
                timeShowEnd: this.formatDate(new Date()),
            }
        } else {
            model = this.state.models[index];
            model.timeActiveStart = this.formatDate(new Date(model.timeActiveStart))
            model.timeActiveEnd = this.formatDate(new Date(model.timeActiveEnd))
            model.timeShowStart = this.formatDate(new Date(model.timeShowStart))
            model.timeShowEnd = this.formatDate(new Date(model.timeShowEnd))
        }

        this.setState({
            model: model
        })

        if (model) {
            let idResource1 = "update-animation1";
            if (model.top1 && model.top1.frameUrl && model.top1.frameUrl.endsWith("svga")) {
                this.loadSVGA4(model.top1.frameUrl, idResource1);
            } else if (model.top1.frameUrl != null) {
                $("#" + idResource1).replaceWith("<img style=\"height: 50px; width: 50px; align: center; \" src=\"" + model.top1.frameUrl + "\"  />");
            } else {
                $("#" + idResource1).html("");
            }

            let idResource2 = "update-animation2";
            if (model.top2 && model.top2.frameUrl && model.top2.frameUrl.endsWith("svga")) {
                this.loadSVGA4(model.top2.frameUrl, idResource2);
            } else if (model.top2.frameUrl != null) {
                $("#" + idResource2).replaceWith("<img style=\"height: 50px; width: 50px; align: center; \" src=\"" + model.top2.frameUrl + "\"  />");
            } else {
                $("#" + idResource2).html("");
            }

            let idResource3 = "update-animation3";
            if (model.top3 && model.top3.frameUrl && model.top3.frameUrl.endsWith("svga")) {
                this.loadSVGA4(model.top3.frameUrl, idResource3);
            } else if (model.top3.frameUrl != null) {
                $("#" + idResource3).replaceWith("<img style=\"height: 50px; width: 50px; align: center; \" src=\"" + model.top3.frameUrl + "\"  />");
            } else {
                $("#" + idResource3).html("");
            }
        }
    }

    handlerCreateOrUpdate() {
        let obj = { ...this.state.model };

        obj.timeShowStart = Date.parse(obj.timeShowStart);
        obj.timeActiveStart = obj.timeShowStart;
        obj.timeShowEnd = Date.parse(obj.timeShowEnd);
        obj.timeActiveEnd = obj.timeShowEnd;

        obj.top1.notificationUrl = "https://ikara-data.s3.us-west-002.backblazeb2.com/images/giftitems/systemnotification.png";
        obj.top1.notificationMessage = "Thông báo\ntoàn hệ thống";
        obj.top1.message = "Cup TOP 1\n20000 iCoin";
        obj.top2.message = "Cup TOP 2\n10000 iCoin";
        obj.top3.message = "Cup TOP 3\n5000 iCoin";

        if (obj.type == 'Recording') {
            obj.top1.frameMessage = "Khung top 1\nTrong 1 ngày";
            obj.top2.frameMessage = "Khung top 2\nTrong 1 ngày";
            obj.top3.frameMessage = "Khung top 3\nTrong 1 ngày";
        }

        console.log('handlerCreateOrUpdate');
        console.log(obj);

        axios.post(global.config.apiDomain + "/rest/contentEventRest/createOrUpdate", JSON.stringify(obj), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.data && res.data.status == "OK") {
                    toast.success(res.data.message);
                    this.getAllContentEvent(this.state.type);
                } else {
                    toast.error(res.data.message)
                }
            })
    }

    handlerDelete(index) {
        let obj = { ...this.state.models[index] };
        obj.timeShowStart = Date.parse(obj.timeShowStart);
        obj.timeActiveStart = obj.timeShowStart;
        obj.timeShowEnd = Date.parse(obj.timeShowEnd);
        obj.timeActiveEnd = obj.timeShowEnd;

        axios.post(global.config.apiDomain + "/rest/contentEventRest/delete", JSON.stringify(obj), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.data && res.data.status == "OK") {
                    toast.success(res.data.message);
                    this.getAllContentEvent(this.state.type);
                } else {
                    toast.error(res.data.message)
                }
            })
    }

    formatDate(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear().toString();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because getMonth() returns zero-based index
        const day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    render() {
        const bind = bindModel(this);
        const { type, model, property, decorType, models } = this.state;
        return (
            <>
                <ModalSetCup
                    model={model}
                    property={property}
                    update={this.updateState}
                ></ModalSetCup>
                <ModalSetAvatarFrame
                    model={model}
                    property={property}
                    update={this.updateState}
                ></ModalSetAvatarFrame>
                <ModalSetDecoration
                    model={model}
                    property={property}
                    decorType={decorType}
                    update={this.updateState}
                ></ModalSetDecoration>

                <div id="content">
                    <div className="container">
                        <div className="row mb-2">
                            <div className="col-4">
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <span className="btn btn-success">Loại CUP</span>
                                    </div>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="type"
                                        onChange={this.changeType}
                                    >
                                        <option value='Recording'>Bài thu</option>
                                        <option value='Kara'>Kara</option>
                                        <option value='Family'>Gia tộc</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <h1 className="text-center">CUP Sự Kiện <span className='text-info'>{type}</span></h1><br />
                        <div>
                            <div className="tab-content" id="nav-tabContent">
                                <div className='row'>
                                    <div className='col-md-2'>
                                        <button
                                            className="btn btn-success"
                                            name="-1"
                                            onClick={this.createOrUpdate}
                                        >Thêm Mới</button>&ensp;&ensp;
                                    </div>
                                    <div className='col-md-4'>
                                        <input
                                            className="form-control" placeholder="Nhập tên sự kiện"
                                            aria-label="Username" aria-describedby="basic-addon1"
                                            type="text"
                                            {...bind('model.eventName')}></input>
                                    </div>
                                    <div className='col-md-2'>
                                        <select
                                            className="form-control"
                                            type="text"
                                            name="app"
                                            {...bind('model.language')} >
                                            <option value="">Chọn APP</option>
                                            <option value="vi">IKARA</option>
                                            <option value="en.yokara">YOKARA</option>
                                            <option value="all">ALL</option>
                                        </select>
                                    </div>
                                    <div className='col-md-2'>
                                        <button className="btn btn-primary"
                                            onClick={this.handlerCreateOrUpdate}
                                        >{model && model.name ? 'SỬA' : 'Tạo Mới'}
                                        </button>
                                    </div>
                                </div> <br />
                                {/* <h5 className='text-danger'>HƯỚNG DẪN</h5> */}
                                <h6>Cup trao <span className='text-warning'>ngày 20 tháng 10 năm 2023</span>: Thời gian bắt đầu <span className='text-success'>10/20/2023</span> Thời gian kết thúc <span className='text-success'>10/20/2023</span></h6>
                                <h6>Cup trao <span className='text-warning'>ngày 20 và 21 tháng 10 năm 2023</span>: Thời gian bắt đầu <span className='text-success'>10/20/2023</span> Thời gian kết thúc <span className='text-success'>10/21/2023</span></h6>
                                <br />
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <h5 className='text-danger'>Thời gian bắt đầu</h5>
                                        <input
                                            className="form-control mb-2"
                                            type="date"
                                            name="timeShowStart"
                                            {...bind('model.timeShowStart')}
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                        <h5 className='text-danger'>Thời gian kết thúc</h5>
                                        <input
                                            className="form-control mb-2"
                                            type="date"
                                            name="timeShowEnd"
                                            {...bind('model.timeShowEnd')}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-2 text-danger'>Quà Top 1</div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-2'>
                                        CUP
                                    </div>
                                    {
                                        model && model.top1 ?
                                            <>
                                                <div className='col-md-2'>{model.top1.keyCup}</div>
                                                <div className='col-md-1'>
                                                    <img className="gift-image" src={model.top1.cupUrl}></img>
                                                </div>
                                            </>
                                            : <div className='col-md-3'></div>
                                    }
                                    <div className='col-md-1'>
                                        <button className="btn btn-info"
                                            onClick={() => {
                                                this.setState({
                                                    property: 'top1'
                                                })
                                            }}
                                            data-toggle="modal"
                                            data-target="#modalSetCup">Chọn</button>
                                    </div>
                                    {
                                        type == 'Recording' ?
                                            <>
                                                <div className='col-md-2'>
                                                    TRANG TRÍ
                                                </div>
                                                {
                                                    model && model.top1 && model.top1.decorationItemsType ?
                                                        <>
                                                            <div className='col-md-2'>{model.top1.decorationItemsType[0]}</div>
                                                            <div className='col-md-1'>
                                                            </div>
                                                        </>
                                                        : <div className='col-md-3'></div>
                                                }
                                                <div className='col-md-1'>
                                                    <button className="btn btn-info"
                                                        onClick={() => {
                                                            this.setState({
                                                                property: 'top1',
                                                                decorType: 'FRAME'
                                                            })
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#modalSetDecoration">Chọn</button>
                                                </div>
                                            </> : ''
                                    }


                                </div>
                                {
                                    type == 'Recording' ?
                                        <div className='row'>
                                            <div className='col-md-2'>
                                                KHUNG
                                            </div>
                                            {
                                                model && model.top1 ?
                                                    <>
                                                        <div className='col-md-2'>{model.top1.frameIdString}</div>
                                                        <div className='col-md-1'>
                                                            <div id='update-animation1' style={{ maxHeight: "50px", maxWidth: "50px" }} className="gift-image">
                                                                <div width={50} height={50} id="canvas"></div>
                                                            </div>
                                                        </div>
                                                    </> : <div className='col-md-3'></div>
                                            }
                                            <div className='col-md-1'>
                                                <button className="btn btn-info"
                                                    onClick={() => {
                                                        this.setState({
                                                            property: 'top1'
                                                        })
                                                    }}
                                                    data-toggle="modal"
                                                    data-target="#modalSetAvatarFrame">Chọn</button>
                                            </div>

                                            <div className='col-md-2'>
                                                HUY HIỆU
                                            </div>
                                            {
                                                model && model.top1 && model.top1.decorationItemsType ?
                                                    <>
                                                        <div className='col-md-2'>{model.top1.decorationItemsType[1]}</div>
                                                        <div className='col-md-1'>
                                                        </div>
                                                    </>
                                                    : <div className='col-md-3'></div>
                                            }
                                            <div className='col-md-1'>
                                                <button className="btn btn-info"
                                                    onClick={() => {
                                                        this.setState({
                                                            property: 'top1',
                                                            decorType: 'MEDAL'
                                                        })
                                                    }}
                                                    data-toggle="modal"
                                                    data-target="#modalSetDecoration">Chọn</button>
                                            </div>
                                        </div> : ''
                                }

                                <br></br>

                                <div className='row'>
                                    <div className='col-md-2 text-danger'>Quà Top 2</div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-2'>
                                        CUP
                                    </div>
                                    {
                                        model && model.top2 ?
                                            <>
                                                <div className='col-md-2'>{model.top2.keyCup}</div>
                                                <div className='col-md-1'>
                                                    <img className="gift-image" src={model.top2.cupUrl}></img>
                                                </div>
                                            </>
                                            : <div className='col-md-3'></div>
                                    }
                                    <div className='col-md-1'>
                                        <button className="btn btn-info"
                                            onClick={() => {
                                                this.setState({
                                                    property: 'top2'
                                                })
                                            }}
                                            data-toggle="modal"
                                            data-target="#modalSetCup">Chọn</button>
                                    </div>
                                </div>
                                {
                                    type == 'Recording' ?
                                        <div className='row'>
                                            <div className='col-md-2'>
                                                KHUNG
                                            </div>
                                            {
                                                model && model.top2 ?
                                                    <>
                                                        <div className='col-md-2'>{model.top2.frameIdString}</div>
                                                        <div className='col-md-1'>
                                                            <div id='update-animation2' style={{ maxHeight: "50px", maxWidth: "50px" }} className="gift-image">
                                                                <div width={50} height={50} id="canvas"></div>
                                                            </div>
                                                        </div>
                                                    </> : <div className='col-md-3'></div>
                                            }
                                            <div className='col-md-1'>
                                                <button className="btn btn-info"
                                                    onClick={() => {
                                                        this.setState({
                                                            property: 'top2'
                                                        })
                                                    }}
                                                    data-toggle="modal"
                                                    data-target="#modalSetAvatarFrame">Chọn</button>
                                            </div>
                                        </div> : ''
                                }
                                <br></br>

                                <div className='row'>
                                    <div className='col-md-2 text-danger'>Quà Top 3</div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-2'>
                                        CUP
                                    </div>
                                    {
                                        model && model.top3 ?
                                            <>
                                                <div className='col-md-2'>{model.top3.keyCup}</div>
                                                <div className='col-md-1'>
                                                    <img className="gift-image" src={model.top3.cupUrl}></img>
                                                </div>
                                            </>
                                            : <div className='col-md-3'></div>
                                    }
                                    <div className='col-md-1'>
                                        <button className="btn btn-info"
                                            onClick={() => {
                                                this.setState({
                                                    property: 'top3'
                                                })
                                            }}
                                            data-toggle="modal"
                                            data-target="#modalSetCup">Chọn</button>
                                    </div>
                                </div>
                                {
                                    type == 'Recording' ?
                                        <div className='row'>
                                            <div className='col-md-2'>
                                                KHUNG
                                            </div>
                                            {
                                                model && model.top3 ?
                                                    <>
                                                        <div className='col-md-2'>{model.top3.frameIdString}</div>
                                                        <div className='col-md-1'>
                                                            <div id='update-animation3' style={{ maxHeight: "50px", maxWidth: "50px" }} className="gift-image">
                                                                <div width={50} height={50} id="canvas"></div>
                                                            </div>
                                                        </div>
                                                    </> : <div className='col-md-3'></div>
                                            }
                                            <div className='col-md-1'>
                                                <button className="btn btn-info"
                                                    onClick={() => {
                                                        this.setState({
                                                            property: 'top3'
                                                        })
                                                    }}
                                                    data-toggle="modal"
                                                    data-target="#modalSetAvatarFrame">Chọn</button>
                                            </div>
                                        </div> : ''
                                }
                                <br /><br />

                                <h2>DANH SÁCH CUP SỰ KIỆN</h2>


                                {models && models.map((model, index) => {
                                    // let idResource1 = "show-animation-1" + index;
                                    // let idResource2 = "show-animation-2" + index;
                                    // let idResource3 = "show-animation-3" + index;
                                    return (
                                        <div key={index}>
                                            <hr />
                                            <div className='row'>
                                                <div className='col-md-9'>
                                                    <div className="row text-danger"><strong>{model.eventName}</strong></div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <button className="btn btn-danger ml-3"
                                                        onClick={() => {
                                                            if (window.confirm("Bạn có chắc chắn xóa sự kiện này")) {
                                                                this.handlerDelete(index)
                                                            }
                                                        }}
                                                    >Xóa</button>
                                                    <button className='btn btn-primary ml-3'
                                                        onClick={this.createOrUpdate}
                                                        name={index}
                                                    >Sửa
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-2'>
                                                    <b className='text-info'>Thời gian sự kiện</b>
                                                </div>
                                                <div className='col-md-9'> Từ {this.formatDate(new Date(model.timeShowStart))} Đến {this.formatDate(new Date(model.timeShowEnd))}</div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-2'></div>
                                                <div className='col-md-2 text-success'>Cup</div>
                                                <div className='col-md-2 text-success'>Khung</div>
                                                <div className='col-md-5 text-success'>Huy Hiệu</div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-2 text-success'>Top 1</div>
                                                <div className='col-md-2'>
                                                    <h6>{model && model.top1 && model.top1.keyCup}</h6>
                                                    {/* <img className="gift-image" src={model.top1.cupUrl} /> */}
                                                </div>
                                                <div className='col-md-2'>
                                                    <h6>{model && model.top1 && model.top1.frameIdString}</h6>
                                                    {/* <div id={idResource1} style={{ maxHeight: "50px", maxWidth: "50px", align: 'center' }}>
                                                        <div width={50} height={50} id="canvas"></div>
                                                    </div> */}
                                                </div>
                                                <div className='col-md-2'>
                                                    {
                                                        model.top1.decorationItemsType && model.top1.decorationItemsType.map((value, index) => (
                                                            <h6 key={index}>{value}</h6>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <br />
                                            <div className='row'>
                                                <div className='col-md-2 text-success'>Top 2</div>
                                                <div className='col-md-2'>
                                                    <h6>{model && model.top2 && model.top2.keyCup}</h6>
                                                    {/* <img className="gift-image" src={model.top2.cupUrl} /> */}
                                                </div>
                                                <div className='col-md-2'>
                                                    <h6>{model && model.top2 && model.top2.frameIdString}</h6>
                                                    {/* <div id={idResource2} style={{ maxHeight: "50px", maxWidth: "50px", align: 'center' }}>
                                                        <div width={50} height={50} id="canvas"></div>
                                                    </div> */}
                                                </div>
                                                <div className='col-md-2'>
                                                </div>
                                            </div>
                                            <br />
                                            <div className='row'>
                                                <div className='col-md-2 text-success'>Top 3</div>
                                                <div className='col-md-2'>
                                                    <h6>{model && model.top3 && model.top3.keyCup}</h6>
                                                    {/* <img className="gift-image" src={model.top3.cupUrl} /> */}
                                                </div>
                                                <div className='col-md-2'>
                                                    <h6>{model && model.top3 && model.top3.frameIdString}</h6>
                                                    {/* <div id={idResource3} style={{ maxHeight: "50px", maxWidth: "50px", align: 'center' }}>
                                                        <div width={50} height={50} id="canvas"></div>
                                                    </div> */}
                                                </div>
                                                <div className='col-md-2'>
                                                </div>
                                            </div>
                                            <br />

                                        </div>
                                    )
                                }
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}