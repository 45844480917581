import React, {Component} from 'react';
import { ga } from 'react-ga';
import { toast } from 'react-toastify';
import $ from 'jquery';
import bindModel from '../../../../../common/bindModel.js';
import ShowGameAnswers from './ShowGameAnswers.js';

export default class DetailQuestion extends Component {
    constructor(props) {
        super(props);

        let gameQuestion = JSON.parse(JSON.stringify(props.gameQuestion));
        let type = props.type;

        this.state = {
            gameQuestion: gameQuestion,
            type: type,
            loanding: false
        }

        this.uploadImage = this.uploadImage.bind(this);
        this.guid = this.guid.bind(this);
        this.saveGameQuestion = this.saveGameQuestion.bind(this);
        this.removeGameQuestion = this.removeGameQuestion.bind(this);
        this.setLoading = this.setLoading.bind(this);
    }

    componentWillReceiveProps(props) {
        let gameQuestion = JSON.parse(JSON.stringify(props.gameQuestion));
        let type = props.type;

        this.setState({
            gameQuestion: gameQuestion,
            type: type,
            loanding: false
        })
    }

    setLoading(value) {
        this.setState({
            loading: value
        })
    }

    
    uploadImage(event) {
        var target = event.target;
        
        if (target.files[0].size > 10495760 ){
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        this.setState({
            loading: true
        });

        var id = target.id;
        var {gameQuestion} = this.state;
        var bucketName = "ikara-data/game/images";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/jpeg";
        console.log(extension)
        if (extension == "png" && (id == "imageQuestion" || id == "imageAnswer")) {
            bucketName = "ikara-data/game/images";
            contentType = "image/png";
        } else if((extension == "jpeg" || extension == "jpg") && (id == "imageQuestion" || id == "imageAnswer")) {
            bucketName = "ikara-data/game/images";
            contentType = "image/jpeg";
        } else if(extension == "mp3" && id == "audioQuestion") {
            bucketName = "ikara-data/game/songs";
            contentType = "audio/mpeg";
        } else {
            if(id == "imageQuestion" || id == "imageAnswer") {
                toast.warn("Hình ảnh không đúng định dạng (png|jpeg|jpg)")
            } else if(id == "audioQuestion") {
                toast.warn("Đoạn nhạc không đúng định dạng (mp3)")
            } else {
                toast.warn("UNKNOW")
            }
            this.setState({
                loading: false
            });

            return;
        }


        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    this.setState({
                        loading: false
                    });

                    if(id == "imageQuestion") {
                        gameQuestion.imageQuestion = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                        document.getElementById("imageQuestion").value = "";
                    } else if(id == "imageAnswer")  {
                        gameQuestion.imageAnswer = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                        document.getElementById("imageAnswer").value = "";
                    } else if(id == "audioQuestion")  {
                        gameQuestion.audioQuestion = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                        document.getElementById("audioQuestion").value = "";
                    } else {
                        toast.error("ERRORRRRRRR")
                    }

                    this.setState({
                        gameQuestion: gameQuestion
                    });
                }.bind(this),error: function(jqXHR, exception) {
                    toast.error(exception);
                    this.setState({
                        loading: false
                    });
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    saveGameQuestion() {
        const {type, gameQuestion} = this.state;
        const {iGameQuestion, iGameType} = this.props;
        if(type == "CREATE") {
            this.props.addGameQuestion(gameQuestion, iGameType);
        } else {
            this.props.updateGameQuestion(gameQuestion, iGameQuestion, iGameType);
        }
    }

    removeGameQuestion() {
        const {iGameQuestion, iGameType} = this.props;
        this.props.removeGameQuestion(iGameQuestion, iGameType);
    }


    render() {
        const model = bindModel(this);
        const {gameQuestion, type, loading} = this.state;
        const {loadingRemoveGameQuestion} = this.props;
        console.log(type)
        return (
            <div>
                <div className="modal fade" id="DetailQuestion" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">
                                    {gameQuestion.id != null ? "Chi tiết câu hỏi" : "Thêm mới câu hỏi"}
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div id="link" className="row mb-2">
                                    <div className="col-3 my-auto">
                                        <label>Nội dung</label>
                                    </div>
                                    <div className="col-9">
                                        <textarea 
                                            className="form-control" 
                                            type="text" 
                                            name="cPackage.url" 
                                            placeholder="Nhập nội dung câu hỏi..."
                                            {...model('gameQuestion.content')}
                                        />
                                    </div>
                                </div>
                                <div id="link" className="row mb-2">
                                    <div className="col-3 my-auto">
                                        <label>Mô tả</label>
                                    </div>
                                    <div className="col-9">
                                        <textarea 
                                            className="form-control" 
                                            type="text" 
                                            name="cPackage.url" 
                                            placeholder="Nhập mô tả câu hỏi..."
                                            {...model('gameQuestion.description')}
                                        />
                                    </div>
                                </div>

                                <div id="link" className="row mb-2">
                                    <div className="col-3 my-auto">
                                        <label className="m-0">Hình ảnh câu hỏi</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="imageQuestion"
                                            onChange={this.uploadImage}
                                        />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="imageQuestion">Choose file image</label>
                                    </div>
                                </div>
                                {
                                    gameQuestion.imageQuestion != null && gameQuestion.imageQuestion != "" &&
                                        <div id="link" className="row mb-2">
                                            <div className="col-3"></div>
                                            <div className="col-9">
                                                <img src={gameQuestion.imageQuestion} />
                                            </div>
                                        </div>
                                }

                                <div id="link" className="row mb-2">
                                    <div className="col-3">
                                        <label className="m-0 my-auto">Hình ảnh câu trả lời</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="imageAnswer"
                                            onChange={this.uploadImage}
                                        />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="imageAnswer">Choose file image</label>
                                    </div>
                                </div>
                                {
                                    gameQuestion.imageAnswer != null && gameQuestion.imageAnswer != "" &&
                                        <div id="link" className="row mb-2">
                                            <div className="col-3"></div>
                                            <div className="col-9">
                                                <img src={gameQuestion.imageAnswer} />
                                            </div>
                                        </div>
                                }

                                <div id="link" className="row mb-2">
                                    <div className="col-3">
                                        <label className="m-0 my-auto">Đoạn nhạc câu hỏi</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="audioQuestion"
                                            onChange={this.uploadImage}
                                        />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="audioQuestion">Choose file mp3</label>
                                    </div>
                                </div>
                                {
                                    gameQuestion.audioQuestion != null && gameQuestion.audioQuestion != "" &&
                                        <div id="link" className="row mb-2">
                                            <div className="col-3"></div>
                                            <div className="col-9">
                                                <audio src={gameQuestion.audioQuestion} controls></audio>
                                            </div>
                                        </div>
                                }

                                <ShowGameAnswers 
                                    gameQuestion={gameQuestion}
                                    loading={loading}
                                    setLoading={this.setLoading}
                                >
                                </ShowGameAnswers>
                            </div>
                            <div className="modal-footer">
                                <button style={{width: "120px"}} type="button" className="btn btn-secondary" data-dismiss="modal">Hủy</button>
                                {type == "UPDATE" ?
                                    (loadingRemoveGameQuestion || loading) ?
                                    (
                                        <button style={{width: "120px"}} className="btn btn-danger" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xóa...
                                        </button>
                                    ) : 
                                    (
                                        <button style={{width: "120px"}} type="button" className="btn btn-danger" onClick={this.removeGameQuestion}>
                                        Xóa
                                        </button>
                                    )
                                 : ""}
                                
                                {(loadingRemoveGameQuestion || loading) ? 
                                    (
                                        <button style={{width: "120px"}} className="btn btn-primary" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Lưu  <i className="fa fa-refresh" aria-hidden="true"></i>
                                        </button>
                                    ) :
                                    (
                                        <button style={{width: "120px"}} type="button" className="btn btn-primary" onClick={this.saveGameQuestion}>Lưu  <i className="fa fa-refresh" aria-hidden="true"></i></button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}