import React, { Component } from 'react';
import axios from 'axios';
import { Parser, Player } from 'svga'
import SVGA from 'svgaplayerweb'
import ModalShowSVGA from './components/ModalShowSVGA';
import $ from 'jquery';
import ModalCreateOrUpdateDecorationItemType from './components/ModalCreateOrUpdateDecorationItemType';
import ModalDeleteDecorationItem from './components/ModalDeleteDecorationItemType';
import { toast } from 'react-toastify';

export default class DecorationItemType extends Component {
    constructor(props) {
        super(props);

        this.state = {
            decorationItemTypeShow: null,
            decorationItemsType: [],
            decorationItemType: null,
          }
        this.getDecorationItemType = this.getDecorationItemType.bind(this);
    }

    componentWillMount() {
        this.getDecorationItemType();
    }

    componentDidMount() {

        $("#modalCreateOrUpdateDecorationItemType").on("hidden.bs.modal", function () {
            this.setState({
                decorationItemType: null
            });
        }.bind(this));


        $("#modalShowGiftAnimation").on("hidden.bs.modal", function () {
            this.setState({
                decorationItemTypeShow: null
            });
        }.bind(this));

    }

    getDecorationItemType(){

        let url = global.config.apiDomain + "/rest/decorationItemsType/getAllItems";

        axios.post(url)
        .then(res =>{
            this.setState({
                decorationItemsType: res.data.decorationItemTypes
            })
        })
        
    }

    transferDecorationData(decorationItemsType){
        let url = global.config.apiDomain + "/rest/decorationItemsType/transferDecorationData"
        this.setState({
            loading : true
        })

        axios.post(url,decorationItemsType)
        .then(res =>{
            if(res.data.status == "OK"){
                toast.success(res.data.message)
            }else{
                toast.warning("Error: " + res.data.message)
            }
        })
    }


    async loadSVGA(id, url) {
        const parser = new Parser()
        const svga = await parser.load(url)

        const player = new Player(document.getElementById(id))
        await player.mount(svga)

        player.onStart = () => console.log('onStart')

        player.start()
    }

    loadSVGA4(url, canvasId) {
        var parser = new SVGA.Parser('#' + canvasId);
        parser.load(url, function (videoItem) {
            var player = new SVGA.Player('#' + canvasId);
            console.log("videoItem")
            console.log(canvasId)
            console.log(player)
            console.log(videoItem)
            player.setVideoItem(videoItem);
            // player.setFrame(0, 0, 750, 750);
            player.setContentMode("AspectFill")
            player.startAnimation();
        })
    }

    componentDidUpdate() {
        const { decorationItemsType } = this.state;
        decorationItemsType.map((decorationItemType, index) => {
            let idResource = "show-animation_resourceUrl" + index;
            let idNameFrameUrl = "show-animation_preview" + index;

            if (decorationItemType.resourceUrl != null && decorationItemType.resourceUrl != "" && decorationItemType.resourceUrl.endsWith("svga")) {
                // $("#" + idResource).children().replaceWith("<div style=\" height: 40px !important\" id=\" " + canvasIdResource + "\"></div>");
                this.loadSVGA4(decorationItemsType.resourceUrl, idResource);
            } else if (decorationItemType.resourceUrl != null) {
                $("#" + idResource).replaceWith("<img style=\"height: 50px; width: 50px; align: center; \" src=\"" + decorationItemType.resourceUrl + "\"  />");
            }

            if (decorationItemType.nameFrameUrl != null && decorationItemType.nameFrameUrl.endsWith("svga")) {
                // $("#" + idPreview).replaceWith("<div style=\"height: 50px; width: 50px; \" id=\" " + gift.previewImg + "\"></div>");
                this.loadSVGA4(decorationItemType.nameFrameUrl, idNameFrameUrl);
            } else if (decorationItemType.nameFrameUrl != null) {
                $("#" + idNameFrameUrl).replaceWith("<img style=\"height: 50px; width: 50px; align: center; \" src=\"" + decorationItemType.nameFrameUrl + "\"  />");
            }
        })

    }

    render(){
        const { decorationItemsType, decorationItemType } = this.state;
        return (
            <>
                <ModalShowSVGA decorationItem={this.state.decorationItemTypeShow}></ModalShowSVGA>
                <ModalCreateOrUpdateDecorationItemType decorationItemType = {decorationItemType}></ModalCreateOrUpdateDecorationItemType>
                <ModalDeleteDecorationItem decorationItemType = {decorationItemType}></ModalDeleteDecorationItem>

                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí Trang trí</h1>
                        <button className='btn btn-success mb-2'
                        data-toggle="modal"
                        data-target="#modalCreateOrUpdateDecorationItemType"
                        onClick={()=>{
                            this.setState({
                                decorationItemType: {
                                    name : '',
                                    expiry : 0,
                                    colorCode1 : '',
                                    colorCode2 : '',
                                    colorCode3 : '',
                                    colorCode4 : '',
                                    colorCode5 : '',
                                    topRightUrl : '',
                                    bottomRightUrl : '',
                                    bottomLeftUrl : '',
                                    topLeftUrl : '',
                                    type:"",
                                    thumbnailUrl:"",
                                    id:0,
                                }
                            })
                        }}
                        >Thêm Mới</button>
                        <table className="table table-hover">
                            <thead>
                                <tr className="align-middle">
                                    <th className="align-middle" scope="col">ID</th>
                                    <th className="align-middle" scope="col">Tên</th>
                                    <th className="align-middle" scope="col">Thời gian sử dụng</th>
                                    {/* <th className="align-middle" scope="col">Góc phải trên</th>
                                    <th className="align-middle" scope="col">Góc phải dưới</th>
                                    <th className="align-middle" scope="col">Góc trái trên</th>
                                    <th className="align-middle" scope="col">Góc trái dưới</th>
                                    <th className="align-middle" scope="col">Mã màu</th> */}
                                    <th className="align-middle" scope="col">Hình vật phẩm</th>
                                    <th className="align-middle" scope="col">Hình mô tả</th>
                                    <th className="align-middle" scope="col">Loại</th>
                                    <th className="align-middle" scope="col">Ngày tạo</th>
                                    <th className="align-middle" scope="col" colSpan={1}>Tùy chỉnh</th>
                                    <th className="align-middle" scope="col">Chuyển dữ liệu</th>
                                </tr>
                            </thead>
                            <tbody>
                                {decorationItemsType && decorationItemsType.map((decorationItemType, index) => {
                                    let idResource = "show-animation_resourceUrl" + index;
                                    let idNameFrameUrl = "show-animation_preview" + index;
                                    let result = (
                                        <tr key={decorationItemType._id}>
                                            <td><strong>{decorationItemType.id}</strong></td>
                                            <td><strong>{decorationItemType.name}</strong></td>
                                            <td>{decorationItemType.expiry}</td>
                                            {/* <td style={{maxWidth : "100px", height: "50px"}}><img src={decorationItemType.topRightUrl} alt="" /></td>
                                            <td style={{maxWidth : "100px", height: "50px"}}><img src={decorationItemType.bottomRightUrl} alt="" /></td>
                                            <td style={{maxWidth : "100px", height: "50px"}}><img src={decorationItemType.topLeftUrl} alt="" /></td>
                                            <td style={{maxWidth : "100px" , height: "50px"}}><img src={decorationItemType.bottomLeftUrl} alt="" /></td>
                                            <td>{decorationItemType.colorCode1}</td> */}
                                            <td style={{maxWidth : "100px", height: "50px"}}>
                                            {decorationItemType.resourceUrl != null && decorationItemType.resourceUrl.endsWith(".json") ? "Trái tim" :
                                                <img src={decorationItemType.resourceUrl} alt="" />
                                            }
                                            </td>
                                            <td style={{maxWidth : "100px", height: "50px"}}><img src={decorationItemType.thumbnailUrl} alt="" /></td>
                                            <td>{decorationItemType.type}</td>
                                            <td>{(new Date(decorationItemType.addTime)).toLocaleString('en-GB')}</td>

                                            <td>
                                                {decorationItemType.type == "MEDAL" ? "" : 
                                                <>
                                                <button
                                                    onClick={() => {
                                                        this.setState({
                                                            decorationItemType: decorationItemType,
                                                        })
                                                    }}
                                                    className="btn btn-info"
                                                    data-toggle="modal"
                                                    data-target="#modalCreateOrUpdateDecorationItemType"
                                                >Sửa</button>
                                                <button
                                                    onClick={() => {
                                                        this.setState({
                                                            decorationItemType: decorationItemType,
                                                        })
                                                    }}
                                                    className="btn btn-warning ml-sm-2"
                                                    data-toggle="modal"
                                                    data-target="#modalDeleteDecorationItem"
                                                >Xóa</button>
                                                </>}
                                            </td>
                                            <td>
                                                {decorationItemType.type == "MEDAL" ? "" : 
                                                <>
                                                <button
                                                    onClick={() => {
                                                        if(window.confirm("Bạn có chắc là muốn chuyển dữ liệu: " + decorationItemType.name + " ?")){
                                                            this.transferDecorationData(decorationItemType)
                                                        }
                                                    }}
                                                    className="btn btn-info"
                                                >Chuyển</button>
                                                </>}
                                            </td>
                                
                                        </tr>
                                    )

                                    return result;
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}