import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';

import bindModel from '../../../common/bindModel.js';

// const $ = window.$;

export default class ModalUpdateCoupleDecoration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            decorationModel: null,
            loading: false
        }

        this.uploadAnimationToS3 = this.uploadAnimationToS3.bind(this);
        this.uploadAnimationToS4 = this.uploadAnimationToS4.bind(this);
        this.createOrUpdateCoupleDecoration = this.createOrUpdateCoupleDecoration.bind(this);
        this.uploadFileJson = this.uploadFileJson.bind(this);
    }

    componentWillReceiveProps(props) {
        let decorationModel = props.decorationModel;

        this.setState({
            decorationModel: decorationModel,
        })   
    }

    
    uploadFileJson(event) {
        const { decorationModel } = this.state;
        var target = event.target;
        const namejson = target.files[0].name;
        const targetFIle = target.files[0];
        const fileReader1 = new FileReader();
        fileReader1.readAsDataURL(target.files[0]);

        fileReader1.onload =  (fe) => {
        //   const image = new JSON();
        //   image.src = fe.currentTarget.result;
        //   image.onload = function(ie){

                this.setState({
                    loading: true
                });
        
                var iconUrl = "";
                var frameUrl = "";
                var bucketName = "ikara-data/images/iconAnimationInApp";
                // var extension = target.files[0].name.split('.').pop().toLowerCase();
                var extension = namejson.split('.').pop().toLowerCase();
                var keyName = target.files[0].name;
                var contentType = "application/json";
                // if (extension == "png") contentType = "image/png";
        
                var fileReader = new FileReader();
        
                fileReader.onload = function () {
                    var fd = new FormData();
                    fd.append('file', targetFIle);
                    fd.append('bucketName', bucketName);
                    fd.append('objectKey', keyName);
                    fd.append('contentType', contentType)
                    $.ajax({
                        url: global.config.apiDomain + '/web.UploadFile',
                        data: fd,
                        processData: false,
                        contentType: false,
                        type: 'POST',
                        success: async function (data) {
                            console.log(data)
                            iconUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                            this.setState({
                                loading: false,
                                decorationModel: {
                                    ...decorationModel,
                                sweetHeartJson: iconUrl
                                }
                            });

                            $("#sweetHeartJsonUrl").html(`<a href=${iconUrl} target="blank">${iconUrl}</a>`)

                            // if(target.id == 'inputFileIconAfterClick6') {
                            //     this.setState({
                            //         inputFileIconAfterClick6: iconUrl
                            //     });
                            // }
                        }.bind(this),
                        error: function (request, status, error) {
                            toast.error("Đang xảy ra lỗi, xin hãy thử lại nha!");
                        }.bind(this),
                    });
        
                }.bind(this);
                fileReader.readAsArrayBuffer(targetFIle);
            // }.bind(this);
        }
    }

    createOrUpdateCoupleDecoration() {
        let obj = {...this.state.decorationModel};
        console.log(obj)
        if (
            obj == undefined ||
            obj._id == 0 ||
            obj.castleName === '',
            obj.castleUrl == '',
            obj.backgroundColorCode == '',
            obj.frameColorCode == '',
            obj.lightColorCode == '',
            obj.sweetHeartUrl == '',
            obj.sweetHeartJson == ''
        ) {
           return toast.error('Nhập đầy đủ thông tin');
        }

        const regexColor = /^#(?:[0-9a-fA-F]{3,4}){1,2}$/g;
        if (
            obj.backgroundColorCode == undefined ||
            !regexColor.test(obj.backgroundColorCode) 
        ) {
            return toast.error('Mã màu nền không đúng');
        }
        if (
            obj.frameColorCode == undefined ||
            !/^#(?:[0-9a-fA-F]{3,4}){1,2}$/g.test(obj.frameColorCode) 
        ) {
            return toast.error('Mã màu khung không đúng');
        }
        if (obj.lightColorCode == undefined || 
            !/^#(?:[0-9a-fA-F]{3,4}){1,2}$/g.test(obj.lightColorCode)) {
            return toast.error('Mã màu nhạt không đúng');
        }

        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/couple/createOrUpdateCoupleDecoration', obj)
            .then(res => {
                this.setState({
                    loading: false
                });

                if (res.data.status == "OK") {
                    window.$("#modalUpdateCoupleDecoration").modal("hide");
                    this.props.resetList();
                    toast.success(res.data.message);
                } else {
                    toast.warning(res.data.message);
                }
            }, error => {
                this.setState({
                    loading: false
                });
                toast.error(error);
            })
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadAnimationToS3(event) {
        let target = event.target;
        if (target.files[0].size > 10495760) {
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        // // Allowing file type
        // let allowedExtensions = /(\.svg)$/i;

        // if (!allowedExtensions.exec(target.value)) {
        //     toast.error("Chỉ upload file png");
        //     return ;
        // }

        this.setState({
            ...this.state,
            loading: true
        });

        let property = target.name;

        let decorationModel = this.state.decorationModel;
        let bucketName = "ikara-data/images/couple";
        let extension = target.files[0].name.split('.').pop().toLowerCase();
        let keyName = this.guid() + "." + extension;
        let contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        let fileReader = new FileReader();

        fileReader.onload = function () {
            let fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: async function (data) {
                    this.setState({
                        loading: false
                    });
                    this.setState({
                        decorationModel: {
                            ...decorationModel,
                            [property]: 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName
                        }
                    });
                }.bind(this),
            });
        }.bind(this);
        fileReader.readAsArrayBuffer(target.files[0]);
    }

    uploadAnimationToS4(event) {
        let target = event.target;
        if (target.files[0].size > 10495760) {
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        this.setState({
            ...this.state,
            loading: true
        });

        let property = target.name;

        let decorationModel = this.state.decorationModel;
        let bucketName = "ikara-data/images/couple";
        let extension = target.files[0].name.split('.').pop().toLowerCase();
        let keyName = this.guid() + "." + extension;
        let contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        let fileReader = new FileReader();

        fileReader.onload = function () {
            let fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    this.setState({
                        loading: false
                    });
                    this.setState({
                        decorationModel: {
                            ...decorationModel,
                            [property]: 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName
                        }
                    });
                }.bind(this),
            });
        }.bind(this);
        fileReader.readAsArrayBuffer(target.files[0]);
    }

    render() {
        const model = bindModel(this);
        const { loading, decorationModel } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalUpdateCoupleDecoration" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật Trang trí Theo Level</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>Level</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            id="_id"
                                            className="form-control mb-2"
                                            type="number"
                                            name="_id"
                                            placeholder="Nhập Level ..."
                                            {...model('decorationModel._id')} />
                                    </div>
                                </div>
                                <div id="castleName" className="row mb-2">
                                    <div className="col-3">
                                        <label>Tên lâu đài</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="castleName"
                                            placeholder="Nhập Tên lâu đài ..."
                                            {...model('decorationModel.castleName')} />
                                    </div>
                                </div>

                                <div id="castleUrl" className="row mb-2">
                                    <div className="col-3">
                                        <label>Hình Lâu đài</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            name="castleUrl"
                                            onChange={this.uploadAnimationToS4} />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="imgUrl">Choose file</label>
                                    </div>
                                </div>
                                <div id="sweetHeartUrl" className="row mb-2">
                                    <div className="col-3">
                                        <label>Trái tim</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            name="sweetHeartUrl"
                                            // accept=".svg" 
                                            onChange={this.uploadAnimationToS3} />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="imgUrl">Choose file</label>
                                    </div>
                                </div>
                                <div id="sweetHeartJson" className="row mb-2">
                                    <div className="col-3">
                                        <label>Trái tim File Json</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            name="sweetHeartJson"
                                            // accept=".svg" 
                                            onChange={this.uploadFileJson} />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="imgJson">Choose file</label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-3'>

                                    </div>
                                    <div className='col-9' id="sweetHeartJsonUrl">
                                    </div>
                                </div>
                              
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label></label>
                                    </div>
                                    <div className="col-4">
                                        {(this.props.decorationModel && decorationModel.castleUrl) ? (
                                            <>
                                                <label>Lâu đài</label>
                                                <img src={decorationModel.castleUrl} />
                                            </>
                                        ) : ""}
                                    </div>

                                    <div className="col-1">
                                    </div>

                                    <div className="col-4">
                                        {(this.props.decorationModel && decorationModel.sweetHeartUrl) ? (
                                            <>
                                                <label>Trái tim</label>
                                                <img src={decorationModel.sweetHeartUrl} />
                                            </>
                                        ) : ""}
                                    </div>
                                </div>
                          
                
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>Màu nền</label>
                                    </div>
                                    <div className="form-check col-4">
                                        <input
                                            height={40}
                                            className="form-control mb-2"
                                            type="text"
                                            name="backgroundColorCode"
                                            placeholder="Mã màu footer"
                                            {...model('decorationModel.backgroundColorCode')}
                                        />
                                    </div>
                                    <div className="col-5">
                                        {
                                            (decorationModel && decorationModel.backgroundColorCode) ? 
                                                (/^#(?:[0-9a-fA-F]{3,4}){1,2}$/g.test(decorationModel.backgroundColorCode)) ? 
                                                    (<div style={{ marginTop: '1%', marginRight: '1%', marginLeft: '1%', marginBottom: '1%', height: '75%', width: '100%', backgroundColor: decorationModel.backgroundColorCode }} />) :
                                                (<div><h5>Mã màu sai định dạng</h5></div>)
                                            : ""
                                        }
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>Màu khung</label>
                                    </div>
                                    <div className="form-check col-4">
                                        <input
                                            height={40}
                                            className="form-control mb-2"
                                            type="text"
                                            name="frameColorCode"
                                            placeholder="Mã màu footer"
                                            {...model('decorationModel.frameColorCode')}
                                        />
                                    </div>
                                    <div className="col-5">
                                        {
                                            (decorationModel && decorationModel.frameColorCode) ? 
                                                (/^#(?:[0-9a-fA-F]{3,4}){1,2}$/g.test(decorationModel.frameColorCode)) ? 
                                                    (<div style={{ marginTop: '1%', marginRight: '1%', marginLeft: '1%', marginBottom: '1%', height: '75%', width: '100%', backgroundColor: decorationModel.frameColorCode }} />) :
                                                (<div><h5>Mã màu sai định dạng</h5></div>)
                                            : ""
                                        }
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>Màu nhạt</label>
                                    </div>
                                    <div className="form-check col-4">
                                        <input
                                            height={40}
                                            className="form-control mb-2"
                                            type="text"
                                            name="lightColorCode"
                                            placeholder="Mã màu footer"
                                            {...model('decorationModel.lightColorCode')}
                                        />
                                    </div>
                                    <div className="col-5">
                                        {
                                            (decorationModel && decorationModel.lightColorCode) ? 
                                                (/^#(?:[0-9a-fA-F]{3,4}){1,2}$/g.test(decorationModel.lightColorCode)) ? 
                                                    (<div style={{ marginTop: '1%', marginRight: '1%', marginLeft: '1%', marginBottom: '1%', height: '75%', width: '100%', backgroundColor: decorationModel.lightColorCode }} />) :
                                                (<div><h5>Mã màu sai định dạng</h5></div>)
                                            : ""
                                        }
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.createOrUpdateCoupleDecoration}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}