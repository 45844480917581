import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export default class ModalReceiverGiftHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            paymentDetail: null
        }
    }

    componentWillReceiveProps(nextProps) {
        const giftPlusId = nextProps.giftPlusId;

        if (giftPlusId) {
            axios.post(global.config.apiDomain + '/rest/admin/getReceiverDetail', giftPlusId, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                console.log(res.data);
                this.setState({
                    paymentDetail: res.data
                })
            }, err => {
                toast.error(err);
            });
        }
    }

    render() {

        const { paymentDetail } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalReceiverGiftHistory" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Lịch sử chi tiết</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {paymentDetail &&
                                    (
                                        <div>
                                            <h3>Owner</h3>
                                            <ol>
                                                <li>FacebookID: {paymentDetail.user.facebookId}</li>
                                                <li>DeviceID: {paymentDetail.deviceId}</li>
                                                <li>UID: {paymentDetail.user.uid}</li>
                                                <li>Name: {paymentDetail.user.uid}</li>
                                                <li>Avatar: <img src={paymentDetail.user.profileImageLink} height={50} width={50} /></li>
                                            </ol>
                                            {paymentDetail.targetUser &&
                                                (
                                                    <>
                                                        <h3>Receiver</h3>
                                                        <ol>
                                                            <li>FacebookID: {paymentDetail.targetUser.facebookId}</li>
                                                            <li>UID: {paymentDetail.targetUser.uid}</li>
                                                            <li>Name: {paymentDetail.targetUser.name}</li>
                                                            <li>Avatar: <img src={paymentDetail.targetUser.profileImageLink} height={50} width={50} /></li>
                                                        </ol>
                                                    </>
                                                )}
                                            {paymentDetail.gift &&
                                                (
                                                    <>
                                                        <h3>Gift</h3>
                                                        <ol>
                                                            <li>ID: {paymentDetail.gift.id}</li>
                                                            <li>Name: {paymentDetail.gift.name}</li>
                                                            <li>BuyPrice: {paymentDetail.gift.buyPrice}</li>
                                                            <li>SellPrice: {paymentDetail.gift.sellPrice}</li>
                                                            <li>NoItem: {paymentDetail.gift.noItem}</li>
                                                            <li>Img: <img src={paymentDetail.gift.url} height={50} width={50} /></li>
                                                        </ol>
                                                    </>
                                                )}
                                            {paymentDetail.luckyGift &&
                                                (
                                                    <>
                                                        <h3>LuckyGift</h3>
                                                        <ol>
                                                            <li>ID: {paymentDetail.luckyGift.id}</li>
                                                            <li>Name: {paymentDetail.luckyGift.name}</li>
                                                            <li>BuyPrice: {paymentDetail.luckyGift.buyPrice}</li>
                                                        </ol>
                                                    </>
                                                )}
                                            {paymentDetail.recording &&
                                                (
                                                    <>
                                                        <h3>Recording</h3>
                                                        <ol>
                                                            <li>ID: {paymentDetail.recording._id}</li>
                                                            <li>Name: {paymentDetail.recording.yourName}</li>
                                                            <li>URL: <a href={paymentDetail.recording.onlineRecordingUrl}>{paymentDetail.recording.yourName}</a></li>
                                                        </ol>
                                                    </>
                                                )}
                                            {paymentDetail.liveRoom &&
                                                (
                                                    <>
                                                        <h3>LiveRoom</h3>
                                                        <ol>
                                                            <li>ID: {paymentDetail.liveRoom.id}</li>
                                                            <li>UID: {paymentDetail.liveRoom.uid}</li>
                                                            <li>Name: {paymentDetail.liveRoom.name}</li>
                                                            <li>Img: <img src={paymentDetail.liveRoom.thumbnail} height={50} width={50} /></li>
                                                        </ol>
                                                    </>
                                                )}
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}