import React, { Component } from 'react';
import bindModel from '../../../common/bindModel.js';
import {toast} from 'react-toastify';
import axios from 'axios';

export default class ModalTransferIcoin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            icoin: "",
            toUid: ""
        }

        this.transferIcoin = this.transferIcoin.bind(this);
    }

    transferIcoin(event) {
        if(event.key == 'Enter' || event.target.tagName == "BUTTON") {
            this.setState({
                loading: true
            });

            var json = {
                fromFacebookId: this.props.id,
                toUid: this.state.toUid,
                icoin: this.state.icoin
            }

            axios.post(global.config.apiDomain + '/rest/admin/transferIcoin', JSON.stringify(json), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.setState({
                    loading: false
                });

                if(res.data.status == "OK") {
                    window.$("#modalTransferIcoin").modal("hide");
                    toast.success(res.data.message);
                    this.props.resetAccount(); 
                } else {
                    toast.warning(res.data.message); 
                }
            }, error => {
                this.setState({
                    loading: false
                });
                toast.error(error); 
            })
        }
    }

    render() {
        const model = bindModel(this);
        // const {id} = this.state;

        return (
            <div>
                <div className="modal fade" id="modalTransferIcoin" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Chuyển iCoin</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <input
                                    onKeyUp={this.addIcoin}
                                    type="number"
                                    placeholder="Nhập icoin"
                                    className="form-control"
                                    {...model('icoin')} 
                                    onKeyPress={this.transferIcoin}/>
                                <input
                                    onKeyUp={this.addIcoin}
                                    type="number"
                                    placeholder="Nhập ID cần chuyển"
                                    className="form-control mt-1"
                                    {...model('toUid')} 
                                    onKeyPress={this.transferIcoin}/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button
                                            style={{ width: "120px" }}
                                            type="button"
                                            className="btn btn-red"
                                            onClick={this.transferIcoin}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}