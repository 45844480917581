import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';

import bindModel from '../../common/bindModel.js';
import SVGA from 'svgaplayerweb'
import { update } from 'lodash';
export default class ModalCreate extends Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
        this.state = {
            recordings: [],
            Gifts: [],
            recording : {
                id:0,
                thumbnail:'',
                addTime:'',
                status:2,
                mixedVideoUrl:'',
                recordingType:'',
                performanceType:'',
                userId:'',
                message :'',
                songId:''
                
            },
            loading: false,
            song:false,
            update: 0,
            user :null,
            url: '',
            gift: '',
            status:1,
            type:'',
            Types:[],
        }
        // this.GiftImg=this.GiftImg.bind(this)
        this.saveVipPrivilege = this.saveVipPrivilege.bind(this);
        // this.listGift = this.listGift.bind(this);
        this.uploadAnimationToS5 = this.uploadAnimationToS5.bind(this);
        // this.uploadFile = this.uploadFile.bind(this);
    }

    componentWillReceiveProps(props) {
        $("#iconPreview").children("svg").remove();
        $("#show-animation_thumbnailUrl").children().remove();
        let recording = props.recording;

        let update = props.update
        let user = props.user
        console.log(user)
        console.log(recording)
        this.setState({
            recording: recording,
            update: update,
            user:user,
            loading:false
        })
        

    }
    componentWillUnmount() {
        this.setState({
            loading:false
        })

    }

    selectDate(e){
        let value=e.target.value;
        let obj ={...this.state.recording}
        obj.addTime=value
        this.setState({
            recording: obj
        })
    }
    selectStatus(e){
        let value=e.target.value;
        let obj ={...this.state.recording}
        obj.message=value
        this.setState({
            recording: obj
        })
    }
    handleSong(e){
        let value=e.target.value;
        let obj ={...this.state.recording}
        obj.songId=value
        this.setState({
            recording: obj
        })
    }
    handleLink(e){
        let value=e.target.value;
        let obj ={...this.state.recording}
        obj.mixedVideoUrl=value
        this.setState({
            recording: obj
        })
    }
    handleRecordingType(e){
        let value=e.target.value;
        let obj ={...this.state.recording}
        obj.recordingType=value
        this.setState({
            recording: obj
        })
    }


    uploadFile(event) {
        var target = event.target;

        this.setState({
            loading: true
        });

        var id = target.id;
        var obj = {...this.state.recording}
        var bucketName = "ikara-data";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = "recordings/vip/mp3/" + this.state.user + "." + extension;
        var contentType = "audio/mp3";
        if (extension == "mp3" && obj.recordingType=="AUDIO") {
            keyName = "recordings/vip/mp3/" + this.state.user + "." + extension;
            contentType = "audio/mp3";
            // obj.recordingType = "AUDIO"
        }
        else  if(extension == "mp4" && obj.recordingType=="VIDEO") {
            keyName = "recordings/vip/mp4/" + this.state.user + "." + extension;
            contentType = "video/mp4";
            // obj.recordingType = "VIDEO"
        }
        else{
            toast.error("Vui lòng chọn đúng loại bài thu")
            this.setState({
                loading: false
            });
            return;
        }

        var self = this;
        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    
                        obj.mixedVideoUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                   

                    this.setState({
                        recording: obj,
                            loading: false,
                            song:true
                    });
                }.bind(this),error: function(jqXHR, exception) {
                    if(id == "vipVocalUrl") {
                        document.getElementById("vipVocalUrl").value = "";
                    } else if(id == "vipMixedVideoUrl"){
                        document.getElementById("vipMixedVideoUrl").value = "";
                    }
                    
                    toast.error(exception);
                    this.setState({
                        loading: false
                    });
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);
    }
    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
    loadSVGA4(url) {
        var player = new SVGA.Player('#demoCanvas4');
        var parser = new SVGA.Parser('#demoCanvas4');
        parser.load(url, function (videoItem) {
            console.log(videoItem)
            player.setVideoItem(videoItem);
            player.startAnimation();
        })
    }
    uploadAnimationToS5(event) {
        var target = event.target;
        //10495760
        if (target.files[0].size > 1049576) {
            toast.error("Kích thước file phải dưới 1mb.");
            return;
        }

        this.setState({
            loading: true
        });


        var obj = { ...this.state.recording };
        var bucketName = "ikara-data/images/avatarFarme";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";
        console.log("định dạng ảnh " + extension)
        if(extension !="png" && extension !="jpg"){
            this.setState({
                loading: false
            });
            toast.error('Vui lòng nhập đúng định dạng ảnh ')
        return;
        }

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    this.setState({
                        loading: false
                    });
                    
                    obj.thumbnail = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    // this.state.gift.thumbnailUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        recording:obj
                    });
                        console.log(obj)
                    if( obj.thumbnail.endsWith("svga")){
                        $("#show-animation_thumbnailUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas4\"></div>");
                        this.loadSVGA4(obj.thumbnail);
                    }else if (obj.thumbnail != null) {
                        $("#show-animation_thumbnailUrl").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + obj.thumbnail + "\"  />");
                    }
                }.bind(this),error: function (jqXHR, exception) {
                    toast.error(exception);
                    this.setState({
                        loading: false
                    });
                }.bind(this),
            });
        }.bind(this);
        this.setState({
            loading: false
        })
        fileReader.readAsArrayBuffer(target.files[0]);

    }   
    saveVipPrivilege = () => {

        console.log(this.state.recording)
        console.log(this.state.update)
        let obj = { ...this.state.recording }
        obj.userId = this.state.user
        const currentDate = new Date();
        const selectedDateObject = new Date(obj.addTime);
        if(selectedDateObject > currentDate){
            toast.error("Ngày phải nhỏ hơn hoặc bằng ngaỳ hiện tại "); 
            return;
        }
        if (obj.thumbnail == null || obj.thumbnail == ''
        ) {
            var json = {
                id: obj.userId
            }

            axios.post(global.config.apiDomain + '/rest/admin/getAccount', JSON.stringify(json), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                obj.thumbnail = res.data && res.data.avatar ? res.data.avatar : null;
                console.log()
                if (res.data.status == "OK") {
                    console.log("ảnh api :" + res.data.avatar)
                    obj.thumbnail = res.data.avatar
                    console.log("ảnh" + obj.thumbnail)
                    console.log(res.data)
                } else {
                    toast.error(res.data.message);
                }
            }, err => {
                this.setState({
                    loading: false,
                });
                toast.error("ERR");
            });

        }
        this.setState({
            loading: true
        });
        if (this.state.update == 1) {
            console.log("updatr")
            axios.post(global.config.apiDomain + '/rest/idol-recording/update',
                obj
            )
                .then(res => {
                   

                    if (res.data.status == "OK") {
                        this.setState({
                            loading: false,
                            update: 0,
                        });
                        if (this.fileInputRef.current) {
                            this.fileInputRef.current.value = '';
                          }
                        window.$("#modalUpdateVipPrivilege").modal("hide");
                        this.props.resetList();
                        toast.success(res.data.message);
                        console.log("thành công")
                    } else {
                        toast.warning(res.data.message);
                        console.log("thất bại")
                    }
                }, error => {
                    this.setState({
                        loading: false
                    });
                    toast.error(error);
                })
        }
        else {
            console.log("đối tượng ");
            console.log(obj);
            if (obj == null ||
                obj.addTime == null ||
                obj.mixedVideoUrl == null ||
                obj.status == null ||
                obj.performanceType == null ||
                obj.userId == null ||
                obj.thumbnail == null ||
                obj.message == null
            ) {
                this.setState({
                    loading: false,
                });
                toast.error("Vui lòng điền đầy đủ thông tin.");
                return;
            }
            // this.setState({
            //     loading: false,
            // });
            console.log("trước khi gọi api")
            console.log(obj)
            axios.post(global.config.apiDomain + '/rest/idol-recording/bot-create',
                obj
            )
                .then(res => {
                    this.setState({
                        loading: false
                    });
                    if (res.data.status == "OK") {
                        
                        if (this.fileInputRef.current) {
                            this.fileInputRef.current.value = '';
                          }
                        window.$("#modalUpdateVipPrivilege").modal("hide");
                        this.props.resetList();
                        toast.success(res.data.message);
                    } else {
                        toast.warning(res.data.message);
                    }
                }, error => {
                    this.setState({
                        loading: false
                    });
                    toast.error(error);
                })
        }
        // let obj = { ...this.state.vipPrivilege };
        // let MyGift = [...this.state.Gifts]
        // console.log([...this.state.Gifts])
        // console.log({ ...this.state.vipPrivilege })
        // this.setState({
        //     loading: true
        // });
        // if (this.state.update == 1) {
        //     console.log("updatr")
        //     axios.post(global.config.apiDomain + '/rest/eventLevelPrivilege/update', {
        //         levelRequirement: obj.levelRequirement,
        //         arrayGiftID: [...this.state.Gifts],
        //         types:[...this.state.Types],
        //         description: obj.description,
        //         thumbnai:obj.thumbnai,
        //         thumbnaiCard:obj.thumbnaiCard,
        //     })
        //         .then(res => {
        //             this.setState({
        //                 loading: false,
        //                 update: 0,
        //             });

        //             if (res.data.status == "OK") {
        //                 window.$("#modalUpdateVipPrivilege").modal("hide");
        //                 this.props.resetList();
        //                 toast.success(res.data.message);
        //                 console.log("thành công")
        //             } else {
        //                 toast.warning(res.data.message);
        //                 console.log("thất bại")
        //             }
        //         }, error => {
        //             this.setState({
        //                 loading: false
        //             });
        //             toast.error(error);
        //         })
        // }
        // else {
        //     if (obj == null ||
        //         MyGift == null ||
        //         obj.levelRequirement == null
        //     ) {
        //         toast.error("Vui lòng điền đầy đủ thông tin.");
        //         return;
        //     }
        //     this.setState({
        //         loading: false,
        //     });
        //     axios.post(global.config.apiDomain + '/rest/eventLevelPrivilege/create', {
        //         levelRequirement: obj.levelRequirement,
        //         arrayGiftID: [...this.state.Gifts],
        //         types:[...this.state.Types],
        //         thumbnai:obj.thumbnai,
        //         thumbnaiCard:obj.thumbnaiCard,
        //         description: obj.description,
        //     })
        //         .then(res => {
        //             if (res.data.status == "OK") {
        //                 window.$("#modalUpdateVipPrivilege").modal("hide");
        //                 this.props.resetList();
        //                 toast.success(res.data.message);
        //             } else {
        //                 toast.warning(res.data.message);
        //             }
        //         }, error => {
        //             this.setState({
        //                 loading: false
        //             });
        //             toast.error(error);
        //         })
        // }
    }
  
    

    render() {
        const model = bindModel(this);
        const { loading, vipPrivilege, Gifts, update ,type,Types,recording,status ,song} = this.state;

        return (
            <div>
                <div className="modal fade" id="modalUpdateVipPrivilege" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật bài thu</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {update ==0 ? 
                                <div id="levelRequirement" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ngày Đăng</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="date"
                                            name="addTime"
                                            placeholder="Chọn ngày"
                                            onChange={(e) => this.selectDate(e)}
                                            {...model('recording.addTime')}
                                        />
                                    </div>
                                </div>
                                :""}
                                <div id="thumbnailUrl" className="row mb-2">
                                        <div className="col-3">
                                            <label>ID bài thu</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                type="text"
                                                className="form-control mb-2"
                                                // className="custom-file-input"
                                                {...model('recording.mixedVideoUrl')}
                                                id="inputGroupFile05"
                                                placeholder="Nhâp Link"
                                                onChange={(e)=>this.handleLink(e)}
                                            />
                                        </div>
                                    </div>
                                    <div id="thumbnailUrl" className="row mb-2">
                                        <div className="col-3">
                                            <label>Message</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                type="text"
                                                className="form-control mb-2"
                                                // className="custom-file-input"
                                                {...model('recording.message')}
                                                id="inputGroupFile05"
                                                placeholder="Nhâp Message"
                                                onChange={(e)=>this.selectStatus(e)}
                                            />
                                        </div>
                                    </div>
                                <div id="levelRequirement" className="row mb-2">
                                    {/* <div className="col-3">
      <select  onChange={ (e)=> this.handleRecordingType(e)}  {...model('recording.recordingType')} >
                                        <option value="">--Chọn Recording Type--</option>
                                        <option value="VIDEO">VIDEO</option>
                                        <option value="AUDIO">AUDIO</option>
      </select>
                                    </div> */}
                                    {/* <div className="col-9">
                                    <select value={type} onChange={ (e)=> this.selectType(e)} {...model('recording.performanceType')}>
                                        <option value="">--performanceType--</option>
                                        <option value="SOLO">SOLO</option>
                                        <option value="ASK4DUET">ASK4DUET</option>
                                        <option value="DUET">DUET</option>
                                        </select>
                                        
                                    </div> */}
                                    <div id="thumbnailUrl" className="row mb-2 ml-2 mt-2">
                                        <div className="col-6">
                                            <label>Hình mô tả</label>
                                        </div>
                                        <div className="col-6">
                                            <input
                                            ref={this.fileInputRef}
                                                type="file"
                                                // className="custom-file-input"
                                                placeholder="Chọn File"
                                                onChange={this.uploadAnimationToS5}
                                            />
                                           
                                        </div>
                                        <input className="col" style={{outline:'none', border:'none' }} {...model('recording.thumbnail')}/>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div id="show-animation_thumbnailUrl" className="col-9" style={{ maxHeight: "300px" }}>
                                            <div width={500} height={500} id="canvas"></div>
                                        </div>
                                        
                                    </div>
                                    { recording && recording.thumbnail ?
                                        <img width={200} height={200} src={recording.thumbnail}></img> :''}
                                </div>
                            </div>



                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.saveVipPrivilege}>Xác nhận</button>
                                    )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        );
    }
}
//    "postbuild":  "rm -r /Users/quhuy/ikaragae/src/main/webapp/react-source && mkdir /Users/quhuy/ikaragae/src/main/webapp/react-source && mv -v build/* /Users/quhuy/ikaragae/src/main/webapp/react-source",


