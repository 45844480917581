import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';

import lodash from 'lodash';
import bindModel from '../../../common/bindModel.js'; 
import { toast } from 'react-toastify';

export default class ModalUpdateMinigame extends Component {
    constructor(props) {
        super(props);

        this.state = {
            minigame: null
        }

        this.uploadThumbnailQuestion = this.uploadThumbnailQuestion.bind(this);
        this.uploadThumbnailResult = this.uploadThumbnailResult.bind(this);
        this.updateMiniGame = this.updateMiniGame.bind(this);

        this.updateTimePostQuestion = this.updateTimePostQuestion.bind(this);
        this.updateTimePostResult = this.updateTimePostResult.bind(this);
    }

    componentWillReceiveProps(props) {
        let minigame = JSON.parse(JSON.stringify(props.minigame));

        this.setState({
            minigame: minigame
        })
    }


    updateMiniGame() {
        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/minigame/save', JSON.stringify(this.state.minigame), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                loading: false
            });
            if (res.data.status == "OK") {
                toast.success(res.data.message);
                window.$("#modalUpdateMinigame").modal("hide");

                this.props.resetList();
            } else {
                toast.warning(res.data.message);
            }
        }, error => {
            this.setState({
                loading: false
            });
        })
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadThumbnailQuestion(event) {

        this.setState({
            loading: true
        });

        var { minigame } = this.state;
        var target = event.target;
        var bucketName = "ikara-data/images/miniGame";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/jpeg";
        if (extension == "png") contentType = "image/png";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data)
                    this.setState({
                        loading: false
                    });

                    minigame.thumbnailQuestion = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        minigame: minigame
                    });

                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }

    uploadThumbnailResult(event) {

        this.setState({
            loading: true
        });

        var { minigame } = this.state;
        var target = event.target;
        var bucketName = "ikara-data/images/miniGame";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/jpeg";
        if (extension == "png") contentType = "image/png";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data)
                    this.setState({
                        loading: false
                    });

                    minigame.thumbnailResult = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        minigame: minigame
                    });

                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }

    updateTimePostQuestion(props) {
        let {minigame} = this.state;
        let currentTime = props.value
        if(minigame) {
            minigame.timePostQuestion = currentTime;
            this.setState({
                minigame: minigame
            });
        }
        
    }

    updateTimePostResult(props) {
        let {minigame} = this.state;
        let currentTime = props.value
        if(minigame) {
            minigame.timePostResult = currentTime;
            this.setState({
                minigame: minigame
            });
        }
        
    }

    render() {
        const { minigame } = this.state;
        const model = bindModel(this);

        return (
            <div className="modal fade" id="modalUpdateMinigame" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật Banner</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        {minigame ? (
                            <div className='modal-body'>
                                
                                <h3 className="text-center">Đăng bài</h3>
                                <div id="id" className="row mb-2">
                                    <div className="col-2">
                                        <label>ID</label>
                                    </div>
                                    <div className="col-10">
                                        <input 
                                            className="form-control mb-2" 
                                            type="text" 
                                            name="startDate"
                                            readOnly={true}
                                            {...model('minigame.id')}/>
                                    </div>
                                </div>
                                <div id="link" className="row mb-2">
                                    <div className="col-2">
                                        <label>Status câu hỏi</label>
                                    </div>
                                    <div className="col-10">
                                        <textarea
                                            className="form-control"
                                            type="text"
                                            name="url"
                                            rows={6}
                                            {...model('minigame.statusQuestion')}
                                        />
                                    </div>
                                </div>

                                <div id="link" className="row mb-2">
                                    <div className="col-2">
                                        <label>Thời gian đăng câu hỏi</label>
                                    </div>
                                    <div className="col-10">
                                        {/* <input
                                            className="form-control"
                                            type="datetime-local"
                                            name="url"
                                            {...model('minigame.timePostQuestion')}
                                        /> */}
                                        <DateTimePickerComponent 
                                            change= {this.updateTimePostQuestion}
                                            value = {new Date(minigame.timePostQuestion)}
                                        >    
                                        </DateTimePickerComponent>
                                    </div>
                                </div>
                                <div id="thumbnailQuestion" className="row mb-2">
                                    <div className="col-2">
                                        <label>Hình ảnh câu hỏi</label>
                                    </div>
                                    <div className="col-10">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="inputGroupFile03"
                                            onChange={this.uploadThumbnailQuestion} />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                                    </div>
                                </div>
                                {minigame.thumbnailQuestion != null ? (
                                    <div id="thumbnailResult" className="row mb-2">
                                        <div className="col-2">

                                        </div>
                                        <div className="col-10">
                                            <img width={500} src={minigame.thumbnailQuestion} />

                                        </div>
                                    </div>
                                ) : ""}
                                <div id="link" className="row mb-2">
                                    <div className="col-2">
                                        <label>Status kết quả</label>
                                    </div>
                                    <div className="col-10">
                                        <textarea
                                            className="form-control"
                                            type="text"
                                            name="url"
                                            rows={6}
                                            {...model('minigame.statusResult')}
                                        />
                                    </div>
                                </div>
                                <div id="link" className="row mb-2">
                                    <div className="col-2">
                                        <label>Thời gian đăng kết quả</label>
                                    </div>
                                    <div className="col-10">
                                        {/* <input
                                            className="form-control"
                                            type="datetime-local"
                                            name="url"
                                            {...model('minigame.timePostResult')}
                                        /> */}

                                        <DateTimePickerComponent 
                                            change= {this.updateTimePostResult}
                                            value = {new Date(minigame.timePostResult)}
                                        >    
                                        </DateTimePickerComponent>
                                    </div>
                                </div>
                                <div id="thumbnailResult" className="row mb-2">
                                    <div className="col-2">
                                        <label>Hình ảnh kết quả</label>
                                    </div>
                                    <div className="col-10">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="inputGroupFile03"
                                            onChange={this.uploadThumbnailResult} />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                                    </div>
                                </div>
                                {minigame != null && minigame.thumbnailResult != null ? (
                                    <div id="thumbnailResult" className="row mb-2">
                                        <div className="col-2">

                                        </div>
                                        <div className="col-10">
                                            <img width={500} src={minigame.thumbnailResult} />

                                        </div>
                                    </div>
                                ) : ""}

                                <div id="link" className="row mb-2">
                                    <div className="col-2">
                                        <label>Kết quả</label>
                                    </div>
                                    <div className="col-10">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="url"
                                            rows={4}
                                            {...model('minigame.result')}
                                        />
                                    </div>
                                </div>
                                <div id="link" className="row mb-2">
                                    <div className="col-2">
                                        <label>ID người trả lời đúng</label>
                                    </div>
                                    <div className="col-10">
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="url"
                                            {...model('minigame.userId')}
                                        />
                                    </div>
                                </div>
                                <div id="link" className="row mb-2">
                                    <div className="col-2">
                                        <label>Phần thưởng (iCoin)</label>
                                    </div>
                                    <div className="col-10">
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="url"
                                            {...model('minigame.iCoinPlus')}
                                        />
                                    </div>
                                </div>
                                <div id="link" className="row mb-2">
                                    <div className="col-2">
                                        <label>Đăng câu hỏi</label>
                                    </div>
                                    <div className="col-10">
                                        <div className="onoffswitch">
                                            <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" tabIndex="0" {...model('minigame.isPostQuestion')} />
                                            <label className="onoffswitch-label" htmlFor="myonoffswitch">
                                                <span className="onoffswitch-inner"></span>
                                                <span className="onoffswitch-switch"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div id="link" className="row mb-2">
                                    <div className="col-2">
                                        <label>Đăng kết quả</label>
                                    </div>
                                    <div className="col-10">
                                        <div className="onoffswitch">
                                            <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch2" tabIndex="0" {...model('minigame.isPostResult')} />
                                            <label className="onoffswitch-label" htmlFor="myonoffswitch2">
                                                <span className="onoffswitch-inner"></span>
                                                <span className="onoffswitch-switch"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ''}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                            {this.state.loading ?
                                (
                                    <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Xác nhận...
                                    </button>
                                ) :
                                (
                                    <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.updateMiniGame}>Xác nhận</button>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}