import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';

const $ = window.$;
export default class ModalDeleteMinigame extends Component {
    constructor(props) {
        super(props);

        this.state = {
            minigame: null,
            loading: false
        }

        this.deleteMinigame = this.deleteMinigame.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            minigame : props.minigame
        })
    }

    deleteMinigame() {
        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/minigame/delete', JSON.stringify(this.state.minigame), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                loading: false
            });
            if(res.data.status == "OK") {
                toast.success(res.data.message); 
                this.props.resetList();
            } else {
                toast.warning(res.data.message); 
            }

            $("#modalDeleteMinigame").modal("hide");

        })
    }

    render() {

        const {minigame} = this.state;

        return (
            <>
                <div className="modal fade" id="modalDeleteMinigame" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Xóa minigame</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {minigame ? (
                                <div className="modal-body">
                                <h3 className="text-center text-danger">Bạn chắc chắn muốn xóa ?</h3>
                            </div>
                            ) : ""}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ? 
                                (
                                <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Xác nhận...
                                </button>
                                ) :
                                (
                                <button 
                                    style={{width: "120px"}} 
                                    type="button" 
                                    className="btn btn-red"
                                    onClick={this.deleteMinigame}>Xóa</button>                            
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}