import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { toast } from 'react-toastify';
import bindModel from '../../common/bindModel.js';

export default class SendCupHistoryPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
    }

    this.getSendCupHistory = this.getSendCupHistory.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentWillMount() {
    let request = {
      timeStart: new Date(new Date().getTime() - 7 * 86400000),
      timeEnd: new Date()
    }
    this.getSendCupHistory(request);
  }

  handleFilter() {
    let timeStart = $("#timeStart").val();
    let timeEnd = $("#timeEnd").val();
    if (!timeStart || !timeEnd) {
      toast.error("Chọn cả 2 giá trị Bắt đầu và Kết thúc")
      return
    }
    if (new Date(timeEnd) < new Date(timeStart)) {
      toast.error("Ngày Bắt đầu trước Ngày Kết thúc")
      return
    }

    if (new Date(timeEnd) > new Date()) {
      toast.error("Ngày Kết thúc không quá Ngày hiện tại")
      return
    }

    let request = {
      timeStart: new Date(timeStart),
      timeEnd: new Date(timeEnd)
    }
    this.getSendCupHistory(request);
  }

  handleReset() {
    $("#timeStart").val(0);
    $("#timeEnd").val(0);
    
    let request = {
      timeStart: new Date(new Date().getTime() - 7 * 86400000),
      timeEnd: new Date()
    }
    this.getSendCupHistory(request);
  }

  getSendCupHistory(request) {
    axios.post(global.config.apiDomain + '/rest/cup-history/getSendCupHistory', request)
      .then(res => {
        if (res.data.status == 'OK') {
          toast.success(res.data.message)
          this.setState({
            list: res.data.list,
          })
        } else {
          toast.warning(res.data.message)
          this.setState({
          })
        }
      });
  }


  render() {
    const { list } = this.state;
    return (
      <>
        <div id="content">
          <div className="container-fluid">
            <h1 className="text-center">Lịch sử tặng CUP</h1>

            <div className='mt-2'>
              <div id="name" className="row mb-2">
                <div className='col-2'>
                  Ngày bắt đầu
                </div>
                <div className='col-2'>
                  Ngày kết thúc
                </div>
              </div>
            </div>
            <div className='mt-2 mb-2 row'>
              <div className='col-2'>
                <input
                  className="form-control mb-2"
                  type="dateTime-local"
                  id="timeStart"
                />
              </div>
              <div className='col-2'>
                <input
                  className="form-control mb-2"
                  type="dateTime-local"
                  id="timeEnd"
                />
              </div>
              <div className='col-2'>
                <button className='btn btn-success' onClick={this.handleFilter}>Lọc</button>
                <button className='btn btn-primary ml-2 mr-2' onClick={this.handleReset}>Reset</button>
              </div>
            </div>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Đã Trao Giải</th>
                  <th scope="col">Thời gian nhận</th>
                  <th scope="col">User</th>
                  <th scope="col">Ảnh user</th>
                  <th scope="col">Tên user</th>
                  <th scope="col">Chi tiết Cup</th>
                  <th width={500} scope="col">Thông tin</th>
                  <th scope="col">Điểm</th>
                </tr>
              </thead>
              <tbody>
                {list && list.map((item, index) => {
                  return (
                    <tr key={index}>
                      {/* <td>{index + 1}</td> */}
                      <td>{item.rewarded? <i class="fa-solid fa-circle-check fa-xl" style={{color: "#47d016"}}></i>: <i className="fa-solid fa-ban fa-xl" style={{color: '#df410c'}}></i>}</td>
                      <td>{new Date(item.dateTime).toLocaleString()}</td>
                      <td><strong>{item.userId}</strong></td>
                      <td>
                        <img src={item.userImage} alt={item.userName} width="125"></img>
                      </td>
                      <td>{item.userName}</td>
                      <td>{item.topRank && item.topRank.includes('Top 1')? <p class="text-danger">{item.topRank}</p> : item.topRank.includes('Top 2')? <p class="text-success">{item.topRank}</p>: <p class="text-secondary">{item.topRank}</p>}</td>
                      <td>{item.info && item.info.includes('http')? <a href={item.info}>{item.songName}</a>: <strong>{item.info}</strong>}</td>
                      <td>{item.score.toLocaleString()}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}