import React, { Component } from 'react'
import '../../../assets/css/style.css';

export default class GiftLine extends Component {
    constructor(props) {
        super(props);

        this.giveGift = this.giveGift.bind(this);
    }

    giveGift() {
        const {gift} = this.props;
        this.props.toggleModalDonate(gift);
    }


    render() {
        const {gift, giftSelected} = this.props;
        
        let giftImage = gift.url;
        let price = gift.buyPrice;
        let key = gift.key;
        let name = gift.name;
        let score = gift.score;
        let id = gift.id;

        let idGiftSelected;

        if(giftSelected) {
            idGiftSelected = giftSelected.id;
        }

        let styleImage = (id == idGiftSelected ? 'active-gift' : 'hover-gift');
        return (
            <div onClick={this.giveGift} className="col-3 text-center mt-4 ">
                <div className={styleImage}>
                    <img alt={name}  className="rounded-circle " height="100px" width="100px" src={giftImage}></img>
                </div>
                <div className="">
                    <span className="font-weight-bold rounded">{price}<img height="15px" width="15px" src={require('../../../assets/images/icoin.png')}></img></span>
                </div>
            </div>
        )
    }
}
