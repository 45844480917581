import React, { Component } from 'react';
import bindModel from '../../../common/bindModel.js';
import axios from 'axios';
import { toast } from 'react-toastify';
import SVGA from 'svgaplayerweb'
import $ from 'jquery';

export default class ModalUpdateScreen extends Component{
    constructor(props) {
        super(props);
        this.state = {
            screen: null,
            loading: false,
            noAnswer: 0
        }
        
        this.updateInfoScreen = this.updateInfoScreen.bind(this);
        this.uploadAnimationToS3 = this.uploadAnimationToS3.bind(this);
        this.guid = this.guid.bind(this);

    }

    updateInfoScreen(){

        const { screen } = this.state;

        if(screen.screenName == null || "" == screen.screenName){
            toast.warning("Vui lòng thêm tên màn hình")
        }

        // if(screen.description == null || "" == screen.description){
        //     toast.warning("Vui lòng thêm mô tả màn hình")
        // }

        if(screen.thumbnailUrl == null || "" == screen.thumbnailUrl){
            toast.warning("Vui lòng thêm ảnh mô tả màn hình")
        }
        
        let url =  global.config.apiDomain + "/rest/screens/createOrUpdateScreen"
        axios.post(url,screen)
        .then(res =>{
            if(res.data.status == "FAILED"){
                toast.warning(res.data.message)
            }  
            else if(res.data.status == "OK"){
                toast.success(res.data.message)
                window.$("#ModalUpdateScreen").modal('hide')
            } 
        })
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadAnimationToS3(event) {
        let target = event.target;
        if (target.files[0].size > 10495760) {
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        this.setState({
            ...this.state,
            loading: true
        });

        let property = target.name;

        let screen = this.state.screen;
        let bucketName = "ikara-data/images/screens";
        let extension = target.files[0].name.split('.').pop().toLowerCase();
        let keyName = this.guid() + "." + extension;
        let contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        let fileReader = new FileReader();

        fileReader.onload = function () {
            let fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    this.setState({
                        loading: false
                    });
                    this.setState({
                        screen: {
                            ...screen,
                            [property]: 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName
                        }
                    });

                    $("#previewImg").children().replaceWith(`<img src=${'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName}
                    style={{maxHeight : "300px"}} ></img>`)
                }.bind(this),
            });
        }.bind(this);
        fileReader.readAsArrayBuffer(target.files[0]);
    }

    componentWillReceiveProps(props){
        const { screen } = props;

        if(screen == null){
            let json  = {
                thumbnailUrl: ""
            }
            this.setState({
                screen: json,
                loading:false
            })

        }

        this.setState({
            screen: screen
        })
        if (screen!= null){

            if(screen.thumbnailUrl){
                $("#previewImg").children().replaceWith(`<img src=${screen.thumbnailUrl}
                style={{maxHeight : "300px"}} ></img>`)
            }

            screen && screen.deleted ? $("#deletedSelect").val(0) : $("#deletedSelect").val(1) 
            
        }
    }

    render(){
        const model = bindModel(this);
        const { screen, loading } = this.state;
        return (
            <div>
                <div className="modal fade" id="ModalUpdateScreen" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật thông tin màn hình</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                    <div id="id" className="row mb-2">
                                        <div className="col-3">
                                            <label>ID</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="text"
                                                name="order"
                                                placeholder="Nhập Id"
                                                {...model('screen.id')} />
                                        </div>
                                    </div>
                                    <div id="screenName" className="row mb-2">
                                        <div className="col-3">
                                            <label>Tên màn hình</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="text"
                                                name="order"
                                                placeholder="Nhập tên màn hình"
                                                {...model('screen.screenName')} />
                                        </div>
                                    </div>
                                    {/* <div id="description" className="row mb-2">
                                        <div className="col-3">
                                            <label>Mô tả</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="text"
                                                name="order"
                                                placeholder="Nhập mô tả"
                                                {...model('screen.description')} />
                                        </div>
                                    </div> */}
                                    <div id="thumbnailUrl" className="row mb-2">
                                        <div className="col-3">
                                            <label>Ảnh mô tả</label>
                                        </div>
                                        <div className="col-9">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            name="thumbnailUrl"
                                            onChange={this.uploadAnimationToS3}
                                        />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="miniBackgroundUrl">Choose file</label>
                                        </div>
                                    </div>
                                    <div id="previewThumbnail" className="row mb-2">
                                        <div className="col-3">
                                            
                                        </div>
                                        <div className="col-9" id="previewImg">
                                            <div></div>
                                        </div>
                                    </div>
                                    <div id="deleted" className="row mb-2">
                                        <div className="col-3">
                                            <label>Trạng thái</label>
                                        </div>
                                        <div className="col-9">
                                            <select name="deleted" id="deletedSelect" className='form-control' onChange={(e)=>{
                                                let thisValue = false;
                                                if(e.target.value == 0){
                                                    thisValue = true
                                                }
                                                this.setState({
                                                    screen: {
                                                        ...screen,
                                                        deleted: thisValue
                                                    }
                                                })
                                            }}>
                                                <option value="0">Không sử dụng</option>
                                                <option value="1">Đang sử dụng</option>
                                            </select>
                                        </div>
                                    </div>
                            </div>
                            <div className='modal-footer'>
                                    <button className='btn btn-secondary ml-2 mr-2'  data-dismiss="modal">Hủy</button>
                                    {loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.updateInfoScreen}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}